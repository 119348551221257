import type { FieldNode, InputName, Literal } from '@nartex/sfm-form-engine'
import { FuncNameEnum } from '@nartex/sfm-form-engine'
import type { MessageDescriptor } from 'react-intl'
import { defineMessage } from 'react-intl'

export enum ArgumentTypeEnum {
  variable = 'variable',
  boolean = 'boolean',
  fixedOption = 'fixedOption',
  openTextValue = 'openTextValue',
  openNumberValue = 'openNumberValue',
}

export interface ArgumentLiteral extends Literal<any> {
  meta: {
    argumentType: ArgumentTypeEnum
  }
}

export function literalHasMeta(
  literal: Literal<any>,
): literal is ArgumentLiteral {
  return 'meta' in literal
}

export interface ArgumentVariableValue extends ArgumentValue {
  type: ArgumentTypeEnum.variable
  value: FieldNode
}

interface ArgumentBooleanValue extends ArgumentValue {
  type: ArgumentTypeEnum.boolean
  value: boolean
}

export interface ArgumentOpenValue extends ArgumentValue {
  type: ArgumentTypeEnum.openTextValue | ArgumentTypeEnum.openNumberValue
  value: string | number
}

export interface ArgumentFixedValue extends ArgumentValue {
  type: ArgumentTypeEnum.fixedOption | ArgumentTypeEnum.boolean
  value: string | boolean
}

export interface ArgumentValue {
  type: ArgumentTypeEnum
  value?: boolean | string | number | FieldNode
}

interface Argument {
  options: (
    variables: FieldNode[],
    args: Record<string, ArgumentValue>,
    currentField?: FieldNode,
  ) => ArgumentValue[]
}

export interface Operator {
  name: FuncNameEnum
  displayText: MessageDescriptor
  selectText: MessageDescriptor
  arguments: Record<string, Argument>
}

export function isArgumentVariableValue(
  value: ArgumentValue,
): value is ArgumentVariableValue {
  return value.type === ArgumentTypeEnum.variable
}

export function isArgumentBooleanValue(
  value: ArgumentValue,
): value is ArgumentBooleanValue {
  return value.type === ArgumentTypeEnum.boolean
}

export function isArgumentFixedValue(
  value: ArgumentValue,
): value is ArgumentFixedValue {
  return [ArgumentTypeEnum.boolean, ArgumentTypeEnum.fixedOption].includes(
    value.type,
  )
}

export const DEFAULT_POSSIBLE_TYPE: InputName[] = [
  'Checkbox',
  'Number',
  'Text',
  'SelectOne',
  'SelectMany',
  'Date',
  'DateTime',
  'Time',
]

export function fieldToVariableValue(field: FieldNode): ArgumentVariableValue {
  return { type: ArgumentTypeEnum.variable, value: field }
}

export function getVariablesOptions(
  variables: FieldNode[],
  currentField?: FieldNode,
  possibleVariableType: InputName[] = DEFAULT_POSSIBLE_TYPE,
): ArgumentVariableValue[] {
  return variables
    .filter(
      (variable) =>
        possibleVariableType.includes(variable.type) &&
        variable.data.name !== currentField?.data?.name,
    )
    .map(fieldToVariableValue)
}

export function getOptionsForVariablesValue(
  argument: ArgumentVariableValue,
  variables: FieldNode[],
  currentField?: FieldNode,
): ArgumentValue[] {
  if (!argument.value) return []
  const { type, data } = argument.value
  let options: ArgumentValue[] = []

  if (type === 'Checkbox') {
    options = [
      { type: ArgumentTypeEnum.boolean, value: true },
      { type: ArgumentTypeEnum.boolean, value: false },
    ]
  } else if (['SelectMany', 'SelectOne'].includes(type)) {
    if (data.options) {
      options = data.options?.map((option) => {
        return { type: ArgumentTypeEnum.fixedOption, value: option }
      })
    }
  } else if (type === 'Text') {
    options = [{ type: ArgumentTypeEnum.openTextValue }]
  } else if (type === 'Number') {
    options = [{ type: ArgumentTypeEnum.openNumberValue }]
  }

  return [
    ...options,
    ...variables
      .filter(
        (variable) =>
          variable.type === type &&
          variable.data.name !== data.name &&
          variable.data.name !== currentField?.data?.name,
      )
      .map(fieldToVariableValue),
  ]
}

const isEqualToOperator: Operator = {
  name: FuncNameEnum.isEqualTo,
  displayText: defineMessage({
    id: 'operator.isEqualTo.displayText',
    description:
      'Text displaying subject & comparison variables of isEqualTo operator',
    defaultMessage: '{subject} = {comparison}',
  }),
  selectText: defineMessage({
    id: 'operator.isEqualTo.selectText',
    description: 'Name of operator isEqualTo in select component',
    defaultMessage: 'égale à',
  }),
  arguments: {
    subject: {
      options(variables, _, currentField) {
        return getVariablesOptions(variables, currentField)
      },
    },
    comparison: {
      options(variables, args, currentField) {
        const { subject } = args
        if (!args.subject) return []
        if (isArgumentBooleanValue(subject)) {
          return [
            ...variables
              .filter((variable) => variable.type === 'Checkbox')
              .map(fieldToVariableValue),
            { type: ArgumentTypeEnum.boolean, value: true },
            { type: ArgumentTypeEnum.boolean, value: false },
          ]
        } else if (isArgumentVariableValue(subject)) {
          return getOptionsForVariablesValue(subject, variables, currentField)
        }

        return [
          { type: ArgumentTypeEnum.openTextValue },
          { type: ArgumentTypeEnum.openNumberValue },
        ]
      },
    },
  },
}

const isFilledOperator: Operator = {
  name: FuncNameEnum.isFilled,
  displayText: defineMessage({
    id: 'operator.isFilled.displayText',
    description: 'Text displaying subject variable of isFilled operator',
    defaultMessage: '{subject} est renseigné',
  }),
  selectText: defineMessage({
    id: 'operator.isFilled.selectText',
    description: 'Name of operator isFilled in select component',
    defaultMessage: 'est renseigné',
  }),
  arguments: {
    subject: {
      options(variables, _, currentField) {
        return getVariablesOptions(variables, currentField, [
          ...DEFAULT_POSSIBLE_TYPE,
          'Audio',
          'Video',
          'Photo',
        ])
      },
    },
  },
}

function getNumberOptions(variables: FieldNode[], currentField?: FieldNode) {
  return getVariablesOptions(variables, currentField, ['Number'])
}

const isBetweenOperator: Operator = {
  name: FuncNameEnum.isBetween,
  displayText: defineMessage({
    id: 'operator.isBetween.displayText',
    description:
      'Text displaying subject, start & end variables of isBetween operator',
    defaultMessage: '{start} ≤ {subject} ≤ {end}',
  }),
  selectText: defineMessage({
    id: 'operator.isBetween.selectText',
    description: 'Name of operator isBetween in select component',
    defaultMessage: 'est entre',
  }),
  arguments: {
    subject: {
      options(variables, _, currentField) {
        return getNumberOptions(variables, currentField)
      },
    },
    start: {
      options(variables, _, currentField) {
        return [
          ...getNumberOptions(variables, currentField),
          { type: ArgumentTypeEnum.openNumberValue },
        ]
      },
    },
    end: {
      options(variables, _, currentField) {
        return [
          ...getNumberOptions(variables, currentField),
          { type: ArgumentTypeEnum.openNumberValue },
        ]
      },
    },
  },
}

const comparisonOperatorArguments: Record<'subject' | 'comparison', Argument> =
  {
    subject: {
      options(variables, _, currentField) {
        return getNumberOptions(variables, currentField)
      },
    },
    comparison: {
      options(variables, args, currentField) {
        const { subject } = args
        if (!args.subject) return []

        if (isArgumentVariableValue(subject)) {
          return getOptionsForVariablesValue(subject, variables, currentField)
        }

        return [{ type: ArgumentTypeEnum.openNumberValue }]
      },
    },
  }

const isGreaterThanOperator: Operator = {
  name: FuncNameEnum.isGreaterThan,
  displayText: defineMessage({
    id: 'operator.isGreaterThan.displayText',
    description:
      'Text displaying subject & comparison variables of isGreaterThan operator',
    defaultMessage: '{subject} > {comparison}',
  }),
  selectText: defineMessage({
    id: 'operator.isGreaterThan.selectText',
    description: 'Name of operator isGreaterThan in select component',
    defaultMessage: 'est supérieur à',
  }),
  arguments: comparisonOperatorArguments,
}

const isGreaterThanOrEqualToOperator: Operator = {
  name: FuncNameEnum.isGreaterThanOrEqualTo,
  displayText: defineMessage({
    id: 'operator.isGreaterThanOrEqualTo.displayText',
    description:
      'Text displaying subject & comparison variables of isGreaterThanOrEqualTo operator',
    defaultMessage: '{subject} ≥ {comparison}',
  }),
  selectText: defineMessage({
    id: 'operator.isGreaterThanOrEqualTo.selectText',
    description: 'Name of operator isGreaterThanOrEqualTo in select component',
    defaultMessage: 'est supérieur ou égale à',
  }),
  arguments: comparisonOperatorArguments,
}

const isLessThanOperator: Operator = {
  name: FuncNameEnum.isLessThan,
  displayText: defineMessage({
    id: 'operator.isLessThan.displayText',
    description:
      'Text displaying subject & comparison variables of isLessThan operator',
    defaultMessage: '{subject} < {comparison}',
  }),
  selectText: defineMessage({
    id: 'operator.isLessThan.selectText',
    description: 'Name of operator isLessThan in select component',
    defaultMessage: 'est inférieur à',
  }),
  arguments: comparisonOperatorArguments,
}

const isLessThanOrEqualToOperator: Operator = {
  name: FuncNameEnum.isLessThanOrEqualTo,
  displayText: defineMessage({
    id: 'operator.isLessThanOrEqualTo.displayText',
    description:
      'Text displaying subject & comparison variables of isLessThanEqualTo operator',
    defaultMessage: '{subject} ≤ {comparison}',
  }),
  selectText: defineMessage({
    id: 'operator.isLessThanOrEqualTo.selectText',
    description: 'Name of operator isLessThanOrEqualTo in select component',
    defaultMessage: 'est inférieur ou égale à',
  }),
  arguments: comparisonOperatorArguments,
}

export type OperatorNames = Exclude<
  FuncNameEnum,
  FuncNameEnum.getValue | FuncNameEnum.getValues
>

export const OperatorMap: Record<OperatorNames, Operator> = {
  [FuncNameEnum.isEqualTo]: isEqualToOperator,
  [FuncNameEnum.isFilled]: isFilledOperator,
  [FuncNameEnum.isBetween]: isBetweenOperator,
  [FuncNameEnum.isGreaterThan]: isGreaterThanOperator,
  [FuncNameEnum.isGreaterThanOrEqualTo]: isGreaterThanOrEqualToOperator,
  [FuncNameEnum.isLessThan]: isLessThanOperator,
  [FuncNameEnum.isLessThanOrEqualTo]: isLessThanOrEqualToOperator,
}
