import { useMemo } from 'react'
import type { UseFormReturn } from 'react-hook-form'
import type { AnyFunction, FuncNameEnum } from 'src/Interpreter/types'

import { interpret, isExpr } from '../Interpreter'

import { getWatchedProperties } from './getWatchedProperties'

function interpretFunctions(getValues: {
  (): { [x: string]: any }
  (name: string): any
  (names: string[]): { [x: string]: any }
}): Record<FuncNameEnum, AnyFunction> {
  return {
    getValues() {
      return getValues()
    },
    getValue(name: string) {
      return getValues(name)
    },
    isFilled(value: any) {
      return !!value || value === 0
    },
    isEqualTo(x: any, y: any) {
      if (x === undefined && y === undefined) return false
      const arrayX = arrayify(x)
      const arrayY = arrayify(y)
      return (
        arrayX.length === arrayY.length &&
        arrayX.every((element: any) => arrayY.includes(element))
      )
    },
    isBetween(subject: number, start: number, end: number) {
      return start <= subject && subject <= end
    },
    isGreaterThan(subject: number, comparison: number) {
      return subject > comparison
    },
    isGreaterThanOrEqualTo(subject: number, comparison: number) {
      return subject >= comparison
    },
    isLessThan(subject: number, comparison: number) {
      return subject < comparison
    },
    isLessThanOrEqualTo(subject: number, comparison: number) {
      return subject <= comparison
    },
  }
}

export function useInterpretProperty(
  property: any,
  methods: UseFormReturn<any>,
): any {
  const { getValues, watch } = methods

  const watchProp = useMemo(() => {
    return getWatchedProperties(property)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [property, watch])

  const watchedValues = watchProp ? watch(watchProp) : watch()

  return useMemo(() => {
    if (isExpr(property))
      return interpret(property, interpretFunctions(getValues))
    return property
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [property, getValues, watchedValues])
  // We add the watched properties to the dependency array to catch if they change
  // & re-interpret the property
}

function arrayify<T>(x: T) {
  if (Array.isArray(x)) return x
  return [x]
}
