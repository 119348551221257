import type { PropsWithChildren } from 'react'
import { useContext, useMemo } from 'react'
import type { DataProvider } from 'react-admin'
import { DataProviderContext } from 'react-admin'
import { toApiPlatformId } from 'src/libs/apiPlatformId'

export function DataProviderIdNormalizer(props: PropsWithChildren<{}>) {
  const { children } = props
  const dataProvider = useContext(DataProviderContext)

  const wrappedDataProvider: DataProvider = useMemo(
    () => IdNormalizerProxy(dataProvider),
    [dataProvider],
  )

  return (
    <DataProviderContext.Provider value={wrappedDataProvider}>
      {children}
    </DataProviderContext.Provider>
  )
}

export function IdNormalizerProxy(dataProvider: DataProvider): DataProvider {
  return {
    ...dataProvider,
    getOne(resource, params) {
      return dataProvider.getOne(resource, {
        ...params,
        id: toApiPlatformId(resource, params.id),
      })
    },
    getMany(resource, params) {
      return dataProvider.getMany(resource, {
        ...params,
        ids: params.ids.map((id) => toApiPlatformId(resource, id)),
      })
    },
    getManyReference(resource, params) {
      return dataProvider.getManyReference(resource, {
        ...params,
        id: toApiPlatformId(params.target, params.id),
      })
    },
    update(resource, params) {
      return dataProvider.update(resource, {
        ...params,
        id: toApiPlatformId(resource, params.id),
      })
    },
    updateMany(resource, params) {
      return dataProvider.updateMany(resource, {
        ...params,
        ids: params.ids.map((id) => toApiPlatformId(resource, id)),
      })
    },
    create(resource, params) {
      if ('id' in params.data) {
        return dataProvider.create(resource, {
          ...params,
          data: {
            ...params.data,
            id: toApiPlatformId(resource, params.data.id),
          },
        })
      }
      return dataProvider.create(resource, params)
    },
    delete(resource, params) {
      return dataProvider.delete(resource, {
        ...params,
        id: toApiPlatformId(resource, params.id),
      })
    },
    deleteMany(resource, params) {
      return dataProvider.deleteMany(resource, {
        ...params,
        ids: params.ids.map((id) => toApiPlatformId(resource, id)),
      })
    },
  }
}
