import axios from 'axios'
import type { Identifier } from 'react-admin'
import { useAuthProvider } from 'react-admin'
import { useEnv } from 'src/adapters/Env'
import { useHttpClient } from 'src/adapters/HTTPClient'

import { formatApiPlatformId } from './apiPlatformId'

export function useMinioFile() {
  const env = useEnv()
  const httpClient = useHttpClient()
  const authProvider = useAuthProvider()

  const upload = async (fileName: string, file: File, bucketId: Identifier) => {
    return httpClient
      .get(
        `/presigned_minio_put/${fileName}/${formatApiPlatformId(bucketId)}`,
        {
          baseURL: env.MINIO_URL,
        },
      )
      .then(async (response) => {
        return axios.put(response.data, file, {
          headers: { 'Content-Type': file.type },
        })
      })
  }

  const getFileUrl = (fileName: string, bucketId: Identifier) => {
    return `${env.MINIO_URL}/redirect_to_file/${fileName}/${formatApiPlatformId(
      bucketId,
    )}?token=${authProvider.getCurrentAccessToken()}`
  }

  return { upload, getFileUrl }
}
