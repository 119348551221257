import type { SfmEditProps } from 'src/AppScopes'
import { SfmEdit } from 'src/AppScopes'
import { SfmTextInput } from 'src/components/SfmInputs'
import { RoleEnum } from 'src/UserIdentity'

export function Edit(props: SfmEditProps) {
  return (
    <SfmEdit {...props} acceptedRoles={[RoleEnum.ROLE_CONFIGURATOR]}>
      <SfmTextInput source="name" label="resource.reports.name" />
    </SfmEdit>
  )
}
