import { MenuItem, ListItemIcon, ListItemText } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import EmailOutlinedIcon from '@material-ui/icons/EmailOutlined'
import logo from '@nartex/smartforest-design-tokens/graphics/svg/logoReverse.svg'
import {
  AppBar as RaAppBar,
  UserMenu,
  useNotify,
  useTranslate,
} from 'react-admin'

import type { AppBarProps, UserMenuProps } from 'react-admin'

import { useHttpClient } from 'src/adapters/HTTPClient'

import { theme } from './theme'

const useStyles = makeStyles({
  logo: {
    marginLeft: 'auto',
    maxHeight: theme.spacing(5),
  },
})

export function AppBar(props: AppBarProps) {
  const classes = useStyles()

  return (
    <RaAppBar {...props} userMenu={<CustomUserMenu />}>
      <img className={classes.logo} src={logo} alt="Smart Forest Mobility®" />
    </RaAppBar>
  )
}

function CustomUserMenu(props: UserMenuProps) {
  const notify = useNotify()
  const httpClient = useHttpClient()
  const translate = useTranslate()

  function resetPassword() {
    httpClient
      .get('/keycloak/send_password_email')
      .then(() =>
        notify(translate('notifications.success.email_sent'), 'success'),
      )
      .catch((error) => notify(`${error}`, 'warning'))
  }

  return (
    <UserMenu {...props}>
      <MenuItem>
        <ListItemIcon style={{ minWidth: '40px' }}>
          <EmailOutlinedIcon />
        </ListItemIcon>
        <ListItemText
          onClick={resetPassword}
          primary={translate('menu.reset_password')}
        />
      </MenuItem>
    </UserMenu>
  )
}
