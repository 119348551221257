import { ListItem, makeStyles, Typography, Switch } from '@material-ui/core'
import { useEffect, useState } from 'react'
import type { OnSuccess } from 'react-admin'
import {
  TextField,
  Link,
  useNotify,
  useRecordContext,
  useUpdate,
} from 'react-admin'
import { DataList } from 'src/components/DataList'
import { EditableReferenceField } from 'src/components/EditableReferenceField'
import { LabeledField } from 'src/components/LabeledField'
import { MultipleResourcePicker } from 'src/components/Picker'
import { SfmReferenceArrayField } from 'src/components/SfmFields'
import { useCreateResourceUrl } from 'src/Resources/useCreateResourceUrl'
import type {
  Contact,
  IriReference,
  User,
  Intervention,
  Group,
} from 'src/types/api'
import { ResourceEnum } from 'src/types/api/resources'
import { AddIcon } from 'src/UI/theme/icons'

export function ReportRecipients() {
  const intervention = useRecordContext<Intervention>()
  const notify = useNotify()

  const [update] = useUpdate()
  const updateRecipient = (
    values: IriReference[],
    source: 'recipientUsers' | 'recipientContacts',
    onSuccess: OnSuccess,
  ) => {
    update(
      ResourceEnum.interventions,
      intervention.id,
      {
        [source]: values,
      },
      {},
      {
        onSuccess: function (res) {
          notify('notifications.update.intervention', 'success')
          onSuccess(res.data[source])
        },
        onFailure: function (error) {
          notify(error, 'error')
        },
      },
    )
  }

  const updateSendtoOperator = (value: boolean) => {
    update(
      ResourceEnum.interventions,
      intervention.id,
      {
        sendToOperator: value,
      },
      {},
      {
        onSuccess: function () {
          notify('notifications.update.intervention', 'success')
        },
        onFailure: function (error) {
          notify(error, 'error')
        },
      },
    )
  }

  const [recipientUsersIds, setRecipientUsersIds] = useState<IriReference[]>()
  useEffect(() => {
    setRecipientUsersIds(undefined)
  }, [intervention.recipientUsers])

  const updateUsersRecipient = (values: User[]) => {
    const selectedUsersIds = values.map((user) => user.id)
    if (
      selectedUsersIds.join() !==
      (recipientUsersIds?.join() ?? intervention.recipientUsers.join())
    ) {
      updateRecipient(selectedUsersIds, 'recipientUsers', (res) => {
        setRecipientUsersIds(res)
      })
    }
  }

  const [recipientContactsIds, setRecipientContactsIds] =
    useState<IriReference[]>()
  useEffect(() => {
    setRecipientContactsIds(undefined)
  }, [intervention.recipientContacts])

  const updateContactsRecipient = (values: Contact[]) => {
    const selectedContactsIds = values.map((contact) => contact.id)
    if (
      selectedContactsIds.join() !==
      (recipientContactsIds?.join() ?? intervention.recipientContacts.join())
    ) {
      updateRecipient(selectedContactsIds, 'recipientContacts', (res) => {
        setRecipientContactsIds(res)
      })
    }
  }

  return (
    <>
      <SfmReferenceArrayField
        label="resource.interventions.recipientUsers"
        source="recipientUsers"
        reference="users"
        record={
          recipientUsersIds && {
            ...intervention,
            recipientUsers: recipientUsersIds,
          }
        }
        actions={
          <MultipleResourcePicker<User>
            Icon={<AddIcon />}
            resource={ResourceEnum.users}
            label="actions.add"
            title="actions.add_report_recipients"
            getOptionTitle={(user) => `${user.firstName} ${user.lastName}`}
            getOptionSubtitle={(user) => user.emailAddress ?? ''}
            selectedIds={intervention.recipientUsers}
            onSave={updateUsersRecipient}
            filters={{ 'exists[emailAddress]': true }}
          />
        }
      >
        <RecipientList<User>
          resource="users"
          getName={(user) => `${user.firstName} ${user.lastName}`}
        />
      </SfmReferenceArrayField>

      <LabeledField label="resource.interventions.sendToOperator">
        <Switch
          color="primary"
          defaultChecked={intervention.sendToOperator ?? false}
          onChange={(event, checked) => updateSendtoOperator(checked)}
        />
      </LabeledField>

      <EditableReferenceField<Intervention, Group>
        reference={ResourceEnum.groups}
        resource={ResourceEnum.interventions}
        source="recipientsGroup"
        label="resource.interventions.recipientsGroup"
        editOptionText={(group) => {
          if (group) return group.name
          return ''
        }}
        editLabel={(recipient) => {
          if (recipient) return 'actions.reassign'
          return 'actions.assign'
        }}
        editSuccessMessage="notifications.update.intervention"
      >
        <TextField source="name" label="resource.groups.name" />
      </EditableReferenceField>

      <SfmReferenceArrayField
        label="resource.interventions.recipientContacts"
        source="recipientContacts"
        reference="contacts"
        record={
          recipientContactsIds && {
            ...intervention,
            recipientContacts: recipientContactsIds,
          }
        }
        actions={
          <MultipleResourcePicker<Contact>
            Icon={<AddIcon />}
            resource={ResourceEnum.contacts}
            label="actions.add"
            title="actions.add_report_recipients"
            getOptionTitle={(contact) => contact.name}
            getOptionSubtitle={(contact) => contact.emailAddress ?? ''}
            selectedIds={intervention.recipientContacts}
            onSave={updateContactsRecipient}
          />
        }
      >
        <RecipientList<Contact>
          resource="contacts"
          getName={(contact) => contact.name}
        />
      </SfmReferenceArrayField>
    </>
  )
}

const useStyles = makeStyles(function (theme) {
  return {
    li: {
      display: 'flex',
      alignItems: 'baseline',
      gap: theme.spacing(1),
      paddingLeft: 0,
    },
    mail: {
      color: theme.palette.grey[700],
    },
  }
})

interface RecipientListProps<T> {
  resource: 'users' | 'contacts'
  getName: (record: T) => string
}

function RecipientList<T extends Contact | User>(props: RecipientListProps<T>) {
  const { resource, getName } = props
  const classes = useStyles()
  const resourceUrl = useCreateResourceUrl()

  return (
    <DataList<T>
      render={(record) => (
        <ListItem key={record.id} className={classes.li}>
          <Link to={resourceUrl(resource, record.id, 'show')}>
            <Typography>{getName(record)}</Typography>
          </Link>
          <Typography className={classes.mail}>
            {record.emailAddress}
          </Typography>
        </ListItem>
      )}
    />
  )
}
