import { ArrayInput, required, SimpleFormIterator, email } from 'react-admin'
import { SfmTextInput, SfmTextArea } from 'src/components/SfmInputs'

export function ContactForm() {
  return (
    <>
      <SfmTextInput
        source="name"
        label="resource.contacts.name"
        validate={required('validation.required')}
      />

      <SfmTextInput
        source="serviceName"
        label="resource.contacts.serviceName"
      />

      <SfmTextInput
        source="emailAddress"
        label="resource.contacts.email"
        type="email"
        validate={[
          required('validation.required'),
          email('error.invalid_email'),
        ]}
      />

      <ArrayInput source="phoneNumbers" label="resource.contacts.phoneNumbers">
        <SimpleFormIterator>
          <SfmTextInput source="number" label="resource.contacts.phoneNumber" />
        </SimpleFormIterator>
      </ArrayInput>

      <SfmTextInput source="street" label="resource.contacts.street" />

      <SfmTextInput source="zipCode" label="resource.contacts.zipCode" />

      <SfmTextInput source="city" label="resource.contacts.city" />

      <SfmTextInput source="country" label="resource.contacts.country" />

      <SfmTextArea source="notes" label="resource.contacts.notes" />
    </>
  )
}
