import {
  TextField,
  Typography,
  FormControlLabel,
  Switch,
  Button,
} from '@material-ui/core'
import type { FieldNode } from '@nartex/sfm-form-engine'
import TrashIcon from '@nartex/smartforest-design-tokens/graphics/react/BxBxsTrash'
import type { ProfunctorState } from '@staltz/use-profunctor-state'
import classNames from 'classnames'
import type { ChangeEvent } from 'react'
import { useMemo } from 'react'
import type { Blueprint } from 'src/ComponentsLibrary/types'

import { useTranslate } from '../adapters/I18nProvider'
import { Separator } from '../components/Separator'
import { definitions } from '../ComponentsLibrary/definitions'
import { features } from '../config'
import { slugify, displayFieldName } from '../libs/fieldNamesUtils'
import { RuleEditor } from '../RuleEditor'
import { ErrorCodeEnum, useErrors } from '../Validation/Errors'

import { DefaultValueInput } from './DefaultValueInput'

import { EmptyMessage } from './EmptyMessage'
import { ErrorMessage } from './ErrorMessage'
import { ImagePicker } from './ImagePicker'
import { QualityPicker } from './QualityPicker'
import { useStyles } from './styles'

interface Props {
  className?: string
  store: ProfunctorState<FieldNode | undefined>
}

export function ComponentEditor(props: Props) {
  const { className, store } = props
  const type = store.state?.type
  const id = store.state?.data['@id']
  const fieldPropsStore = store.promap<FieldNode['data'] | undefined>({
    get(field) {
      return field?.data
    },
    set(newVal, field) {
      if (!newVal || !field) return field
      if (newVal === field.data) return field
      return {
        ...field,
        data: newVal,
      }
    },
  })
  const { state: fieldProps, setState: setFieldProps } = fieldPropsStore

  const styles = useStyles()
  const __ = useTranslate()

  const errors = useErrors()
  const fieldError = id && errors[id]
  const hasDuplicatedName = fieldError === ErrorCodeEnum.DuplicatedName

  const hasNameError = fieldError === ErrorCodeEnum.InvalidName

  const definition = useMemo(() => {
    if (type) {
      return definitions[type]
    }
  }, [type])
  const bluePrint = definition?.props
  const bind = useBind(fieldPropsStore)

  if (!type || !fieldProps) {
    return (
      <section className={classNames(styles.mainContainer, className)}>
        <EmptyMessage />
      </section>
    )
  }

  if (!definition) {
    return (
      <section className={classNames(styles.mainContainer, className)}>
        <ErrorMessage />
      </section>
    )
  }

  const { min, max } = fieldProps

  const hasMinMaxError =
    (Boolean(max) || max === 0) &&
    (Boolean(min) || min === 0) &&
    (['Date', 'Datetime'].includes(type)
      ? new Date(min as string) > new Date(max as string)
      : min! > max!)

  const dateMinMaxProps = ['Date', 'DateTime'].includes(type)
    ? {
        type: 'date',
        InputLabelProps: {
          shrink: true,
        },
      }
    : {}

  const setOptions = (options: string[]) =>
    setFieldProps((prev) => {
      if (!prev) return prev
      return setProp(prev, 'options', options, {
        equalityFunc(a, b) {
          return a?.join('\n') === b?.join('\n')
        },
      })
    })

  return (
    <section className={classNames(styles.mainContainer, className)}>
      <header className={styles.header}>
        <div className={styles.headerContent}>
          <Typography variant="caption">
            {__({
              id: 'ComponentEditor.selectedElement.title',
              defaultMessage: 'Élément sélectionné',
              description: `Title of the editor when a component is selected`,
            })}
          </Typography>
          <Typography variant="h2">{fieldProps.label}</Typography>
          {bluePrint?.name && (
            <Typography variant="subtitle2">
              {displayFieldName(fieldProps.name)} ({__(definition.displayName)})
            </Typography>
          )}
        </div>
        <Button
          className={styles.deleteButton}
          variant="text"
          color="secondary"
          onClick={() => store.setState(() => undefined)} // TODO display a toast
        >
          <TrashIcon />
        </Button>
      </header>
      <Separator />
      {(bluePrint?.label || bluePrint?.longLabel) && (
        <TextField
          className={styles.input}
          label={
            bluePrint?.longLabel
              ? __({
                  id: 'ComponentEditor.longLabel.fieldTitle',
                  description: "Component's longLabel field title",
                  defaultMessage: "Texte de l'élément",
                })
              : __({
                  id: 'ComponentEditor.label.fieldTitle',
                  description: "Component's label field title",
                  defaultMessage: "Titre de l'élément",
                })
          }
          value={fieldProps.label}
          multiline={bluePrint?.longLabel}
          minRows={4}
          onChange={(event) => {
            const { value } = event.target
            setFieldProps((prev) => {
              if (!prev) return prev
              const newProps = setProp(prev, 'label', value)

              if (bluePrint?.name) {
                const nameAndLabelAreTheSame = slugify(prev.label) === prev.name
                if (nameAndLabelAreTheSame) {
                  return setProp(newProps, 'name', slugify(value))
                }
              }

              return newProps
            })
          }}
        />
      )}
      {bluePrint?.name && (
        <TextField
          className={styles.input}
          label={__({
            id: 'ComponentEditor.name.fieldTitle',
            description: "Component's name field title",
            defaultMessage: "Nom de l'élement",
          })}
          error={hasDuplicatedName || hasNameError}
          helperText={
            hasDuplicatedName
              ? __({
                  id: 'ComponentEditor.error.nameDuplicate',
                  defaultMessage: 'Ce nom est déjà utilisé.',
                  description: `Message when a name is already used in another field.`,
                })
              : hasNameError &&
                __({
                  id: 'ComponentEditor.error.nameStartChar',
                  defaultMessage: 'Le nom doit commencer par une lettre',
                  description:
                    "Message when a name doesn't start with a letter",
                })
          }
          value={fieldProps.name}
          onChange={(event) => {
            setFieldProps((data) => {
              if (!data) return
              return setProp(data, 'name', slugify(event.target.value))
            })
          }}
        />
      )}

      {bluePrint?.media && (
        <ImagePicker
          value={fieldProps.media}
          onChange={(newValue) => {
            setFieldProps((data) => {
              if (!data) return
              return setProp(data, 'media', newValue)
            })
          }}
        />
      )}

      {bluePrint?.quality && (
        <>
          <Separator />
          <QualityPicker
            type={type as 'Video' | 'Photo'}
            {...bind('quality')}
          />
        </>
      )}

      {bluePrint?.multiline && (
        <>
          <Separator />
          <FormControlLabel
            label={__({
              id: 'ComponentEditor.multiline.fieldTitle',
              description: 'Is component multiline (usually for texts) ?',
              defaultMessage: "Permettre l'écriture sur plusieurs lignes",
            })}
            control={
              <Switch
                checked={Boolean(fieldProps.multiline)}
                onChange={(event) => {
                  setFieldProps((prev) => {
                    if (!prev) return prev
                    return setProp(prev, 'multiline', event.target.checked)
                  })
                }}
              />
            }
          />
        </>
      )}

      {(bluePrint?.decimalCount ||
        bluePrint?.min ||
        bluePrint?.max ||
        bluePrint?.hasDefaultValue) && <Separator />}

      {bluePrint?.hasDefaultValue && (
        <FormControlLabel
          label={__({
            id: 'ComponentEditor.hasDefaultValue.fieldTitle',
            description: 'Does the component have a default value ?',
            defaultMessage: 'Afficher une valeur par défaut',
          })}
          control={
            <Switch
              checked={Boolean(fieldProps.hasDefaultValue)}
              onChange={(event) => {
                setFieldProps((prev) => {
                  if (!prev) return prev
                  return setProp(prev, 'hasDefaultValue', event.target.checked)
                })
              }}
            />
          }
        />
      )}

      {bluePrint?.min && (
        <TextField
          className={styles.input}
          type="number"
          label={__({
            id: 'ComponentEditor.min.fieldTitle',
            description: "Component's min value field's title",
            defaultMessage: 'Valeur minimum',
          })}
          error={hasMinMaxError}
          {...bind('min')}
          {...dateMinMaxProps}
        />
      )}

      {bluePrint?.max && (
        <TextField
          className={styles.input}
          type="number"
          label={__({
            id: 'ComponentEditor.max.fieldTitle',
            description: "Component's max value field's title",
            defaultMessage: 'Valeur maximum',
          })}
          error={hasMinMaxError}
          {...bind('max')}
          {...dateMinMaxProps}
        />
      )}

      {bluePrint?.decimalCount && (
        <TextField
          className={styles.input}
          type="number"
          label={__({
            id: 'ComponentEditor.decimalCount.fieldTitle',
            description: "Component's decimal count value field's title",
            defaultMessage: 'Nombre de décimales',
          })}
          {...bind('decimalCount')}
        />
      )}

      {bluePrint?.options && (
        <>
          <Separator />
          <TextField
            className={styles.input}
            multiline
            minRows={3}
            maxRows={15}
            label={__({
              id: 'ComponentEditor.options.fieldTitle',
              description: "Component's options field's title",
              defaultMessage: 'Options',
            })}
            placeholder={__({
              id: 'ComponentEditor.options.fieldPlaceholder',
              description: "Component's step value field's help message",
              defaultMessage: 'Renseignez une valeur par ligne',
            })}
            value={fieldProps.options?.join('\n') ?? ''}
            onChange={(event) => {
              setOptions(event.target.value.split('\n'))
            }}
            onBlur={(event) => {
              setOptions(
                event.target.value
                  .split('\n')
                  .map((str) => str.trim())
                  .filter(Boolean),
              )
            }}
          />
        </>
      )}

      {bluePrint?.defaultValue && (
        <>
          <Separator />
          <DefaultValueInput type={type} fieldPropsStore={fieldPropsStore} />
        </>
      )}

      {(bluePrint?.hidden || bluePrint?.required) && (
        <>
          <Separator />
          <RuleEditorFeatureFlag
            bluePrint={bluePrint}
            store={store}
            fieldPropsStore={fieldPropsStore}
          />
        </>
      )}
    </section>
  )
}

function useBind(store: ProfunctorState<FieldNode['data'] | undefined>) {
  const { state, setState } = store
  return (key: keyof FieldNode['data']) => {
    return {
      value: state?.[key] ?? '', // react don't like undefined values, it's considering it an uncontrolled input
      checked: Boolean(state?.[key]),
      onChange(event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) {
        const { value } = event.target
        setState((prev) => {
          if (!prev) return prev
          return setProp(prev, key, value)
        })
      },
    }
  }
}

function setProp<T extends Record<string, any>, U extends keyof T>(
  state: T,
  prop: U,
  value: T[U],
  opts?: { equalityFunc: (a: T[U], b: T[U]) => any },
) {
  const equalityFunc = opts?.equalityFunc ?? ((a, b) => a === b)
  const prev = state[prop]
  if (equalityFunc(prev as any, value)) return state
  return {
    ...state,
    [prop]: value,
  }
}

interface RuleEditorFeatureFlagProps {
  bluePrint: Blueprint['props']
  store: ProfunctorState<FieldNode | undefined>
  fieldPropsStore: ProfunctorState<FieldNode['data'] | undefined>
}

export function RuleEditorFeatureFlag(props: RuleEditorFeatureFlagProps) {
  const { bluePrint, store, fieldPropsStore } = props

  const { state: fieldProps, setState: setFieldProps } = fieldPropsStore

  const __ = useTranslate()
  const styles = useStyles()
  if (features.enableRulesEditor) {
    return (
      <>
        <Typography variant="h3" className={styles.dynamicRules}>
          {__({
            id: 'ComponentEditor.ruleEditor.title',
            description: "Component's rule editor title",
            defaultMessage: 'Règles dynamiques',
          })}
        </Typography>

        {bluePrint?.hidden && (
          <RuleEditor fieldStore={store} property="hidden" />
        )}

        {bluePrint?.required && (
          <RuleEditor fieldStore={store} property="required" />
        )}
      </>
    )
  }

  if (!fieldProps) return null

  return (
    <>
      {bluePrint?.required && (
        <FormControlLabel
          label={__({
            id: 'ComponentEditor.required.fieldTitle',
            description: "Component's default visibility state",
            defaultMessage: "Cet élément est requis s'il est affiché",
          })}
          control={
            <Switch
              checked={Boolean(fieldProps.required)}
              onChange={(event) => {
                setFieldProps((prev) => {
                  if (!prev) return prev
                  return setProp(prev, 'required', event.target.checked)
                })
              }}
            />
          }
        />
      )}

      {bluePrint?.hidden && (
        <FormControlLabel
          label={__({
            id: 'ComponentEditor.hidden.fieldTitle',
            description: "Component's default visibility state",
            defaultMessage: 'Cet élément sera visible par défaut',
          })}
          control={
            <Switch
              checked={!fieldProps.hidden}
              onChange={(event) => {
                setFieldProps((prev) => {
                  if (!prev) return prev
                  return setProp(prev, 'hidden', !event.target.checked)
                })
              }}
            />
          }
        />
      )}
    </>
  )
}
