import { IonImg } from '@ionic/react'
import AddPhotoIcon from '@nartex/smartforest-design-tokens/graphics/react/BxBxsCameraPlus'
import PhotoIcon from '@nartex/smartforest-design-tokens/graphics/react/BxBxsPhotoAlbum'

import type { BaseMediaInputProps } from '../types'

import { MediaInput } from './MediaInput'

export function PhotoInput(props: BaseMediaInputProps) {
  return (
    <MediaInput
      {...props}
      mediaType="Image"
      Icon={PhotoIcon}
      AddIcon={AddPhotoIcon}
      renderMedia={(src) => <IonImg src={src} />}
    />
  )
}
