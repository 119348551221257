import { Button } from '@material-ui/core'
import { isExpr } from '@nartex/sfm-form-engine'
import type { FieldNode, Eval, Rule } from '@nartex/sfm-form-engine'
import type { ProfunctorState } from '@staltz/use-profunctor-state'

import { useTranslate } from '../adapters/I18nProvider'

import { ConditionsListEditor } from './ConditionsEditor'
import { CurrentFieldContext } from './CurrentFieldContext'
import {
  HiddenPropertyToggle,
  RequiredPropertyToggle,
} from './PropertyValueToggle'
import { useEditorStyle } from './useEditorStyle'

interface Props {
  fieldStore: ProfunctorState<FieldNode | undefined>
  property: 'hidden' | 'required'
}

export function RuleEditor(props: Props) {
  const { fieldStore, property } = props
  const __ = useTranslate()

  const propertyStore = fieldStore.promap<boolean | Rule | Eval | undefined>(
    {
      get(field) {
        return field?.data[property]
      },
      set(newVal, field) {
        if (!field) return field
        return {
          ...field,
          data: {
            ...field!.data,
            [property]: newVal,
          },
        }
      },
    },
    [fieldStore.state], // Force re-calculation on fieldNode change
  )

  if (!fieldStore.state) return null
  const { state: propertyValue, setState } = propertyStore

  function setProperty(value: boolean | Rule | Eval) {
    setState(() => value)
  }

  if (isExpr(propertyValue)) {
    if (propertyValue.type !== 'rule') {
      throw Error(
        `Unrecognized expression type : ${JSON.stringify(propertyValue.type)}`,
      )
    }
    return (
      <CurrentFieldContext.Provider value={fieldStore.state}>
        <RuleBody
          propertyStore={propertyStore}
          rule={propertyValue}
          property={property}
          setProperty={setProperty}
        />
      </CurrentFieldContext.Provider>
    )
  }

  const PropertyToggle = getPropertyToggleComponent(property)
  return (
    <>
      <span>
        {__(
          {
            id: 'RuleEditor.staticValue.text',
            defaultMessage: 'Cet élément {value}',
            description: 'Text next to the static value of property',
          },
          {
            value: (
              <PropertyToggle
                value={propertyValue ?? false}
                onChange={setProperty}
              />
            ),
          },
        )}
      </span>
      <AddCondition
        onClick={() =>
          setProperty({
            $$isExpr: true,
            type: 'rule',
            cases: [{ condition: undefined, then: false }],
            default: propertyValue ?? false,
          })
        }
      />
    </>
  )
}

interface RuleProps {
  propertyStore: ProfunctorState<boolean | Rule | Eval | undefined>
  rule: Rule
  property: 'hidden' | 'required'
  setProperty: (value: Rule) => void
}
function RuleBody(props: RuleProps) {
  const { rule, propertyStore, property, setProperty } = props
  const buttonStyle = useEditorStyle()
  const __ = useTranslate()
  const PropertyToggle = getPropertyToggleComponent(property)

  return (
    <div className={buttonStyle.wrapper}>
      <ConditionsListEditor
        PropertyToggle={PropertyToggle}
        propertyStore={propertyStore}
      />
      <span className={buttonStyle.case}>
        {__(
          {
            id: 'RuleEditor.defaultValue.text',
            defaultMessage: 'et sinon {value}',
            description: 'Text next to the static value of property',
          },
          {
            value: (
              <PropertyToggle
                onChange={(value) => {
                  setProperty({ ...rule, default: value })
                }}
                value={rule.default ?? false}
              />
            ),
          },
        )}
      </span>

      <AddCondition
        onClick={() =>
          setProperty({
            ...rule,
            cases: [...rule.cases, { condition: undefined, then: false }],
          })
        }
      />
    </div>
  )
}

interface addConditionProps {
  onClick: () => void
}

function AddCondition(props: addConditionProps) {
  const { onClick } = props
  const buttonStyle = useEditorStyle()
  const __ = useTranslate()
  return (
    <Button size="small" className={buttonStyle.addRule} onClick={onClick}>
      <span className={buttonStyle.addButtonIcon}>+ </span>
      {__({
        id: 'RuleEditor.button.addRule',
        defaultMessage: 'Ajouter une condition',
        description: 'Label of button to add a condition',
      })}
    </Button>
  )
}

function getPropertyToggleComponent(property: 'hidden' | 'required') {
  if (property === 'hidden') {
    return HiddenPropertyToggle
  }
  if (property === 'required') {
    return RequiredPropertyToggle
  }
  throw Error(`Unrecognized property : ${JSON.stringify(property)}`)
}
