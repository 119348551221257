import type { FC } from 'react'
import { useMemo } from 'react'
import { IntlProvider } from 'react-intl'
import usePromise from 'react-use-promise'

import defaultMessages from '../../locales/fr.json'

export { useTranslate } from './useTranslate'

type Messages = typeof defaultMessages
type MessageKey = keyof Messages
interface Props {
  locale: string
}

export const I18nProvider: FC<Props> = function I18nProvider(props) {
  const { locale, children } = props

  const [messages, error] = usePromise<Partial<Messages>>(async () => {
    if (locale === 'en') {
      return (await import(`../../locales/en.json`)).default
    }
    return (await import(`../../locales/fr.json`)).default
  }, [locale])

  if (error) {
    throw error
  }

  const allMessages = useMemo(() => {
    const result: Record<string, string> = {}

    Object.keys(defaultMessages).forEach((key) => {
      const k = key as MessageKey
      result[k] = messages?.[k]?.message || defaultMessages[k]?.message
    })

    return result
  }, [messages])

  return (
    <IntlProvider locale={locale} messages={allMessages}>
      {children}
    </IntlProvider>
  )
}
