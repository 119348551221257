import { DateInput, required } from 'react-admin'
import { SfmTextInput, SfmTextArea } from 'src/components/SfmInputs'

export function WorkOrderForm() {
  return (
    <>
      <SfmTextInput
        source="reference"
        label="resource.work_orders.reference"
        validate={required()}
      />
      <DateInput
        source="start"
        label="resource.work_orders.start"
        validate={required()}
      />
      <SfmTextArea source="notes" label="resource.work_orders.notes" />
    </>
  )
}
