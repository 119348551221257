import Typography from '@material-ui/core/Typography'
import { useTranslate } from 'react-admin'

import type { SfmFunctionFieldProps } from './SfmFields'
import { EmptyText, SfmFunctionField } from './SfmFields'

/**
 * Field to translate status (for Intervention & WorkOrder object)
 */
function StatusField(props: Omit<SfmFunctionFieldProps, 'render'>) {
  const __ = useTranslate()

  return (
    <SfmFunctionField
      {...props}
      render={(record) => {
        if (!record || !record.status) return <EmptyText />

        return (
          <Typography component="span" variant="body2">
            {__(`status.${record.status}`)}
          </Typography>
        )
      }}
    />
  )
}

export default StatusField
