import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import type { MenuItemProps } from '@material-ui/core/MenuItem'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import { ReactComponent as ChevronDownIcon } from '@nartex/smartforest-design-tokens/graphics/svg/bxBxChevronDown.svg'
import React, { useState, forwardRef, useMemo, useCallback } from 'react'
import { useTranslate } from 'react-admin'
import { Link } from 'react-router-dom'
import type { LinkProps } from 'react-router-dom'

import { ClientIcon } from 'src/UI/theme/icons'

import { useGetIdentity } from '../IdentityProvider'

import { usePersona } from './PersonaProvider'
import { useCreateRoleUrl } from './Role'

const useStyles = makeStyles(function (theme) {
  return {
    button: {
      display: 'flex',
      padding: `0 ${theme.spacing(2)}px`,
      minHeight: theme.spacing(6),
      background: theme.palette.info.main,
      color: theme.palette.info.contrastText,
      flexDirection: 'row',
      justifyContent: 'flex-start',
      alignItems: 'center',
      gap: theme.spacing(1),
      width: '100%',
      border: 0,
    },

    content: {
      textAlign: 'left',
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
    clientName: {
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      maxWidth: theme.spacing(18),
    },
  }
})

const ITEM_HEIGHT = 48

interface Props {
  compact?: boolean
}

export function ClientPicker(props: Props) {
  const { compact } = props

  const __ = useTranslate()
  const { identity } = useGetIdentity()
  const roleUrl = useCreateRoleUrl()
  const persona = usePersona()
  const styles = useStyles()

  const clients = useMemo(() => identity?.clients || [], [identity?.clients])
  const currentClient = clients.find((cus) => cus.id === persona.clientId)

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget)
    },
    [setAnchorEl],
  )

  const handleClose = useCallback(() => {
    setAnchorEl(null)
  }, [setAnchorEl])

  return (
    <>
      <button
        className={styles.button}
        aria-label="more"
        aria-controls="clients-picker"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <ClientIcon />
        {!compact && (
          <>
            <div className={styles.content}>
              <Typography variant="overline">
                {__('menu.current_client')}
              </Typography>
              <Typography variant="button" className={styles.clientName}>
                {currentClient?.name}
              </Typography>
            </div>
            <ChevronDownIcon />
          </>
        )}
      </button>

      <Menu
        id="clients-picker"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        PaperProps={useMemo(() => {
          return {
            style: {
              maxHeight: ITEM_HEIGHT * 4.5,
              width: '20ch',
            },
          }
        }, [])}
      >
        {clients.map((client) => (
          <MenuItemLink
            to={roleUrl({ clientId: client.id })}
            key={client.id}
            selected={client.id === currentClient?.id}
            onClick={handleClose}
          >
            {client.name}
          </MenuItemLink>
        ))}
      </Menu>
    </>
  )
}

const MenuItemLink = forwardRef(function MenuItemLink(
  props: MenuItemProps & LinkProps,
  ref,
) {
  const Component = MenuItem as any

  return <Component ref={ref} component={Link} {...props} />
})
