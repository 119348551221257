import { ThemeProvider } from '@material-ui/core'
import type { TypographyVariant } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import React from 'react'
import { theme } from 'src/UI'

const variants: TypographyVariant[] = [
  'h1',
  'h2',
  'h3',
  'h4',
  'h5',
  'h6',
  'subtitle1',
  'subtitle2',
  'body1',
  'body2',
  'button',
  'caption',
  'overline',
]

export function KitchenSink() {
  return (
    <ThemeProvider theme={theme}>
      <p>Typography</p>

      {variants.map((variant) => {
        return (
          <React.Fragment key={variant}>
            <Typography variant={variant}>
              <strong>{variant}</strong> : Lorem ipsum
              <br />
              dolor sit amet
            </Typography>
            <br />
          </React.Fragment>
        )
      })}
    </ThemeProvider>
  )
}
