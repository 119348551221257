import classNames from 'classnames'

import { groups } from './groups'
import { Section } from './Section'
import { useStyles } from './styles'

interface Props {
  className?: string
}

export const droppableId = 'LIBRARY'

export function ComponentsLibrary(props: Props) {
  const { className } = props

  const styles = useStyles()

  return (
    <section className={classNames(className)}>
      <ul className={classNames(styles.mainContainer)}>
        {groups.map((group, index) => {
          return (
            <Section
              key={group.displayName.id}
              isFirst={index === 0}
              {...group}
            />
          )
        })}
      </ul>
    </section>
  )
}
