import { Link } from '@material-ui/core'
import { useRecordContext } from 'react-admin'
import { MinioFileField } from 'src/components/MinioFileField'
import type { Document } from 'src/types/api'

interface Props {
  document?: Document
}

export function DocumentFileField(props: Props) {
  const document = useRecordContext<Document>(props.document)

  if (!document) {
    return null
  }
  if (document.isUrl) {
    return (
      <Link href={document.fileName} target="_blank" rel="noreferrer">
        {document.name}
      </Link>
    )
  }
  return (
    <MinioFileField
      fileName={document.fileName || ''}
      bucketId={document.client || ''}
      title={document.name}
    />
  )
}
