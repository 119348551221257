import { ButtonGroup, Button, makeStyles } from '@material-ui/core'
import classNames from 'classnames'
import type { ReactNode } from 'react'
import { useTranslate } from 'react-admin'
import { border, shadow } from 'src/UI/theme/mixins'

const useStyles = makeStyles(function (theme) {
  return {
    button: {
      minHeight: 0,
      height: theme.spacing(4),
      ...theme.typography.caption,
      border: 0,
      color: theme.palette.grey[600],
    },
    activeButton: {
      backgroundColor: theme.palette.text.primary,
      boxShadow: shadow(1),
      color: theme.palette.secondary.contrastText,
      '&:hover': {
        backgroundColor: theme.palette.grey[800],
      },
    },
    group: {
      border: border(theme),
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      paddingRight: theme.spacing(2),
      paddingLeft: theme.spacing(2),
    },
  }
})

interface Props<T> {
  value: T
  onChange: (newVal: T) => void
  options: { value: T; label: ReactNode }[]
}

export function SegmentedControl<T>(props: Props<T>) {
  const { value, onChange, options } = props
  const __ = useTranslate()
  const styles = useStyles()

  return (
    <ButtonGroup className={classNames(styles.group)}>
      {options.map((option, index) => {
        const isActive = option.value === value

        return (
          <Button
            key={index}
            className={classNames(
              styles.button,
              isActive && styles.activeButton,
            )}
            onClick={() => {
              if (!isActive) {
                onChange(option.value)
              }
            }}
          >
            {typeof option.label === 'string' ? __(option.label) : option.label}
          </Button>
        )
      })}
    </ButtonGroup>
  )
}
