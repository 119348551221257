/** https://marmelab.com/react-admin/Caching.html */
import type { AxiosInstance } from 'axios'
import axios from 'axios'
import { formatISO } from 'date-fns'
import { extension } from 'mime-types'
import type {
  DataProvider,
  DeleteManyParams,
  DeleteParams,
  Identifier,
  Record,
  CreateParams,
  GetListParams,
  GetManyParams,
  GetOneParams,
  UpdateManyParams,
  UpdateParams,
  GetManyReferenceParams,
} from 'react-admin'
import { formatApiPlatformId } from 'src/libs/apiPlatformId'
import { EntitlementTypeEnum } from 'src/types/api'
import type { Client } from 'src/types/api'
import { ResourceEnum } from 'src/types/api/resources'
import { RoleEnum } from 'src/UserIdentity'
import { v4 as uuidv4 } from 'uuid'

import type {
  Document,
  Entitlement,
  Equipment,
  User,
  UserEntitlement,
  WorkOrder,
} from '../types/api'

interface MinioConfig {
  httpClient: AxiosInstance
  minioUrl: string
}

export function antiCorruptionLayerProxy(
  dataProvider: DataProvider,
  minioConfig: MinioConfig,
) {
  const proxies = Proxies(dataProvider, minioConfig)
  return new Proxy(dataProvider, {
    get(_, name: any) {
      if (typeof name !== 'string') return dataProvider[name]
      return function wrappedMethod(resource: string, params: any) {
        if (resource in proxies) {
          // @ts-ignore
          const proxy = proxies[resource]
          if (name in proxy) {
            // @ts-ignore
            return proxy[name](params)
          }
        }

        return dataProvider[name](resource, params)
      }
    },
  })
}

function Proxies(dataProvider: DataProvider, minioConfig: MinioConfig) {
  return {
    contacts: ContactProxy(dataProvider),
    clients: ClientProxy(dataProvider),
    operators: OperatorProxy(dataProvider),
    entitlements: EntitlementProxy(dataProvider),
    habilitations: HabilitationProxy(dataProvider),
    autorisations: AutorisationProxy(dataProvider),
    interventions: InterventionProxy(dataProvider),
    users: UsersProxy(dataProvider),
    documents: DocumentsProxy(dataProvider, minioConfig),
    work_orders: WorkOrderProxy(dataProvider),
    user_entitlements: UserEntitlementProxy(dataProvider, minioConfig),
    documents_of_form: DocumentOfFormProxy(dataProvider),
    documents_of_intervention: DocumentOfInterventionProxy(dataProvider),
    equipment_of_intervention: EquipmentOfInterventionProxy(dataProvider),
    round: RoundProxy(dataProvider),
  }
}

function defaultDataprovider(resource: string, dataProvider: DataProvider) {
  return {
    getOne: async function (params: GetOneParams) {
      return dataProvider.getOne(resource, params)
    },
    getList: async function (params: GetListParams) {
      return dataProvider.getList(resource, params)
    },
    getMany: async function (params: GetManyParams) {
      return dataProvider.getMany(resource, params)
    },
    create: async function (params: CreateParams) {
      return dataProvider.create(resource, params)
    },
    update: async function (params: UpdateParams) {
      return dataProvider.update(resource, params)
    },
    updateMany: async function (params: UpdateManyParams) {
      return dataProvider.updateMany(resource, params)
    },
    delete: async function (params: DeleteParams) {
      return dataProvider.delete(resource, params)
    },
    deleteMany: async function (params: DeleteManyParams) {
      return dataProvider.deleteMany(resource, params)
    },
  }
}

interface ResourceWithPhoneNumbers extends Record {
  phoneNumbers?: string[]
}

export interface HydratedPhoneNumbers extends Record {
  phoneNumbers: { number: string }[]
}

type HydratedResourceWithPhoneNumbers<T extends ResourceWithPhoneNumbers> =
  Omit<T, 'phoneNumbers'> & HydratedPhoneNumbers

function hydrateRecord<T extends ResourceWithPhoneNumbers>(
  record: T,
): HydratedResourceWithPhoneNumbers<T> {
  return {
    ...record,
    phoneNumbers:
      record.phoneNumbers?.map((value) => {
        return { number: value }
      }) || [],
  }
}
function dehydrateRecord<T extends HydratedPhoneNumbers>(
  record: T,
): Omit<T, 'phoneNumbers'> & ResourceWithPhoneNumbers {
  return {
    ...record,
    phoneNumbers: record.phoneNumbers
      ?.map((value) => value.number)
      ?.filter(Boolean),
  }
}

function ContactProxy(dataProvider: DataProvider) {
  return HydraterProxy(dataProvider, ResourceEnum.contacts, {
    hydrate: hydrateRecord,
    dehydrate: dehydrateRecord,
  } as any)
}

interface Hydrater<T, U> {
  hydrate: (raw: T) => U
  dehydrate: (hydrated: Partial<U>) => Partial<T>
}
function HydraterProxy<T, U>(
  dataProvider: DataProvider,
  resource: string,
  hydrater: Hydrater<T, U>,
) {
  const { hydrate, dehydrate } = hydrater
  return {
    getOne: async function (params: GetOneParams) {
      const result = await dataProvider.getOne<T & { id: Identifier }>(
        resource,
        params,
      )
      return { ...result, data: hydrate(result.data) }
    },
    getMany: async function (params: GetManyParams) {
      const result = await dataProvider.getMany<T & { id: Identifier }>(
        resource,
        params,
      )
      return { ...result, data: result.data.map(hydrate) }
    },
    getList: async function (params: GetListParams) {
      const result = await dataProvider.getList<T & { id: Identifier }>(
        resource,
        params,
      )
      return { ...result, data: result.data.map(hydrate) }
    },
    create: async function (params: CreateParams) {
      return dataProvider.create(resource, {
        ...params,
        data: dehydrate(params.data),
      })
    },
    update: async function (params: UpdateParams) {
      return dataProvider.update(resource, {
        ...params,
        data: dehydrate(params.data),
      })
    },
    updateMany: async function (params: UpdateManyParams) {
      return dataProvider.updateMany(resource, {
        ...params,
        data: params.data.map(dehydrate),
      })
    },
  }
}

function dehydrateClient(client: HydratedResourceWithPhoneNumbers<Client>) {
  return dehydrateRecord({
    ...client,
    disabledAt: (client.disabledAt as string) === '' ? null : client.disabledAt,
  })
}

function ClientProxy(dataProvider: DataProvider) {
  return HydraterProxy(dataProvider, ResourceEnum.clients, {
    hydrate: hydrateRecord,
    dehydrate: dehydrateClient,
  } as any)
}

function OperatorProxy(dataProvider: DataProvider) {
  return {
    getList: async function (params: GetListParams) {
      if (params?.filter?.operators_can_be_assigned) {
        return dataProvider.getList(
          `intervention/${params.filter.operators_can_be_assigned}/operators_can_be_assigned`,
          params,
        )
      }
      return dataProvider.getList<User>(ResourceEnum.users, {
        ...params,
        filter: { ...params.filter, isOperator: true },
      })
    },
  }
}

function EntitlementProxy(dataProvider: DataProvider) {
  return {
    getList: async function (params: GetListParams) {
      return dataProvider.getList(ResourceEnum.entitlements, {
        ...params,
        filter: {
          ...params.filter,
          'client.id': undefined,
          'clients.id': undefined,
          client: undefined,
        },
        headers: { 'X-Sfm-Role': RoleEnum.ROLE_ADMIN },
      } as any)
    },
  }
}

function HabilitationProxy(dataProvider: DataProvider) {
  function transformFilters(
    filters: GetListParams['filter'] | GetManyReferenceParams['filter'],
  ) {
    return {
      ...filters,
      'client.id': undefined,
      'clients.id': undefined,
      client: undefined,
      type: EntitlementTypeEnum.habilitation,
    }
  }
  return {
    ...defaultDataprovider(ResourceEnum.entitlements, dataProvider),
    getList: async function (params: GetListParams) {
      return dataProvider.getList(ResourceEnum.entitlements, {
        ...params,
        filter: transformFilters(params.filter),
        headers: { 'X-Sfm-Role': RoleEnum.ROLE_ADMIN },
      } as any)
    },
    getManyReference: async function (params: GetManyReferenceParams) {
      return dataProvider.getManyReference(ResourceEnum.entitlements, {
        ...params,
        filter: transformFilters(params.filter),
        headers: { 'X-Sfm-Role': RoleEnum.ROLE_ADMIN },
      } as any)
    },
    create: async function (params: CreateParams) {
      return dataProvider.create<Entitlement>(ResourceEnum.entitlements, {
        ...params,
        data: { ...params.data, type: EntitlementTypeEnum.habilitation },
      })
    },
  }
}

function AutorisationProxy(dataProvider: DataProvider) {
  function transformFilters(
    filters: GetListParams['filter'] | GetManyReferenceParams['filter'],
  ) {
    return {
      ...filters,
      type: EntitlementTypeEnum.autorisation,
    }
  }
  return {
    ...defaultDataprovider(ResourceEnum.entitlements, dataProvider),
    getList: async function (params: GetListParams) {
      return dataProvider.getList(ResourceEnum.entitlements, {
        ...params,
        filter: transformFilters(params.filter),
      })
    },
    getManyReference: async function (params: GetManyReferenceParams) {
      return dataProvider.getManyReference(ResourceEnum.entitlements, {
        ...params,
        filter: transformFilters(params.filter),
      } as any)
    },
    create: async function (params: CreateParams) {
      return dataProvider.create<Entitlement>(ResourceEnum.entitlements, {
        ...params,
        data: { ...params.data, type: EntitlementTypeEnum.autorisation },
      })
    },
  }
}

function InterventionProxy(dataProvider: DataProvider) {
  return {
    getList: async function (params: GetListParams) {
      return dataProvider.getList(ResourceEnum.interventions, {
        ...params,
        filter: {
          ...params.filter,
          isRound: false,
        },
      })
    },
    create: async function (params: CreateParams) {
      return dataProvider.create(ResourceEnum.interventions, {
        ...params,
        data: { ...params.data, isRound: false },
      })
    },
  }
}

function RoundProxy(dataProvider: DataProvider) {
  return {
    getList: async function (params: GetListParams) {
      return dataProvider.getList(ResourceEnum.interventions, {
        ...params,
        filter: {
          ...params.filter,
          isRound: true,
        },
      })
    },
    create: async function (params: CreateParams) {
      return dataProvider.create(ResourceEnum.interventions, {
        ...params,
        data: { ...params.data, isRecurrent: false, isRound: true },
      })
    },
  }
}

function UsersProxy(dataProvider: DataProvider) {
  return HydraterProxy<User, User>(dataProvider, ResourceEnum.users, {
    hydrate(user) {
      return {
        ...user,
        roles: user.roles.filter((role: string) => role !== RoleEnum.ROLE_USER),
      }
    },
    dehydrate(user) {
      if (!user.roles) return user
      return { ...user, roles: [...user.roles, RoleEnum.ROLE_USER] }
    },
  })
}

type EditDocumentFormData = Document & { file?: { rawFile: File } }
export type CreateDocumentFormData = Partial<Document> & {
  file: { rawFile: File }
  bucket?: Identifier
}

function DocumentsProxy(dataProvider: DataProvider, minioConfig: MinioConfig) {
  const { httpClient, minioUrl } = minioConfig

  const upload = async (fileName: string, file: File, bucketId: Identifier) => {
    return httpClient
      .get(
        `/presigned_minio_put/${fileName}/${formatApiPlatformId(bucketId)}`,
        {
          baseURL: minioUrl,
        },
      )
      .then(async (response) => {
        return axios.put(response.data, file, {
          headers: { 'Content-Type': file.type },
        })
      })
  }

  return {
    create: async function (params: CreateParams<CreateDocumentFormData>) {
      const {
        data: { file, bucket, client, isUrl, ...documentData },
      } = params

      if (isUrl) {
        return dataProvider.create(ResourceEnum.documents, {
          ...params,
          data: { ...params.data, size: 0 },
        })
      }

      const uuid = uuidv4()

      const fileName = `${uuid}.${extension(file.rawFile.type)}`

      if (!bucket && !client) {
        throw new Error('Client or user ID is missing')
      }

      await upload(fileName, file.rawFile, bucket || (client as Identifier)) // il aime pas mon check plus haut et je vois pas ce qui le dérange donc j'ai fais ça

      const document = {
        ...documentData,
        id: uuid,
        fileName,
        size: file.rawFile.size,
        mimeType: file.rawFile.type,
        client,
      }

      return dataProvider.create(ResourceEnum.documents, {
        ...params,
        data: document,
      })
    },
    update: async function (params: UpdateParams<EditDocumentFormData>) {
      const {
        data: { file, id, isUrl, ...documentData },
      } = params

      if (isUrl) {
        return dataProvider.update(ResourceEnum.documents, params)
      }

      let document = documentData

      if (file?.rawFile && file.rawFile instanceof File) {
        const fileName = `${formatApiPlatformId(id)}.${extension(
          file.rawFile.type,
        )}`

        await upload(fileName, file.rawFile, documentData.client || '')

        document = {
          ...document,
          fileName,
          size: file.rawFile.size,
          mimeType: file.rawFile.type,
        }
      }
      return dataProvider.update(ResourceEnum.documents, {
        ...params,
        data: { ...document, id: undefined },
      })
    },
  }
}

function WorkOrderProxy(dataProvider: DataProvider) {
  return {
    create: async function (params: CreateParams<WorkOrder>) {
      return dataProvider.create(ResourceEnum.work_orders, {
        ...params,
        data: { ...params.data, status: params.data.status ?? 'created' },
      })
    },
  }
}

export type UserEntitlementFormData = Omit<UserEntitlement, 'document'> & {
  document?: CreateDocumentFormData
}

function UserEntitlementProxy(
  dataProvider: DataProvider,
  minioConfig: MinioConfig,
) {
  const documentDataProvider = DocumentsProxy(dataProvider, minioConfig)
  return {
    ...defaultDataprovider(ResourceEnum.user_entitlements, dataProvider),
    create: async function (params: CreateParams<UserEntitlementFormData>) {
      const { start, endTime, document } = params.data

      const createdDocument =
        document?.file &&
        (await documentDataProvider.create({
          ...params,
          data: document,
        }))

      return dataProvider.create<UserEntitlement>(
        ResourceEnum.user_entitlements,
        {
          ...params,
          data: {
            ...params.data,
            start: start ? formatISO(new Date(start)) : undefined,
            endTime: endTime ? formatISO(new Date(endTime)) : undefined,
            document: createdDocument?.data.id,
          },
        },
      )
    },
    update: async function (params: UpdateParams<UserEntitlementFormData>) {
      const { start, endTime, document } = params.data
      const createdDocument =
        document &&
        (await documentDataProvider.create({
          ...params,
          data: document,
        }))
      return dataProvider.update<UserEntitlement>(
        ResourceEnum.user_entitlements,
        {
          ...params,
          data: {
            ...params.data,
            start: start ? formatISO(new Date(start)) : undefined,
            endTime: endTime ? formatISO(new Date(endTime)) : undefined,
            document: createdDocument?.data.id,
          },
        },
      )
    },
  }
}

interface CreateDocumentForm {
  form: string
  documents: string[]
}

function DocumentOfFormProxy(dataProvider: DataProvider) {
  return {
    getList: async function (params: GetListParams) {
      return dataProvider.getList<Document>(
        `${ResourceEnum.documents}/form`,
        params,
      )
    },
    create: async function (params: CreateParams<CreateDocumentForm>) {
      const { form, documents } = params.data
      return dataProvider.create(
        `${ResourceEnum.form_documents}/batch?formId=${formatApiPlatformId(
          form,
        )}`,
        {
          data: {
            documents,
          },
        },
      )
    },
  }
}

interface CreateDocumentIntervention {
  intervention: string
  documents: string[]
}

function DocumentOfInterventionProxy(dataProvider: DataProvider) {
  return {
    getList: async function (params: GetListParams) {
      return dataProvider.getList<Document>(
        `${ResourceEnum.documents}/intervention`,
        params,
      )
    },
    create: async function (params: CreateParams<CreateDocumentIntervention>) {
      const { intervention, documents } = params.data
      return dataProvider.create(
        `${
          ResourceEnum.intervention_documents
        }/batch?interventionId=${formatApiPlatformId(intervention)}`,
        {
          data: {
            documents,
          },
        },
      )
    },
  }
}

interface CreateEquipmentIntervention {
  intervention: string
  equipments: string[]
}

function EquipmentOfInterventionProxy(dataProvider: DataProvider) {
  return {
    getList: async function (params: GetListParams) {
      return dataProvider.getList<Equipment>(
        `${ResourceEnum.equipment}/intervention`,
        params,
      )
    },
    create: async function (params: CreateParams<CreateEquipmentIntervention>) {
      const { intervention, equipments } = params.data
      return dataProvider.create(
        `${
          ResourceEnum.intervention_equipments
        }/batch?interventionId=${formatApiPlatformId(intervention)}`,
        {
          data: {
            equipments,
          },
        },
      )
    },
  }
}
