import { Box, makeStyles, Typography } from '@material-ui/core'
import History from '@material-ui/icons/History'
import ErrorIcon from '@material-ui/icons/Report'
import type { PropsWithChildren } from 'react'
import { Button, Loading, useTranslate } from 'react-admin'
import { useHistory } from 'react-router'
import { RoleEnum, useGetIdentity } from 'src/UserIdentity'

const useStyles = makeStyles(function (theme) {
  return {
    content: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      gap: theme.spacing(4),
    },
    icon: {
      height: theme.spacing(4),
      width: theme.spacing(4),
    },
    title: {
      maxWidth: '100%',
    },
  }
})

interface Props {
  acceptedRoles?: RoleEnum[]
}

export function ProtectedPage(props: PropsWithChildren<Props>) {
  const {
    acceptedRoles = [RoleEnum.ROLE_ADMIN, RoleEnum.ROLE_CONFIGURATOR],
    children,
  } = props
  const __ = useTranslate()
  const history = useHistory()
  const styles = useStyles()

  const { identity, loading } = useGetIdentity()

  if (loading) {
    return <Loading />
  }

  if (
    !identity ||
    !acceptedRoles.some((role) => identity.roles?.includes(role))
  ) {
    return (
      <Box padding={4} className={styles.content}>
        <Typography
          variant="h1"
          color="secondary"
          align="center"
          className={styles.title}
        >
          <ErrorIcon className={styles.icon} /> {__('error.page_not_allowed')}
        </Typography>
        <Button
          color="inherit"
          variant="contained"
          onClick={history.goBack}
          label={__('actions.back')}
        >
          <History />
        </Button>
      </Box>
    )
  }
  return <>{children}</>
}
