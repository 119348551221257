import { SfmEdit } from 'src/AppScopes/resource'
import type { SfmEditProps } from 'src/AppScopes/resource'

import { Form } from './Form'

export function Edit(props: SfmEditProps) {
  return (
    <SfmEdit {...props}>
      <Form />
    </SfmEdit>
  )
}
