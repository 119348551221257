import type { RaThemeOverrides } from 'react-admin'

import { tokens, typeScale } from './tokens'

const formWrapper = {
  root: {
    maxWidth: '1200px',
    margin: 'auto',
  },
  card: {
    border: 0,
    backgroundColor: 'transparent',
    boxShadow: 'none',
  },
}
const menuWidth = {
  width(props: { open: boolean }) {
    const { open } = props
    if (open) return 'auto'
    else return tokens.spacing(2 + 3 + 2)
  },
  minWidth(props: { open: boolean }) {
    const { open } = props
    if (open) return tokens.spacing(2 + 28 + 2)
    else return tokens.spacing(2 + 3 + 2)
  },
}

export const overrides: RaThemeOverrides = {
  RaLayout: {
    appFrame: {
      '@media (min-width: 0px)': {
        marginTop: tokens.spacing(6),
      },
    },
    content: {
      padding: 0,
      '@media (min-width: 0px)': {
        padding: tokens.spacing(0),
      },
      [tokens.breakpoints.up('sm')]: {
        padding: tokens.spacing(0),
      },
      [tokens.breakpoints.up('md')]: {
        padding: tokens.spacing(0),
      },
    },
  },

  RaAppBar: {
    toolbar: {
      paddingLeft: tokens.spacing(0),
      minHeight: tokens.spacing(6),
    },
    menuButton: {
      paddingLeft: tokens.spacing(1),
      paddingRight: tokens.spacing(1),
      marginRight: tokens.spacing(1),
      marginLeft: tokens.spacing(1),
    },
  },

  RaSidebar: {
    drawerPaper: {
      transition: 'none',
      ...menuWidth,
    },
    fixed: {
      ...menuWidth,
      zIndex: 30,
      borderRight: `1px solid ${tokens.palette.grey[100]}`,
      background: tokens.palette.background.default,
    },
  },

  MuiCard: {
    root: {
      overflow: 'visible',
    },
  },

  MuiPaper: {
    root: {
      backgroundColor: tokens.palette.background.paper,
    },
  },

  RaMenuItemLink: {
    root: {
      padding: `${tokens.spacing(1.5)}px ${tokens.spacing(2)}px`,
      ...tokens.typography.button,
      ...typeScale(-0.5),
      color: tokens.palette.text.primary,
    },

    active: {
      color: tokens.palette.primary.main,
    },

    icon: {
      minWidth: 0,
      marginRight: tokens.spacing(2),
      color: tokens.palette.text.secondary,
    },
  },

  MuiButton: {
    root: {
      minHeight: tokens.spacing(6),
      padding: `${tokens.spacing(1.5)}px ${tokens.spacing(3)}px`,
    },
    containedSizeSmall: {
      padding: tokens.spacing(1.5),
    },
  },

  RaSaveButton: {
    icon: {
      marginRight: tokens.spacing(2),
    },
  },

  MuiSvgIcon: {
    root: {
      width: tokens.spacing(3),
      height: tokens.spacing(3),
    },
  },

  MuiSwitch: {
    switchBase: {
      color: tokens.palette.grey[200],
    },
  },

  MuiToolbar: {
    gutters: {
      padding: tokens.spacing(2),
      [tokens.breakpoints.down('md')]: {
        padding: tokens.spacing(2),
      },
    },
    regular: {
      minHeight: tokens.spacing(6),
      [tokens.breakpoints.down('md')]: {
        minHeight: tokens.spacing(6),
      },
    },
  },

  MuiInputBase: {
    root: {
      lineHeight: `${tokens.spacing(2)}px`,
      minHeight: `${tokens.spacing(6)}px`,
      border: `1px solid ${tokens.palette.text.primary}`,
      maxWidth: `90ch`,
    },
    input: {
      height: tokens.spacing(6) - 2,
      boxSizing: 'border-box',
    },
  },

  MuiFilledInput: {
    root: {
      backgroundColor: tokens.palette.background.default,
    },
    underline: {
      '&:before': {
        bottom: -1,
        left: -1,
        right: -1,
      },
      '&:after': {
        bottom: -1,
        left: -1,
        right: -1,
      },
    },
    inputMarginDense: {
      paddingTop: '23px',
      paddingBottom: '7px',
    },
  },

  MuiFormHelperText: {
    contained: { marginLeft: 0, marginRight: 0 },
  },
  RaCreate: {
    ...formWrapper,
  },
  RaEdit: {
    ...formWrapper,
  },
  RaShow: {
    ...formWrapper,
  },
  MuiTableRow: {
    root: {
      backgroundColor: 'white',
    },
  },
  MuiTablePagination: {
    input: {
      minHeight: 0,
    },
  },
  MuiTableCell: {
    body: {
      color: 'inherit',
    },
  },
  RaPaginationActions: {
    button: {
      minWidth: tokens.spacing(5),
    },
  },
  RaLabeled: {
    value: { padding: 0 },
  },

  RaBulkActionsToolbar: {
    collapsed: {
      paddingTop: 0,
      paddingBottom: 0,
    },
    toolbar: {
      paddingTop: tokens.spacing(3),
      paddingBottom: tokens.spacing(3),
    },
    topToolbar: {
      paddingTop: 0,
      paddingBottom: 0,
    },
  },

  RaList: {
    content: {
      background: 'transparent',
    },
  },

  RaCardContentInner: {
    root: {
      padding: 0,
    },
  },

  RaTopToolbar: {
    root: {
      paddingTop: 0,
      paddingBottom: 0,
      minHeight: tokens.spacing(6),
    },
  },
  RaFilterForm: {
    form: {
      minHeight: 0,
      margin: 0,
    },
  },
  RaFilterFormInput: {
    body: {
      marginBottom: tokens.spacing(-2),
      alignItems: 'center',
    },
  },

  MuiFormControl: {
    root: {
      minWidth: 256 * 1.5,
      display: 'flex',
    },
  },

  MuiAlert: {
    root: {
      alignItems: 'center',
    },
  },
}
