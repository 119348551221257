import { defineMessage } from '@formatjs/intl'
import {
  InputBase,
  MenuItem,
  Select,
  ListSubheader,
  makeStyles,
  Typography,
} from '@material-ui/core'
import type { Literal, Get } from '@nartex/sfm-form-engine'
import PencilIcon from '@nartex/smartforest-design-tokens/graphics/react/BxBxsPencil'
import { useEffect, useMemo } from 'react'
import AutosizeInput from 'react-input-autosize'

import { useTranslate } from 'src/adapters/I18nProvider'

import { definitions } from '../ComponentsLibrary/definitions'
import { displayFieldName } from '../libs/fieldNamesUtils'
import { code, shadow } from '../theme/mixins'

import type {
  ArgumentFixedValue,
  ArgumentOpenValue,
  ArgumentValue,
  ArgumentVariableValue,
} from './operators'
import {
  isArgumentFixedValue,
  literalHasMeta,
  ArgumentTypeEnum,
  isArgumentVariableValue,
} from './operators'
import { useSelectStyle } from './useSelectStyle'

type ArgumentsArrays = [
  ArgumentOpenValue[],
  ArgumentFixedValue[],
  ArgumentVariableValue[],
]
function filterArguments(options: ArgumentValue[]): ArgumentsArrays {
  return options.reduce<ArgumentsArrays>(
    ([openOptions, fixedOptions, variableOptions], argValue) => {
      if (isArgumentVariableValue(argValue)) {
        return [openOptions, fixedOptions, [...variableOptions, argValue]]
      }
      if (isArgumentFixedValue(argValue)) {
        return [openOptions, [...fixedOptions, argValue], variableOptions]
      }

      return [
        [...openOptions, argValue as ArgumentOpenValue],
        fixedOptions,
        variableOptions,
      ]
    },
    [[], [], []],
  )
}

const useFieldStyle = makeStyles(function (theme) {
  return {
    container: {
      // display: 'flex',
      borderRadius: '1px',
      boxShadow: shadow(),
      minHeight: 'fit-content',
      maxHeight: '38px',
      backgroundColor: theme.palette.background.paper,
      '& input': {
        minWidth: theme.spacing(4),
        minHeight: theme.spacing(4),
      },
    },
    input: {
      height: '38px',
      textAlign: 'center',
      backgroundColor: theme.palette.background.paper,
      color: theme.palette.text.primary,
      ...code(),
      '&[type=number]': {
        '-moz-appearance': 'textfield',
      },
      '&::-webkit-outer-spin-button': {
        '-webkit-appearance': 'none',
        margin: 0,
      },
      '&::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
        margin: 0,
      },
    },
    inputRoot: {
      minHeight: 'fit-content',
    },
    button: {
      color: theme.palette.background.paper,
    },
    numeric: {
      backgroundColor: theme.palette.background.paper,
      color: theme.palette.grey[600],
      height: '24px',
      width: '24px',
    },
  }
})

interface ArgumentSelectProps {
  arg: Literal<string | number | boolean> | Get | undefined
  argumentList: ArgumentValue[]
  setArgument: (arg: ArgumentValue | undefined) => void
}

export function ArgumentSelect(props: ArgumentSelectProps) {
  const { arg } = props

  if (!arg) {
    return <SelectComponent {...props} currentValue={''} />
  }

  if (arg.type === 'function') {
    return <SelectComponent {...props} currentValue={arg.args[0].value} />
  }

  if (arg.type === 'literal') {
    return <LiteralArg {...props} arg={arg} /> // Needed to tell TS that arg is not undefined
  }

  return null
}

type LiteralArgProps = Omit<ArgumentSelectProps, 'arg'> & {
  arg: Literal<string | number | boolean>
}

function LiteralArg(props: LiteralArgProps) {
  const { arg, argumentList, setArgument } = props

  const isOpenValue =
    literalHasMeta(arg) &&
    [ArgumentTypeEnum.openTextValue, ArgumentTypeEnum.openNumberValue].includes(
      arg.meta.argumentType,
    )

  const isInvalidType =
    isOpenValue &&
    !argumentList.some((argument) => argument.type === arg.meta.argumentType)

  useEffect(() => {
    if (isInvalidType) {
      setArgument(undefined)
    }
  }, [isInvalidType, setArgument])

  const fieldStyles = useFieldStyle()

  if (!isOpenValue) {
    return <SelectComponent {...props} currentValue={arg.value.toString()} />
  }

  if (isInvalidType) {
    return null
  }

  return (
    <AutosizeInput
      className={fieldStyles.container}
      value={arg.value ?? ''}
      onChange={(event) =>
        setArgument({
          type: arg.meta.argumentType,
          value: event.target.value,
        })
      }
    />
  )
}

type SelectComponentProps = Omit<ArgumentSelectProps, 'arg'> & {
  currentValue: string
}

function SelectComponent(props: SelectComponentProps) {
  const { currentValue, argumentList, setArgument } = props

  const __ = useTranslate()
  const styles = useSelectStyle()

  const [openArguments, fixedArguments, variableArguments] = useMemo(
    () => filterArguments(argumentList),
    [argumentList],
  )

  useEffect(() => {
    const hasValueInOptions =
      currentValue &&
      !variableArguments.find(
        (argument) => argument.value.data.name === currentValue,
      ) &&
      !fixedArguments.find(
        (argument) => argument.value.toString() === currentValue,
      )

    if (hasValueInOptions) {
      setArgument(undefined)
    }
  }, [currentValue, variableArguments, fixedArguments, setArgument])

  const openOptions = openArguments.map((argValue) => {
    const label =
      argValue.type === ArgumentTypeEnum.openTextValue
        ? defineMessage({
            id: 'ArgumentSelect.openValue.text',
            description: 'Label of the open text value option',
            defaultMessage: 'Texte',
          })
        : defineMessage({
            id: 'ArgumentSelect.openValue.number',
            description: 'Label of the open number value option',
            defaultMessage: 'Nombre',
          })
    return (
      <MenuItem
        key={argValue.type}
        button
        value={argValue.type}
        onClick={() => setArgument(argValue)}
      >
        <div className={styles.option}>
          <PencilIcon />
          {__(label)}
        </div>
      </MenuItem>
    )
  })

  const fixedOptions = fixedArguments.map((argValue) => (
    <MenuItem
      key={argValue.value.toString()}
      button
      value={argValue.value.toString()}
      onClick={() => setArgument(argValue)}
    >
      {argValue.value.toString()}
    </MenuItem>
  ))
  const variableOptions = variableArguments.map((argValue) => (
    <MenuItem
      key={argValue.value.data.name}
      button
      value={argValue.value.data.name}
      onClick={() => setArgument(argValue)}
    >
      <div className={styles.option}>
        {definitions[argValue.value.type].icon}
        {displayFieldName(argValue.value.data.name)}
      </div>
    </MenuItem>
  ))

  return (
    <Select
      value={currentValue}
      displayEmpty
      input={
        <InputBase
          classes={{
            root: styles.root,
          }}
        />
      }
      classes={{
        root: styles.select,
      }}
    >
      <MenuItem value="" disabled>
        <em>
          {__({
            id: 'ArgumentSelect.placeholder',
            description: 'Argument selection component placeholder',
            defaultMessage: 'Argument',
          })}
        </em>
      </MenuItem>
      {openOptions.length > 0 && [
        <ListSubheader key="openValueGroup">
          <Typography variant="caption">
            {__({
              id: 'ArgumentSelect.optionsGroup.openValue',
              description:
                'Title of the group of options representing the open value',
              defaultMessage: 'Valeurs manuelles',
            })}
          </Typography>
        </ListSubheader>,
        ...openOptions,
      ]}
      {fixedOptions.length > 0 && [
        <ListSubheader key="fixedValueGroup">
          <Typography variant="caption">
            {__({
              id: 'ArgumentSelect.optionsGroup.fixedValue',
              description:
                'Title of the group of options representing the fixed value',
              defaultMessage: 'Valeurs fixes',
            })}
          </Typography>
        </ListSubheader>,
        ...fixedOptions,
      ]}
      {variableOptions.length > 0 && [
        <ListSubheader key="variableValueGroup">
          <Typography variant="caption">
            {__({
              id: 'ArgumentSelect.optionsGroup.variables',
              description:
                'Title of the group of options representing the available variable',
              defaultMessage: 'Variables',
            })}
          </Typography>
        </ListSubheader>,
        ...variableOptions,
      ]}
    </Select>
  )
}
