// THIS FILE IS AUTO-GENERATED BY FIGMAGIC. DO NOT MAKE EDITS IN THIS FILE! CHANGES WILL GET OVER-WRITTEN BY ANY FURTHER PROCESSING.

const colors = {
 "onInfo": "rgba(255, 255, 255, 1)",
 "onSuccess": "rgba(255, 255, 255, 1)",
 "onError": "rgba(0, 0, 0, 1)",
 "onShade": "rgba(255, 255, 255, 1)",
 "onPrimary": "rgba(255, 255, 255, 1)",
 "onSurface": "rgba(0, 0, 0, 1)",
 "onBackground": "rgba(0, 0, 0, 1)",
 "shade40": "rgba(154, 154, 160, 1)",
 "shade10": "rgba(2, 2, 20, 0.07)",
 "shade20": "rgba(221, 221, 225, 1)",
 "background": "rgba(255, 255, 255, 1)",
 "surface": "rgba(248, 248, 248, 1)",
 "shade80": "rgba(64, 64, 68, 1)",
 "shadeMain": "rgba(109, 111, 114, 1)",
 "primary": "rgba(255, 0, 0, 1)",
 "shade100": "rgba(0, 0, 0, 1)",
 "info": "rgba(0, 136, 206, 1)",
 "success": "rgba(52, 178, 51, 1)",
 "error": "rgba(254, 209, 0, 1)"
} as const;

export default colors;