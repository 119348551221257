/* eslint-disable react/jsx-key */

import { Resource } from 'react-admin'
import type { SfmResource, SfmResourceProps } from 'src/AppScopes'
import { AppScopeEnum } from 'src/AppScopes/useAppScope'
import { ResourceEnum } from 'src/types/api/resources'

import { Resource as ClientResource } from './Client'
import { Resource as ContactResource } from './Contact'
import { Resource as DocumentResource } from './Document'
import { AutorisationResource, HabilitationResource } from './Entitlement'
import { Resource as EquipmentResource } from './Equipment'
import { Resource as FormResource } from './Form'
import { Resource as InterventionResource } from './Intervention'
import { Resource as InterventionDataResource } from './InterventionData'
import { Resource as ItemResource } from './Item'
import { Resource as ProjectResource } from './Project'
import { Resource as ReportResource } from './Report'
import { ReportVersionResource } from './Report/ReportVersion'
import { Resource as UserResource } from './User'
import { UserGroupResource } from './UserGroup'
import { Resource as WorkOrderResource } from './WorkOrder'

type ResourceDefinition =
  | { scope: AppScopeEnum; Component: typeof SfmResource }
  | { scope?: undefined; Component?: undefined }

export type ResourceDefinitions = Record<ResourceEnum, ResourceDefinition>

export const definitions: ResourceDefinitions = {
  [ResourceEnum.users]: { scope: AppScopeEnum.ADMIN, Component: UserResource },
  [ResourceEnum.clients]: {
    Component: ClientResource,
    scope: AppScopeEnum.ADMIN,
  },
  [ResourceEnum.entitlements]: {},
  [ResourceEnum.habilitations]: {
    Component: HabilitationResource,
    scope: AppScopeEnum.ADMIN,
  },
  [ResourceEnum.autorisations]: {
    Component: AutorisationResource,
    scope: AppScopeEnum.CLIENT,
  },
  [ResourceEnum.user_entitlements]: {},
  [ResourceEnum.contacts]: {
    Component: ContactResource,
    scope: AppScopeEnum.CLIENT,
  },
  [ResourceEnum.projects]: {
    Component: ProjectResource,
    scope: AppScopeEnum.CLIENT,
  },
  [ResourceEnum.work_orders]: {
    Component: WorkOrderResource,
    scope: AppScopeEnum.CLIENT,
  },
  [ResourceEnum.interventions]: {
    Component: InterventionResource,
    scope: AppScopeEnum.CLIENT,
  },
  [ResourceEnum.round]: {
    Component(props: SfmResourceProps) {
      return <InterventionResource {...props} isRound={true} />
    },
    scope: AppScopeEnum.CLIENT,
  },
  [ResourceEnum.intervention_datas]: {
    Component: InterventionDataResource,
    scope: AppScopeEnum.CLIENT,
  },
  [ResourceEnum.intervention_documents]: {},
  [ResourceEnum.documents_of_intervention]: {},
  [ResourceEnum.intervention_items]: {},
  [ResourceEnum.intervention_equipments]: {},
  [ResourceEnum.equipment_of_intervention]: {},
  [ResourceEnum.operators]: {},
  [ResourceEnum.documents]: {
    Component: DocumentResource,
    scope: AppScopeEnum.CLIENT,
  },
  [ResourceEnum.equipment]: {
    Component: EquipmentResource,
    scope: AppScopeEnum.CLIENT,
  },
  [ResourceEnum.forms]: { Component: FormResource, scope: AppScopeEnum.CLIENT },
  [ResourceEnum.form_versions]: {},
  [ResourceEnum.form_documents]: {},
  [ResourceEnum.documents_of_form]: {},
  [ResourceEnum.items]: { Component: ItemResource, scope: AppScopeEnum.CLIENT },
  [ResourceEnum.reports]: {
    Component: ReportResource,
    scope: AppScopeEnum.CLIENT,
  },
  [ResourceEnum.report_versions]: {
    Component: ReportVersionResource,
    scope: AppScopeEnum.CLIENT,
  },
  [ResourceEnum.groups]: {
    Component: UserGroupResource,
    scope: AppScopeEnum.CLIENT,
  },
}

export function resources() {
  return Object.entries(definitions).map((entry) => {
    const resourceName = entry[0] as ResourceEnum
    const definition = entry[1]
    const { Component = Resource } = definition

    return <Component name={resourceName} />
  })
}
