import {
  Checkbox,
  FormControl,
  MenuItem,
  Select,
  TextField,
  Button,
  InputLabel,
  FormControlLabel,
  makeStyles,
} from '@material-ui/core'
import DownloadIcon from '@material-ui/icons/GetApp'
import type { FieldNode } from '@nartex/sfm-form-engine'
import QRCode from 'easyqrcodejs'
import printJS from 'print-js'
import type { ReactElement } from 'react'
import { useState, useEffect, useRef } from 'react'
import { Controller, useForm } from 'react-hook-form'
import type { ControllerRenderProps } from 'react-hook-form'

import { useTranslate } from '../adapters/I18nProvider'
import { border } from '../theme/mixins'

const useStyles = makeStyles(function (theme) {
  return {
    mainContainer: {
      display: 'flex',
      gap: theme.spacing(4),
    },
    qrContainer: {
      padding: theme.spacing(4),
      borderLeft: border(theme),
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(1),
      alignItems: 'center',
      justifyContent: 'space-between',
      minWidth: 400,
      minHeight: 400,
    },
    qrCode: {
      display: 'flex',
      justifyContent: 'center',
    },
  }
})

interface Props {
  fields: FieldNode[]
}

const QRCODE_ID = 'SFM-QRCODE'

export function QrCodeForm(props: Props) {
  const { fields } = props
  const { control, handleSubmit, watch } = useForm()
  const __ = useTranslate()

  const onSubmit = () => {
    printJS({
      printable: QRCODE_ID,
      type: 'html',
    })
  }

  const styles = useStyles()

  const qrCodeRef = useRef<HTMLDivElement>(null)
  const [qrCode, setQrCode] = useState<QRCode>()

  useEffect(() => {
    if (qrCodeRef.current) {
      setQrCode(
        new QRCode(qrCodeRef.current, {
          text: {},
          width: 200,
          height: 200,
        }),
      )
    }
  }, [qrCodeRef])

  useEffect(() => {
    const subscription = watch((value) =>
      qrCode?.makeCode(JSON.stringify(value)),
    )
    return () => {
      subscription.unsubscribe()
    }
  }, [watch, qrCode])

  useEffect(() => {
    return () => qrCode?.clear()
  })

  return (
    <div className={styles.mainContainer}>
      <form>
        {fields.map((field) => (
          <Controller
            key={field.data['@id']}
            name={field.data.name}
            defaultValue={field.type === 'SelectMany' ? [] : ''}
            control={control}
            render={(renderProps) => {
              const { field: controllerProps } = renderProps
              return (
                <FormControl>
                  {renderFields(field, controllerProps)}
                </FormControl>
              )
            }}
          />
        ))}
      </form>
      <div className={styles.qrContainer}>
        <div id={QRCODE_ID} ref={qrCodeRef} className={styles.qrCode} />
        <Button
          onClick={handleSubmit(onSubmit)}
          variant="contained"
          color="primary"
          startIcon={<DownloadIcon />}
        >
          {__({
            id: 'QrCodeGenerator.QrCodeDownload.label',
            defaultMessage: 'Télécharger',
            description:
              'Label displayed on the button to download the QR Code',
          })}
        </Button>
      </div>
    </div>
  )
}

function renderFields(
  field: FieldNode,
  controllerProps: ControllerRenderProps,
): ReactElement {
  const label = `${field.data.label} - \${${field.data.name}}`
  if (field.type === 'Checkbox') {
    return (
      <FormControlLabel
        control={<Checkbox {...controllerProps} />}
        label={label}
      />
    )
  }
  if (field.type === 'Text') {
    return <TextField {...controllerProps} label={label} />
  }
  if (field.type === 'Number') {
    const { max, min } = field.data
    return (
      <TextField
        {...controllerProps}
        type="number"
        inputProps={{
          min,
          max,
        }}
        label={label}
      />
    )
  }

  if (field.type === 'SelectOne' || field.type === 'SelectMany') {
    const isMultiple = field.type === 'SelectMany'
    return (
      <>
        <InputLabel>{label}</InputLabel>
        <Select {...controllerProps} multiple={isMultiple}>
          {field.data?.options?.map((option, index) => (
            <MenuItem key={option + index.toString()} value={option}>
              {option}
            </MenuItem>
          ))}
        </Select>
      </>
    )
  }

  if (field.type === 'Date') {
    return (
      <TextField
        {...controllerProps}
        type="date"
        label={label}
        InputLabelProps={{
          shrink: true,
        }}
      />
    )
  }
  if (field.type === 'DateTime') {
    return (
      <TextField
        {...controllerProps}
        type="datetime-local"
        label={label}
        InputLabelProps={{
          shrink: true,
        }}
      />
    )
  }
  if (field.type === 'Time') {
    return (
      <TextField
        {...controllerProps}
        type="time"
        label={label}
        InputLabelProps={{
          shrink: true,
        }}
      />
    )
  }

  throw new Error(
    'Unsupported input type for QrCode generation : ' + field.type,
  )
}
