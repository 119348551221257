import { Typography, useTheme } from '@material-ui/core'
import { get } from 'lodash'
import DatagridContext from 'ra-ui-materialui/esm/list/datagrid/DatagridContext'
import type { FC } from 'react'
import { useContext } from 'react'
import {
  BooleanField,
  DateField,
  EmailField,
  FileField,
  FunctionField,
  ImageField,
  NumberField,
  ReferenceArrayField,
  ReferenceField,
  ReferenceManyField,
  RichTextField,
  TextField,
  UrlField,
  useRecordContext,
  useTranslate,
} from 'react-admin'
import type {
  TextFieldProps,
  BooleanFieldProps,
  DateFieldProps,
  EmailFieldProps,
  FunctionFieldProps,
  FileFieldProps,
  ImageFieldProps,
  NumberFieldProps,
  RichTextFieldProps,
  UrlFieldProps,
  InjectedFieldProps,
  PublicFieldProps,
  ReferenceArrayFieldProps,
  ReferenceManyFieldProps,
  ReferenceFieldProps,
} from 'react-admin'

import type { LabeledFieldProps } from './LabeledField'
import { LabeledField } from './LabeledField'

type WithCustomLabel<T> = Omit<T, 'label'> &
  Omit<LabeledFieldProps, 'children'> & { emptyText?: string }

type RaFieldProps = InjectedFieldProps & PublicFieldProps

function withCustomLabel<T extends RaFieldProps>(
  Component: FC<T>,
  defaultProps?: Partial<T>,
  labeledFieldProps?: Partial<LabeledFieldProps>,
): FC<WithCustomLabel<T>> {
  return function WithCustomLabel(props) {
    const { label, actions, emptyText, Icon, ...fieldProps } = props

    const inList = Object.keys(useContext(DatagridContext)).length
    const record = useRecordContext(props)
    const value = props.source ? get(record, props.source) : record

    const body = isEmpty(value) ? (
      <EmptyText label={emptyText} />
    ) : (
      <Component {...defaultProps} {...(fieldProps as any)} />
    )

    if (inList) return body

    return (
      <LabeledField
        {...labeledFieldProps}
        label={label}
        actions={actions}
        Icon={Icon}
      >
        {body}
      </LabeledField>
    )
  }
}

function isEmpty(value: any): value is null | undefined | '' {
  return value === undefined || value === null || value === ''
}

export interface EmptyTextProps {
  label?: string
  className?: string
}
export function EmptyText(props: EmptyTextProps) {
  const { label, className } = props
  const translate = useTranslate()
  const theme = useTheme()
  return (
    <Typography
      className={className}
      style={{ color: theme.palette.grey[400] }}
    >
      {label ?? translate('fields.empty')}
    </Typography>
  )
}

export type SfmBooleanFieldProps = WithCustomLabel<BooleanFieldProps>
export const SfmBooleanField = withCustomLabel(BooleanField)

export type SfmDateFieldProps = WithCustomLabel<DateFieldProps>
export const SfmDateField = withCustomLabel(DateField)

export type SfmEmailFieldProps = WithCustomLabel<EmailFieldProps>
export const SfmEmailField = withCustomLabel(EmailField)

export type SfmFunctionFieldProps = WithCustomLabel<FunctionFieldProps>
export const SfmFunctionField = withCustomLabel(FunctionField)

export type SfmImageFieldProps = WithCustomLabel<ImageFieldProps>
export const SfmImageField = withCustomLabel(ImageField)

export type SfmFileFieldProps = WithCustomLabel<FileFieldProps>
export const SfmFileField = withCustomLabel(FileField)

export type SfmNumberFieldProps = WithCustomLabel<NumberFieldProps>
export const SfmNumberField = withCustomLabel(NumberField, {
  variant: 'body1',
})

export type SfmRichTextFieldProps = WithCustomLabel<RichTextFieldProps>
export const SfmRichTextField = withCustomLabel(RichTextField)

export type SfmTextFieldProps = WithCustomLabel<TextFieldProps>
export const SfmTextField = withCustomLabel(TextField, { variant: 'body1' })

export type SfmUrlFieldProps = WithCustomLabel<UrlFieldProps>
export const SfmUrlField = withCustomLabel(UrlField)

export type SfmReferenceArrayFieldProps =
  WithCustomLabel<ReferenceArrayFieldProps>
export const SfmReferenceArrayField = withCustomLabel(
  ReferenceArrayField,
  { addLabel: false },
  { fullBleed: true },
)

export type SfmReferenceManyFieldProps =
  WithCustomLabel<ReferenceManyFieldProps>
export const SfmReferenceManyField = withCustomLabel(
  ReferenceManyField,
  { addLabel: false },
  { fullBleed: true },
)

export type SfmReferenceFieldProps = WithCustomLabel<ReferenceFieldProps>
export const SfmReferenceField = withCustomLabel(
  ReferenceField,
  { addLabel: false, link: 'show' },
  {},
)
