import { SfmResource } from 'src/AppScopes/resource'
import type { SfmResourceProps } from 'src/AppScopes/resource'

import { Create } from './Create'
import { Edit } from './Edit'
import { ClientList } from './List'
import { Show } from './Show'

export function Resource(props: SfmResourceProps) {
  return (
    <SfmResource
      {...props}
      list={ClientList}
      create={Create}
      edit={Edit}
      show={Show}
    />
  )
}
