import { keyBy } from 'lodash'
import { useRecordContext } from 'react-admin'
import { CustomDatagrid } from 'src/components/CustomDatagrid'
import { LabeledField } from 'src/components/LabeledField'

import { SfmTextField } from 'src/components/SfmFields'
import { formatApiPlatformId } from 'src/libs/apiPlatformId'
import { useGetList } from 'src/libs/useGetList'
import type { Intervention, Form } from 'src/types/api'
import { ResourceEnum } from 'src/types/api/resources'

import { useCreateResourceUrl } from '../useCreateResourceUrl'

export function InterventionsField() {
  const form = useRecordContext<Form>()
  const { data: interventions } = useGetList<Intervention>(
    ResourceEnum.interventions,
    {
      filter: { 'form.id': formatApiPlatformId(form.id) },
    },
    {
      enabled: Boolean(form.id),
    },
  )

  const resourceUrl = useCreateResourceUrl()

  return (
    <LabeledField label="titles.interventions.list" fullBleed>
      <CustomDatagrid
        rowClick={(id) => resourceUrl('interventions', id, 'show')}
        data={keyBy(interventions, 'id')}
        ids={interventions?.map((intervention) => intervention.id) ?? []}
        currentSort={{ field: 'id', order: 'ASC' }}
      >
        <SfmTextField source="name" label="resource.interventions.name" />
      </CustomDatagrid>
    </LabeledField>
  )
}
