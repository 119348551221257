import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import BlockIcon from '@material-ui/icons/Block'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import { useState } from 'react'
import type { PropsWithChildren } from 'react'
import type {
  Identifier,
  Record as RaRecord,
  ShowActionsProps,
} from 'react-admin'
import {
  EditButton,
  TopToolbar,
  useUpdate,
  Button,
  useGetMany,
  useRecordContext,
} from 'react-admin'

import { SfmShow } from 'src/AppScopes/resource'
import type { SfmShowProps } from 'src/AppScopes/resource'
import type { ActionsProps } from 'src/components/ProtectedActions/types'
import { RolesField } from 'src/components/RolesField'

import {
  SfmTextField,
  SfmEmailField,
  SfmReferenceArrayField,
  SfmDateField,
} from 'src/components/SfmFields'
import { ShowLink } from 'src/components/ShowLink'
import type { User } from 'src/types/api'

import { RoleEnum, usePersona } from 'src/UserIdentity'

import { LabeledField } from '../../components/LabeledField'
import { ShowLayout } from '../../components/ShowLayout'

import { useCreateResourceUrl } from '../useCreateResourceUrl'

import { UserEntitlementList } from './EntitlementList'

export function Show(props: SfmShowProps) {
  const [deletedEntitlements, setDeletedEntitlements] = useState<
    Set<Identifier>
  >(new Set())

  const { role } = usePersona()

  const isAdmin = role === RoleEnum.ROLE_ADMIN

  return (
    <SfmShow
      {...props}
      acceptedRoles={[
        RoleEnum.ROLE_ADMIN,
        RoleEnum.ROLE_CONFIGURATOR,
        RoleEnum.ROLE_MANAGER,
      ]}
      actions={<CustomShowActions />}
    >
      <ShowLayout<User>
        title={(record) => `${record.firstName} ${record.lastName}`}
        meta={() => (
          <SfmDateField source="lastLogin" label="resource.users.lastLogin" />
        )}
      >
        <SfmTextField
          source="personalNumber"
          label="resource.users.personalNumber"
        />
        <SfmTextField source="username" label="resource.users.username" />
        <SfmEmailField
          source="emailAddress"
          label="resource.users.emailAddress"
        />
        <SfmTextField source="companyName" label="resource.users.companyName" />
        <SfmTextField source="serviceName" label="resource.users.serviceName" />
        <SfmTextField source="workplace" label="resource.users.workplace" />
        <LabeledField label="resource.users.roles">
          <RolesField source="roles" />
        </LabeledField>

        {isAdmin && (
          <SfmReferenceArrayField
            source="clients"
            reference="clients"
            label="resource.users.clients"
          >
            <ClientList />
          </SfmReferenceArrayField>
        )}

        <UserEntitlementList
          label="resource.users.userEntitlements"
          deletedEntitlements={deletedEntitlements}
          setDeletedEntitlements={setDeletedEntitlements}
          filter="habilitation"
          isAdmin={isAdmin}
        />
        <UserEntitlementList
          label="resource.users.userEntitlements"
          deletedEntitlements={deletedEntitlements}
          setDeletedEntitlements={setDeletedEntitlements}
          filter="autorisation"
          isAdmin={isAdmin}
        />
      </ShowLayout>
    </SfmShow>
  )
}

interface ClientListProps {}

function ClientList(props: ClientListProps) {
  const resourceUrl = useCreateResourceUrl()
  const user = useRecordContext(props)
  const { clients: clientsIds } = user
  const { data: clients } = useGetMany('clients', clientsIds)
  return (
    <List dense>
      {clients.map((client: RaRecord) => {
        return (
          <ListItem key={client.id}>
            <ShowLink
              to={resourceUrl('clients', client.id, 'show')}
              label={client.name}
            />
          </ListItem>
        )
      })}
    </List>
  )
}

type Props = PropsWithChildren<ShowActionsProps & ActionsProps>
function CustomShowActions(props: Props) {
  const { basePath, data } = props
  const { role } = usePersona()
  const user = useRecordContext<User>()

  if (!role || role !== RoleEnum.ROLE_ADMIN) return null
  return (
    <TopToolbar>
      <ToggleUserStatus
        userId={user?.id}
        userStatus={Boolean(user?.isEnabled)}
      />

      <EditButton basePath={basePath} record={data} />
    </TopToolbar>
  )
}
interface ToggleUserStatusProps {
  userId: Identifier
  userStatus: boolean
}
function ToggleUserStatus(props: ToggleUserStatusProps) {
  const { userId, userStatus } = props
  const [update] = useUpdate()
  function disableUser() {
    update(
      'users',
      userId,
      { isEnabled: !userStatus },
      {},
      {
        mutationMode: 'optimistic',
      },
    )
  }
  return (
    <Button
      color={userStatus ? 'secondary' : 'primary'}
      variant="text"
      label={
        userStatus ? 'resource.users.disableUser' : 'resource.users.enableUser'
      }
      onClick={disableUser}
      startIcon={userStatus ? <BlockIcon /> : <CheckCircleOutlineIcon />}
    />
  )
}
