import type {
  FieldNode,
  Case,
  Func,
  Literal,
  Rule,
} from '@nartex/sfm-form-engine'
import { FuncNameEnum } from '@nartex/sfm-form-engine'
import { getArgumentsValues } from 'src/RuleEditor/argumentsValuesUtils'
import {
  ArgumentTypeEnum,
  DEFAULT_POSSIBLE_TYPE,
  isArgumentVariableValue,
  OperatorMap,
} from 'src/RuleEditor/operators'

export function ruleHasError(
  rule: Rule,
  fieldNames: Set<string>,
  fields: FieldNode[],
): boolean {
  if (rule.cases.length === 0) {
    return true
  }

  if (
    rule.cases.some((ruleCase) => caseHasError(ruleCase, fieldNames, fields))
  ) {
    return true
  }

  return false
}

export function caseHasError(
  ruleCase: Case,
  fieldNames: Set<string>,
  fields: FieldNode[],
): boolean {
  const { condition } = ruleCase

  if (!condition) return true

  if (condition.type === 'function') {
    return functionHasError(condition, fieldNames, fields)
  }

  return false
}

function functionHasError(
  func: Func,
  fieldNames: Set<string>,
  fields: FieldNode[],
): boolean {
  if (func.funcName === 'getValues') {
    throw new Error('GetValues function')
  }

  if (func.funcName === 'getValue') {
    if (fieldNames.has((func.args[0] as Literal<string>).value)) {
      return false
    }
    return true
  }

  const hasArgumentUndefined = func.args.some((arg) => {
    if (!arg) return true
    return false
  })
  if (hasArgumentUndefined) return true

  const hasUnknownReference = func.args.some((arg) => {
    if (arg?.type === 'function') {
      return functionHasError(arg, fieldNames, fields)
    }
    return false
  })
  if (hasUnknownReference) return true

  const operator = OperatorMap[func.funcName]
  const args = getArgumentsValues(fields, operator, func.args)
  const { subject, ...comparisonArgs } = args
  if (!isArgumentVariableValue(subject)) {
    return true
  }
  if (func.funcName === FuncNameEnum.isFilled) {
    const subjectTypeIsInValid = ![
      ...DEFAULT_POSSIBLE_TYPE,
      'Audio',
      'Video',
      'Photo',
    ].includes(subject?.value.type)

    if (subjectTypeIsInValid) {
      return true
    }
  } else {
    const {
      type,
      data: { name },
    } = subject.value

    let validArgumentType: ArgumentTypeEnum
    if (type === 'Checkbox') {
      validArgumentType = ArgumentTypeEnum.boolean
    } else if (['SelectMany', 'SelectOne'].includes(type)) {
      validArgumentType = ArgumentTypeEnum.fixedOption
    } else if (type === 'Text') {
      validArgumentType = ArgumentTypeEnum.openTextValue
    } else if (type === 'Number') {
      validArgumentType = ArgumentTypeEnum.openNumberValue
    }

    return Object.values(comparisonArgs).some((argument) => {
      if (!isArgumentVariableValue(argument)) {
        return argument.type !== validArgumentType
      }
      // Test if subject appears multiple time in arg
      if (argument.value.data.name === name) return true

      // Test if args type is the same as subject
      if (argument.value.type !== type) return true

      return false
    })
  }

  return false
}
