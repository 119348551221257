import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core'
import { VideoQualityEnum, PhotoSizeEnum } from '@nartex/sfm-form-engine'
import { useTranslate } from 'src/adapters/I18nProvider'

interface Props {
  type: 'Photo' | 'Video'
  value: any
  onChange: (newValue: any) => void
}

export function QualityPicker(props: Props) {
  const { type, value, onChange } = props

  const __ = useTranslate()

  let options:
    | { value: PhotoSizeEnum; label: string }[]
    | { value: VideoQualityEnum; label: string }[] = []

  let label = ''

  if (type === 'Photo') {
    label = __({
      id: `ComponentEditor.quality.label.photo`,
      description:
        'label of the select component for the resolution / size of photo',
      defaultMessage: 'Taille',
    })
    options = [
      {
        value: PhotoSizeEnum.SMALL,
        label: __({
          id: `ComponentEditor.quality.photo.small`,
          description: 'Label for the small value of photo size',
          defaultMessage: 'Petite',
        }),
      },
      {
        value: PhotoSizeEnum.MEDIUM,
        label: __({
          id: `ComponentEditor.quality.photo.medium`,
          description: 'Label for the medium value of photo size',
          defaultMessage: 'Moyenne',
        }),
      },
      {
        value: PhotoSizeEnum.LARGE,
        label: __({
          id: `ComponentEditor.quality.photo.large`,
          description: 'Label for the large value of photo size',
          defaultMessage: 'Grande',
        }),
      },
    ]
  }
  if (type === 'Video') {
    label = __({
      id: `ComponentEditor.quality.label.video`,
      description: 'label of the select component for the quality of video',
      defaultMessage: 'Qualité',
    })
    options = [
      {
        value: VideoQualityEnum.LOW,
        label: __({
          id: `ComponentEditor.quality.video.low`,
          description: 'Label for the low value of video quality',
          defaultMessage: 'Basse',
        }),
      },
      {
        value: VideoQualityEnum.HIGH,
        label: __({
          id: `ComponentEditor.quality.video.high`,
          description: 'Label for the high value of video quality',
          defaultMessage: 'Haute',
        }),
      },
    ]
  }

  return (
    <FormControl>
      <InputLabel shrink={true}>{label}</InputLabel>
      <Select value={value} onChange={onChange}>
        {options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}
