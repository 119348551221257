const packageJson = require('../package.json')

export const config = {
  APP_VERSION: packageJson.version,
  APP_NAME: packageJson.name,
  APP_DISPLAY_NAME: packageJson.displayName,
  APP_RELEASE: `${packageJson.name}@${packageJson.version}` as const,

  SUPPORTED_LANGUAGES: ['fr', 'en'],
  DEFAULT_LOCALE: 'en',
} as const

export const features = {
  enableRulesEditor: true,
} as const
