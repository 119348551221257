import { FormControlLabel, makeStyles, Switch } from '@material-ui/core'
import { useState } from 'react'
import { FieldTitle, useTranslate } from 'react-admin'
import type { SfmListProps } from 'src/AppScopes'
import { SfmList } from 'src/AppScopes'
import { CustomDatagrid } from 'src/components/CustomDatagrid'
import { SegmentedControl } from 'src/components/SegmentedControl'
import { SfmFunctionField, SfmTextField } from 'src/components/SfmFields'
import { ShowDocumentButton } from 'src/components/ShowDocumentButton'
import { getFileExtension } from 'src/libs/fileMimeType'
import { RoleEnum } from 'src/UserIdentity'

type DocumentType = 'generique' | 'document'

const useStyles = makeStyles(function (theme) {
  return {
    filters: {
      display: 'flex',
      gap: theme.spacing(2),
    },
  }
})

export function DocumentList(props: SfmListProps) {
  const __ = useTranslate()
  const [typeFilter, setTypeFilter] = useState<DocumentType>('document')
  const [showArchived, setShowArchived] = useState(false)

  const styles = useStyles()
  return (
    <SfmList
      {...props}
      acceptedEditRoles={[RoleEnum.ROLE_CONFIGURATOR]}
      filter={{ type: typeFilter, showArchived }}
      filters={
        <div className={styles.filters}>
          <SegmentedControl<DocumentType>
            value={typeFilter}
            onChange={setTypeFilter}
            options={[
              { value: 'document', label: 'filters.document.type.document' },
              { value: 'generique', label: 'filters.document.type.generique' },
            ]}
          />

          <FormControlLabel
            control={
              <Switch
                color="primary"
                checked={showArchived}
                onChange={(e, checked) => setShowArchived(checked)}
              />
            }
            label={<FieldTitle label="filters.archived.documents" />}
          />
        </div>
      }
      empty={false}
    >
      <CustomDatagrid>
        <SfmTextField source="name" label="resource.documents.name" />
        <SfmFunctionField
          label="resource.documents.mimeType"
          render={(document) => {
            if (document?.isUrl) return __('resource.documents.url')
            return getFileExtension(document?.mimeType)
          }}
        />
        <ShowDocumentButton />
      </CustomDatagrid>
    </SfmList>
  )
}
