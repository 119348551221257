import type { ReactElement } from 'react'
import type { RecordMap } from 'react-admin'
import {
  useListContext,
  ReferenceArrayField,
  ListContextProvider,
} from 'react-admin'

import type { SfmReferenceManyFieldProps } from './SfmFields'
import { SfmReferenceManyField } from './SfmFields'

interface ManyToManyFieldRenderProps {
  reference: string
  using: string
  children: ReactElement
}

type ManyToManyFieldProps = ManyToManyFieldRenderProps &
  Omit<SfmReferenceManyFieldProps, 'reference'> & {
    through: string
  }

export function ManyToManyField(props: ManyToManyFieldProps) {
  const { reference, through, using, children, ...rest } = props
  return (
    <SfmReferenceManyField {...rest} reference={through}>
      <RenderInContext reference={reference} using={using}>
        {children}
      </RenderInContext>
    </SfmReferenceManyField>
  )
}

function RenderInContext(props: ManyToManyFieldRenderProps) {
  const { reference, using, children } = props
  const { data, loading } = useListContext()

  if (loading) return null

  const associateDataMap: RecordMap = {}

  const list = Object.values(data).map((record) => {
    const id = record[using]
    associateDataMap[id] = record
    return id
  })

  return (
    <ReferenceArrayField
      addLabel={false}
      source="ids"
      reference={reference}
      record={{ id: '', ids: list }}
    >
      <AssociateDataInjecter dataMap={associateDataMap} using={using}>
        {children}
      </AssociateDataInjecter>
    </ReferenceArrayField>
  )
}

interface DataInjecterProps {
  dataMap: RecordMap
  using: string
  children: ReactElement
}

function AssociateDataInjecter(props: DataInjecterProps) {
  const { dataMap, using, children } = props
  const { data, ...rest } = useListContext()

  if (rest.loading) return null

  const injectedData: RecordMap = {}

  Object.values(data).forEach((record) => {
    const { id } = record
    injectedData[id] = { ...dataMap[id], [using]: data[id] }
  })

  return (
    <ListContextProvider value={{ data: injectedData, ...rest }}>
      {children}
    </ListContextProvider>
  )
}
