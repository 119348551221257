import { CircularProgress, makeStyles } from '@material-ui/core'
import { useCallback, useState } from 'react'
import {
  Button,
  DateInput,
  useNotify,
  useRecordContext,
  useUpdate,
} from 'react-admin'
import { CustomForm } from 'src/components/CustomForm'
import { SfmDateField } from 'src/components/SfmFields'
import type { Intervention } from 'src/types/api'
import { ResourceEnum } from 'src/types/api/resources'
import { CancelIcon, CheckIcon, EditIcon } from 'src/UI/theme/icons'

interface Props {
  readonly?: boolean
}

const useStyles = makeStyles({
  container: {
    display: 'flex',
    alignItems: 'flex-start',
    gap: '1rem',
  },
  inputContainer: {
    width: 'fit-content',
    flexGrow: 0,
  },
  input: {
    minWidth: 256,
  },
  alignWithInput: {
    marginTop: 8,
  },
})

export function StartDateField(props: Props) {
  const { readonly } = props
  const intervention = useRecordContext<Intervention>()
  const [startDate, setStartDate] = useState<Intervention['start']>()
  const overridenIntervention = {
    ...intervention,
    ...(startDate && { start: startDate }),
  }

  const [isEditing, setIsEditing] = useState(false)
  const hide = useCallback(() => setIsEditing(false), [])
  const show = useCallback(() => setIsEditing(true), [])

  const notify = useNotify()
  const [update, { loading }] = useUpdate()
  const submit = (value: Intervention) => {
    update(
      ResourceEnum.interventions,
      intervention.id,
      { start: value.start },
      { start: startDate },
      {
        onSuccess: function () {
          setStartDate(value.start)
          notify('notifications.update.intervention', 'success')
          hide()
        },
        onFailure: function (error) {
          notify(error.message, 'error')
        },
      },
    )
  }

  const styles = useStyles()

  if (readonly || !isEditing) {
    return (
      <SfmDateField
        record={overridenIntervention}
        source="start"
        label="resource.interventions.start"
        actions={
          !readonly && (
            <Button label="actions.edit" onClick={show}>
              <EditIcon />
            </Button>
          )
        }
      />
    )
  }

  return (
    <CustomForm<Intervention>
      resource={ResourceEnum.interventions}
      record={overridenIntervention}
      submit={submit}
      render={(handleSubmit) => (
        <div className={styles.container}>
          <DateInput
            source="start"
            label="resource.interventions.start"
            className={styles.inputContainer}
          />
          <Button
            label="actions.cancel"
            onClick={hide}
            disabled={loading}
            className={styles.alignWithInput}
            color="secondary"
          >
            <CancelIcon />
          </Button>
          <Button
            onClick={handleSubmit}
            disabled={loading}
            className={styles.alignWithInput}
            label="actions.confirm"
            variant="contained"
          >
            {loading ? <CircularProgress size={16} /> : <CheckIcon />}
          </Button>
        </div>
      )}
    />
  )
}
