import type { FieldNode, InputName } from '@nartex/sfm-form-engine'
import getSlug from 'speakingurl'

import { definitions } from '../ComponentsLibrary/definitions'

export interface Variable {
  id: string
  type: InputName
  name: string
  label: string
  selectable: boolean
}

const NotSelectableFields: FieldNode['type'][] = [
  'Photo',
  'Video',
  'Audio',
  'Signature',
]

export function fieldsToVariablesList(fields: FieldNode[]): Variable[] {
  return fields
    .filter((field) => definitions[field.type].props.name)
    .map((field) => {
      return {
        id: field.data['@id'],
        type: field.type,
        name: field.data.name,
        label: field.data.label || '',
        selectable: !NotSelectableFields.includes(field.type),
      }
    })
}

export function slugify(name?: string) {
  if (!name) return ''
  const options = { separator: '_', custom: { '-': '_' } }

  // preserve last char, in case of input filling, for example
  const lastChar = name[name.length - 1]
  if (getSlug(lastChar, options) === '') {
    return getSlug(name, options) + '_'
  }

  return getSlug(name, options)
}

export function displayFieldName(name: string) {
  return '${' + name + '}'
}

export function getNewFieldName(
  proposedName: string,
  fieldNames: Pick<Variable, 'label' | 'name'>[],
) {
  const names = fieldNames.map((variable) => variable.name)
  const labels = fieldNames.map((variable) => variable.label)

  let n = 1

  while (true) {
    const label = n === 1 ? proposedName : `${proposedName} ${n}`
    const name = slugify(label)
    if (names.includes(name) || labels.includes(label)) {
      n++
    } else {
      return { name, label }
    }
  }
}
