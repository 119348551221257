import { makeStyles } from '@material-ui/core'
import { useState, useMemo } from 'react'
import { SfmList } from 'src/AppScopes/resource'
import type { SfmListProps } from 'src/AppScopes/resource'
import { CustomDatagrid } from 'src/components/CustomDatagrid'
import { SegmentedControl } from 'src/components/SegmentedControl'
import { SfmTextField, SfmDateField } from 'src/components/SfmFields'
import StatusField from 'src/components/StatusField'
import { WorkOrderStatusEnum } from 'src/types/api'
import { RoleEnum } from 'src/UserIdentity'

type OrderFilterType = 'created' | 'started' | 'validating' | 'ended'

export function List(props: SfmListProps) {
  const [filterType, setFilterType] = useState<OrderFilterType>('created')
  const useStyles = makeStyles(function (theme) {
    return {
      filters: {
        display: 'flex',
        gap: theme.spacing(2),
      },
    }
  })

  const filters = useMemo(() => getFilterParams(filterType), [filterType])
  const styles = useStyles()

  return (
    <SfmList
      {...props}
      acceptedEditRoles={[RoleEnum.ROLE_CONFIGURATOR]}
      filter={filters}
      filters={
        <div className={styles.filters}>
          <SegmentedControl<OrderFilterType>
            key="managerFilter"
            value={filterType}
            onChange={setFilterType}
            options={[
              {
                value: 'created',
                label: 'status.created',
              },
              {
                value: 'started',
                label: 'status.started',
              },
              {
                value: 'validating',
                label: 'status.validating',
              },
              {
                value: 'ended',
                label: 'status.ended',
              },
            ]}
          />
        </div>
      }
    >
      <CustomDatagrid>
        <SfmTextField
          source="reference"
          label="resource.work_orders.reference"
        />
        <SfmDateField source="start" label="resource.work_orders.start" />
        <StatusField label="resource.work_orders.status" />
      </CustomDatagrid>
    </SfmList>
  )
}

function getFilterParams(filterType: OrderFilterType): Record<string, any> {
  if (filterType === 'created') {
    return {
      status: WorkOrderStatusEnum.created,
    }
  }
  if (filterType === 'started') {
    return {
      status: WorkOrderStatusEnum.started,
    }
  }
  if (filterType === 'validating') {
    return {
      status: WorkOrderStatusEnum.validating,
    }
  }
  if (filterType === 'ended') {
    return {
      status: WorkOrderStatusEnum.ended,
    }
  }
  return {} as never
}
