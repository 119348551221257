import {
  Checkbox,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core'
import type { FieldNode, InputName } from '@nartex/sfm-form-engine'
import type { ProfunctorState } from '@staltz/use-profunctor-state'
import { useTranslate } from 'src/adapters/I18nProvider'

interface Props {
  type: InputName
  fieldPropsStore: ProfunctorState<FieldNode['data'] | undefined>
}

export function DefaultValueInput(props: Props) {
  const { type, fieldPropsStore } = props

  const { state: fieldProps, setState: setFieldProps } = fieldPropsStore

  const __ = useTranslate()

  if (!fieldProps) return null

  const label = __({
    id: 'ComponentEditor.defaultValue.fieldTitle',
    description: "Component's default value field title",
    defaultMessage: 'Valeur par défaut',
  })

  const value = fieldProps.defaultValue ?? ''
  const setDefaultValue = (newValue: any) => {
    setFieldProps((prev) => {
      if (!prev) return prev
      return {
        ...prev,
        defaultValue: newValue === '' ? undefined : newValue,
      }
    })
  }

  if (type === 'SelectOne') {
    return (
      <SelectDefaultInput
        label={label}
        options={fieldProps.options}
        value={value}
        setValue={setDefaultValue}
      />
    )
  }

  if (type === 'Checkbox') {
    return (
      <CheckboxDefaultInput
        label={label}
        value={value}
        setValue={setDefaultValue}
      />
    )
  }

  return (
    <DefaultInput
      label={label}
      value={value}
      setValue={setDefaultValue}
      type={type === 'Number' ? 'number' : 'text'}
      multiline={Boolean(fieldProps.multiline)}
    />
  )
}
interface BaseDefaultInputProps {
  label: string
  value: any
  setValue: (newValue: any) => void
}
interface SelectDefaultInputProps extends BaseDefaultInputProps {
  options?: string[]
}

function SelectDefaultInput(props: SelectDefaultInputProps) {
  const { options, label, value, setValue } = props
  const __ = useTranslate()

  const hasOptions = options && options.length > 0
  return (
    <FormControl>
      <InputLabel shrink={true}>{label}</InputLabel>
      <Select
        displayEmpty={!hasOptions}
        value={value}
        onChange={(e) => setValue(e.target.value)}
      >
        {hasOptions ? (
          [
            <MenuItem key="" value="">
              <i>
                {__({
                  id: 'ComponentEditor.defaultValue.select.emptyValue',
                  description:
                    'Option to empty the defaultValue select component',
                  defaultMessage: 'Aucune valeur',
                })}
              </i>
            </MenuItem>,
            options.map((option, index) => (
              <MenuItem key={`${option}${index}`} value={option}>
                {option}
              </MenuItem>
            )),
          ]
        ) : (
          <MenuItem value="" disabled>
            {__({
              id: 'ComponentEditor.defaultValue.select.placeholder',
              description:
                'Placeholder of the defaultValue select when there is no option',
              defaultMessage: 'Veuillez renseigner des options',
            })}
          </MenuItem>
        )}
      </Select>
    </FormControl>
  )
}

function CheckboxDefaultInput(props: BaseDefaultInputProps) {
  const { label, value, setValue } = props
  return (
    <>
      <InputLabel>{label}</InputLabel>
      <Checkbox
        style={{ width: 'fit-content' }}
        checked={Boolean(value)}
        onChange={(e) => setValue(e.target.checked || undefined)}
      />
    </>
  )
}

interface DefaultInputProps extends BaseDefaultInputProps {
  type: 'number' | 'text'
  multiline: boolean
}

function DefaultInput(props: DefaultInputProps) {
  const { label, value, setValue, type, multiline } = props

  return (
    <TextField
      type={type}
      multiline={multiline}
      rows={multiline ? 4 : undefined}
      label={label}
      value={value}
      onChange={(e) => setValue(e.target.value)}
    />
  )
}
