import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import { AppLocationContext } from '@react-admin/ra-navigation'
import type { PropsWithChildren } from 'react'
import { Layout as RaLayout } from 'react-admin'
import type { LayoutProps } from 'react-admin'
import { DataProviderIdNormalizer } from 'src/adapters/DataProvider/IdNormalizerProxy'
import { useEnv } from 'src/adapters/Env'
import { AppScopeProvider } from 'src/AppScopes'
import { definitions } from 'src/Resources'
import type { RoleEnum } from 'src/UserIdentity'
import { IdentityProvider } from 'src/UserIdentity'

import { AppBar } from './AppBar'
import { useSupportedBrowserLocale } from './localization'
import { Menu } from './Menu'

export { theme } from './theme'
export * from './localization'

function MainContextProvider(props: PropsWithChildren<{}>) {
  const { children } = props
  useSupportedBrowserLocale()

  return (
    <AppScopeProvider resourceDefinitions={definitions}>
      <DataProviderIdNormalizer>
        <IdentityProvider>{children}</IdentityProvider>
      </DataProviderIdNormalizer>
    </AppScopeProvider>
  )
}

export function NakedLayout(props: PropsWithChildren<{ role?: RoleEnum }>) {
  const { children } = props
  useSupportedBrowserLocale()

  return <MainContextProvider>{children}</MainContextProvider>
}

export function Layout(props: LayoutProps) {
  const { children } = props
  const env = useEnv()

  return (
    <MainContextProvider>
      <AppLocationContext>
        <RaLayout {...props} menu={Menu} appBar={AppBar as any}>
          {children}

          <Box paddingY={1} paddingX={4} marginTop="auto">
            <Typography
              variant="body2"
              align="center"
              style={{ margin: 'auto' }}
            >
              {env.APP_DISPLAY_NAME} (v{env.APP_VERSION}) — API (v
              {env.API_VERSION})
            </Typography>
          </Box>
        </RaLayout>
      </AppLocationContext>
    </MainContextProvider>
  )
}
