import {
  IonButton,
  IonCheckbox,
  IonInput,
  IonLabel,
  IonTextarea,
} from '@ionic/react'
import FontIcon from '@nartex/smartforest-design-tokens/graphics/react/BxBxFont'
import MinusIcon from '@nartex/smartforest-design-tokens/graphics/react/BxBxMinus'
import PlusIcon from '@nartex/smartforest-design-tokens/graphics/react/BxBxPlus'
import NumericIcon from '@nartex/smartforest-design-tokens/graphics/react/IconNumeric'
import classNames from 'classnames'

import { useQuickActions } from '../../components/useQuickActions'

import type {
  CheckboxProps,
  InputProps,
  NumberInputProps,
  TextInputProps,
  BaseTextInputProps,
} from '../types'

import { BaseInputWrapper as InputWrapper } from './BaseInputWrapper'

export * from './SelectInput'
export { TimeInput, DateTimeInput, CalendarInput } from './DateInput'

export function TextInput(props: TextInputProps) {
  const { multiline } = props

  if (multiline) {
    return <TextAreaInput {...props} />
  } else {
    return <BaseTextInput {...props} type="text" />
  }
}

function BaseTextInput(props: BaseTextInputProps) {
  const { type } = props
  const { inputProps, className, ActionsBar } = useQuickActions()

  return (
    <InputWrapper
      {...props}
      className={classNames('border-input', className)}
      Icon={FontIcon}
      render={(controllerProps) => {
        const { value, onChange } = controllerProps
        return (
          <>
            <IonInput
              {...inputProps}
              type={type}
              value={value}
              inputMode={type === 'password' ? 'text' : type}
              onIonChange={(e) => onChange(e.detail.value)}
              debounce={200}
            />
            <ActionsBar onChange={onChange} />
          </>
        )
      }}
    />
  )
}

function TextAreaInput(props: InputProps) {
  const { inputProps, className, ActionsBar } = useQuickActions()

  return (
    <InputWrapper
      {...props}
      className={classNames('border-input', className)}
      Icon={FontIcon}
      render={(controllerProps) => {
        const { value, onChange } = controllerProps
        return (
          <>
            <IonTextarea
              {...inputProps}
              value={value}
              onIonChange={(e) => onChange(e.detail.value)}
              debounce={500}
              style={{ '--padding-top': 0 }}
            />
            <ActionsBar onChange={onChange} />
          </>
        )
      }}
    />
  )
}

export function NumberInput(props: NumberInputProps) {
  const { min, max, decimalCount } = props
  const { inputProps, className, ActionsBar } = useQuickActions()

  const step =
    decimalCount !== undefined ? Math.pow(10, -1 * decimalCount) : undefined

  return (
    <InputWrapper
      {...props}
      className={classNames('border-input', className)}
      Icon={NumericIcon}
      render={(controllerProps) => {
        const { value } = controllerProps

        const onChange = (newVal?: string | number | null) => {
          if (typeof newVal === 'string') {
            newVal = parseFloat(newVal)
          }
          controllerProps.onChange(Number.isFinite(newVal) ? newVal : undefined)
        }

        return (
          <>
            <IonInput
              {...inputProps}
              type="number"
              value={value}
              step={step?.toString() ?? 'any'}
              inputMode="numeric"
              onIonChange={(e) => onChange(e.detail.value)}
              debounce={500}
            />
            <ActionsBar onChange={onChange} />

            <IonButton
              slot="end"
              className="input-icon -isAction"
              fill="clear"
              strong
              onClick={(e) => {
                e.preventDefault()
                const result = (value ?? 0) - (step ?? 1)
                if (min !== undefined) {
                  return onChange(Math.max(min, result))
                } else return onChange(result)
              }}
              disabled={
                min !== undefined && value !== undefined && value <= min
              }
            >
              <MinusIcon />
            </IonButton>
            <IonButton
              slot="end"
              className="input-icon -isAction"
              fill="clear"
              strong
              onClick={(e) => {
                e.preventDefault()
                const result = (value ?? 0) + (step ?? 1)
                if (max !== undefined) {
                  return onChange(Math.min(max, result))
                } else return onChange(result)
              }}
              disabled={
                max !== undefined && value !== undefined && value >= max
              }
              style={{ marginRight: '-0.5rem' }}
            >
              <PlusIcon />
            </IonButton>
          </>
        )
      }}
    />
  )
}

export function CheckboxInput(props: CheckboxProps) {
  const { slot = 'start' } = props
  return (
    <InputWrapper
      {...props}
      showLabel={false}
      className="checkbox-item"
      render={(controllerProps, itemProps) => {
        const { value, onChange } = controllerProps
        const { label } = itemProps

        return (
          <>
            <IonCheckbox
              checked={value}
              onIonChange={(e) => onChange(e.detail.checked)}
              slot={slot}
              color="primary"
              className="checkbox-icon"
            />
            <IonLabel className="ion-text-wrap">{label}</IonLabel>
          </>
        )
      }}
    />
  )
}
