import AddIcon from '@nartex/smartforest-design-tokens/graphics/react/BxBxPlus'
import SignatureIcon from '@nartex/smartforest-design-tokens/graphics/react/LaSignature'

import type { InputProps } from '../types'

import { MediaInput } from './MediaInput'
import './SignatureInput.css'

export function SignatureInput(props: InputProps) {
  return (
    <MediaInput
      {...props}
      mediaType="Signature"
      AddIcon={AddIcon}
      Icon={SignatureIcon}
      renderMedia={(src) => {
        return (
          <div className="SignatureInput-content">
            <img src={src} className="image" />
          </div>
        )
      }}
    />
  )
}
