import { IonRippleEffect } from '@ionic/react'
import './QuickKeyboardActions.css'
import ScanIcon from '@nartex/smartforest-design-tokens/graphics/react/BxBxBarcodeReader'
import NFCIcon from '@nartex/smartforest-design-tokens/graphics/react/IcSharpNfc'
import type { MouseEventHandler, PropsWithChildren } from 'react'
import { Portal } from 'react-portal'

import { usePlugins } from 'src/plugins'

import type { SvgComponent } from '../types'

interface Props {
  onChange: (value: string) => any
}

export function QuickKeyboardActions(props: Props) {
  const { onChange } = props
  const { BarCodePlugin, NfcPlugin } = usePlugins()

  return (
    <Portal>
      <div className="QuickKeyboardActions">
        {
          <QuickKeyboardAction
            onClick={async () => {
              const result = await NfcPlugin.scan()
              if ('value' in result) {
                onChange(tryParseJson(result.value))
              }
            }}
            Icon={NFCIcon}
          >
            NFC
            {/* TODO translate */}
            {/* {"QuickKeyboardActions.nfcButton.label"} */}
          </QuickKeyboardAction>
        }
        <QuickKeyboardAction
          onClick={async () => {
            const result = await BarCodePlugin.scan()
            if ('value' in result) {
              onChange(tryParseJson(result.value))
            }
          }}
          Icon={ScanIcon}
        >
          Scanner
          {/* TODO translate */}
          {/* {"QuickKeyboardActions.scanButton.label"} */}
        </QuickKeyboardAction>
      </div>
    </Portal>
  )
}

interface ActionProps {
  Icon: SvgComponent
  onClick?: MouseEventHandler<HTMLButtonElement>
}

function QuickKeyboardAction(props: PropsWithChildren<ActionProps>) {
  const { children, Icon, onClick } = props
  return (
    <button
      onPointerDown={onClick}
      type="button"
      className="QuickKeyboardAction ion-activatable ripple-parent"
    >
      <Icon />
      {children}
      <IonRippleEffect />
    </button>
  )
}

function tryParseJson(value?: string): any {
  try {
    if (value === undefined) return value
    return JSON.parse(value)
  } catch {
    return value
  }
}
