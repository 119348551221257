import { makeStyles } from '@material-ui/core'
import type { PropsWithChildren } from 'react'

const useStyles = makeStyles(function (theme) {
  const bleed = 1000
  return {
    root: {
      marginLeft: -1 * bleed,
      marginRight: -1 * bleed,
      paddingLeft: 1 * bleed,
      paddingRight: 1 * bleed,
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(2),
      background: theme.palette.background.paper,
    },
  }
})

export function FullBleed(props: PropsWithChildren<{}>) {
  const { children } = props
  const styles = useStyles()

  return <div className={styles.root}>{children}</div>
}
