import { ThemeProvider } from '@material-ui/core'
import Container from '@material-ui/core/Container'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import footer from '@nartex/smartforest-design-tokens/graphics/svg/footer.svg'
import logo from '@nartex/smartforest-design-tokens/graphics/svg/logo.svg'
import { useEnv } from 'src/adapters/Env'
import { theme } from 'src/UI'

const useStyles = makeStyles({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    height: '100vh',
    width: '100vh',
  },
  logo: {
    maxHeight: theme.spacing(16),
  },
  footer: {
    position: 'fixed',
    left: 0,
    bottom: 0,
  },
  appVersion: {
    position: 'fixed',
    left: '50%',
    transform: 'translateX(-50%)',
    bottom: theme.spacing(1),
  },
})

export function LoadingPage() {
  const styles = useStyles()
  const env = useEnv()

  return (
    <ThemeProvider theme={theme}>
      <Container className={styles.root}>
        <img
          className={styles.logo}
          src={logo}
          alt="Smart Forest Mobility® (logo)"
        />
        <br />
        <Typography variant="h4">Connexion en cours…</Typography>

        <img className={styles.footer} src={footer} alt="Eiffage (logo)" />

        <Typography
          variant="body2"
          align="center"
          className={styles.appVersion}
        >
          {env.APP_DISPLAY_NAME} (v{env.APP_VERSION}) — API (v
          {env.API_VERSION})
        </Typography>
      </Container>
    </ThemeProvider>
  )
}
