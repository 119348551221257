import type { Theme } from '@material-ui/core'
import { makeStyles, ThemeProvider } from '@material-ui/core'

import { SnackbarProvider } from 'notistack'
import type { PropsWithChildren } from 'react'

import type { IEnv } from './adapters/Env'
import { EnvContext } from './adapters/Env'
import { I18nProvider } from './adapters/I18nProvider'
import { StorageContext } from './adapters/Storage'

import type { AppProps } from './App'
import { App } from './App'

export interface WysiwygProps extends AppProps {
  adapters: {
    env: IEnv
    storage: Storage
  }
  theme: Theme
  locale: string
}

export * from '@nartex/sfm-form-engine'

export function Wysiwyg(props: WysiwygProps) {
  const { theme, adapters, locale } = props
  const { env, storage } = adapters

  return (
    <EnvContext.Provider value={env}>
      <StorageContext.Provider value={storage}>
        <ThemeProvider theme={theme}>
          <I18nProvider locale={locale}>
            <Notification>
              <App {...props} />
            </Notification>
          </I18nProvider>
        </ThemeProvider>
      </StorageContext.Provider>
    </EnvContext.Provider>
  )
}

const useStyle = makeStyles(function (theme) {
  return {
    notification: {
      borderRadius: theme.shape.borderRadius,
    },
    success: {
      backgroundColor: theme.palette.success.main,
      color: theme.palette.success.contrastText,
    },
    error: {
      backgroundColor: theme.palette.error.main,
      color: theme.palette.error.contrastText,
    },
    warning: {
      backgroundColor: theme.palette.warning.main,
      color: theme.palette.warning.contrastText,
    },
  }
})

function Notification(props: PropsWithChildren<{}>) {
  const style = useStyle()
  return (
    <SnackbarProvider
      maxSnack={1}
      anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
      hideIconVariant
      classes={{
        variantSuccess: style.success,
        variantError: style.error,
        variantWarning: style.warning,
      }}
      className={style.notification}
    >
      {props.children}
    </SnackbarProvider>
  )
}
