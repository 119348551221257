import { Box } from '@material-ui/core'
import saveAs from 'file-saver'
import type { ShowActionsProps } from 'react-admin'
import {
  TextField,
  DeleteButton,
  useGetOne,
  useTranslate,
  useNotify,
  useRecordContext,
  Button,
  TopToolbar,
  useRefresh,
  useUpdate,
} from 'react-admin'
import { useMutation } from 'react-query'
import { useEnv } from 'src/adapters/Env'
import { useHttpClient } from 'src/adapters/HTTPClient'
import { SfmShow } from 'src/AppScopes/resource'
import type { SfmShowProps } from 'src/AppScopes/resource'
import { EditableReferenceField } from 'src/components/EditableReferenceField'
import { EntitlementsFields } from 'src/components/EntitlementsFields'
import { FullNameField } from 'src/components/FullNameField'
import { ManyToManyField } from 'src/components/ManyToManyField'
import { ShowActions } from 'src/components/ProtectedActions'
import { RichDataList } from 'src/components/RichDataList'
import { Section } from 'src/components/Section'
import {
  EmptyText,
  SfmDateField,
  SfmFunctionField,
  SfmTextField,
} from 'src/components/SfmFields'
import { ShowLayout } from 'src/components/ShowLayout'
import { formatApiPlatformId, toApiPlatformId } from 'src/libs/apiPlatformId'
import { getFileExtension } from 'src/libs/fileMimeType'
import type {
  Item,
  InterventionItem,
  Equipment,
  User,
  Report,
  Project,
  WorkOrder,
  Form,
  Document,
  Intervention,
  Group,
} from 'src/types/api'
import { InterventionStatusEnum } from 'src/types/api'
import type { FullIntervention } from 'src/types/api/extendedTypes'
import { ResourceEnum } from 'src/types/api/resources'
import {
  CheckIcon,
  FormIcon,
  LocationIcon,
  ProjectIcon,
  WorkOrderIcon,
} from 'src/UI/theme/icons'
import { RoleEnum, usePersona } from 'src/UserIdentity'

import { UploadEquipmentsSheetField } from '../UploadEquipmentsSheet'

import { AssignItems } from './AssignItems'
import { AssociatedDataField } from './AssociatedDataField'
import { EditableUserOrGroupField } from './EditableUserOrGroupField'
import { InterventionDataSection } from './InterventionDataSection'
import { ListCheckerResult } from './ListCheckerResult'
import { ReportRecipients } from './ReportRecipients'
import { StartDateField } from './StartDateField'

type ExtendedInterventionItem = Omit<InterventionItem, 'item'> & {
  item: Item
}

export function Show(props: SfmShowProps & { isRound: boolean }) {
  const { isRound, ...showProps } = props
  const { role } = usePersona()

  const { data } = useGetOne<Intervention>(
    ResourceEnum.interventions,
    props.id ?? '',
  )

  const { status } = data ?? {}
  const readonly =
    !status ||
    [InterventionStatusEnum.validated, InterventionStatusEnum.ended].includes(
      status,
    )

  const isManager = role === RoleEnum.ROLE_MANAGER

  return (
    <SfmShow
      {...showProps}
      actions={isManager ? <ManagerAction /> : <ConfiguratorAction />}
      acceptedEditRoles={readonly ? [] : [RoleEnum.ROLE_CONFIGURATOR]}
    >
      <ShowInContext isRound={isRound} />
    </SfmShow>
  )
}

function ShowInContext(ShowProps: { isRound: boolean }) {
  const { isRound } = ShowProps
  const { role } = usePersona()
  const translate = useTranslate()

  const { id, status, workOrder } = useRecordContext<Intervention>()
  const isManager = role === RoleEnum.ROLE_MANAGER
  const isConfigurator = role === RoleEnum.ROLE_CONFIGURATOR
  const readonly =
    status &&
    [InterventionStatusEnum.validated, InterventionStatusEnum.ended].includes(
      status,
    )

  return (
    <ShowLayout<Intervention>
      caption={(record) => {
        if (record.isRound) {
          if (record.status) {
            return translate(`resource.rounds.fullStatus.${record.status}`)
          }
          return translate('resourceNames.rounds')
        }
        const name = record.status
          ? translate(`resource.interventions.fullStatus.${record.status}`)
          : translate('resourceNames.interventions')

        if (record.isRecurrent) {
          return `${name} (${translate('resource.interventions.recurring')})`
        }

        return name
      }}
      title={(intervention) => intervention.name}
    >
      <SfmTextField
        Icon={LocationIcon}
        source="location"
        label="resource.interventions.location"
      />

      {isConfigurator && (
        <>
          <FormField readonly={readonly} />
        </>
      )}

      {isManager && (
        <>
          {isRound ? (
            <>
              <SfmDateField
                source="startRound"
                label="resource.interventions.start"
                showTime
              />
              <SfmDateField
                source="endTime"
                label="resource.rounds.endTime"
                showTime
              />
            </>
          ) : (
            <StartDateField readonly={readonly} />
          )}
          <Section label="resource.interventions.sections.report">
            <ReportField readonly={readonly} />
            <ReportRecipients />
            {isRound ? (
              <ListCheckerResult />
            ) : (
              <>
                <ValidatorField />
                <InterventionDataSection />
              </>
            )}
          </Section>

          <Section label="resource.interventions.sections.operator">
            <EntitlementsFields
              filter={{ 'interventions.id': id }}
              resource={ResourceEnum.interventions}
              notifResource="intervention"
              readonly={readonly}
            />
            <OperatorField readonly={readonly} />
          </Section>

          {workOrder && (
            <Section label="resource.interventions.workOrderInherited">
              <EntitlementsFields
                filter={{ 'workOrders.id': workOrder }}
                resource={ResourceEnum.interventions}
                notifResource=""
                readonly
              />
            </Section>
          )}

          <Section label="resource.interventions.sections.prerequisites">
            <ItemsField readonly={readonly} />
            <AssociatedDataField<Equipment>
              readonly={readonly}
              listResource={ResourceEnum.equipment_of_intervention}
              pickerResource={ResourceEnum.equipment}
              label="resource.interventions.interventionEquipments"
              pickerTitle="actions.assign_equipments"
              getPickerOptionTitle={(equipment) => equipment.name || ''}
              getPickerOptionSubtitle={(equipment) => equipment.reference || ''}
            >
              <RichDataList<Equipment>
                title={(equipment) => equipment.name}
                caption={(equipment) => equipment.reference}
                subtitle={(equipment) => equipment.designation}
                content={(equipment) => equipment.description}
                getShowRecord={(equipment) => {
                  return {
                    ...equipment,
                    id: toApiPlatformId(ResourceEnum.equipment, equipment.id),
                  }
                }}
              />
            </AssociatedDataField>
            <AssociatedDataField<Document>
              readonly={readonly}
              listResource={ResourceEnum.documents_of_intervention}
              pickerResource={ResourceEnum.documents}
              label="resource.interventions.interventionDocuments"
              pickerTitle="actions.assign_documents"
              getPickerOptionTitle={(document) => document.name || ''}
              getPickerOptionSubtitle={(document) => {
                if (document.isUrl) return translate('resource.documents.url')
                return getFileExtension(document.mimeType)
              }}
              pickerFilters={{
                showAll: true,
              }}
            >
              <RichDataList<Document>
                caption={(document) => {
                  if (document.isUrl) return translate('resource.documents.url')
                  return getFileExtension(document.mimeType)
                }}
                title={(document) => document.name}
                content={(document) => document.comment}
                getShowRecord={(document) => {
                  return {
                    ...document,
                    id: toApiPlatformId(ResourceEnum.documents, document.id),
                  }
                }}
              />
            </AssociatedDataField>
          </Section>
        </>
      )}

      <Section>
        <WorkOrderField />
        <ProjectsField />
      </Section>
      {isRound && (
        <Section label="titles.round.equipments_to_check">
          <EquipmentsToCheck />
        </Section>
      )}
    </ShowLayout>
  )
}

interface OperatorFieldProps {
  readonly?: boolean
}

function OperatorField(props: OperatorFieldProps) {
  const { readonly } = props
  const { id } = useRecordContext<Intervention>()

  return (
    <EditableUserOrGroupField<Intervention>
      userReference={ResourceEnum.operators}
      resource={ResourceEnum.interventions}
      userSource="operator"
      groupSource="operatorsGroup"
      label="resource.interventions.operator"
      editSuccessMessage="notifications.update.intervention"
      userSearchFilter={{
        operators_can_be_assigned: formatApiPlatformId(id),
      }}
      readonly={readonly}
      refetchOnOpen
    >
      <UserOrGroupRecordField />
    </EditableUserOrGroupField>
  )
}

interface ReportFieldProps {
  readonly?: boolean
}

function ReportField(props: ReportFieldProps) {
  const { readonly } = props
  const intervention = useRecordContext<FullIntervention>()
  const { form } = intervention

  return (
    <EditableReferenceField<Intervention, Report>
      label="resource.interventions.defaultReport"
      resource={ResourceEnum.interventions}
      source="defaultReport"
      reference={ResourceEnum.reports}
      editSearchFilter={{ 'forms.id': form }}
      editOptionText="name"
      editSuccessMessage="notifications.update.intervention"
      readonly={readonly}
    >
      <SfmTextField source="name" />
    </EditableReferenceField>
  )
}

interface ValidatorFieldProps {
  readonly?: boolean
}
function ValidatorField(props: ValidatorFieldProps) {
  const { readonly } = props
  const { client } = useRecordContext<Intervention>()

  return (
    <>
      <EditableUserOrGroupField<Intervention>
        resource={ResourceEnum.interventions}
        userSource="validator"
        groupSource="validatorsGroup"
        label="resource.interventions.validator"
        editSuccessMessage="notifications.update.intervention"
        userSearchFilter={{ isManager: true, 'client.id': client }}
        readonly={readonly}
        refetchOnOpen
      >
        <UserOrGroupRecordField />
      </EditableUserOrGroupField>
    </>
  )
}

function UserOrGroupRecordField() {
  return (
    <SfmFunctionField
      render={(record) => {
        if (!record) return <EmptyText />
        const userOrGroup = record as User | Group
        if (
          userOrGroup['@type'] === 'User' ||
          userOrGroup.id.startsWith('/api/user')
        ) {
          return <FullNameField />
        }
        return <TextField source="name" label="resource.groups.name" />
      }}
    />
  )
}

interface ProjectsFieldProps {
  readonly?: boolean
}

function ProjectsField(props: ProjectsFieldProps) {
  const { readonly } = props
  return (
    <EditableReferenceField<Intervention, Project>
      Icon={ProjectIcon}
      label="resource.interventions.project"
      source="project"
      reference={ResourceEnum.projects}
      resource={ResourceEnum.interventions}
      editOptionText="name"
      editSuccessMessage="notifications.update.intervention"
      readonly={readonly}
    >
      <SfmTextField source="name" />
    </EditableReferenceField>
  )
}

interface WorkOrderFieldProps {
  readonly?: boolean
}

function WorkOrderField(props: WorkOrderFieldProps) {
  const { readonly } = props
  return (
    <EditableReferenceField<Intervention, WorkOrder>
      Icon={WorkOrderIcon}
      label="resource.interventions.workOrder"
      source="workOrder"
      reference={ResourceEnum.work_orders}
      resource={ResourceEnum.interventions}
      editOptionText="reference"
      editSuccessMessage="notifications.update.intervention"
      readonly={readonly}
    >
      <SfmTextField source="reference" />
    </EditableReferenceField>
  )
}

interface FormFieldProps {
  readonly?: boolean
}

function FormField(props: FormFieldProps) {
  const { readonly } = props
  return (
    <EditableReferenceField<Intervention, Form>
      Icon={FormIcon}
      label="resource.interventions.form"
      source="form"
      reference={ResourceEnum.forms}
      resource={ResourceEnum.interventions}
      editOptionText="name"
      editSuccessMessage="notifications.update.intervention"
      editSearchFilter={{
        'exists[lastVersion]': true,
        isArchived: false,
      }}
      readonly={readonly}
    >
      <SfmTextField source="name" />
    </EditableReferenceField>
  )
}

interface ItemsFieldProps {
  readonly?: boolean
}

function ItemsField(props: ItemsFieldProps) {
  const { readonly } = props
  const intervention = useRecordContext()

  return (
    <ManyToManyField
      label="resource.interventions.interventionItems"
      actions={!readonly && <AssignItems intervention={intervention} />}
      target="intervention.id"
      through="intervention_items"
      reference="items"
      using="item"
    >
      <RichDataList<ExtendedInterventionItem>
        title={(intervention_item) =>
          `${intervention_item.quantity}x ${intervention_item.item.name ?? ''}`
        }
        caption={(intervention_item) => intervention_item.item.reference}
        subtitle={(intervention_item) => intervention_item.item.designation}
        getShowRecord={(intervention_item) => intervention_item.item}
        dataAction={(intervention_item) => (
          <DeleteButton
            label="actions.deassociate"
            record={intervention_item}
            redirect={false}
          />
        )}
      />
    </ManyToManyField>
  )
}

type ActionProps = Omit<ShowActionsProps, 'data'> & {
  data?: Intervention
}

function ManagerAction(props: ActionProps) {
  const { data } = props
  const refresh = useRefresh()
  const notify = useNotify()
  const [update, { loading }] = useUpdate()

  const setReady = () => {
    if (!data) return
    update(
      'interventions',
      data.id,
      { status: InterventionStatusEnum.ready },
      data,
      {
        onSuccess: function () {
          refresh()
          notify('notifications.status_changed')
        },
        onFailure: function ({ error }: { error: any }) {
          notify(error.message, 'error')
        },
      },
    )
  }

  return (
    <TopToolbar>
      {data?.status === InterventionStatusEnum.created && (
        <Button label="actions.set_ready" onClick={setReady} saving={loading}>
          <CheckIcon />
        </Button>
      )}
    </TopToolbar>
  )
}

function ConfiguratorAction(props: ActionProps) {
  const { data: intervention } = props
  const refresh = useRefresh()
  const notify = useNotify()
  const [update, { loading }] = useUpdate()
  const setEnded = () => {
    if (!intervention) return
    update(
      'interventions',
      intervention.id,
      { status: InterventionStatusEnum.ended },
      intervention,
      {
        onSuccess: function () {
          refresh()
          notify('notifications.status_changed')
        },
        onFailure: function ({ error }: { error: any }) {
          notify(error.message, 'error')
        },
      },
    )
  }

  if (!intervention) return null

  const cantBeDeleted =
    intervention.isRecurrent && intervention.interventionData.length > 0
  const canBeEnded =
    cantBeDeleted && intervention.status !== InterventionStatusEnum.ended

  return (
    <ShowActions {...props} hasDelete={!cantBeDeleted}>
      {canBeEnded && (
        <Button
          label="actions.end"
          color="secondary"
          onClick={setEnded}
          saving={loading}
        >
          <CheckIcon />
        </Button>
      )}
    </ShowActions>
  )
}

function EquipmentsToCheck() {
  const record = useRecordContext<FullIntervention>()
  const { id, name } = record
  const env = useEnv()
  const httpClient = useHttpClient()

  const { mutate: download, isLoading } = useMutation({
    async mutationFn() {
      const parsedId = formatApiPlatformId(id)
      const res = await httpClient.get(
        `${env.API_URL}/interventions/round/download/${parsedId}`,
      )

      const blob = new Blob([res.data], { type: 'text/csv;charset=utf-8' })
      saveAs(blob, `${name}.rapport.csv`)
    },
  })

  return (
    <>
      <Box sx={{ display: 'flex', alignItems: 'baseline' }}>
        <Button
          label="actions.download_csv"
          onClick={() => download()}
          disabled={!record.statusListChecker}
          saving={isLoading}
        />
      </Box>
      {record.status === InterventionStatusEnum.created && (
        <UploadEquipmentsSheetField />
      )}
    </>
  )
}
