// THIS FILE IS AUTO-GENERATED BY FIGMAGIC. DO NOT MAKE EDITS IN THIS FILE! CHANGES WILL GET OVER-WRITTEN BY ANY FURTHER PROCESSING.

const fontWeights = {
 "code": 500,
 "label": 400,
 "button": 700,
 "microbutton": 700,
 "bodyXsBold": 700,
 "bodyXs": 400,
 "bodySBold": 900,
 "bodyS": 400,
 "bodyMBold": 700,
 "bodyM": 400,
 "bodyL": 400,
 "headingXxs": 700,
 "headingXs": 900,
 "headingS": 900,
 "headingM": 700,
 "headingL": 700
} as const;

export default fontWeights;