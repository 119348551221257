import { parse } from 'dotenv'
import { createContext, useContext } from 'react'

export type IEnv = Omit<ReturnType<typeof Env>, 'SENTRY_DSN'>
export function Env() {
  const localEnv = loadDotenv()

  function resolve(
    name: string,
    getDefaultValue: () => string = () => isMissing(name),
  ): string {
    return (
      localEnv[name] ?? process.env[`REACT_APP_${name}`] ?? getDefaultValue()
    )
  }

  const APP_ENV = validateAppEnv(resolve('APP_ENV', () => 'production'))

  return {
    APP_ENV: APP_ENV,
    IS_DEV: APP_ENV === 'development',
    BASE_URL: formatUrl(resolve('BASE_URL')),

    SENTRY_DSN: resolve('SENTRY_DSN'),
  } as const
}

export const EnvContext = createContext<IEnv | undefined>(undefined)

export function useEnv(): IEnv {
  const env = useContext(EnvContext)

  if (!env) {
    throw new Error('Please provide an env object')
  }

  return env
}

function loadDotenv(fileName?: string): { [key: string]: string } {
  return parse(resolveEnv(fileName))
}

function resolveEnv(fileName = 'env'): string {
  let i = 0
  const pathName = window.location.pathname.split('/').filter(Boolean)
  let env = null

  while (true) {
    const filePath = ('/' + pathName.concat([fileName]).join('/')).replaceAll(
      '//',
      '/',
    )

    env = loadTextFileAjaxSync(filePath, 'text/plain')

    // if response is <!doctype html because of htaccess redirection
    if (env?.startsWith('<')) {
      env = null
    }

    if (env) break
    if (!pathName.length) break
    if (i++ >= 25) break

    pathName.pop()
  }

  return env || ''
}

// Load text with Ajax synchronously: takes path to file and optional MIME type
function loadTextFileAjaxSync(filePath: string, mimeType?: string) {
  const xmlHttp = new XMLHttpRequest()
  xmlHttp.open('GET', filePath, false)

  if (mimeType && xmlHttp.overrideMimeType) {
    xmlHttp.overrideMimeType(mimeType)
  }
  xmlHttp.send()
  if (xmlHttp.status === 200) {
    return xmlHttp.responseText
  }
  return null
}

function isMissing(name: string): never {
  throw new Error(`Missing env ${name}`)
}

function formatUrl(url: string) {
  return url.replace(/(\/)+$/, '') // remove trailing slash
}

function validateAppEnv(appEnv: string) {
  const validValues = ['development', 'production', 'integ']
  if (!validValues.includes(appEnv)) {
    throw new Error(
      `Invalid value for APP_ENV: ${JSON.stringify(
        appEnv,
      )}. Valid values are: ${validValues.join(', ')}`,
    )
  }

  return appEnv as 'development' | 'production' | 'integ'
}
