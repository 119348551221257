import type { AxiosRequestConfig } from 'axios'
import type {
  Identifier,
  GetListParams,
  GetManyReferenceParams,
  GetOneParams,
  UpdateParams,
  CreateParams,
  DeleteParams,
  Record as RaRecord,
} from 'react-admin'

import { buildFilters } from './buildFilters'
import type {
  HydraFilters,
  HydraPagination,
  HydraOrder,
  WithHeaders,
} from './types'

function toItemUrl(resource: string, id: Identifier) {
  // Check if this is a jsonLd id
  if (typeof id === 'string' && id.includes('/')) {
    // Keep only the last 2 parts of the id (ex: /api/users/uuid becomes users/uuid)
    return `/${id.split('/').slice(-2).join('/')}`
  }
  throw new Error(
    `Simple ids are not allowed to prevent caching errors with react-admin. Please use the "/api/:resource/:id" (jsonLd) format.

If you get this error, you may need to normalize your ids, with eg. <IdNormalizerProxy /> or toApiPlatformId()`,
  )
}

function toResourceUrl(resource: string) {
  return `/${resource}`
}

const baseHeaders = { Accept: 'application/ld+json' }

export const requestBuilder = {
  getOne(
    resource: string,
    options: GetOneParams & WithHeaders,
  ): AxiosRequestConfig {
    const { id, headers } = options

    return {
      headers: { ...baseHeaders, ...headers },
      method: 'GET',
      url: toItemUrl(resource, id),
    }
  },

  getList(
    resource: string,
    options: GetListParams & WithHeaders,
  ): AxiosRequestConfig {
    const { pagination, sort, filter, headers } = options

    const params: HydraFilters & HydraPagination & HydraOrder = {
      page: pagination.page,
      itemsPerPage: pagination.perPage,
      [`order[${sort.field}]`]: sort.order ?? 'asc',
      ...(buildFilters(filter) as any),
    }

    return {
      headers: { ...baseHeaders, ...headers },
      method: 'GET',
      url: toResourceUrl(resource),
      params,
    }
  },

  getManyReference(
    resource: string,
    options: GetManyReferenceParams & WithHeaders,
  ): AxiosRequestConfig {
    const { target, id } = options

    return this.getList(resource, {
      ...options,
      filter: { ...options.filter, [target]: id },
    })
  },

  create(
    resource: string,
    options: CreateParams & WithHeaders,
  ): AxiosRequestConfig {
    const { data, headers } = options

    return {
      headers: { ...baseHeaders, ...headers },
      method: 'POST',
      url: toResourceUrl(resource),
      data: data,
    }
  },

  update(
    resource: string,
    options: UpdateParams & WithHeaders,
  ): AxiosRequestConfig {
    const { id, data, headers, previousData } = options

    return {
      headers: { ...baseHeaders, ...headers },
      method: 'PUT',
      url: toItemUrl(resource, id),
      data: diff(previousData, data),
    }
  },

  delete(
    resource: string,
    options: DeleteParams & WithHeaders,
  ): AxiosRequestConfig {
    const { id, headers, previousData } = options

    return {
      headers: { ...baseHeaders, ...headers },
      method: 'DELETE',
      url: id ? toItemUrl(resource, id) : toResourceUrl(resource),
      data: previousData, // used to pass à payload in Delete operation
    }
  },
}

type DiffResult<T> = Partial<T> & { id: Identifier }
function diff<T extends RaRecord>(
  previous: T,
  updated: Partial<T>,
): DiffResult<T> {
  const result: Partial<T> = { id: previous.id } as DiffResult<T>
  Object.keys(updated).forEach((key: keyof T) => {
    const prevValue = previous[key]
    const updatedValue = updated[key]
    if (['@type', '@id'].includes(key as string)) {
      result[key] = updatedValue
      return
    }

    if (Array.isArray(prevValue) && Array.isArray(updatedValue)) {
      if (prevValue.join('') !== updatedValue.join('')) {
        result[key] = updatedValue
      }
      return
    }

    if (prevValue !== updatedValue) {
      result[key] = updatedValue
    }
  })

  return result as DiffResult<T>
}
