import ActionDelete from '@material-ui/icons/Delete'
import React from 'react'
import type { Dispatch } from 'react'

import type { Identifier } from 'react-admin'
import {
  Button,
  FunctionField,
  ReferenceField,
  useRecordContext,
  useRefresh,
  useNotify,
  useDelete,
} from 'react-admin'

import { CustomDatagrid } from 'src/components/CustomDatagrid'
import { FullNameField } from 'src/components/FullNameField'
import { MinioFileField } from 'src/components/MinioFileField'
import { SfmDateField, SfmReferenceArrayField } from 'src/components/SfmFields'
import type { Document, Entitlement, UserEntitlement } from 'src/types/api'

import { AddUserEntitlementButton } from './AddUserEntitlementButton'

import type { EntitlementsTypes } from '.'

interface Props {
  label: string
  resource: EntitlementsTypes
  deletedUsers: Set<Identifier>
  setDeletedUsers: Dispatch<React.SetStateAction<Set<Identifier>>>
}

export function UserList(props: Props) {
  const { resource, deletedUsers, setDeletedUsers, label } = props
  const entitlement = useRecordContext<Entitlement>()
  const notify = useNotify()
  const refresh = useRefresh()

  const [deleteOne, { loading }] = useDelete()
  const handleDelete = (userEntitlement: UserEntitlement) => {
    deleteOne('user_entitlements', userEntitlement.id, userEntitlement, {
      onSuccess: function () {
        setDeletedUsers(
          new Set([...Array.from(deletedUsers), userEntitlement.id]),
        )
        notify(`notifications.delete.user_${resource}`, 'success')
      },
      onFailure: function ({ error }: { error: any }) {
        notify(error.message, 'error')
      },
    })
  }

  const userEntitlementList =
    entitlement?.userEntitlements?.filter(
      (userEntitlementId: string) => !deletedUsers.has(userEntitlementId),
    ) || []

  return (
    <SfmReferenceArrayField
      label={label}
      actions={
        <AddUserEntitlementButton
          resource="entitlement"
          resourceId={entitlement.id}
          type="autorisations"
          onChange={refresh}
        />
      }
      record={{
        ...entitlement,
        userEntitlements: userEntitlementList,
      }}
      source="userEntitlements"
      reference="user_entitlements"
    >
      <CustomDatagrid rowClick={undefined}>
        <OperatorField addLabel label="resource.user_entitlements.operator" />
        <SfmDateField source="start" label="resource.user_entitlements.start" />
        <SfmDateField
          source="endTime"
          label="resource.user_entitlements.endDate"
        />
        <DocumentField addLabel label="resource.user_entitlements.document" />

        <FunctionField<UserEntitlement>
          render={(userEntitlement) => {
            return (
              <Button
                label="actions.delete"
                color="secondary"
                disabled={loading}
                onClick={(e) => {
                  e.stopPropagation()
                  if (userEntitlement) handleDelete(userEntitlement)
                }}
              >
                <ActionDelete />
              </Button>
            )
          }}
        />
      </CustomDatagrid>
    </SfmReferenceArrayField>
  )
}

interface LabeledCustomField {
  addLabel: boolean
  label: string
}

function DocumentField(_props: LabeledCustomField) {
  const user_entitlement = useRecordContext<UserEntitlement>()

  return (
    <ReferenceField source="document" reference="documents" link={false}>
      <FunctionField<Document>
        render={(document) => {
          if (document) {
            return (
              <MinioFileField
                fileName={document.fileName || ''}
                bucketId={user_entitlement.userId['@id']}
                title={document.name}
              />
            )
          }
          return null
        }}
      />
    </ReferenceField>
  )
}

function OperatorField(props: LabeledCustomField) {
  const user_entitlement = useRecordContext<UserEntitlement>()

  return (
    <ReferenceField
      record={{
        ...user_entitlement,
        userId: user_entitlement.userId['@id'],
      }}
      source="userId"
      reference="users"
      link="show"
    >
      <FullNameField {...props} />
    </ReferenceField>
  )
}
