import Typography from '@material-ui/core/Typography'
import { useTranslate } from 'react-admin'
import { joinStrings } from 'src/libs/joinStrings'
import { LocationIcon } from 'src/UI/theme/icons'

import type { SfmFunctionFieldProps } from './SfmFields'
import { EmptyText, SfmFunctionField } from './SfmFields'

type Props = Omit<SfmFunctionFieldProps, 'render'> & {
  multiline?: boolean
}

export function AddressField(props: Props) {
  const { multiline = false, ...fieldProps } = props
  const __ = useTranslate()

  function getAddress(record?: any) {
    if (!record) return null
    const address = joinStrings(
      [record.street, record.zipCode, record.city, record.country],
      ' ',
    ).trim()

    if (!address) return <EmptyText />

    if (multiline) {
      const city = joinStrings([record?.zipCode, record?.city], ' ').trim()
      return (
        <>
          {record.street && <Typography>{record.street}</Typography>}
          {record.city && <Typography>{city}</Typography>}
          {record.country && <Typography>{record.country}</Typography>}
        </>
      )
    }

    return address
  }

  return (
    <SfmFunctionField Icon={LocationIcon} {...fieldProps} render={getAddress} />
  )
}
