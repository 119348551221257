import * as Sentry from '@sentry/react'
import { createBrowserHistory } from 'history'
import React from 'react'
import ReactDOM from 'react-dom'
import { QueryClient, QueryClientProvider } from 'react-query'
import { AuthProvider } from 'src/adapters/AuthProvider'
import { DataProvider } from 'src/adapters/DataProvider'
import { Env } from 'src/adapters/Env'
import { HTTPClient, HttpClientContext } from 'src/adapters/HTTPClient'
import { Storage, StorageContext } from 'src/adapters/Storage'
import { filterConsole } from 'src/libs/filterConsole'

import { antiCorruptionLayerProxy } from './adapters/antiCorruptionLayerProxy'
import { cacheDataProviderProxy } from './adapters/DataProvider/cacheDataProviderProxy'
import { App } from './App'
import reportWebVitals from './reportWebVitals'
import { I18nProvider } from './UI'
import './index.css'

// We can't remove those warnings, because it's happening inside react-admin's sources
// Use `console.noFilter()` to print all warnings again, and keep original log location

// @ts-ignore
window.console.noFilter = filterConsole([
  'Warning: Failed prop type: The prop `onExited` of `ForwardRef(Snackbar)` is deprecated.',
  'Warning: React does not recognize the `titleAccess` prop on a DOM element.',
  'Warning: Failed prop type: The prop `onExited` of `ForwardRef(Snackbar)` is deprecated.',
  'Material-UI: The `fade` color utility was renamed to `alpha` to better describe its functionality.',
  'Warning: Failed prop type: The prop `onChangePage` of `ForwardRef(TablePagination)` is deprecated.',
  'Warning: Failed prop type: The prop `onChangeRowsPerPage` of `ForwardRef(TablePagination)` is deprecated.',
  'Warning: Failed prop type: The prop `onPageChange` is marked as required in `ForwardRef(TablePagination)`,',
  'Material-UI: The key `fixed` provided to the classes prop is not implemented in ForwardRef(Drawer).',
  /Droppable setup issue \[droppableId: "LIBRARY\/bluePrintGroups\.(.*)"\]/,
  'Check the render method of `FilterForm`.',
])

const env = Env()
console.log(env)

if (!env.IS_DEV && env.SENTRY_DSN) {
  Sentry.init({
    dsn: env.SENTRY_DSN,
    environment: env.APP_ENV,
    enabled: !env.IS_DEV,
    release: env.APP_RELEASE,
  })
}

const history = createBrowserHistory()
const storage = Storage()
const i18nProvider = I18nProvider()
const authProvider = AuthProvider({
  env,
  storage,
  translate: i18nProvider.translate,
  autoSignIn: true,
  history,
})

const httpClient = HTTPClient({
  env,
  getAccessToken: authProvider.getCurrentAccessToken,
})

const dataProvider = cacheDataProviderProxy(
  antiCorruptionLayerProxy(DataProvider({ httpClient }), {
    httpClient,
    minioUrl: env.MINIO_URL,
  }),
  1 * 60 * 1000,
)

const queryClient = new QueryClient()

ReactDOM.render(
  <React.StrictMode>
    <StorageContext.Provider value={storage}>
      <HttpClientContext.Provider value={httpClient}>
        <QueryClientProvider client={queryClient}>
          <App
            env={env}
            storage={storage}
            dataProvider={dataProvider}
            i18nProvider={i18nProvider}
            authProvider={authProvider}
            history={history}
          />
        </QueryClientProvider>
      </HttpClientContext.Provider>
    </StorageContext.Provider>
  </React.StrictMode>,
  document.getElementById('root'),
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
