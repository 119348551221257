import { Capacitor } from '@capacitor/core'
import { Directory, Filesystem } from '@capacitor/filesystem'
import { useEffect, useState } from 'react'

import type { MediaType } from '../../plugins/types'

export function useMediaSrc(mediaType: MediaType, mediaId?: string) {
  const [mediaSrc, setMediaSrc] = useState<string>()

  useEffect(() => {
    if (!mediaId) return

    const path = `interventionData/${getFileName(mediaType, mediaId)}`

    Filesystem.getUri({ path, directory: Directory.Data }).then((result) => {
      const { uri } = result
      setMediaSrc(Capacitor.convertFileSrc(uri))
    })
  }, [mediaId, mediaType, setMediaSrc])

  return mediaSrc
}

function getFileName(mediaType: MediaType, mediaId: string): string {
  const hasExtension = mediaId.includes('.')
  if (hasExtension) return mediaId
  if (mediaType === 'Image') return `${mediaId}.png`
  if (mediaType === 'Signature') return `${mediaId}.png`
  if (mediaType === 'Video') return `${mediaId}.mp4`
  if (mediaType === 'Audio') return `${mediaId}.mp4`
  throw Error(`Unrecognized media type: ${JSON.stringify(mediaType)}`)
}
