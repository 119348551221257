import { Typography } from '@material-ui/core'
import {
  useCreateController,
  useTranslate,
  CreateContextProvider,
} from 'react-admin'

import { SfmSimpleForm } from 'src/components/SfmSimpleForm'

import { useStyles } from './styles'

import type { SfmCreateProps } from '.'

export function Create(props: SfmCreateProps) {
  const { children, noForm } = props

  const controllerProps = useCreateController(props)
  const { basePath, record, resource, save, saving, version } = controllerProps
  const __ = useTranslate()

  const styles = useStyles()

  return (
    <CreateContextProvider value={controllerProps}>
      <div className={styles.root}>
        <Typography variant="h1">{__(`titles.${resource}.create`)}</Typography>

        {noForm ? (
          children
        ) : (
          <SfmSimpleForm
            record={record}
            basePath={basePath}
            resource={resource}
            save={save}
            saving={saving}
            version={version}
            redirect="list"
          >
            {children}
          </SfmSimpleForm>
        )}
      </div>
    </CreateContextProvider>
  )
}
