import { Select, MenuItem, Grid, Typography } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import ellipsize from 'ellipsize'
import { useState, useMemo, useCallback, useEffect } from 'react'
import { Button, useNotify, useRecordContext, useTranslate } from 'react-admin'
import { useForm } from 'react-final-form'
import { useMutation } from 'react-query'
import { useHttpClient } from 'src/adapters/HTTPClient'
import { formatApiPlatformId } from 'src/libs/apiPlatformId'
import type { Intervention } from 'src/types/api'
import type { FullIntervention } from 'src/types/api/extendedTypes'
import * as XLSX from 'xlsx'

import { LabeledField } from '../../components/LabeledField'

type ListCheckerValues = Pick<
  FullIntervention,
  'fullListChecker' | 'statusListChecker' | 'listCheckerField'
>
type Headers = string[]

type Row = Record<string, any>

interface CsvValues {
  headers: Headers
  rows: Row[]
}

interface OnChangeValues {
  values?: ListCheckerValues
  isValid: boolean
}
interface UploadEquipmentsSheetProps {
  onChange: (event: OnChangeValues) => void
}
function UploadEquipmentsSheet(props: UploadEquipmentsSheetProps) {
  const { onChange } = props

  const translate = useTranslate()

  const [csvValues, setCsvValues] = useState<CsvValues>()
  const [selectedHeader, setSelectedHeader] = useState<string | undefined>(
    undefined,
  )

  function parseFile(files: FileList | null) {
    if (!files?.length) {
      return
    }

    const reader = new FileReader()

    reader.onload = () => {
      const workbook = XLSX.read(reader.result)
      if (!workbook.SheetNames.length) {
        throw Error('Provided file is empty')
      }

      const parsedDatas = XLSX.utils.sheet_to_json<string[]>(
        workbook.Sheets[workbook.SheetNames[0]],
        { header: 1 },
      )

      if (!parsedDatas.length) {
        throw Error('Provided file is empty')
      }

      const headers = parsedDatas[0]

      if (!selectedHeader || !headers.includes(selectedHeader)) {
        setSelectedHeader(headers[0])
      }

      const rows = parsedDatas.slice(1).map((values): Row => {
        const row: Row = {}

        values.forEach((value: string, index) => {
          const header = headers[index]
          row[header] = value
        })

        return row
      })
      setCsvValues({ headers, rows })
    }

    reader.readAsArrayBuffer(files[0])
  }

  const isUnique = useMemo(() => {
    if (!csvValues?.rows || !selectedHeader) return true

    const uniqueIds = new Set(csvValues?.rows.map((row) => row[selectedHeader]))
    return csvValues.rows.length === uniqueIds.size
  }, [csvValues?.rows, selectedHeader])

  const invalid = !isUnique || !selectedHeader

  useEffect(() => {
    if (!csvValues || !selectedHeader) {
      onChange({
        values: undefined,
        isValid: false,
      })
      return
    }

    onChange({
      values: {
        listCheckerField: selectedHeader,
        fullListChecker: {
          id_key: selectedHeader,
          data: csvValues.rows,
        },
        statusListChecker: Object.fromEntries(
          csvValues.rows.map((row) => [row[selectedHeader], null]),
        ),
      },
      isValid: !invalid,
    })
  }, [invalid, csvValues, selectedHeader, onChange])

  return (
    <Grid container direction={'column'} spacing={4}>
      <Grid item>
        <LabeledField label={'actions.addCsv'}>
          <input
            type="file"
            onChange={(event) => parseFile((event.target as any).files)}
            accept=".csv,.xlsx,.xls"
          />
          {csvValues && (
            <Typography variant={'body2'}>
              {translate(
                'resource.interventions.listChecker.importedElementsCount',
                {
                  count: csvValues.rows.length,
                },
              )}
            </Typography>
          )}
        </LabeledField>
      </Grid>

      {csvValues?.headers && (
        <Grid item>
          <LabeledField label="actions.select_header">
            <Select
              labelId="select-header"
              fullWidth
              style={{ padding: 8 }}
              value={selectedHeader}
              onChange={(event) =>
                setSelectedHeader(event.target.value as string)
              }
            >
              {csvValues.headers.map((header) => (
                <MenuItem key={header} value={header}>
                  {header}
                </MenuItem>
              ))}
            </Select>
            {selectedHeader && (
              <Typography variant={'body2'}>
                {ellipsize(
                  csvValues.rows
                    .slice(0, 5)
                    .map((row) => row[selectedHeader])
                    .join(', '),
                  200,
                )}
              </Typography>
            )}
          </LabeledField>
        </Grid>
      )}

      {!isUnique && (
        <Grid item>
          <Alert severity="warning">{translate('warning.duplicate_ids')}</Alert>
        </Grid>
      )}
    </Grid>
  )
}

export function UploadEquipmentsSheetInput() {
  const form = useForm()

  return (
    <UploadEquipmentsSheet
      onChange={useCallback(
        (event) => {
          if (event.isValid) {
            form.change('listCheckerField', event.values?.listCheckerField)
            form.change('fullListChecker', event.values?.fullListChecker)
            form.change('statusListChecker', event.values?.statusListChecker)
          } else {
            form.change('listCheckerField', null)
            form.change('fullListChecker', null)
            form.change('statusListChecker', null)
          }
        },
        [form],
      )}
    />
  )
}

export function UploadEquipmentsSheetField() {
  const intervention = useRecordContext<Intervention>()
  const { id } = intervention

  const [fieldState, setFieldState] = useState<OnChangeValues>({
    values: undefined,
    isValid: false,
  })
  const notify = useNotify()
  const httpClient = useHttpClient()

  const { mutate, isLoading } = useMutation({
    mutationFn(variables: ListCheckerValues) {
      const cleanId = formatApiPlatformId(id)
      return httpClient.put(`/interventions/import_round/${cleanId}`, variables)
    },
    onSuccess() {
      notify('notifications.update.intervention', 'success')
    },
    onError(error) {
      notify((error as any)?.message ?? error, 'error')
    },
  })

  return (
    <>
      <UploadEquipmentsSheet
        onChange={useCallback(
          (event) => {
            setFieldState(event)
          },
          [setFieldState],
        )}
      />
      <Button
        disabled={!fieldState.isValid}
        label="actions.importCsv"
        color="primary"
        onClick={() => {
          if (fieldState.values) {
            mutate(fieldState.values)
          }
        }}
        saving={isLoading}
      />
    </>
  )
}
