import { makeStyles } from '@material-ui/core'
import { format } from 'date-fns'
import { saveAs } from 'file-saver'
import { extension } from 'mime-types'
import { useState, useMemo } from 'react'
import {
  AutocompleteInput,
  Button,
  FormDataConsumer,
  ListContextProvider,
  ReferenceInput,
  useList,
  useNotify,
  useTranslate,
} from 'react-admin'
import { useHttpClient } from 'src/adapters/HTTPClient'
import { CustomForm } from 'src/components/CustomForm'
import { LabeledField } from 'src/components/LabeledField'
import { RichDataList } from 'src/components/RichDataList'
import { formatApiPlatformId } from 'src/libs/apiPlatformId'
import type { IriReference } from 'src/types/api'
import type {
  FullInterventionData,
  InterventionDataReportVersion,
} from 'src/types/api/extendedTypes'
import { ResourceEnum } from 'src/types/api/resources'

interface ReportSectionProps {
  interventionData: FullInterventionData
}

type InterventionDataReportVersionWithId = InterventionDataReportVersion & {
  id: IriReference
}

export function ReportSection(props: ReportSectionProps) {
  const { interventionData } = props

  const reportList = useMemo(() => {
    return interventionData.reportVersions?.map((reportVersion) => {
      return { ...reportVersion, id: reportVersion.reportId }
    })
  }, [interventionData])

  const listContext = useList({
    data: reportList ?? [],
    ids: reportList?.map((reportVersion) => reportVersion.id) ?? [],
    loading: false,
    loaded: true,
  })

  return (
    <ListContextProvider value={listContext}>
      <LabeledField
        label="resource.intervention_datas.report_generation"
        fullBleed
      >
        <RichDataList<InterventionDataReportVersionWithId>
          title={(reportVersion) => reportVersion.name}
          subtitle={(reportVersion) => reportVersion.version}
          hasShowLink={false}
          dataAction={(reportVersion) => (
            <GenerateReportButton
              interventionDataId={interventionData.id}
              reportVersion={reportVersion}
            />
          )}
        />

        <GenerateReportForm interventionDataId={interventionData.id} />
      </LabeledField>
    </ListContextProvider>
  )
}

function useGenerateReport() {
  const httpClient = useHttpClient()
  const notify = useNotify()

  return function generate(
    interventionDataId: IriReference,
    reportVersionId: IriReference,
    fileName: string,
  ) {
    return httpClient
      .get(
        `report/generate-report/${formatApiPlatformId(
          interventionDataId,
        )}/${formatApiPlatformId(reportVersionId)}`,
        {
          responseType: 'blob',
        },
      )
      .then((res: { data: Blob }) => {
        const ext = extension(res.data.type)
        saveAs(res.data, `${fileName}.${ext}`)
      })
      .catch((error) => {
        notify(error.message, 'error')
      })
  }
}

interface GenerateReportButtonProps {
  interventionDataId: IriReference
  reportVersion: InterventionDataReportVersion
}
function GenerateReportButton(props: GenerateReportButtonProps) {
  const { interventionDataId, reportVersion } = props
  const [loading, setLoading] = useState(false)

  const generate = useGenerateReport()
  const __ = useTranslate()

  function onClick() {
    setLoading(true)
    generate(
      interventionDataId,
      reportVersion.reportVersionId,
      `${__('resource.reports.resource')}-${reportVersion.name}_${format(
        new Date(),
        'd-M-u',
      )}`,
    ).finally(() => setLoading(false))
  }

  return (
    <Button
      disabled={loading}
      onClick={onClick}
      label="actions.generate_report"
    />
  )
}

const useStyles = makeStyles(function (theme) {
  return {
    root: {
      marginTop: theme.spacing(2),
    },
    container: {
      display: 'flex',
      justifyContent: 'center',
      gap: '1rem',
    },
  }
})

interface GenerateReportFormProps {
  interventionDataId: IriReference
}
function GenerateReportForm(props: GenerateReportFormProps) {
  const { interventionDataId } = props
  const [showForm, setShowForm] = useState(false)

  const generate = useGenerateReport()
  const [loading, setLoading] = useState(false)
  const __ = useTranslate()

  function onSubmit(values: { reportVersionId: IriReference }) {
    const { reportVersionId } = values

    setLoading(true)
    generate(
      interventionDataId,
      reportVersionId,
      `${__('resource.reports.resource')}_${format(new Date(), 'd-M-u')}`,
    ).finally(() => setLoading(false))
  }

  const styles = useStyles()

  if (!showForm) {
    return (
      <div className={styles.root}>
        <Button
          label="actions.use_other_report"
          onClick={() => setShowForm(true)}
        />
      </div>
    )
  }

  return (
    <div className={styles.root}>
      <CustomForm
        resource="intervention_data"
        submit={onSubmit}
        render={(handleSubmit) => (
          <div className={styles.container}>
            <ReferenceInput
              source="reportVersionId"
              reference={ResourceEnum.reports}
              label="resourceNames.reports"
              filter={{
                'exists[lastVersion]': true,
              }}
            >
              <AutocompleteInput optionValue="lastVersion" resettable />
            </ReferenceInput>
            <FormDataConsumer>
              {(params) => {
                const { formData } = params
                return (
                  <Button
                    onClick={handleSubmit}
                    label="actions.generate_report"
                    disabled={!formData.reportVersionId || loading}
                  />
                )
              }}
            </FormDataConsumer>
          </div>
        )}
      />
    </div>
  )
}
