import { Typography } from '@material-ui/core'
import { useDefineAppLocation } from '@react-admin/ra-navigation'
import type { Record } from 'react-admin'
import {
  EditContextProvider,
  ResourceContextProvider,
  useEditController,
  useTranslate,
  Loading,
} from 'react-admin'

import { SfmSimpleForm } from 'src/components/SfmSimpleForm'

import { useStyles } from './styles'

import type { SfmEditProps } from '.'
import { useAppScope } from '.'

export function Edit<T extends Record>(props: SfmEditProps<T>) {
  const { children, getName, noForm } = props

  const appScope = useAppScope()
  const controllerProps = useEditController<T>(props)
  const { basePath, record, resource, save, saving, version } = controllerProps
  const __ = useTranslate()

  useDefineAppLocation(`${appScope}.${props.resource}.edit`, record)

  const styles = useStyles()

  if (!record) return <Loading />

  // Copied from react-admin sources of `<Edit /> component`
  const body = (
    <EditContextProvider value={controllerProps}>
      <div className={styles.root}>
        <Typography
          variant="caption"
          display="block"
          color="secondary"
          gutterBottom
        >
          {__(`resourceNames.${props.resource}`)}
        </Typography>

        <Typography variant="h1">
          {__('breadcrumbs.edit', getName ? { name: getName(record) } : record)}
        </Typography>

        {noForm ? (
          children
        ) : (
          <SfmSimpleForm
            record={record}
            basePath={basePath}
            resource={resource}
            save={save}
            saving={saving}
            version={version}
          >
            {children}
          </SfmSimpleForm>
        )}
      </div>
    </EditContextProvider>
  )

  if (!props.resource) return body

  return (
    <ResourceContextProvider value={props.resource}>
      {body}
    </ResourceContextProvider>
  )
}
