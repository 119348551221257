import { Accordion } from './Accordion'
import { BarCodeInput } from './BarCodeInput'
import {
  SelectMany,
  SelectOne,
  TextInput,
  NumberInput,
  CheckboxInput,
  TimeInput,
  DateTimeInput,
  CalendarInput,
} from './BaseInput'
import {
  PhotoInput,
  VideoInput,
  AudioInput,
  SignatureInput,
} from './MediaInput'
import { PictureDisplay } from './PictureDisplay'
import { BodyM, BodyS, HeadingM, HeadingS } from './TextDisplay'

export * from './types'
export * from './getWatchedProperties'

export const fieldRenderersMap = {
  Text: TextInput, // string
  Number: NumberInput, // number
  Checkbox: CheckboxInput, // boolean

  SelectOne: SelectOne, // string
  SelectMany: SelectMany, // string[]

  Time: TimeInput, // ISO Time
  Date: CalendarInput, // ISO Date
  DateTime: DateTimeInput, // ISO DateTime

  Photo: PhotoInput, // media: Photo
  Video: VideoInput, // media: Video
  Audio: AudioInput, // media: Audio
  Signature: SignatureInput, // media: Audio

  BarCodeReader: BarCodeInput, // unnamed

  Accordion: Accordion, // presentational
  PictureDisplay: PictureDisplay, // presentational
  HeadingM: HeadingM, // presentational
  HeadingS: HeadingS, // presentational
  BodyM: BodyM, // presentational
  BodyS: BodyS, // presentational
}
