import { useEffect, useState } from 'react'
import {
  ListContextProvider,
  useCreate,
  useNotify,
  useRecordContext,
  useTranslate,
} from 'react-admin'
import { LabeledField } from 'src/components/LabeledField'
import { MultipleResourcePicker } from 'src/components/Picker'
import { RichDataList } from 'src/components/RichDataList'
import { formatApiPlatformId, toApiPlatformId } from 'src/libs/apiPlatformId'
import { getFileExtension } from 'src/libs/fileMimeType'
import { useGetList } from 'src/libs/useGetList'
import type { Document, Form } from 'src/types/api'
import { ResourceEnum } from 'src/types/api/resources'
import { AddIcon } from 'src/UI/theme/icons'

export function DocumentsField() {
  const __ = useTranslate()
  const form = useRecordContext<Form>()
  const [documents, setDocuments] = useState<Document[]>()

  const { data, loading, loaded } = useGetList<Document>(
    ResourceEnum.documents_of_form,
    {
      filter: {
        formId: formatApiPlatformId(form.id),
      },
    },
    {
      enabled: Boolean(form.id),
    },
  )

  useEffect(() => {
    if (loaded && data) {
      setDocuments(data)
    }
  }, [data, loaded])

  const [create, { loading: saving }] = useCreate()
  const notify = useNotify()

  const save = (values: Document[]) => {
    const oldsIds = documents?.map((document) => document.id) ?? []
    const valuesIds = values.map((document) => formatApiPlatformId(document.id))

    if (oldsIds.join() !== valuesIds.join()) {
      create(
        ResourceEnum.documents_of_form,
        {
          form: form.id,
          documents: valuesIds,
        },
        {
          onSuccess: function (res) {
            setDocuments(res.data.documents)
            notify('notifications.update.forms', 'success')
          },
          onFailure: function (error) {
            notify(error.message, 'error')
          },
        },
      )
    }
  }

  return (
    <LabeledField
      label="resource.forms.formDocuments"
      fullBleed
      actions={
        <MultipleResourcePicker<Document>
          resource={ResourceEnum.documents}
          filters={{
            type: 'document',
          }}
          selectedIds={
            documents?.map((document) =>
              toApiPlatformId(ResourceEnum.documents, document.id),
            ) ?? []
          }
          label="actions.assign"
          title="actions.assign_documents"
          getOptionTitle={(document) => document.name ?? ''}
          getOptionSubtitle={(document) => {
            if (document.isUrl) return __('resource.documents.url')
            return getFileExtension(document.mimeType)
          }}
          onSave={save}
          saving={loading || saving}
          Icon={<AddIcon />}
        />
      }
    >
      <ListContextProvider
        value={{
          data: documents ?? [],
          loading,
          basePath: '/' + ResourceEnum.documents,
        }}
      >
        <RichDataList<Document>
          title={(document) => document.name}
          content={(document) => document.comment}
          caption={(document) => {
            if (document.isUrl) return __('resource.documents.url')
            return getFileExtension(document.mimeType)
          }}
          getShowRecord={(document) => {
            return {
              ...document,
              id: toApiPlatformId(ResourceEnum.documents, document.id),
            }
          }}
        />
      </ListContextProvider>
    </LabeledField>
  )
}
