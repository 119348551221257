import type { EditProps } from 'react-admin'
import { SfmEdit } from 'src/AppScopes'
import { ProtectedPage } from 'src/components/ProtectedPage'

import { ContactForm } from './Form'

export function Edit(props: EditProps) {
  return (
    <ProtectedPage>
      <SfmEdit {...props}>
        <ContactForm />
      </SfmEdit>
    </ProtectedPage>
  )
}
