import type { FieldNode } from '@nartex/sfm-form-engine'
import { createContext, useContext } from 'react'

export const CurrentFieldContext = createContext<FieldNode | undefined>(
  undefined,
)

export function useCurrentField(): FieldNode | undefined {
  return useContext(CurrentFieldContext)
}
