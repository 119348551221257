import type { PropsWithChildren } from 'react'
import React, { useContext, useState } from 'react'

interface AccordionContextType {
  openedAccordionId: string | number | null
  setOpenedAccordionId: React.Dispatch<
    React.SetStateAction<string | number | null>
  >
}

const AccordionContext = React.createContext<AccordionContextType>({
  openedAccordionId: null,
  setOpenedAccordionId: () => {},
})

export function useAccordionContext() {
  return useContext(AccordionContext)
}

export function AccordionContextProvider(props: PropsWithChildren<{}>) {
  const { children } = props
  const [openedAccordionId, setOpenedAccordionId] = useState<
    string | number | null
  >(null)

  return (
    <AccordionContext.Provider
      value={{
        openedAccordionId: openedAccordionId,
        setOpenedAccordionId: setOpenedAccordionId,
      }}
    >
      {children}
    </AccordionContext.Provider>
  )
}
