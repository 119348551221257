import type { Theme } from '@material-ui/core'
import type { CreateCSSProperties } from '@material-ui/core/styles/withStyles'

export function overflowEllipsis(): CreateCSSProperties<{}> {
  return {
    display: 'block',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  }
}

export function border(theme: Theme) {
  return `1px solid ${theme.palette.grey[100]}`
}
export function shadow(mul = 1) {
  return `
    ${2 * mul}px ${2 * mul}px ${4 * Math.abs(mul)}px 0px rgba(0,0,0,0.06),
    ${-1 * mul}px ${-1 * mul}px ${2 * Math.abs(mul)}px 0px rgba(0,0,0,0.03)
  `
}

export function rulerStroke(
  theme: Theme,
  orientation: 'horizontal' | 'vertical',
): CreateCSSProperties<{}> {
  const base: CreateCSSProperties<{}> = {
    content: '""',
    position: 'absolute',
    display: 'block',
    zIndex: 1,
  }

  if (orientation === 'vertical') {
    return {
      ...base,
      borderLeft: border(theme),
      top: 0,
      bottom: 0,
    }
  }

  if (orientation === 'horizontal') {
    return {
      ...base,
      borderTop: border(theme),
      left: 0,
      right: 0,
    }
  }

  return null as never
}
