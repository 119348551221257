import type { MutableRefObject, PropsWithChildren } from 'react'
import { useContext, useRef, useEffect, useMemo } from 'react'
import type { DataProvider as RaDataProvider, Identifier } from 'react-admin'
import { DataProviderContext, useRefresh } from 'react-admin'

import { RoleEnum } from './Role'
import type { Persona } from './types'

type Props = PropsWithChildren<{
  persona: Persona
}>

export function ContextualDataProviderContext(props: Props) {
  const { children, persona } = props
  const dataProvider = useContext(DataProviderContext)
  const { clientId, role } = persona

  const refs = useRef<RefProps>({ clientId, role, dataProvider })
  const refresh = useRefresh()

  useEffect(() => {
    refs.current = { clientId, role, dataProvider }
  }, [clientId, dataProvider, refresh, role])

  useEffect(() => {
    refresh()
  }, [role, clientId, dataProvider, refresh])

  const wrappedDataProvider: RaDataProvider = useMemo(
    () => contextualDataProvider(dataProvider, refs),
    [dataProvider],
  )

  return (
    <DataProviderContext.Provider value={wrappedDataProvider}>
      {children}
    </DataProviderContext.Provider>
  )
}

interface RefProps {
  dataProvider: RaDataProvider
  role?: RoleEnum | ''
  clientId?: Identifier
}

function contextualDataProvider(
  dataProvider: RaDataProvider,
  ref: MutableRefObject<RefProps>,
) {
  return new Proxy(dataProvider, {
    get(_, name: string) {
      if (typeof name !== 'string') return dataProvider[name]

      return async (resource: string, params: any) => {
        // The request is executed before the scope changes so we have to wait a bit for it to changes
        await sleep(0)
        const { dataProvider: currProvider, role, clientId } = ref.current
        const isAdmin = role === RoleEnum.ROLE_ADMIN || !clientId

        // TODO remove this 2 overrides when they will be handled server side
        const filter = isAdmin
          ? params.filter
          : { ...params.filter, 'client.id': clientId, 'clients.id': clientId }
        const data = isAdmin
          ? params.data
          : { ...params.data, client: clientId }

        return currProvider[name](resource, {
          ...params,
          filter,
          data,
          headers: isAdmin
            ? { 'X-Sfm-Role': RoleEnum.ROLE_ADMIN }
            : { 'X-Sfm-Role': role, 'X-Sfm-ClientId': clientId },
        })
      }
    },
  })
}

function sleep(ms = 0): Promise<void> {
  return new Promise((resolve) => {
    setTimeout(resolve, ms)
  })
}
