import { Typography } from '@material-ui/core'
import classNames from 'classnames'
import { useCallback } from 'react'
import { Draggable } from 'react-beautiful-dnd'
import type { DraggableChildrenFn } from 'react-beautiful-dnd'

import { useTranslate } from '../adapters/I18nProvider'

import { useStyles } from './styles'
import type { Blueprint } from './types'

interface DraggableElementProps {
  bluePrint: Blueprint
  index: number
  onHover?: (isHovered: boolean) => void
}

export function DraggableElement(props: DraggableElementProps) {
  const { bluePrint, index, onHover } = props

  const __ = useTranslate()
  const styles = useStyles()

  // inspired by this discussion :
  // https://github.com/atlassian/react-beautiful-dnd/issues/216#issuecomment-423708497
  const draggableRenderer = useCallback<DraggableChildrenFn>(
    (provided, snapshot) => {
      const { innerRef, draggableProps, dragHandleProps } = provided
      const { isDragging } = snapshot

      function renderItem(params: { isClone: boolean }) {
        const { isClone } = params

        const itemProps = isClone
          ? {}
          : {
              ref: innerRef,
              ...draggableProps,
              ...dragHandleProps,
              style: {
                ...draggableProps.style,
                transform: isDragging
                  ? draggableProps.style?.transform
                  : 'none',
              },
            }

        return (
          <li
            className={classNames(
              styles.draggableElement,
              isDragging && !isClone && styles.draggableElementDragging,
              isClone && styles.draggableElementClone,
            )}
            onMouseOver={() => onHover?.(true)}
            onMouseOut={() => onHover?.(false)}
            {...itemProps}
          >
            {bluePrint.icon}
            <Typography variant="body1">{__(bluePrint.displayName)}</Typography>
          </li>
        )
      }

      return (
        <>
          {renderItem({ isClone: false })}
          {isDragging && renderItem({ isClone: true })}
        </>
      )
    },
    [
      __,
      styles.draggableElement,
      styles.draggableElementDragging,
      styles.draggableElementClone,
      bluePrint,
      onHover,
    ],
  )

  return (
    <Draggable draggableId={String(bluePrint.inputName)} index={index}>
      {draggableRenderer}
    </Draggable>
  )
}
