import { useAppLocationState } from '@react-admin/ra-navigation'
import { equals } from 'rambda'
import { useEffect } from 'react'

export function useDefineAppLocation(
  path: string,
  values?: Record<string, any>,
) {
  const [location, setLocation] = useAppLocationState()
  useEffect(() => {
    if (
      location.path !== path ||
      (values && !equals(location.values, values))
    ) {
      setLocation(path, values || location.values)
    }
  }, [location, setLocation, path, values])
}
