import { useEffect, useState } from 'react'
import type { Identifier, Record as RaRecord } from 'react-admin'
import { useGetOne, useGetMany } from 'react-admin'
import { useGetList } from 'src/libs/useGetList'
import type { ResourceEnum } from 'src/types/api/resources'

import type { BasePickerProps } from './Picker'

import { MultiplePicker, SinglePicker } from '.'

type ResourcePickerProps<T> = Omit<
  BasePickerProps<T>,
  | 'loading'
  | 'options'
  | 'optionsLoading'
  | 'onSearchChange'
  | 'onOpen'
  | 'onClose'
> & {
  resource: ResourceEnum
  filters?: Partial<Record<keyof T, any>> & Record<string, any>
  refetchOnOpen?: boolean
}

type MultipleResourcePickerProps<T> = ResourcePickerProps<T> & {
  selectedIds: Identifier[]
  onSave: (values: T[]) => void
}

export function MultipleResourcePicker<T extends RaRecord = RaRecord>(
  props: MultipleResourcePickerProps<T>,
) {
  const {
    resource,
    selectedIds,
    onSave,
    filters,
    refetchOnOpen,
    ...pickerProps
  } = props

  const [enabled, setEnabled] = useState(false)
  const [search, setSearch] = useState('')

  const [values, setValues] = useState<T[]>()
  const {
    data: selectedRecord,
    loading,
    loaded,
  } = useGetMany(resource, selectedIds, {
    enabled: enabled,
  })

  useEffect(() => {
    if (selectedRecord) setValues(selectedRecord as T[])
  }, [selectedRecord, enabled])

  const {
    data: options,
    loading: optionsLoading,
    refetch,
  } = useGetList(
    resource,
    {
      filter: {
        ...filters,
        q: search !== '' ? search : undefined,
      },
      pagination: { page: 1, perPage: 100 },
    },
    {
      enabled: enabled,
    },
  )
  return (
    <MultiplePicker<T>
      {...pickerProps}
      loading={loading}
      optionsLoading={optionsLoading}
      onOpen={() => {
        if (refetchOnOpen) refetch()
        setEnabled(true)
      }}
      onChange={(newValues) => setValues(newValues)}
      onSearchChange={(query) => setSearch(query)}
      options={(options as T[]) ?? []}
      values={(loaded && values) || []} // Prevent values being an array of undefined before fetching
      onClose={() => loaded && onSave(values ?? [])}
    />
  )
}

type SingleResourcePickerProps<T> = ResourcePickerProps<T> & {
  selectedId?: Identifier
  onSave: (value?: T) => void
}

export function SingleResourcePicker<T extends RaRecord = RaRecord>(
  props: SingleResourcePickerProps<T>,
) {
  const {
    resource,
    selectedId,
    onSave,
    filters,
    refetchOnOpen,
    ...pickerProps
  } = props

  const [enabled, setEnabled] = useState(false)
  const [search, setSearch] = useState('')

  const [value, setValue] = useState<T>()
  const {
    data: selectedRecord,
    loading,
    loaded,
  } = useGetOne(resource, selectedId ?? '', {
    enabled: enabled && Boolean(selectedId), // Won't fetch if selectedId is undefined
  })

  useEffect(() => {
    if (selectedRecord) {
      if (selectedId) {
        setValue(selectedRecord as T)
      } else setValue(undefined)
    }
  }, [selectedRecord, selectedId, enabled])

  const {
    data: options,
    loading: optionsLoading,
    refetch,
  } = useGetList(
    resource,
    {
      filter: {
        ...filters,
        q: search !== '' ? search : undefined,
      },
      pagination: { page: 1, perPage: 100 },
    },
    {
      enabled: enabled,
    },
  )

  const onClose = () => {
    if (selectedId && !loaded) return

    onSave(value)
  }

  return (
    <SinglePicker<T>
      {...pickerProps}
      loading={loading}
      optionsLoading={optionsLoading}
      onOpen={() => {
        if (refetchOnOpen) refetch()
        setEnabled(true)
      }}
      onChange={(newValue) => setValue(newValue)}
      onSearchChange={(query) => setSearch(query)}
      options={(options as T[]) ?? []}
      value={value}
      onClose={onClose}
    />
  )
}
