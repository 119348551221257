import type { Base64Image } from '@nartex/sfm-form-engine'
import { useSnackbar } from 'notistack'

import { useTranslate } from '../adapters/I18nProvider'

interface Props {
  value: Base64Image | undefined
  onChange: (newValue: Base64Image | undefined) => void
}

export function ImagePicker(props: Props) {
  const { onChange } = props

  const { enqueueSnackbar } = useSnackbar()
  const translate = useTranslate()

  return (
    <>
      <input
        type="file"
        onChange={async (event) => {
          const file = event.target.files?.[0]
          if (file) {
            try {
              onChange(await processImage(file, { maxWidth: 512 }))
            } catch (e) {
              console.error(e)

              enqueueSnackbar(
                translate({
                  id: 'ImagePicker.upload.invalidFileType',
                  defaultMessage: "Format d'image non reconnu",
                  description:
                    'Message printed when the provided image is invalid in the image picker',
                }),
                { variant: 'error' },
              )
            }
          }
        }}
        accept="image/*"
      />
    </>
  )
}

/*
 * This code has been heavily inspired from this stack overflow response
 * https://stackoverflow.com/a/39235724/6125124
 */
interface Options {
  maxWidth: number
}
function processImage(file: File, settings: Options): Promise<Base64Image> {
  const { maxWidth } = settings

  const reader = new FileReader()
  const image = new Image()
  const canvas = document.createElement('canvas')

  const doResize = async (): Promise<Base64Image> => {
    let { width, height } = image

    if (width > maxWidth) {
      height *= maxWidth / width
      width = maxWidth
    }

    canvas.width = width
    canvas.height = height
    canvas.getContext('2d')?.drawImage(image, 0, 0, width, height)

    const blob = await new Promise<Blob | null>((resolve) =>
      canvas.toBlob(resolve, file.type),
    )
    return {
      height,
      width,
      lastModified: file.lastModified,
      name: file.name,
      size: blob?.size || 0,
      type: file.type,
      dataURI: String(canvas.toDataURL(file.type)),
    }
  }

  return new Promise<Base64Image>((resolve, reject) => {
    if (!file.type.match(/image.*/)) {
      reject(new Error('Not an image'))
      return
    }

    reader.onload = (readerEvent: ProgressEvent<FileReader>) => {
      const imageSrc = readerEvent.target?.result
      if (!imageSrc) return

      if (file.type === 'image/svg+xml') {
        resolve({
          height: 0,
          width: 0,
          lastModified: file.lastModified,
          name: file.name,
          size: file.size,
          type: file.type,
          dataURI: String(imageSrc),
        })
        return
      }
      image.onload = () => {
        doResize().then(resolve)
      }

      if (imageSrc) {
        image.src = String(imageSrc)
      }
    }
    reader.readAsDataURL(file)
  })
}
