import { Typography } from '@material-ui/core'
import { useTranslate } from 'ra-core'
import type { PropsWithChildren } from 'react'
import { useRef, useContext, createContext, useEffect, useMemo } from 'react'
import { Loading } from 'react-admin'
import { useHistory } from 'react-router'
import { useStorage } from 'src/adapters/Storage'
import { AppScopeEnum, useAppScope } from 'src/AppScopes'
import { toApiPlatformId } from 'src/libs/apiPlatformId'
import { tryParseJSON } from 'src/libs/tryParseJSON'
import { useMemoByValues } from 'src/libs/useMemoByValues'
import { useParsedLocation } from 'src/libs/useParsedLocation'

import type { UserProfile } from '..'

import { ContextualDataProviderContext } from './contextualDataProvider'
import { RoleEnum, useCreateRoleUrl } from './Role'
import type { Persona, FullPersona } from './types'

const PREFERENCE_KEY = 'user.persona'
const PersonaContext = createContext<FullPersona | null>(null)

export function usePersona(): FullPersona {
  const requestedPersona = useContext(PersonaContext)
  if (!requestedPersona) {
    throw new Error('Accessing PersonaContext without a provider')
  }
  return requestedPersona
}

type Props = PropsWithChildren<{
  identity: Partial<UserProfile> | undefined
}>

export function PersonaRootProvider(props: Props) {
  const { children, identity } = props

  const requestedPersona = useReadPersona()
  const translate = useTranslate()
  const persona = useIdentityFilter(requestedPersona, identity)
  useWritePersona(persona)

  const fullPersona = useMemo<FullPersona>(() => {
    return {
      ...persona,
      client: identity?.clients?.find(
        (client) => client.id === persona.clientId,
      ),
    }
  }, [persona, identity?.clients])

  const filteredPersona = useScopePersona(
    fullPersona,
    getAcceptedRoles(useAppScope()),
    identity?.roles,
  )

  if (!identity?.roles) {
    return <Loading />
  }

  if (!filteredPersona.role) {
    return (
      <Typography variant="h3" align="center">
        {translate('error.not_allowed')}
      </Typography>
    )
  }

  return (
    <PersonaContext.Provider value={filteredPersona}>
      <ContextualDataProviderContext persona={filteredPersona}>
        {children}
      </ContextualDataProviderContext>
    </PersonaContext.Provider>
  )
}

function useScopePersona(
  persona: Persona,
  acceptedRoles: RoleEnum[],
  userRoles?: RoleEnum[],
) {
  const finalRole = getValidRole(persona, acceptedRoles, userRoles)
  const value: Persona = useMemoByValues({ ...persona, role: finalRole })

  return value
}

function getValidRole(
  persona: Persona,
  acceptedRoles: RoleEnum[],
  userRoles?: RoleEnum[],
) {
  if (acceptedRoles?.includes(persona.role as any)) return persona.role
  const acceptedRole = acceptedRoles.find((role) => userRoles?.includes(role))

  return acceptedRole ?? persona.role
}

export function getAcceptedRoles(scope: AppScopeEnum): RoleEnum[] {
  if (scope === AppScopeEnum.ADMIN) {
    return [RoleEnum.ROLE_ADMIN]
  }

  if (scope === AppScopeEnum.CLIENT) {
    return [RoleEnum.ROLE_CONFIGURATOR, RoleEnum.ROLE_MANAGER]
  }

  if (scope === AppScopeEnum.DASHBOARD) {
    return [
      RoleEnum.ROLE_CONFIGURATOR,
      RoleEnum.ROLE_MANAGER,
      RoleEnum.ROLE_ADMIN,
      RoleEnum.ROLE_OPERATOR,
    ]
  }

  return []
}

function useIdentityFilter(
  requestedPersona: Persona,
  identity: Partial<UserProfile> | undefined,
) {
  const { roles, clients } = identity || {}

  const persona = useMemo(() => {
    const result = { ...requestedPersona }

    if (roles?.length && !roles.includes(result.role as any)) {
      result.role = roles[0]
    }

    const clientIds = clients?.map((client) => client['@id'])
    if (clientIds?.length && !clientIds.includes(result.clientId as any)) {
      result.clientId = clientIds[0]
    }

    return result
  }, [roles, clients, requestedPersona])

  return useMemoByValues(persona)
}

function useReadPersona() {
  const { query } = useParsedLocation<Persona>()

  const storage = useStorage()
  return useMemo(() => {
    const preference = tryParseJSON<Persona>(storage.getItem(PREFERENCE_KEY))
    return {
      clientId: toApiPlatformId(
        'clients',
        query.clientId || preference?.clientId,
      ),
      role: query.role || preference?.role,
    }
  }, [query, storage])
}

function useWritePersona(persona: Persona) {
  const storage = useStorage()

  useEffect(
    function toStorage() {
      try {
        storage.setItem(PREFERENCE_KEY, JSON.stringify(persona))
      } catch {
        /* never mind if it fails */
      }
    },
    [persona, storage],
  )

  const createRoleUrl = useCreateRoleUrl()
  const history = useHistory()
  const { query } = useParsedLocation<Persona>()
  const queryIsIncomplete = !query.clientId || !query.role

  // for better performances, we don't want to update the url when the browser is too busy
  // because it will trigger some rerenders with the router
  useIdleEffect(() => {
    if (queryIsIncomplete && persona.role) {
      const targetUrl = createRoleUrl(persona as any)
      const currentUrl = history.location.pathname + history.location.search
      // there can be concurrent updates
      if (targetUrl !== currentUrl) {
        history.replace(targetUrl)
      }
    }
  }, [queryIsIncomplete, persona, createRoleUrl, history])
}

function useIdleEffect(effect: () => any, deps: any[]) {
  const idleCallbackId = useRef<number | undefined>(undefined)

  useEffect(() => {
    idleCallbackId.current && cancelIdleCallback(idleCallbackId.current)
    idleCallbackId.current = requestIdleCallback(effect)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps)
}
