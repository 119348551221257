import type { EditProps } from 'react-admin'
import { SfmEdit } from 'src/AppScopes/resource'
import { RoleEnum } from 'src/UserIdentity'

import { ItemForm } from './Form'

export function Edit(props: EditProps) {
  return (
    <SfmEdit {...props} acceptedRoles={[RoleEnum.ROLE_CONFIGURATOR]}>
      <ItemForm />
    </SfmEdit>
  )
}
