import type { Expr, AnyFunction, FuncNameEnum } from './types'

export * from './types'
export * from './factories'

export function interpret(
  expression: Expr,
  functionsDefinitions: Record<FuncNameEnum, AnyFunction>,
): any {
  if (expression.type === 'literal') {
    return expression.value
  }

  if (expression.type === 'function') {
    const func = functionsDefinitions[expression.funcName]
    if (!func) {
      throw new Error(
        `There is no definition for function name ${expression.funcName}`,
      )
    }

    if (expression.args.some((arg) => !arg)) {
      throw new Error(
        `There is undefined arguments in the expression : ${JSON.stringify(
          expression,
        )}`,
      )
    }

    const args: any[] = expression.args.map((arg) =>
      interpret(arg as any, functionsDefinitions),
    )
    return func(...args)
  }

  if (expression.type === 'rule') {
    const result = expression.cases.find((test) => {
      if (test.condition) return interpret(test.condition, functionsDefinitions)
      return false
    })

    return result?.then ?? expression.default
  }

  if (expression.type === 'eval') {
    try {
      // eslint-disable-next-line no-new-func
      const func = new Function('values = {}', expression.code)
      return func(functionsDefinitions.getValues())
    } catch (e) {
      return undefined
    }
  }

  throw new Error('Unknown expression type')
}

export function isExpr(value: any): value is Expr {
  return value?.$$isExpr
}
