import ActionDelete from '@material-ui/icons/Delete'
import type { Dispatch } from 'react'
import React from 'react'
import type { Identifier, Record } from 'react-admin'
import {
  ReferenceField,
  useNotify,
  Button,
  useRecordContext,
  useRefresh,
  FunctionField,
  useDelete,
} from 'react-admin'
import { CustomDatagrid } from 'src/components/CustomDatagrid'
import { MinioFileField } from 'src/components/MinioFileField'
import {
  SfmDateField,
  SfmReferenceArrayField,
  SfmTextField,
} from 'src/components/SfmFields'
import { useGetList } from 'src/libs/useGetList'
import type { Document, Entitlement, UserEntitlement } from 'src/types/api'
import { ResourceEnum } from 'src/types/api/resources'

import { AddUserEntitlementButton } from '../Entitlement/AddUserEntitlementButton'

interface Props {
  label: string
  deletedEntitlements: Set<Identifier>
  setDeletedEntitlements: Dispatch<React.SetStateAction<Set<Identifier>>>
  filter: 'habilitation' | 'autorisation'
  isAdmin: boolean
}

export function UserEntitlementList(props: Props) {
  const { deletedEntitlements, setDeletedEntitlements, filter, isAdmin } = props
  const user = useRecordContext()
  const notify = useNotify()
  const refresh = useRefresh()

  const { data: entitlements } = useGetList<UserEntitlement>(
    ResourceEnum.user_entitlements,
    {
      filter: { 'entitlementId.type': filter, 'userId.id': user.id },
    },
  )

  const entitlementsList =
    entitlements?.map((entitlement) => entitlement.id) ?? []

  const [deleteOne, { loading }] = useDelete()
  const handleDelete = (userEntitlement: Record) => {
    deleteOne('user_entitlements', userEntitlement.id, userEntitlement, {
      onSuccess: function () {
        setDeletedEntitlements(
          new Set([...Array.from(deletedEntitlements), userEntitlement.id]),
        )
        notify('notifications.delete.habilitation', 'success')
      },
      onFailure: function ({ error }: { error: any }) {
        notify(error.message, 'error')
      },
    })
  }

  return (
    <SfmReferenceArrayField
      label={`resource.interventions.${filter}s`}
      actions={
        filter === 'habilitation' &&
        isAdmin && (
          <AddUserEntitlementButton
            resource="user"
            type="habilitations"
            resourceId={user.id}
            onChange={refresh}
          />
        )
      }
      record={{
        ...user,
        userEntitlements: entitlementsList,
      }}
      source="userEntitlements"
      reference="user_entitlements"
    >
      <CustomDatagrid rowClick="">
        <ReferenceField
          source="entitlementId"
          reference={`${filter}s`}
          label="resource.entitlements.name"
          link={isAdmin && 'show'}
        >
          <SfmTextField source="name" />
        </ReferenceField>
        <SfmDateField source="start" label="resource.user_entitlements.start" />
        <SfmDateField
          source="endTime"
          label="resource.user_entitlements.endDate"
        />
        <ReferenceField
          source="document"
          reference="documents"
          label="resource.user_entitlements.document"
          link={false}
        >
          <FunctionField<Document>
            render={(document) => {
              if (document) {
                return (
                  <MinioFileField
                    fileName={document.fileName || ''}
                    bucketId={user.id}
                    title={document.name}
                  />
                )
              }
              return null
            }}
          />
        </ReferenceField>
        {isAdmin && (
          <FunctionField<Entitlement>
            render={(userEntitlement) => {
              return (
                <Button
                  label="actions.delete"
                  color="secondary"
                  disabled={loading}
                  onClick={(e) => {
                    e.stopPropagation()
                    if (userEntitlement) handleDelete(userEntitlement)
                  }}
                >
                  <ActionDelete />
                </Button>
              )
            }}
          />
        )}
      </CustomDatagrid>
    </SfmReferenceArrayField>
  )
}
