import { useEffect, useState } from 'react'
import type { Record as RaRecord } from 'react-admin'
import {
  ListContextProvider,
  useNotify,
  useRecordContext,
  useUpdate,
} from 'react-admin'
import { DataList } from 'src/components/DataList'
import { LabeledField } from 'src/components/LabeledField'
import { MultipleResourcePicker } from 'src/components/Picker'
import { useGetList } from 'src/libs/useGetList'
import type { Entitlement } from 'src/types/api'
import { EntitlementTypeEnum } from 'src/types/api'
import { ResourceEnum } from 'src/types/api/resources'
import { AddIcon } from 'src/UI/theme/icons'

interface Props {
  filter: Record<string, any>
  resource: ResourceEnum
  notifResource: string
  readonly?: boolean
}

export function EntitlementsFields(props: Props) {
  const { filter, resource, notifResource, readonly } = props
  const record = useRecordContext<RaRecord>()

  const {
    data: entitlements,
    loading,
    loaded,
  } = useGetList<Entitlement>(ResourceEnum.entitlements, {
    filter: {
      ...filter,
      showArchived: true,
    },
  })

  const [habilitations, setHabilitations] = useState<Entitlement[]>()
  const [autorisations, setAutorisations] = useState<Entitlement[]>()

  const [archivedEntitlement, setArchivedEntitlement] =
    useState<Entitlement[]>()

  useEffect(() => {
    if (loaded && entitlements) {
      setHabilitations(
        entitlements.filter(
          (entitlement) =>
            entitlement.type === EntitlementTypeEnum.habilitation &&
            !entitlement.isArchived,
        ),
      )
      setAutorisations(
        entitlements.filter(
          (entitlement) =>
            entitlement.type === EntitlementTypeEnum.autorisation &&
            !entitlement.isArchived,
        ),
      )

      setArchivedEntitlement(
        entitlements.filter((entitlement) => entitlement.isArchived),
      )
    }
  }, [entitlements, loaded])

  const notify = useNotify()
  const [update, { loading: updateLoading }] = useUpdate()

  const saveHabilitations = (values: Entitlement[]) => {
    update(
      resource,
      record.id,
      {
        entitlements: [
          ...values,
          ...(autorisations ?? []),
          ...(archivedEntitlement ?? []),
        ].map((entitlement) => entitlement.id),
      },
      record,
      {
        onSuccess: function () {
          setHabilitations(values)
          notify(`notifications.update.${notifResource}`, 'success')
        },
        onFailure: function (error) {
          notify(error, 'error')
        },
      },
    )
  }

  const saveAutorisations = (values: Entitlement[]) => {
    update(
      resource,
      record.id,
      {
        entitlements: [
          ...values,
          ...(habilitations ?? []),
          ...(archivedEntitlement ?? []),
        ].map((entitlement) => entitlement.id),
      },
      record,
      {
        onSuccess: function () {
          setAutorisations(values)
          notify(`notifications.update.${notifResource}`, 'success')
        },
        onFailure: function (error) {
          notify(error, 'error')
        },
      },
    )
  }

  return (
    <>
      <ListContextProvider
        value={{
          data: habilitations ?? [],
          basePath: '/habilitations',
          loading: loading,
        }}
      >
        <LabeledField
          label="resource.entitlements.habilitations"
          fullBleed
          actions={
            !readonly && (
              <MultipleResourcePicker<Entitlement>
                saving={updateLoading}
                resource={ResourceEnum.habilitations}
                selectedIds={
                  habilitations?.map((habilitation) => habilitation.id) ?? []
                }
                label="actions.assign"
                title="actions.assign_habilitations"
                getOptionTitle={(entitlement) => entitlement.name}
                onSave={saveHabilitations}
                Icon={<AddIcon />}
              />
            )
          }
        >
          <DataList<Entitlement> />
        </LabeledField>
      </ListContextProvider>

      <ListContextProvider
        value={{
          data: autorisations ?? [],
          basePath: '/autorisations',
          loading: loading,
        }}
      >
        <LabeledField
          label="resource.entitlements.autorisations"
          fullBleed
          actions={
            !readonly && (
              <MultipleResourcePicker<Entitlement>
                saving={updateLoading}
                resource={ResourceEnum.autorisations}
                selectedIds={
                  autorisations?.map((autorisation) => autorisation.id) ?? []
                }
                label="actions.assign"
                title="actions.assign_autorisations"
                getOptionTitle={(entitlement) => entitlement.name}
                onSave={saveAutorisations}
                Icon={<AddIcon />}
              />
            )
          }
        >
          <DataList<Entitlement> />
        </LabeledField>
      </ListContextProvider>
    </>
  )
}
