import React from 'react'
import { useTranslate } from 'react-admin'

import type { HydratedPhoneNumbers } from 'src/adapters/antiCorruptionLayerProxy'
import { joinStrings } from 'src/libs/joinStrings'

import type { SfmFunctionFieldProps } from './SfmFields'
import { SfmFunctionField, EmptyText } from './SfmFields'

type Props = Omit<SfmFunctionFieldProps, 'render'>

export function PhoneNumbersField(props: Props) {
  const __ = useTranslate()
  return (
    <SfmFunctionField
      source="phoneNumbers"
      render={(record) => {
        if (record?.phoneNumbers && record.phoneNumbers.length > 0) {
          return joinStrings(
            (record as HydratedPhoneNumbers).phoneNumbers.map(
              (phone) => phone.number,
            ),
            ', ',
          )
        }
        return <EmptyText />
      }}
      {...props}
    />
  )
}
