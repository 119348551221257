import { Link, Button, Typography } from '@material-ui/core'
import { Publish } from '@material-ui/icons'
import type { Spec } from '@nartex/sfm-form-engine'
import type { ProfunctorState } from '@staltz/use-profunctor-state'
import type { AxiosError } from 'axios'
import classNames from 'classnames'
import { useSnackbar } from 'notistack'
import { useCallback, useState } from 'react'
import { Link as RouterLink, useHistory } from 'react-router-dom'

import { useTranslate } from '../adapters/I18nProvider'
import { useErrors } from '../Validation/Errors'

import { Dialog } from './Dialog'
import { useStyles } from './styles'

interface Props {
  className?: string
  backLinkTo: string
  onSave: () => Promise<void>
  title?: string
  isDirty: boolean
  store: ProfunctorState<Spec>
}

export function TopBar(props: Props) {
  const { className, backLinkTo, onSave, title, isDirty, store } = props

  const styles = useStyles()
  const __ = useTranslate()
  const history = useHistory()
  const { enqueueSnackbar } = useSnackbar()

  const [exitDialogIsOpen, setExitDialogIsOpen] = useState(false)
  const { onSave: onClickSave, loading } = useHandleOnSave(onSave)
  const status = loading ? 'loading' : isDirty ? 'dirty' : 'idle'

  function getButtonMessage() {
    if (status === 'idle') {
      return __({
        id: 'TopBar.saveButton.label.idle',
        defaultMessage: 'Enregistré',
        description: 'Label of the save button on the top bar, when idle',
      })
    }

    if (status === 'loading') {
      return __({
        id: 'TopBar.saveButton.label.saving',
        defaultMessage: 'Enregistrement…',
        description: 'Label of the save button on the top bar, when saving',
      })
    }

    if (status === 'dirty') {
      return __({
        id: 'TopBar.saveButton.label.dirty',
        defaultMessage: 'Enregistrer',
        description: 'Label of the save button on the top bar, when dirty',
      })
    }

    return null as never
  }

  const [importDialogIsOpen, setImportDialogIsOpen] = useState(false)
  const importForm = useCallback(
    async (file: File) => {
      try {
        const spec = JSON.parse(await file.text())
        store.setState(spec)
      } catch {
        enqueueSnackbar(
          __({
            id: 'Notification.error.ImportForm',
            defaultMessage:
              'Import du formulaire impossible : fichier non reconnu',
            description:
              'Notification displayed when the import file is not recognized',
          }),
          { variant: 'error' },
        )
      }
    },
    [__, enqueueSnackbar, store],
  )

  return (
    <>
      <nav className={classNames(className, styles.mainContainer)}>
        <Link
          component={RouterLink}
          to={backLinkTo}
          variant="button"
          onClick={(event) => {
            if (isDirty) {
              setExitDialogIsOpen(true)
              event?.preventDefault()
            }
          }}
          className={classNames(styles.back)}
        >
          {__({
            id: 'TopBar.backButton.label',
            defaultMessage: 'Retour',
            description: 'Label of the back button on the top bar',
          })}
        </Link>

        <Dialog
          isOpen={exitDialogIsOpen}
          onClose={() => setExitDialogIsOpen(false)}
          title={__({
            id: 'ExitDialog.Title',
            defaultMessage:
              'Le formulaire contient des modifications non enregistrées',
            description:
              'Title of the dialog displayed when the user want to leave the page but the form has unsaved changes.',
          })}
          content={__({
            id: 'ExitDialog.Text',
            defaultMessage:
              'Vous perdrez vos modifications si vous quittez maintenant.',
            description:
              'Text of the dialog displayed when the user want to leave the page but the form has unsaved changes.',
          })}
          confirmButtonProps={{
            onConfirm() {
              history.push(backLinkTo)
            },
            label: __({
              id: 'ExitDialog.Action.Leave',
              defaultMessage: 'Quitter sans sauvegarder',
              description:
                'Text of the button to leave without saving changes.',
            }),
          }}
        />

        <Typography color="inherit" variant="h2">
          {title}
        </Typography>

        <div className={styles.rightButtons}>
          <Button
            color="inherit"
            onClick={() => {
              if (store.state.fields.length) {
                setImportDialogIsOpen(true)
                return
              }
              chooseFile(importForm)
            }}
            startIcon={<Publish />}
          >
            {__({
              id: 'TopBar.importButton.label',
              defaultMessage: 'Importer un formulaire',
              description: 'Label of the import form button on the top bar',
            })}
          </Button>

          <Dialog
            isOpen={importDialogIsOpen}
            onClose={() => setImportDialogIsOpen(false)}
            title={__({
              id: 'ImportDialog.Title',
              defaultMessage: 'Importer un formulaire.',
              description:
                'Title of the dialog displayed when the user want to import a form',
            })}
            content={__({
              id: 'ImportDialog.Text',
              defaultMessage: 'Le formulaire existant sera écrasé.',
              description:
                'Text of the dialog displayed when the user want to import a new form over an existing one.',
            })}
            confirmButtonProps={{
              onConfirm() {
                chooseFile(importForm)
                setImportDialogIsOpen(false)
              },
              label: __({
                id: 'ImportDialog.Action.Import',
                defaultMessage: 'Importer',
                description:
                  'Text of the button to import a new form and override the existing one.',
              }),
            }}
          />

          <Button
            color="inherit"
            variant="outlined"
            onClick={onClickSave}
            disabled={['loading', 'idle'].includes(status)}
          >
            {getButtonMessage()}
          </Button>
        </div>
      </nav>
    </>
  )
}

function useHandleOnSave(processOnSave: () => Promise<void>) {
  const __ = useTranslate()
  const { enqueueSnackbar } = useSnackbar()
  const [loading, setLoading] = useState(false)

  const errors = useErrors()

  const formInvalidMessage = __({
    id: 'Notification.error.FormIvalid',
    defaultMessage:
      "Impossible d'enregistrer : Certains champs sont invalides.",
    description: 'Notification displayed on save when there is invalid fields',
  })

  const startMessage = __({
    id: 'Notification.FormSaving',
    defaultMessage: 'Enregistrement du formulaire…',
    description: 'Notification displayed when the form is saving.',
  })

  const successMessage = __({
    id: 'Notification.FormSaved',
    defaultMessage: 'Formulaire enregistré',
    description: 'Notification displayed when the form is successfully saved.',
  })

  const errorMessage = __({
    id: 'Notification.error.FormSave',
    defaultMessage:
      'Une erreur est survenue lors de la sauvegarde du formulaire',
    description: 'Notification displayed when the form saving throw an error.',
  })

  const draftVersionAlreadyExistMessage = __({
    id: 'Notification.error.draftVersionExist',
    defaultMessage: 'Une version brouillon existe déjà pour ce formulaire',
    description:
      'Notification displayed when the form saving throw the draft version already existing error.',
  })

  return {
    loading,
    async onSave() {
      const hasError = Object.values(errors).some(Boolean)

      if (hasError) {
        enqueueSnackbar(formInvalidMessage, { variant: 'error' })
        setLoading(false)
        return
      }

      try {
        setLoading(true)
        enqueueSnackbar(startMessage, { variant: 'info' })
        await processOnSave()
        enqueueSnackbar(successMessage, { variant: 'success' })
      } catch (error) {
        console.error(error)
        if ((error as AxiosError)?.response?.status === 409) {
          return enqueueSnackbar(draftVersionAlreadyExistMessage, {
            variant: 'error',
          })
        }
        enqueueSnackbar(errorMessage, { variant: 'error' })
      } finally {
        setLoading(false)
      }
    },
  }
}

function chooseFile(callback: (file: File) => void) {
  // Create an input element
  const inputElement = document.createElement('input')
  // Hide element and append to body (required to run on iOS safari)
  inputElement.style.display = 'none'
  document.body.appendChild(inputElement)
  inputElement.type = 'file'
  inputElement.accept = '.json'
  // set onchange event to call callback when user has selected file
  // inputElement.addEventListener('change', callback);
  inputElement.addEventListener('change', async (arg) => {
    const file = (arg.target as HTMLInputElement).files?.item(0)
    if (file) callback(file)
    // remove element
    document.body.removeChild(inputElement)
  })
  // dispatch a click event to open the file dialog
  inputElement.dispatchEvent(new MouseEvent('click'))
}
