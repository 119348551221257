import type { Identifier } from 'react-admin'

export function formatApiPlatformId<Id extends Identifier | undefined>(
  apiPlatformId: Id,
): Id {
  if (isApiPlatformId(apiPlatformId)) {
    return last(apiPlatformId.split('/')) as Id
  }
  return apiPlatformId
}

export function toApiPlatformId<Id extends Identifier | undefined>(
  resource: string,
  id: Id,
): Id {
  if (typeof id !== 'string') return id
  if (isApiPlatformId(id)) return id
  return `/api/${resource}/${id}`
}

function isApiPlatformId(id?: Identifier): id is string {
  return Boolean(id && typeof id === 'string' && id.startsWith('/api/'))
}

function last<T>(array: T[]): T | undefined {
  return array[array.length - 1]
}
