import React from 'react'
import type { PropsWithChildren } from 'react'
import {
  useTranslate,
  Resource as RaResource,
  Loading,
  ResourceContextProvider,
  useShowController,
  ShowContextProvider,
  ShowView,
} from 'react-admin'

import type { ResourceProps, Record } from 'react-admin'
import { useAppScope } from 'src/AppScopes/useAppScope'
import { ShowActions } from 'src/components/ProtectedActions'
import { useDefineAppLocation } from 'src/libs/useDefineAppLocation'
import { PageLayout } from 'src/UI/PageLayout'
import { usePersona } from 'src/UserIdentity'

import { Create } from './Create'
import { Edit } from './Edit'
import { List } from './List'
import type {
  SfmListProps,
  SfmCreateProps,
  SfmShowProps,
  SfmEditProps,
} from './types'

export * from './types'

/*
 * List
 */

export function SfmList(props: PropsWithChildren<SfmListProps>) {
  const { children, resource, acceptedRoles, acceptedEditRoles } = props

  const __ = useTranslate()
  const appScope = useAppScope()
  const { clientId, client } = usePersona()

  useDefineAppLocation(`${appScope}.${resource}.list`)

  const listProps = {
    ...props,
    title: `titles.${resource}.list`,
    acceptedEditRoles: acceptedEditRoles ?? acceptedRoles,
  }

  if (appScope === 'client') {
    if (!client) return <Loading />
    return (
      <PageLayout acceptedRoles={acceptedRoles}>
        <List
          caption={client?.name}
          {...listProps}
          filter={{
            ...props?.filter,
            'client.id': clientId,
            client: clientId,
          }}
        >
          {children}
        </List>
      </PageLayout>
    )
  }

  return (
    <PageLayout acceptedRoles={acceptedRoles}>
      <List caption={__('menu.administration.title')} {...listProps}>
        {children}
      </List>
    </PageLayout>
  )
}

/*
 * Create
 */
export function SfmCreate(props: SfmCreateProps) {
  const { resource, acceptedRoles } = props

  const appScope = useAppScope()
  useDefineAppLocation(`${appScope}.${resource}.create`)

  return (
    <PageLayout acceptedRoles={acceptedRoles}>
      <Create {...props} />
    </PageLayout>
  )
}

/*
 * Show
 */

export function SfmShow(props: SfmShowProps) {
  const { acceptedRoles, acceptedEditRoles, children, ...childProps } = props

  return (
    <PageLayout acceptedRoles={acceptedRoles}>
      {/* PageLayout provides a ContextualDataProvider, the useShowController call must be done in this context */}
      <RenderInContext />
    </PageLayout>
  )

  function RenderInContext() {
    const appScope = useAppScope()
    const controllerProps = useShowController(props)

    useDefineAppLocation(
      `${appScope}.${props.resource}.show`,
      controllerProps.record,
    )

    // Copied from react-admin sources of `<Show /> component`
    const body = (
      <ShowContextProvider value={controllerProps}>
        <ShowView
          {...childProps}
          {...controllerProps}
          actions={
            childProps.actions ?? (
              <ShowActions acceptedRoles={acceptedEditRoles ?? acceptedRoles} />
            )
          }
        >
          {children}
        </ShowView>
      </ShowContextProvider>
    )

    if (!props.resource) return body

    return (
      <ResourceContextProvider value={props.resource}>
        {body}
      </ResourceContextProvider>
    )
  }
}

/*
 * Edit
 */

export function SfmEdit<T extends Record>(props: SfmEditProps<T>) {
  const { acceptedRoles, ...editProps } = props

  return (
    <PageLayout acceptedRoles={acceptedRoles}>
      {/* PageLayout provides a ContextualDataProvider, the useEditController call must be done in this context */}
      <Edit<T> {...editProps} />
    </PageLayout>
  )
}

/*
 * Resource
 */
export interface SfmResourceProps
  extends Omit<ResourceProps, 'edit' | 'show' | 'list' | 'create'> {
  edit?: React.FC<SfmEditProps>
  show?: React.FC<SfmShowProps>
  list?: React.FC<SfmListProps>
  create?: React.FC<SfmCreateProps>
}

export function SfmResource(props: SfmResourceProps) {
  return <RaResource {...(props as any)} />
}
