import type { ShowActionsProps, ShowProps } from 'react-admin'
import { SfmShow } from 'src/AppScopes/resource'
import { ArchiveButton } from 'src/components/ArchiveButton'
import { ShowActions } from 'src/components/ProtectedActions'
import { SfmTextField } from 'src/components/SfmFields'
import type { Item } from 'src/types/api'
import { RoleEnum, usePersona } from 'src/UserIdentity'

import { ShowLayout } from '../../components/ShowLayout'

export function Show(props: ShowProps) {
  return (
    <SfmShow {...props} actions={<ItemActions />}>
      <ShowLayout<Item> title={(item) => item.name}>
        <SfmTextField source="reference" label="resource.items.reference" />
        <SfmTextField source="designation" label="resource.items.designation" />
      </ShowLayout>
    </SfmShow>
  )
}

function ItemActions(props: ShowActionsProps) {
  const { data: item } = props
  const { role } = usePersona()

  const canArchive =
    role && [RoleEnum.ROLE_ADMIN, RoleEnum.ROLE_CONFIGURATOR].includes(role)

  const canBeDeleted = item && !itemHasRelation(item as Item)

  return (
    <ShowActions {...props} hasDelete={canBeDeleted}>
      {canArchive && item && <ArchiveButton resource="items" record={item} />}
    </ShowActions>
  )
}

function itemHasRelation(item: Item): boolean {
  if (item.interventionItems.length > 0) return true
  return false
}
