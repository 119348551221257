import type { Checkbox, Radio } from '@material-ui/core'
import {
  ListItem as MuiListItem,
  FormControlLabel,
  makeStyles,
  Typography,
} from '@material-ui/core'
import classNames from 'classnames'
import type { Record } from 'react-admin'

export interface ListItemProps<T> {
  record: T
  handleChange: (record: T) => void
  getOptionTitle: (record: T) => string
  getOptionSubtitle?: (record: T) => string
  selected?: boolean
  SelectComponent: typeof Radio | typeof Checkbox
}

const useListItemStyles = makeStyles(function (theme) {
  return {
    listItem: {
      padding: `${theme.spacing(1)}px 0`,
      '&:hover': {
        backgroundColor: theme.palette.grey[100],
      },
    },
    formControl: {
      width: '100%',
      margin: 0,
    },
    optionName: {
      display: 'flex',
      flexDirection: 'column',
    },
    title: {
      fontWeight: 600,
      fontSize: 16,
    },
    subtitle: {
      color: theme.palette.grey[700],
    },
    textEllipsis: {
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
    },
  }
})

export function ListItem<T extends Record>(props: ListItemProps<T>) {
  const {
    record,
    handleChange,
    getOptionTitle,
    getOptionSubtitle,
    selected,
    SelectComponent,
  } = props
  const classes = useListItemStyles()

  return (
    <MuiListItem className={classes.listItem}>
      <FormControlLabel
        className={classes.formControl}
        control={
          <SelectComponent
            color="primary"
            checked={selected}
            onClick={() => {
              if (selected) handleChange(record)
            }}
            onChange={() => handleChange(record)}
          />
        }
        label={
          <div>
            <Typography
              className={classNames(classes.title, classes.textEllipsis)}
              variant="body1"
            >
              {getOptionTitle(record)}
            </Typography>
            <Typography
              className={classNames(classes.subtitle, classes.textEllipsis)}
              variant="body2"
            >
              {getOptionSubtitle && getOptionSubtitle(record)}
            </Typography>
          </div>
        }
        classes={{ label: classes.textEllipsis }}
      />
    </MuiListItem>
  )
}
