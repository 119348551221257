import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import { ReactComponent as IconDisable } from '@nartex/smartforest-design-tokens/graphics/svg/iconDisabled.svg'

import type { ShowActionsProps } from 'react-admin'
import {
  useRefresh,
  useNotify,
  useUpdate,
  Button,
  TopToolbar,
  EditButton,
} from 'react-admin'

import { SfmShow } from 'src/AppScopes/resource'
import type { SfmShowProps } from 'src/AppScopes/resource'

import { ShowFields } from './showFields'

function ClientActions(props: ShowActionsProps) {
  const { basePath, data: client } = props
  const notify = useNotify()
  const [update, { loading }] = useUpdate()
  const refresh = useRefresh()
  const disable = () => {
    update(
      'clients',
      client?.id,
      { ...client, disabledAt: new Date() },
      client,
      {
        onSuccess() {
          notify('notifications.disable.client', 'success')
          refresh()
        },
        onFailure({ error }: { error: any }) {
          notify(error.message, 'error')
        },
      },
    )
  }

  const enable = () => {
    update('clients', client?.id, { ...client, disabledAt: null }, client, {
      onSuccess() {
        notify('notifications.enable.client', 'success')
        refresh()
      },
      onFailure({ error }: { error: any }) {
        notify(error.message, 'error')
      },
    })
  }

  if (!client) return null
  return (
    <TopToolbar>
      {client.valid ? (
        <Button
          label="actions.disable"
          onClick={disable}
          color="secondary"
          disabled={loading}
        >
          <IconDisable height={20} width={20} />
        </Button>
      ) : (
        <Button
          label="actions.enable"
          onClick={enable}
          color="primary"
          disabled={loading}
        >
          <CheckCircleOutlineIcon height={20} width={20} />
        </Button>
      )}
      <EditButton basePath={basePath} record={client} />
    </TopToolbar>
  )
}

export function Show(props: SfmShowProps) {
  return (
    <SfmShow {...props} actions={<ClientActions />}>
      <ShowFields />
    </SfmShow>
  )
}
