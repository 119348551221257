import { Typography } from '@material-ui/core'

import { useTranslate } from '../adapters/I18nProvider'

export function ErrorMessage() {
  const __ = useTranslate()

  return (
    <>
      <Typography variant="caption">
        {__({
          id: 'componentEditor.invalidType.title',
          defaultMessage: "Type d'élément non reconnu",
          description:
            'Title of the message printed in the component props editor when the selected element is not recognized',
        })}
      </Typography>
    </>
  )
}
