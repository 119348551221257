import { makeStyles } from '@material-ui/core'
import type { CreateCSSProperties } from '@material-ui/core/styles/withStyles'

import '@nartex/sfm-form-engine/theme'
import { rulerStroke, shadow } from '../theme/mixins'

export const useStyles = makeStyles(function (theme) {
  const padding = theme.spacing(4)
  const gap = theme.spacing(3)
  const elementsWidth = theme.spacing(60)

  const hoverStyle: CreateCSSProperties<{}> = {
    background: theme.palette.background.paper,
    boxShadow: shadow(),
    '&:before': {
      ...rulerStroke(theme, 'horizontal'),
    },

    '&:after': {
      ...rulerStroke(theme, 'horizontal'),
    },
  }
  const selectedStyle: CreateCSSProperties<{}> = {
    ...hoverStyle,
    boxShadow: `0 0 0 1px ${theme.palette.primary.main}, ${shadow(2)}`,
  }

  return {
    mainContainer: {},
    elements: {
      position: 'relative',
      margin: 0,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      listStyle: 'none',
      minHeight: '100%',
      padding,
      paddingBottom: theme.spacing(50),

      // react-beautiful-dnd does not support the gap property
      '& > *': {
        marginTop: 1,
        paddingTop: gap / 2 - 1,

        marginBottom: 1,
        paddingBottom: gap / 2 - 1,

        marginLeft: -1 * (padding / 2),
        paddingLeft: padding / 2,

        marginRight: -1 * (padding / 2),
        paddingRight: padding / 2,
      },

      '&:before': {
        ...rulerStroke(theme, 'vertical'),
        left: `calc(50% - ${elementsWidth / 2}px)`,
      },

      '&:after': {
        ...rulerStroke(theme, 'vertical'),
        right: `calc(50% - ${elementsWidth / 2}px)`,
      },
    },
    element: {
      width: '100%',
      maxWidth: elementsWidth + padding,
      '&:hover': {
        ...hoverStyle,
      },

      '& > *': {
        pointerEvents: 'none',
        animation: 'none',
      },
    },
    elementSelected: {
      ...selectedStyle,
      '&:hover': {
        ...selectedStyle,
      },
    },
    isHidden: {
      '& > *': {
        opacity: 0.2,
      },
    },
    error: {
      '& div.border-input': {
        border: 'none',
      },
      '&  ion-item, & .PictureDisplay, & .BaseTextDisplay': {
        border: '1px solid',
        borderColor: theme.palette.error.main,
      },
    },
    errorIcon: {
      position: 'absolute',
      zIndex: 100,
      top: theme.spacing(-1.5),
      right: theme.spacing(-1.5),
      color: theme.palette.error.main,
      backgroundColor: theme.palette.background.default,
      borderRadius: '50%',
    },
  }
})
