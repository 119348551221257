import { Capacitor } from '@capacitor/core'
import { usePlugins } from 'src/plugins'

import { useState, useRef, useLayoutEffect } from 'react'

import { QuickKeyboardActions } from './QuickKeyboardActions'

export function useQuickActions() {
  const { MyKeyboardPlugin } = usePlugins()
  const [focused, setFocused] = useState(false)
  const [highlighted, setHighlighted] = useState(false)
  const timeoutRef = useRef<ReturnType<typeof setTimeout>>()
  function flash() {
    setHighlighted(true)
    setTimeout(() => {
      setHighlighted(false)
    }, 400)
  }

  useLayoutEffect(() => {
    if (!Capacitor.isPluginAvailable('MyKeyboardPlugin')) return

    const listener = MyKeyboardPlugin.addListener('keyboardWillHide', () => {
      setFocused(false)
    })

    return () => {
      listener.remove()
    }
  }, [])

  return {
    className: highlighted && '-highlighted',
    inputProps: {
      onIonFocus() {
        timeoutRef.current && clearTimeout(timeoutRef.current)
        setFocused(true)
      },
      onIonBlur() {
        timeoutRef.current = setTimeout(() => {
          setFocused(false)
        }, 100)
      },
    },
    ActionsBar(props: { onChange: (val: any) => void }) {
      const { onChange } = props
      if (!focused) return null
      return (
        <QuickKeyboardActions
          onChange={(val) => {
            onChange(val)
            flash()
          }}
        />
      )
    },
  }
}
