import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles(function (theme) {
  const padding = theme.spacing(2)

  return {
    mainContainer: {
      paddingLeft: padding,
      paddingRight: padding,

      color: theme.palette.secondary.contrastText,

      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',

      gap: theme.spacing(1),
    },
    back: {
      minWidth: 150,
      color: 'inherit',
    },
    rightButtons: {
      display: 'flex',
      gap: theme.spacing(1),
    },
  }
})
