import { List as MuiList, makeStyles } from '@material-ui/core'
import type { ReactNode } from 'react'
import type { Identifier, Record } from 'react-admin'

import { EmptyText } from '../SfmFields'

import type { ListItemProps } from './ListItem'
import { ListItem } from './ListItem'

const useListStyles = makeStyles(function () {
  return {
    emptyText: {
      textAlign: 'center',
    },
  }
})

interface ListProps<T> {
  options: T[]
  render: (record: T) => ReactNode
}

function List<T>(props: ListProps<T>) {
  const { options, render } = props
  const classes = useListStyles()

  if (options.length === 0) {
    return <EmptyText className={classes.emptyText} />
  }

  const list = Object.values(options).map(render)

  return <MuiList dense>{list}</MuiList>
}

type OptionsListProps<T> = Omit<ListItemProps<T>, 'record'> & {
  options: T[]
}

export function OptionsList<T extends Record>(props: OptionsListProps<T>) {
  const { options, ...listItemProps } = props

  const render = (record: T) => {
    return <ListItem<T> key={record.id} record={record} {...listItemProps} />
  }

  return <List options={options} render={render} />
}

type SearchOptionListProps<T> = Omit<
  OptionsListProps<T>,
  'selected' | 'handleChange'
> & {
  valuesIds: Set<Identifier>
  handleSelect: (record: T) => void
  handleUnSelect: (record: T) => void
}

export function SearchOptionList<T extends Record>(
  props: SearchOptionListProps<T>,
) {
  const { options, valuesIds, handleSelect, handleUnSelect, ...listItemProps } =
    props

  const render = (record: T) => {
    const selected = valuesIds.has(record.id)
    return (
      <ListItem<T>
        key={record.id}
        record={record}
        {...listItemProps}
        handleChange={selected ? handleUnSelect : handleSelect}
        selected={selected}
      />
    )
  }

  return <List options={options} render={render} />
}
