import type { Literal, Func, Eval, Get, Case, Rule } from './types'
import { FuncNameEnum } from './types'

export function rule(cases: Case[], then: boolean): Rule {
  return {
    $$isExpr: true,
    type: 'rule',
    cases: cases,
    default: then,
  }
}

export function ruleCase(condition: Func | Eval, then: boolean): Case {
  return {
    condition,
    then,
  }
}

export function literal<T>(value: T): Literal<T> {
  return {
    $$isExpr: true,
    type: 'literal',
    value,
  }
}

export function func(
  funcName: FuncNameEnum,
  ...args: (Literal<any> | Get)[]
): Func {
  return {
    $$isExpr: true,
    type: 'function',
    funcName,
    args,
  }
}

export function get(key: string): Get {
  return {
    $$isExpr: true,
    type: 'function',
    funcName: FuncNameEnum.getValue,
    args: [literal(key)],
  }
}

export function evalExpr(code: string): Eval {
  return {
    $$isExpr: true,
    type: 'eval',
    code,
  }
}
