// THIS FILE IS AUTO-GENERATED BY FIGMAGIC. DO NOT MAKE EDITS IN THIS FILE! CHANGES WILL GET OVER-WRITTEN BY ANY FURTHER PROCESSING.

const fontFamilies = {
 "code": "Roboto Mono",
 "label": "Source Sans Pro",
 "button": "Source Sans Pro",
 "microbutton": "Source Sans Pro",
 "bodyXsBold": "Source Sans Pro",
 "bodyXs": "Source Sans Pro",
 "bodySBold": "Frank Ruhl Libre",
 "bodyS": "Frank Ruhl Libre",
 "bodyMBold": "Source Sans Pro",
 "bodyM": "Source Sans Pro",
 "bodyL": "Source Sans Pro",
 "headingXxs": "Source Sans Pro",
 "headingXs": "Frank Ruhl Libre",
 "headingS": "Frank Ruhl Libre",
 "headingM": "Source Sans Pro",
 "headingL": "Source Sans Pro"
} as const;

export default fontFamilies;