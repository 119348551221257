import type { AxiosError } from 'axios'
import type { History } from 'history'
import * as OIDC from 'oidc-client'
import type { User as OIDCUser } from 'oidc-client'
import type {
  AuthProvider as RaAuthProvider,
  I18nProvider,
  UserIdentity as RaUserIdentity,
} from 'react-admin'
import type { IEnv } from 'src/adapters/Env'

export type UserIdentity = RaUserIdentity & OIDCUser['profile']

interface Params {
  storage: Storage
  translate: I18nProvider['translate']
  env: IEnv
  autoSignIn?: boolean
  history: History
}

type IAuthProvider = RaAuthProvider & {
  init: () => Promise<OIDC.User | null>
  getCurrentAccessToken: () => string | undefined
}

export function AuthProvider(params: Params): IAuthProvider {
  const { storage, translate: __, env, autoSignIn, history } = params

  const domain = env.KEYCLOAK_URL
  const realm = env.KEYCLOAK_REALM
  const client_id = env.KEYCLOAK_CLIENT_ID
  const authority = `${domain}/auth/realms/${realm}`
  const redirect_uri = env.BASE_URL

  if (env.IS_DEV) {
    OIDC.Log.logger = console
  }

  const currentUrl = location.toString()
  const userManager = new OIDC.UserManager({
    authority,
    client_id,
    redirect_uri: currentUrl,
    silent_redirect_uri: redirect_uri,
    post_logout_redirect_uri: redirect_uri,

    response_type: 'code',
    scope: 'openid email profile', // Allow to retrieve the email and user name later api side
    stateStore: new OIDC.WebStorageStateStore({ store: storage }),
    automaticSilentRenew: true,
  })

  let loadedUser: OIDC.User | null = null
  const getUser = dedupe(async function getUser(): Promise<OIDC.User | null> {
    loadedUser = loadedUser ?? (await userManager.getUser())

    if (!loadedUser || loadedUser.expired) return null
    else return loadedUser
  })

  // Those subscriptions are never cleaned up.
  // It should not be a problem as long as the authProvider is used as a singleton
  userManager.startSilentRenew()
  userManager.events.addUserLoaded((user: OIDC.User) => {
    loadedUser = user
  })

  return {
    async init() {
      if (this.hasCodeInUrl()) {
        await userManager.signinCallback()
        const cleanURL = new URL(location.toString())
        cleanURL.searchParams.delete('state')
        cleanURL.searchParams.delete('session_state')
        cleanURL.searchParams.delete('code')
        history.replace(cleanURL.toString().replace(location.origin, ''))
      }

      const user = await getUser()

      if (!user && autoSignIn) {
        await userManager.signinRedirect()
      }
      return user
    },
    /*
     * Returns current access token, if loaded
     **/
    getCurrentAccessToken() {
      return loadedUser?.access_token
    },

    /*
     * Checks wether we a returning from OIDC login flow or not
     **/
    hasCodeInUrl(): boolean {
      const searchParams = new URLSearchParams(location.search)
      const hashParams = new URLSearchParams(location.hash.replace('#', '?'))

      return Boolean(
        searchParams.get('code') ||
          searchParams.get('id_token') ||
          searchParams.get('session_state') ||
          hashParams.get('code') ||
          hashParams.get('id_token') ||
          hashParams.get('session_state'),
      )
    },

    async login() {
      await userManager.clearStaleState()

      if (this.hasCodeInUrl()) {
        return
      }

      const user = await getUser()
      if (user) return

      await userManager.signinRedirect()
    },

    async logout() {
      loadedUser = null
      const user = await getUser()
      if (user) {
        await userManager.signoutRedirect()
      }
    },

    async checkError(error: AxiosError) {
      const LOGGED_OUT_ERROR_MESSAGES = [
        "invalid_request: User session not found or doesn't have client attached on it",
        'invalid_token: Token verification failed',
      ]
      if (LOGGED_OUT_ERROR_MESSAGES.includes(error.response?.data?.detail)) {
        throw error
      }
    },

    async checkAuth() {
      if (!(await getUser())) throw new Error()
    },

    async getIdentity(): Promise<UserIdentity> {
      const user = await getUser()
      if (!user) throw new Error(__('error.not_logged_in'))

      const { profile } = user
      return {
        id: profile.sub,
        avatar: profile.picture,
        fullName: profile.name,
        ...profile,
      }
    },

    async getPermissions(): Promise<boolean> {
      const user = await getUser()

      return Boolean(user)
    },
  }
}

/*
 * A wrapper used to prevent retriggering a promise if one is already pending
 */
function dedupe<T>(thunk: () => Promise<T>): () => Promise<T> {
  let pending: Promise<T> | null
  return () => {
    if (pending) {
      return pending
    }
    pending = thunk().finally(() => (pending = null))
    return pending
  }
}
