import type { SfmEditProps } from 'src/AppScopes/resource'
import { SfmEdit } from 'src/AppScopes/resource'
import { RoleEnum } from 'src/UserIdentity'

import { ProjectForm } from './Form'

export function Edit(props: SfmEditProps) {
  return (
    <SfmEdit {...props} acceptedRoles={[RoleEnum.ROLE_CONFIGURATOR]}>
      <ProjectForm />
    </SfmEdit>
  )
}
