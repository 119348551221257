import { useCallback } from 'react'
import { SfmResource } from 'src/AppScopes/resource'
import type { SfmResourceProps } from 'src/AppScopes/resource'

import { Create } from './Create'
import { Edit } from './Edit'
import { List } from './List'
import { Show } from './Show'

export function Resource(props: SfmResourceProps & { isRound?: boolean }) {
  const { isRound = false } = props
  return (
    <SfmResource
      {...props}
      list={useCallback(
        (listProps) => List({ ...listProps, isRound }),
        [isRound],
      )}
      show={useCallback(
        (showProps) => Show({ ...showProps, isRound }),
        [isRound],
      )}
      create={useCallback(
        (createProps) => Create({ ...createProps, isRound }),
        [isRound],
      )}
      edit={useCallback(
        (editProps) => Edit({ ...editProps, isRound }),
        [isRound],
      )}
    />
  )
}
