import { useMemo } from 'react'
import type {
  PaginationPayload,
  SortPayload,
  Record as RaRecord,
  Identifier,
  useGetList as useRaGetList,
} from 'react-admin'
import { useDataProvider } from 'react-admin'
import type { UseQueryOptions } from 'react-query'
import { useQuery } from 'react-query'

interface UseGetListFinalQueryParams {
  pagination?: Partial<PaginationPayload>
  sort?: Partial<SortPayload>
  filter?: Record<string, any>
}

const defaultPagination = { page: 1, perPage: 25 }
const defaultSort = { field: 'updatedAt', order: 'DESC' }

type UseGetListResult<T> = Omit<ReturnType<typeof useRaGetList>, 'data'> & {
  data?: T[]
  recordMap?: Record<Identifier, T>
}
export function useGetList<RecordType extends RaRecord = RaRecord>(
  resource: string,
  params: UseGetListFinalQueryParams,
  options?: UseQueryOptions,
): UseGetListResult<RecordType> {
  const { sort, filter, pagination } = params

  const dataProvider = useDataProvider()
  const useQueryResult = useQuery([resource, params, options], () => {
    return dataProvider.getList<RecordType>(
      resource,
      {
        ...params,
        pagination: { ...defaultPagination, ...pagination },
        sort: { ...defaultSort, ...sort },
        filter: filter ?? {},
      },
      options,
    )
  })

  const recordMap = useMemo(() => {
    if (!useQueryResult.data) return
    const result: Record<Identifier, RecordType> = {}
    useQueryResult.data.data?.forEach((record) => {
      result[record.id] = record
    })
    return result
  }, [useQueryResult.data])

  const ids = useMemo(() => {
    if (!recordMap) return []
    return Object.keys(recordMap)
  }, [recordMap])

  return {
    ...useQueryResult,
    loading: useQueryResult.isLoading,
    loaded: useQueryResult.isFetched,
    data: useQueryResult.data?.data,
    recordMap,
    ids,
  }
}
