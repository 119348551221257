import base from 'ra-language-english'

export const translations = {
  ...base,
  dashboard: {
    title: 'Welcome %{name}',
    administration: 'Administrate your clients and users from the side menu',
    management:
      'Select a client in the left scrolling menu to create and plan its interventions',
    unauthorized: "You don't have the necessary rights to use this back office",
  },
  menu: {
    administration: {
      title: 'Administration',
      clients: 'Clients',
      users: 'Users',
      habilitations: 'Habilitations',
    },
    current_client: 'Active client',
    reset_password: 'Reset password',
    configuration: {
      title: 'Configuration',
      no_client: "You're not assigned to a client",
      client: "Client's information",
      contacts: 'Contacts',
      autorisations: 'Authorizations',
      projects: 'Projects',
      work_orders: 'Work Orders',
      interventions: 'Interventions',
      documents: 'Documents',
      items: 'Items',
      equipment: 'Equipments',
      forms: 'Forms',
      reports: 'Report templates',
      round: 'Round',
      groups: 'User group',
    },
    management: {
      title: 'Management',
      projects: 'Projects',
      work_orders: 'Work Orders',
      interventions: 'Interventions',
    },
  },
  info: {
    loading: 'Loading',
  },
  error: {
    page_not_allowed: "You're not allowed to access this page",
    not_allowed: "You're not allowed to do this actions",
    not_logged_in: 'Please log in',
    no_client: 'There is no client selected.',
    no_item: 'There is no item added',
    invalid_email: 'Email format is invalid',
    value_not_available: 'This value is already taken',
    recurring_value_blocked:
      "The recurring status of this intervention can't be changed",
  },
  validation: {
    required: 'This field is required.',
    url: 'Please fill-in a valid url',
  },
  warning: {
    duplicate_ids:
      'Several elements have the same id. Please select another column or clean your data',
  },
  roles: {
    administrator: 'administrator',
    manager: 'manager',
    configurator: 'configurator',
    operator: 'operator',
  },
  apiRoles: {
    ROLE_ADMIN: 'Administrator',
    ROLE_MANAGER: 'Manager',
    ROLE_CONFIGURATOR: 'Configurator',
    ROLE_OPERATOR: 'Operator',
    ROLE_USER: 'User',
  },
  status: {
    created: 'Created',
    ready: 'Ready',
    started: 'Started',
    ended: 'Ended',
    validated: 'Validated',
    assigned: 'Assigned',
    validating: 'Validating',
    sent: 'Sent',
    cancelled: 'Cancelled',
    refused: 'Refused',
    need_validation: 'Need validation',
  },
  actions: {
    show: 'Show',
    create: 'Create',
    delete: 'Delete',
    confirm: 'Confirm',
    back: 'Back',
    edit: 'Edit',
    cancel: 'Cancel',
    disable: 'Disable',
    enable: 'Enable',
    set_ready: 'Set ready',
    end: 'End',
    add: 'Add',
    add_users: 'Add users',
    add_report_recipients: 'Add report recipients',
    add_contact: 'Add contact',
    assign: 'Assign',
    reassign: 'Reassign',
    associate: 'Associate',
    'associate.user': 'Associate a user',
    'associate.group': 'Associate a group',
    deassociate: 'Deassociate',
    reassociate: 'Reassociate',
    'reassociate.user': 'Reassociate a user',
    'reassociate.group': 'Reassociate a group',
    assign_operator: 'Assign an operator',
    assign_documents: 'Assign documents',
    assign_items: 'Assign items',
    assign_equipments: 'Assign equipments',
    assign_reports: 'Associate default report templates',
    assign_entitlements: 'Assign habilitations & autorisations',
    assign_habilitations: 'Assign habilitations',
    assign_autorisations: 'Assign autorisations',
    publish: 'Publish',
    generate_report: 'Generate report',
    open_form_editor: 'Open in editor',
    search: 'Search',
    archive: 'Archive',
    unarchive: 'Unarchive',
    validate_datas: 'Validate this datas',
    reject_datas: 'Reject this datas',
    repipe_datas: 'To be completed',
    use_other_report: 'Use an other report model',
    importCsv: 'Import this csv',
    addCsv: 'Add csv file',
    select_header: 'Select a unique header',
    download_csv: 'Download csv file',
    republish: 'Republish',
    export: 'Export',
    import: 'Import',
  },
  notifications: {
    creation: {
      contact: 'Contact created',
      client: 'Client created',
      document: 'Document created',
      form_version: 'Version created',
      report_version: 'Report template version created',
    },
    update: {
      client: 'Client updated',
      intervention: 'Intervention updated',
      contact: 'Contact updated',
      document: 'Document updated',
      work_orders: 'Work order updated',
      items: 'Item updated',
      equipment: 'Equipment updated',
      documents: 'Document updated',
      forms: 'Form updated',
      reports: 'Reports updated',
      groups: 'User group updated',
    },
    archive: {
      client: 'Client archived',
      intervention: 'Intervention archived',
      contact: 'Contact archived',
      document: 'Document archived',
      work_orders: 'Work order archived',
      items: 'Item archived',
      equipment: 'Equipment archived',
      documents: 'Document archived',
      forms: 'Form archived',
      reports: 'Reports archived',
      projects: 'Project archived',
    },
    unarchive: {
      client: 'Client unarchived',
      intervention: 'Intervention unarchived',
      contact: 'Contact unarchived',
      document: 'Document unarchived',
      work_orders: 'Work order unarchived',
      items: 'Item unarchived',
      equipment: 'Equipment unarchived',
      documents: 'Document unarchived',
      forms: 'Form unarchived',
      reports: 'Reports unarchived',
      projects: 'Project unarchived',
    },
    delete: {
      contact: 'Contact deleted',
      habilitation: 'Habilitation deleted',
      autorisation: 'Authorization deleted',
      user_autorisations: "User's authorization deleted",
      user_habilitations: "User's habilitation deleted",
    },
    disable: {
      client: 'Client disabled',
    },
    enable: {
      client: 'Client enabled',
    },
    assign: {
      documents: 'Documents assigned',
      items: 'Items assigned',
      equipments: 'Equipments assigned',
      reports: 'Report templates assigned',
      habilitations: 'Habilitation assigned',
      autorisations: 'Authorization assigned',
      users: 'Users assigned',
    },
    publish: {
      form_version: 'Version published',
    },
    status_changed: 'Status has changed',
    error: {
      unrecognized_file: 'Unrecognized file',
      draft_version_exists: 'A draft version already exists',
    },
    success: {
      email_sent: 'Email successfully sent',
    },
    validation: {
      intervention_datas: {
        validated: "Intervention's datas validated",
        rejected: "Intervention's datas rejected",
        to_complete: "Intervention's datas set to : to complete",
      },
    },
    republish: {
      form_version: 'Version republished',
    },
  },
  breadcrumbs: {
    admin: 'Administration',
    edit: 'Edit “%{name}”',
    users: 'Users',
    clients: 'Clients',
    habilitations: 'Habilitations',
    contacts: 'Contacts',
    autorisations: 'Autorisations',
    projects: 'Projects',
    work_orders: 'Work orders',
    interventions: 'Interventions',
    round: 'Rounds',
    documents: 'Documents',
    items: 'Items',
    equipment: 'Equipments',
    forms: 'Forms',
    reports: 'Report templates',
    information: 'Information',
  },
  titles: {
    clients: {
      list: 'Clients list',
      show: 'Client : %{name}',
      edit: 'Edit client : %{name}',
      create: 'Create client',
    },
    users: {
      list: 'Users list',
      show: 'User : %{name}',
      edit: 'Edit user : %{name}',
      create: 'Create user',
    },
    habilitations: {
      list: 'Habilitations list',
      show: 'Habilitation : %{name}',
      edit: 'Edit habilitation',
      create: 'Create habilitation',
    },
    autorisations: {
      list: 'Authorizations list',
      show: 'Authorization : %{name}',
      edit: 'Edit authorization',
      create: 'Create Authorization',
    },
    interventions: {
      list: 'Interventions list',
      show: 'Intervention : %{name}',
      edit: 'Edit intervention : %{name}',
      create: 'Create intervention',
    },
    contacts: {
      list: 'Contacts list',
      show: 'Contact : %{name}',
      edit: 'Edit contact',
      create: 'Create contact',
    },
    documents: {
      list: 'Documents list',
      show: 'Document : %{name}',
      edit: 'Edit document : %{name}',
      create: 'Create document',
    },
    projects: {
      list: 'Projects list',
      show: 'Project : %{name}',
      edit: 'Edit project : %{name}',
      create: 'Create project',
    },
    work_orders: {
      list: 'Work orders list',
      show: 'Work order : %{name}',
      edit: 'Edit work order : %{name}',
      create: 'Create work order',
    },
    items: {
      list: 'Items list',
      show: 'Item : %{name}',
      edit: 'Edit item : %{name}',
      create: 'Create item',
    },
    equipment: {
      list: 'Equipments list',
      show: 'Equipment : %{name}',
      edit: 'Edit equipment : %{name}',
      create: 'Create equipment',
    },
    forms: {
      list: 'Forms list',
      show: 'Form : %{name}',
      edit: 'Edit form : %{name}',
      create: 'Create form',
    },
    reports: {
      list: 'Report templates list',
      show: 'Report template : %{name}',
      edit: 'Edit report template : %{name}',
      create: 'Create report template',
    },
    report_versions: {
      create: 'Create report template version',
    },
    round: {
      list: 'Rounds list',
      edit: 'Edit round : %{name}',
      create: 'Create round',
      groups: {
        list: 'User group list',
      },
      equipments_to_check: 'Equipements to check',
    },
    groups: {
      list: 'User group list',
      create: 'User group creation',
      edit: 'User group edit',
    },
  },
  filters: {
    disabled_clients: 'Show disabled clients',
    disabled_users: 'Show disabled users',
    show_validated: 'Show validated interventions',
    show_only_interventions_assigned_to_me:
      'Show all interventions needing validation',
    document: {
      type: {
        document: 'Technicals',
        generique: 'Generics',
      },
    },
    archived: {
      items: 'Show archived items',
      equipment: 'Show archived equipments',
      documents: 'Show archived documents',
      forms: 'Show archived forms',
    },
  },
  table: {
    empty: 'No data to display',
  },
  fields: {
    empty: 'Nothing',
  },
  tabs: {
    information: 'Information',
    assignation: 'Assignation',
  },
  inputs: {
    duallistinput: {
      select: 'Select',
      unselect: 'Unselect',
      availableItems: 'Available items',
      selectedItems: 'Selected items',
    },
    picker: {
      selected: 'Selected',
      list: 'List',
    },
  },
  resourceNames: {
    clients: 'Client',
    users: 'User',
    habilitations: 'Habilitation',
    contacts: 'Contact',
    autorisations: 'Autorisation',
    entitlements: 'Habilitations & Autorisations',
    projects: 'Project',
    work_orders: 'Work Order',
    interventions: 'Intervention',
    rondes: 'Round',
    reports: 'Report',
    forms: 'Form',
    intervention_datas: 'Intervention data',
    documents: 'Document',
    items: 'Item',
  },
  resource: {
    clients: {
      name: 'Name',
      reference: 'Reference',
      users: 'Users',
      contacts: 'Contacts',
      autorisation: 'Authorization',
      emailAddress: 'Email',
      phoneNumbers: 'Phone numbers',
      phoneNumber: 'Phone number',
      address: 'Address',
      street: 'Street',
      zipCode: 'Zip code',
      city: 'City',
      country: 'Country',
      isValidAt: 'Debut date of validity',
      disabledAt: 'Validity expiration date',
      valid_until: 'Valid until',
      valid_since: 'Valid since',
      disabled_since: 'Disabled since',
      valid_at: 'Valid at',
      valid: 'Valid',
      disabled: 'Disabled',
      sections: {
        validity: 'Validity',
        contact_detail: 'Contact details',
      },
    },
    users: {
      firstname: 'Firstname',
      lastname: 'Lastname',
      fullname: 'Fullname',
      personalNumber: 'Personal number',
      username: 'Username',
      emailAddress: 'Email',
      companyName: 'Company name',
      serviceName: 'Service name',
      workplace: 'Workplace',
      clients: 'Clients',
      roles: 'Roles',
      lastLogin: 'Last login',
      userEntitlements: 'Entitlements',
      user_habilitations: 'Habilitations',
      user_autorisations: 'Authorizations',
      isEnabled: 'Enabled',
      disableUser: 'Disable User',
      enableUser: 'Enable User',
      'sections.login': 'Login',
      'sections.identity': 'Identity',
      'sections.position': 'Position',
      'sections.access': 'Access',
    },
    contacts: {
      name: 'Full Name',
      serviceName: 'Service name',
      email: 'Email address',
      phoneNumbers: 'Phone numbers',
      phoneNumber: 'Phone number',
      street: 'Street',
      zipCode: 'Zip code',
      city: 'City',
      country: 'Country',
      notes: 'Notes',
      address: 'Address',
    },
    entitlements: {
      name: 'Name',
      type: 'Type',
      client: 'Client',
      description: 'Description',
      operators: 'Operators',
      habilitations: 'Habilitations',
      autorisations: 'Authorizations',
    },
    user_entitlements: {
      start: 'Start date',
      endDate: 'End date',
      document: 'Document',
      operator: 'Operator',
    },
    projects: {
      name: 'Name',
      description: 'Description',
      interventions: 'Interventions',
    },
    work_orders: {
      reference: 'Reference',
      notes: 'Notes',
      start: 'Planned start',
      project: 'Project',
      status: 'Status',
      interventions: 'Interventions',
      assign_operator: 'Assign an operator to available interventions',
      already_assigned:
        '%{smart_count} intervention was already assigned to this operator |||| %{smart_count} interventions Was already assigned to this operator',
      newly_assigned:
        '%{smart_count} intervention has been assigned to this operator |||| %{smart_count} interventions has been assigned to this operator',
      not_assigned:
        '%{smart_count} intervention has not been assigned to this operator |||| %{smart_count} interventions has not been assigned to this operator',
      fullStatus: {
        created: 'Work order created',
        started: 'Work order started',
        ended: 'Work order ended',
        validated: 'Work order validated',
        assigned: 'Work order assigned',
        validating: 'Work order validating',
        sent: 'Work order sent',
        cancelled: 'Work order cancelled',
      },
    },
    rounds: {
      endTime: 'End date',
      fullStatus: {
        created: 'Round created',
        ready: 'Round ready',
        started: 'Round started',
        ended: 'Round ended',
        validated: 'Round validated',
      },
    },
    interventions: {
      name: 'Name',
      location: 'Location',
      start: 'Start date',
      willStart: 'Planned for the %{date}',
      operator: 'Operator',
      operatorsGroup: 'Operator group',
      validator: 'Validator',
      validatorsGroup: 'Validator group',
      client: 'Client',
      project: 'Project',
      workOrder: 'Work order',
      status: 'Status',
      recurring: 'recurring',
      form: 'Form',
      interventionData: "Intervention's data",
      interventionDocuments: 'Documentation',
      interventionItems: 'Items',
      interventionEquipments: 'Equipments',
      no_operator: 'No operator available',
      defaultReport: 'Default report template',
      entitlements: 'Habilitations & Autorisations',
      habilitations: 'Habilitations',
      autorisations: 'Autorisations',
      fullStatus: {
        created: 'Created intervention',
        ready: 'Ready intervention',
        started: 'Started intervention',
        ended: 'Ended intervention',
        validated: 'Validated intervention',
      },
      recipientUsers: "Report's user recipient",
      recipientContacts: "Report's contact recipient",
      recipientsGroup: "Report's user group recipient",
      'sections.report': 'Report',
      'sections.operator': 'operator',
      'sections.prerequisites': 'Prerequisites',
      'sections.taxonomy': 'Taxonomy',
      filters: {
        active: 'Active',
        not_ready: 'Not ready',
        needing_validation: 'Needing validation',
        ended: 'Ended',
      },
      workOrderInherited: 'Inherited from work order',
      roundResult: 'Round result',
      roundData: 'Non-complying items',
      verifiedElements: 'Verified elements: %{smart_count} on %{total}',
      sendToOperator: 'Send a copy to the operator',
      isRound: 'Is round',
      'listChecker.importedElementsCount':
        '%{count} element(s) will be imported',
    },
    intervention_datas: {
      start: 'Start date',
      endTime: 'End date',
      datas: 'Datas',
      operator: 'operator',
      idItemChecker: 'Item id',
      intervention_datas: 'Intervention Datas',
      show: 'Show',
      loading: 'Loading',
      noValues: 'No values',
      variables: 'Variables',
      label: 'Label',
      data: 'Data',
      report_generation: 'Report generation',
      needing_validation: 'Needing validation',
      integrity: 'Data integrity',
    },
    equipment: {
      name: 'Name',
      description: 'Description',
      designation: 'Designation',
      location: 'Location',
      reference: 'Reference',
      comment: 'Comment',
    },
    intervention_equipments: {
      equipment: 'Equipment',
      quantity: 'Quantity',
    },
    items: {
      name: 'Name',
      designation: 'Designation',
      reference: 'Reference',
      description: 'Description',
    },
    intervention_items: {
      item: 'Item',
      quantity: 'Quantity',
    },
    documents: {
      name: 'Name',
      size: 'File size',
      mimeType: 'Type',
      fileName: 'File name',
      comment: 'Comment',
      file: 'File',
      type: 'Type',
      'type.document': 'Technical',
      'type.generique': 'Generic',
      showOldDocument: 'Show old document',
      isUrl: 'It is a link',
      url: 'Link',
    },
    forms: {
      name: 'Name',
      formVersions: 'Form versions',
      draft_version: 'Draft',
      reports: 'Associated report templates',
      variables: 'Show variables',
      updatedAt: 'Last change',
      lastVersion: 'Last version',
      status: 'Status',
      'status.published': 'Published',
      'status.draft': 'Draft',
      formDocuments: 'Documents',
    },
    form_versions: {
      version: 'Version number',
      updatedAt: 'Last change',
    },
    reports: {
      resource: 'Report',
      name: 'Name',
      reportVersions: 'Report template versions',
      file: 'File',
      newReportFile: 'New report template file',
      'newReportFile.helperText': 'Accepted file formats are: %{extensions}',
      createReportVersion: 'Upload new version',
      updatedAt: 'Last change',
    },
    groups: {
      name: 'Name',
      updatedAt: 'Last change',
      users: 'Users',
    },
  },
}
