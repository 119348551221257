import type { ShowActionsProps, ShowProps } from 'react-admin'
import { SfmShow } from 'src/AppScopes/resource'
import { ArchiveButton } from 'src/components/ArchiveButton'
import { ShowActions } from 'src/components/ProtectedActions'
import { SfmTextField } from 'src/components/SfmFields'
import { LocationIcon } from 'src/UI/theme/icons'
import { RoleEnum, usePersona } from 'src/UserIdentity'

import { ShowLayout } from '../../components/ShowLayout'
import type { Equipment } from '../../types/api'

export function Show(props: ShowProps) {
  return (
    <SfmShow {...props} actions={<EquipmentActions />}>
      <ShowLayout<Equipment>
        title={(equipment) => equipment.name}
        subtitle={(equipment) => equipment.reference}
        meta={(equipment) => equipment.designation}
      >
        <SfmTextField
          source="location"
          Icon={LocationIcon}
          label={'resource.equipment.location'}
        />
        <SfmTextField
          source="description"
          label={'resource.equipment.description'}
        />
      </ShowLayout>
    </SfmShow>
  )
}

function EquipmentActions(props: ShowActionsProps) {
  const { data: equipment } = props
  const { role } = usePersona()

  const canArchive =
    role && [RoleEnum.ROLE_ADMIN, RoleEnum.ROLE_CONFIGURATOR].includes(role)

  const canBeDeleted = equipment
    ? !equipmentHasRelation(equipment as Equipment)
    : false

  return (
    <ShowActions {...props} hasDelete={canBeDeleted}>
      {canArchive && equipment && (
        <ArchiveButton resource="equipment" record={equipment} />
      )}
    </ShowActions>
  )
}

function equipmentHasRelation(equipment: Equipment): boolean {
  if (equipment.interventionEquipments.length > 0) return true
  return false
}
