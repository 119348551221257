import { Typography, Box } from '@material-ui/core'
import type { PropsWithChildren } from 'react'
import { useTranslate } from 'react-admin'

import { Separator } from './Separator'

interface SectionProps {
  label?: string
}

export function Section(props: PropsWithChildren<SectionProps>) {
  const { label, children } = props
  const translate = useTranslate()
  return (
    <>
      <Box paddingTop={3} marginBottom={0}>
        <Separator />
        {label && (
          <Typography variant="h2" color="textSecondary">
            {translate(label)}
          </Typography>
        )}
      </Box>
      {children}
    </>
  )
}
