import React, { useEffect, useRef } from 'react'
import type { ControllerRenderProps, FieldError } from 'react-hook-form'
import { Controller, useFormContext } from 'react-hook-form'

import type { Rule } from '../Interpreter/types'

import { useAccordionHiddenContext } from './Accordion/AccordionHiddenContext'

import { useInterpretProperty } from './useInterpretProperty'

export interface WrapperProps {
  name: string
  label?: string
  hidden?: boolean | Rule
  required?: boolean | Rule
  min?: number | string
  max?: number | string
  render: (
    field: ControllerRenderProps<Record<string, any>>,
    props: { label?: string; error?: FieldError },
  ) => React.ReactElement
}

export function Wrapper(props: WrapperProps) {
  const { name, label, hidden, required, min, max, render } = props

  const formContext = useFormContext()
  // Interpret the hidden property
  const isHidden = useInterpretProperty(hidden, formContext)
  const accordionIsHidden = useAccordionHiddenContext()

  useRestoreValueOnShow(name, isHidden || accordionIsHidden)

  // Interpret the required property
  const isRequired = useInterpretProperty(required, formContext)

  const { clearErrors } = useFormContext()
  useEffect(() => {
    if (!isRequired) {
      clearErrors(name)
    }
  }, [clearErrors, isRequired, name])

  if (isHidden || accordionIsHidden) return null

  return (
    <Controller
      name={name}
      rules={{ required: isRequired, min, max }}
      render={(renderProps) => {
        const {
          field,
          fieldState: { error },
        } = renderProps

        return render(field, { label, error })
      }}
    />
  )
}

function useRestoreValueOnShow(name: string, isHidden: boolean) {
  const { getValues, setValue } = useFormContext()

  const savedValueRef = useRef()
  useEffect(() => {
    const value = getValues(name)
    const savedValue = savedValueRef.current

    const isEmpty = value === undefined || value === null
    const hasSavedValue = savedValue !== undefined && savedValue !== null

    if (isHidden && !isEmpty) {
      setValue(name, null)
      savedValueRef.current = value
    }

    if (!isHidden && isEmpty && hasSavedValue) {
      setValue(name, savedValue)
      savedValueRef.current = undefined
    }
  }, [isHidden, name, getValues, setValue])
}
