import type { PropsWithChildren } from 'react'
import type { ShowActionsProps } from 'react-admin'
import {
  DeleteButton,
  EditButton,
  TopToolbar,
  DeleteWithConfirmButton,
} from 'react-admin'
import { RoleEnum, usePersona } from 'src/UserIdentity'

import type { ActionsProps } from './types'

type Props = PropsWithChildren<ShowActionsProps & ActionsProps> & {
  hasDelete?: boolean
}
export function ShowActions(props: Props) {
  const {
    acceptedRoles = [RoleEnum.ROLE_ADMIN, RoleEnum.ROLE_CONFIGURATOR],
    basePath,
    data,
    children,
    hasDelete = true,
  } = props
  const { role } = usePersona()

  function getProtectedActions() {
    if (!role || !acceptedRoles.includes(role)) return null
    return (
      <>
        {hasDelete && basePath === '/interventions' ? (
          <DeleteWithConfirmButton
            undoable={false}
            basePath={basePath}
            record={data}
          />
        ) : (
          <DeleteButton basePath={basePath} record={data} />
        )}
        <EditButton basePath={basePath} record={data} />
      </>
    )
  }

  return (
    <TopToolbar>
      {children}
      {getProtectedActions()}
    </TopToolbar>
  )
}
