import type { Spec } from '@nartex/sfm-wysiwyg'
import { PhotoSizeEnum, VideoQualityEnum } from '@nartex/sfm-wysiwyg'
import { Suspense, lazy, useMemo, useState, useEffect } from 'react'
import {
  useLocale,
  Loading,
  useGetManyReference,
  useMutation,
  useGetOne,
} from 'react-admin'
import { useParams } from 'react-router'

import { useEnv } from 'src/adapters/Env'
import { useStorage } from 'src/adapters/Storage'
import type { Form, FormVersion, IriReference } from 'src/types/api'
import { NakedLayout, theme } from 'src/UI'

import { useCreateResourceUrl } from '../useCreateResourceUrl'

const LazyWysiwyg = lazy(async function () {
  return {
    default: (await import('@nartex/sfm-wysiwyg')).Wysiwyg,
  }
})

export function Wysiwyg() {
  return (
    <NakedLayout>
      <InContext />
    </NakedLayout>
  )
}

function InContext() {
  const env = useEnv()
  const storage = useStorage()
  const locale = useLocale()
  const createUrl = useCreateResourceUrl()
  const formId = decodeURIComponent(useParams<{ id: IriReference }>().id)

  const getOneResult = useGetOne<Form>('forms', formId)
  const { data: form } = getOneResult
  const {
    data: formVersions,
    loaded,
    error,
  } = useGetManyReference(
    'form_versions',
    'form.id',
    formId,
    { page: 1, perPage: 1 },
    { field: 'createdAt', order: 'desc' },
    {},
    'forms',
  )

  const lastApiVersion = Object.values(formVersions)[0] as
    | FormVersion
    | undefined
  const [formVersion, setFormVersion] = useState<FormVersion | undefined>(
    undefined,
  )
  useEffect(() => {
    setFormVersion(lastApiVersion)
  }, [lastApiVersion])

  const [mutate] = useMutation()
  const save = async (payload: Spec) => {
    const augmentedPayload: Spec = {
      ...payload,
      config: {
        photoSize: {
          [PhotoSizeEnum.SMALL]: env.PHOTO_SIZE_SMALL,
          [PhotoSizeEnum.MEDIUM]: env.PHOTO_SIZE_MEDIUM,
          [PhotoSizeEnum.LARGE]: env.PHOTO_SIZE_LARGE,
        },

        videoQuality: {
          [VideoQualityEnum.LOW]: 0,
          [VideoQualityEnum.HIGH]: 1,
        },
      },
    }

    const response = await mutate(
      {
        type: formVersion?.isDraft ? 'update' : 'create',
        resource: 'form_versions',
        payload: {
          id: formVersion?.id,
          data: { payload: augmentedPayload, form: formId },
          previousData: formVersion,
        },
      },
      { returnPromise: true },
    )

    setFormVersion(response.data)
  }

  const initialValues: Spec = useMemo(() => {
    return (
      (formVersion?.payload as unknown as Spec) || {
        fields: [],
        fieldNames: [],
      }
    )
  }, [formVersion?.payload])

  if (error) throw new Error(error)
  if (!loaded) return <Loading />

  return (
    <Suspense fallback={<Loading />}>
      <LazyWysiwyg
        backLinkTo={createUrl('forms', formId, 'show')}
        theme={theme}
        adapters={{ env: env, storage: storage }}
        locale={locale}
        onSave={save}
        initialValues={initialValues as Spec}
        title={`${form?.name || ''} — v${formVersion?.version || 0}`}
      />
    </Suspense>
  )
}
