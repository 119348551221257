import { basename, extname } from 'path'

import type { FormState } from 'final-form'

import { useEffect, useRef } from 'react'
import {
  BooleanInput,
  FileField,
  FileInput,
  regex,
  required,
  SelectInput,
} from 'react-admin'
import { useForm, useFormState } from 'react-final-form'
import { LabeledField } from 'src/components/LabeledField'

import { Separator } from 'src/components/Separator'
import { SfmTextInput, SfmTextArea } from 'src/components/SfmInputs'
import { ShowDocumentButton } from 'src/components/ShowDocumentButton'
import type { Document } from 'src/types/api'

interface RaFile {
  rawFile: File
  'rawFile.name': string
  src: string
}

export function DocumentForm(props: { edit?: boolean }) {
  const { edit } = props

  const form = useForm()
  useWatchField<{ file?: RaFile; name: string }>(
    'file',
    (formState) => {
      if (edit) return
      const { file, name } = formState.values
      if (name) return
      form.change('name', file ? trimExtName(file.rawFile.name) : undefined)
    },
    [],
  )

  const formState = useFormState<Document>()

  const { isUrl } = formState.values

  return (
    <>
      <SelectInput
        source="type"
        label="resource.documents.type"
        choices={[
          { id: 'document', name: 'resource.documents.type.document' },
          { id: 'generique', name: 'resource.documents.type.generique' },
        ]}
        validate={required('validation.required')}
        defaultValue={'document'}
      />

      <BooleanInput source="isUrl" label="resource.documents.isUrl" />
      {isUrl ? (
        <SfmTextInput
          source="fileName"
          label="resource.documents.url"
          validate={[
            required('validation.required'),
            regex(
              /^https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/,
              'validation.url',
            ),
          ]}
        />
      ) : (
        <LabeledField label="resource.documents.file" fullBleed>
          {edit && (
            <ShowDocumentButton title="resource.documents.showOldDocument" />
          )}
          <FileInput
            source="file"
            multiple={false}
            label=""
            validate={edit ? undefined : required('validation.required')}
          >
            <FileField source="src" title="rawFile.name" />
          </FileInput>
        </LabeledField>
      )}

      <SfmTextInput
        source="name"
        label="resource.documents.name"
        validate={required('validation.required')}
      />

      <Separator />

      <SfmTextArea source="comment" label="resource.documents.comment" />
    </>
  )
}

function trimExtName(fileName: string): string {
  return basename(fileName, extname(fileName))
}

function useWatchField<T extends Record<string, any>>(
  fieldName: keyof T,
  onChange: (formState: FormState<T, Partial<T>>) => void,
  deps: any[],
) {
  const form = useForm()
  const fieldValue = useRef<RaFile>()
  useEffect(() => {
    return form.subscribe(
      (formState_) => {
        const formState = formState_ as FormState<T, Partial<T>>
        const value = formState.values[fieldName]
        const hasChanged = fieldValue.current !== value

        if (hasChanged) {
          onChange(formState)
        }

        fieldValue.current = value
      },
      { values: true },
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form, ...deps])
}
