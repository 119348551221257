import type { PropsWithChildren } from 'react'
import { useEffect, useState } from 'react'
import type { Record as RaRecord } from 'react-admin'
import {
  useRecordContext,
  ListContextProvider,
  useCreate,
  useNotify,
} from 'react-admin'
import { LabeledField } from 'src/components/LabeledField'
import { MultipleResourcePicker } from 'src/components/Picker'
import { formatApiPlatformId, toApiPlatformId } from 'src/libs/apiPlatformId'
import { useGetList } from 'src/libs/useGetList'
import type { Intervention } from 'src/types/api'
import { ResourceEnum } from 'src/types/api/resources'
import { AddIcon } from 'src/UI/theme/icons'

interface Props<T> {
  readonly?: boolean
  listResource:
    | ResourceEnum.documents_of_intervention
    | ResourceEnum.equipment_of_intervention
  pickerResource: ResourceEnum
  label: string
  pickerTitle: string
  getPickerOptionTitle: (record: T) => string
  getPickerOptionSubtitle?: (record: T) => string
  pickerFilters?: Partial<Record<keyof T, any>> & Record<string, any>
}

const requestPropertyDict = {
  [ResourceEnum.documents_of_intervention]: 'documents',
  [ResourceEnum.equipment_of_intervention]: 'equipments',
}

export function AssociatedDataField<T extends RaRecord>(
  props: PropsWithChildren<Props<T>>,
) {
  const {
    readonly,
    listResource,
    pickerResource,
    pickerTitle,
    getPickerOptionTitle,
    getPickerOptionSubtitle,
    pickerFilters,
    label,
    children,
  } = props

  const intervention = useRecordContext<Intervention>()
  const [records, setRecords] = useState<RaRecord[]>()

  const { data, loading, loaded } = useGetList<RaRecord>(
    listResource,
    {
      filter: {
        interventionId: formatApiPlatformId(intervention.id),
      },
    },
    {
      enabled: Boolean(intervention.id),
    },
  )

  useEffect(() => {
    if (loaded && data) {
      setRecords(data)
    }
  }, [data, loaded])

  const [create, { loading: saving }] = useCreate()
  const notify = useNotify()

  const save = (values: RaRecord[]) => {
    const oldIds = records?.map((record) => record.id) ?? []
    const valuesIds = values.map((value) => formatApiPlatformId(value.id))
    const valuesKey = requestPropertyDict[listResource]

    if (oldIds.join() !== valuesIds.join()) {
      create(
        listResource,
        {
          intervention: intervention.id,
          [valuesKey]: valuesIds,
        },
        {
          onSuccess: function (res) {
            setRecords(res.data[valuesKey])
            notify('notifications.update.intervention', 'success')
          },
          onFailure: function (error) {
            notify(error.message, 'error')
          },
        },
      )
    }
  }

  return (
    <LabeledField
      label={label}
      fullBleed
      actions={
        !readonly && (
          <MultipleResourcePicker<T>
            resource={pickerResource}
            selectedIds={
              records?.map((record) =>
                toApiPlatformId(pickerResource, record.id),
              ) ?? []
            }
            label="actions.add"
            title={pickerTitle}
            getOptionTitle={getPickerOptionTitle}
            getOptionSubtitle={getPickerOptionSubtitle}
            onSave={save}
            saving={loading || saving}
            Icon={<AddIcon />}
            filters={pickerFilters}
          />
        )
      }
    >
      <ListContextProvider
        value={{
          data: records ?? [],
          loading,
          basePath: '/' + pickerResource,
        }}
      >
        {children}
      </ListContextProvider>
    </LabeledField>
  )
}
