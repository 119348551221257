import { makeStyles, Button } from '@material-ui/core'
import HeroiconsOutlineEye from '@nartex/smartforest-design-tokens/graphics/react/HeroiconsOutlineEye'
import HeroiconsOutlineEyeOff from '@nartex/smartforest-design-tokens/graphics/react/HeroiconsOutlineEyeOff'
import IonSwapHorizontalSharp from '@nartex/smartforest-design-tokens/graphics/react/IonSwapHorizontalSharp'
import OptionnalIcon from '@nartex/smartforest-design-tokens/graphics/react/Optionnal'
import RequiredIcon from '@nartex/smartforest-design-tokens/graphics/react/Required'
import { useState } from 'react'

import { useTranslate } from '../adapters/I18nProvider'
import { smallCode } from '../theme/mixins'

const useStyles = makeStyles(function (theme) {
  return {
    root: {
      ...smallCode(),
      color: theme.palette.background.paper,
      backgroundColor: theme.palette.primary.main,
      border: 'none',
      minHeight: 'fit-content',
      textTransform: 'none',
      padding: theme.spacing(0.5),
      '&:hover': {
        color: theme.palette.background.paper,
        backgroundColor: theme.palette.primary.main,
      },
      verticalAlign: 'baseline',
    },
    eyeIcon: {
      marginRight: theme.spacing(0.5),
      opacity: 0.5,
    },
  }
})

export interface PropertyValueToggleProps {
  value: boolean
  onChange: (value: boolean) => void
}

interface OptionsProps {
  trueOption: React.ReactNode
  falseOption: React.ReactNode
  trueIcon: React.ReactNode
  falseIcon: React.ReactNode
}

function PropertyValueToggle(props: PropertyValueToggleProps & OptionsProps) {
  const { value, onChange, trueOption, falseOption } = props
  const [isHovered, setIsHovered] = useState(false)
  const styles = useStyles()
  function iconToggler(visibleValue: boolean) {
    if (visibleValue) {
      return <HeroiconsOutlineEyeOff className={styles.eyeIcon} />
    }
    return <HeroiconsOutlineEye className={styles.eyeIcon} />
  }

  return (
    <Button
      size="small"
      onClick={() => {
        onChange(!value)
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      className={styles.root}
    >
      <>
        {isHovered ? (
          <IonSwapHorizontalSharp className={styles.eyeIcon} />
        ) : (
          iconToggler(value)
        )}
        {value ? trueOption : falseOption}
      </>
    </Button>
  )
}

export function HiddenPropertyToggle(props: PropertyValueToggleProps) {
  const __ = useTranslate()
  return (
    <PropertyValueToggle
      {...props}
      trueOption={__({
        id: 'RuleEditor.hiddenValue.true',
        defaultMessage: 'est invisible',
        description: 'invisible value',
      })}
      falseOption={__({
        id: 'RuleEditor.hiddenValue.false',
        defaultMessage: 'est visible',
        description: 'visible value',
      })}
      trueIcon={<HeroiconsOutlineEyeOff />}
      falseIcon={<HeroiconsOutlineEye />}
    />
  )
}

export function RequiredPropertyToggle(props: PropertyValueToggleProps) {
  const __ = useTranslate()
  return (
    <PropertyValueToggle
      {...props}
      trueOption={__({
        id: 'RuleEditor.requiredValue.true',
        defaultMessage: 'est requis',
        description: 'required value',
      })}
      falseOption={__({
        id: 'RuleEditor.requiredValue.false',
        defaultMessage: 'est optionnel',
        description: 'optionnal value',
      })}
      trueIcon={<RequiredIcon />}
      falseIcon={<OptionnalIcon />}
    />
  )
}
