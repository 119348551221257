import {
  ReferenceArrayInput,
  AutocompleteArrayInput,
  ReferenceInput,
  AutocompleteInput,
} from 'react-admin'

interface Props {
  source: string
  label: string
  many?: boolean
}

export function ClientInput(props: Props) {
  const { source, label, many } = props

  if (many) {
    return (
      <ReferenceArrayInput
        source={source}
        reference="clients"
        label={label}
        sort={{ field: 'name', order: 'ASC' }}
        filterToQuery={(searchText: string) => {
          return { name: searchText }
        }}
      >
        <AutocompleteArrayInput />
      </ReferenceArrayInput>
    )
  }

  return (
    <ReferenceInput
      source={source}
      reference="clients"
      label={label}
      sort={{ field: 'name', order: 'ASC' }}
      filterToQuery={(searchText: string) => {
        return { name: searchText }
      }}
    >
      <AutocompleteInput />
    </ReferenceInput>
  )
}
