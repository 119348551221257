import type { FieldNode } from '@nartex/sfm-form-engine'
import { fieldRenderersMap, isExpr } from '@nartex/sfm-form-engine'
import ErrorIcon from '@nartex/smartforest-design-tokens/graphics/react/BxBxInfoCircle'
import classNames from 'classnames'
import { createElement, useCallback } from 'react'
import type { DraggableChildrenFn } from 'react-beautiful-dnd'
import { Draggable } from 'react-beautiful-dnd'

import { useErrors } from '../Validation/Errors'

import { useStyles } from './styles'

interface Props {
  field: FieldNode
  index: number
  onSelect: () => void
  selected?: boolean
}

export function DraggableField(props: Props) {
  const { field, index, onSelect, selected } = props
  const errors = useErrors()

  const hasError = errors[field.data['@id']]

  const styles = useStyles()
  const draggableRenderer = useCallback<DraggableChildrenFn>(
    (provided, snapshot) => {
      const { innerRef, draggableProps, dragHandleProps } = provided
      const { isDragging } = snapshot
      const component = (fieldRenderersMap as any)[field.type]

      const { hidden } = field.data
      const isHidden = isExpr(hidden) ? hidden.default : hidden

      return (
        <li
          className={classNames(
            styles.element,
            (isDragging || selected) && styles.elementSelected,
            isHidden && styles.isHidden,
            hasError && styles.error,
          )}
          ref={innerRef}
          onPointerDown={onSelect}
          {...draggableProps}
          {...dragHandleProps}
        >
          <div style={{ position: 'relative' }}>
            {hasError && <ErrorIcon className={styles.errorIcon} />}
            {component &&
              createElement(component, {
                ...field.data,
                hidden: false, // prevent element from disappearing
                required: false, // prevent interpret error when undefined
              })}
          </div>
        </li>
      )
    },
    [field, hasError, onSelect, selected, styles],
  )

  return (
    <Draggable draggableId={field.data['@id']} index={index}>
      {draggableRenderer}
    </Draggable>
  )
}
