import { useGetIdentity } from '../../IdentityProvider'

import type { RoleEnum, RolesMap } from './types'

export function useRoles(): RolesMap {
  const { identity } = useGetIdentity()
  if (!identity) return {}

  const roles = identity.roles ?? []
  const rolesMap: RolesMap = {}
  roles.forEach((key: RoleEnum) => {
    rolesMap[key] = true
  })

  return rolesMap
}
