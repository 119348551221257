import type { PropsWithChildren } from 'react'
import type { ShowProps } from 'react-admin'
import { SfmShow } from 'src/AppScopes/resource'

import { SfmTextField } from 'src/components/SfmFields'
import { ShowLayout } from 'src/components/ShowLayout'
import type { Entitlement } from 'src/types/api'

export function Show(props: PropsWithChildren<ShowProps>) {
  const { children } = props
  return (
    <SfmShow {...props}>
      <ShowLayout<Entitlement> title={(record) => record.name}>
        <SfmTextField
          source="description"
          label="resource.entitlements.description"
        />
        {children}
      </ShowLayout>
    </SfmShow>
  )
}
