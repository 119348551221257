import { ThemeProvider } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Breadcrumb, BreadcrumbItem } from '@react-admin/ra-navigation'
import ellipsize from 'ellipsize'
import { useCallback } from 'react'
import type { Record as RaRecord } from 'react-admin'
import { useTranslate } from 'react-admin'
import { useCreateResourceUrl } from 'src/Resources/useCreateResourceUrl'
import type { User, WorkOrder } from 'src/types/api'
import { usePersona, useGetIdentity } from 'src/UserIdentity'

import { theme, typeScale } from './theme'

const useStyles = makeStyles(function () {
  return {
    root: {
      display: 'flex',
      alignItems: 'center',
    },

    item: {},
  }
})

const overriddenTheme = {
  ...theme,
  typography: {
    ...theme.typography,
    body2: { ...theme.typography.button, ...typeScale(-0.5) },
  },
}

export function BreadCrumbs() {
  const __ = useTranslate()
  const styles = useStyles()

  const { clientId } = usePersona()
  const { identity } = useGetIdentity()
  const currentClient = identity?.clients?.find((cus) => cus.id === clientId)

  return (
    <ThemeProvider theme={overriddenTheme}>
      <Breadcrumb className={styles.root}>
        <BreadcrumbItem name="admin" label={__('breadcrumbs.admin')}>
          <ResourceBreadCrumb<User>
            name="users"
            getRecordName={useCallback(
              (user) =>
                `${user?.firstName || ''} ${user?.lastName || ''}`.trim(),
              [],
            )}
          />
          <ResourceBreadCrumb name="clients" />
          <ResourceBreadCrumb name="habilitations" />
        </BreadcrumbItem>
        <BreadcrumbItem
          name="client"
          label={currentClient?.name || '(no client)'}
        >
          <BreadcrumbItem name="information" label="breadcrumbs.information" />
          <ResourceBreadCrumb name="contacts" />
          <ResourceBreadCrumb name="autorisations" />
          <ResourceBreadCrumb name="projects" />
          <ResourceBreadCrumb<WorkOrder>
            name="work_orders"
            getRecordName={useCallback((workOrder) => workOrder?.reference, [])}
          />
          <ResourceBreadCrumb name="interventions" />
          <ResourceBreadCrumb name="round" />
          <ResourceBreadCrumb name="documents" />
          <ResourceBreadCrumb name="items" />
          <ResourceBreadCrumb name="equipment" />
          <ResourceBreadCrumb name="forms" />
          <ResourceBreadCrumb name="reports" />
        </BreadcrumbItem>
      </Breadcrumb>
    </ThemeProvider>
  )
}

interface ResourceBreadCrumbProps<T> {
  name: string
  path?: string
  getRecordName?: (record: T | undefined) => string | undefined
}

function ResourceBreadCrumb<T>(props: ResourceBreadCrumbProps<T>) {
  const {
    path,
    name,
    getRecordName = (record) => (record as any)?.name,
  } = props
  const resourceUrl = useCreateResourceUrl()
  const __ = useTranslate()

  const nestedPath = [path, name].filter(Boolean).join('.')

  return (
    <BreadcrumbItem
      path={path}
      name={name}
      label={__(`breadcrumbs.${name}`)}
      to={resourceUrl(name)}
    >
      <BreadcrumbItem
        path={nestedPath}
        name="edit"
        to={(params: { record?: RaRecord }) =>
          resourceUrl(name, params.record?.id || '', 'edit')
        }
        label={(values: any) =>
          ellipsize(__(`breadcrumbs.edit`, { name: getRecordName(values) }), 30)
        }
      />

      <BreadcrumbItem
        path={nestedPath}
        name="show"
        to={(params: { record?: RaRecord }) =>
          resourceUrl(name, params.record?.id || '', 'show')
        }
        label={(values: any) => ellipsize(getRecordName(values), 30)}
      />

      <BreadcrumbItem
        path={nestedPath}
        name="create"
        to={resourceUrl(name, 'create')}
        label={__(`titles.${name}.create`)}
      />
    </BreadcrumbItem>
  )
}
