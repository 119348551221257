import type { FieldNode } from '@nartex/sfm-form-engine'
import { isExpr } from '@nartex/sfm-form-engine'
import { equals } from 'ramda'
import type { PropsWithChildren } from 'react'
import React, { useContext } from 'react'
import { useDeepCompareMemo } from 'use-deep-compare'

import { definitions } from '../ComponentsLibrary/definitions'
import { useFields } from '../FieldsContext'

import { ruleHasError } from './ruleValidation'

export enum ErrorCodeEnum {
  'InvalidName' = 'InvalidName',
  'DuplicatedName' = 'DuplicatedName',
  'HiddenRule' = 'HiddenRule',
  'RequiredRule' = 'RequiredRule',
}

type ErrorState = ErrorCodeEnum | false

type FieldsErrorState = Record<string, ErrorState>

export const ErrorsContext = React.createContext<FieldsErrorState | undefined>(
  undefined,
)

interface Props {
  fieldNames: string[]
}

export function ErrorsContextProvider(props: PropsWithChildren<Props>) {
  const { fieldNames, children } = props
  const fields = useFields()

  const errors = useDeepCompareMemo(() => {
    const result: FieldsErrorState = {}

    fields.forEach((field) => {
      const fieldId = field.data['@id']
      result[fieldId] = validateFieldMemoized(field, fieldNames, fields)
    })

    return result
  }, [fields, fieldNames])

  return (
    <ErrorsContext.Provider value={errors}>{children}</ErrorsContext.Provider>
  )
}

export function useErrors() {
  const FieldsErrorState = useContext(ErrorsContext)

  if (!FieldsErrorState) throw new Error('Please provide an ErrorsContext')

  return FieldsErrorState
}

const validateFieldMemoized = memoizeValidator(validateField)

function memoizeValidator(validator: typeof validateField) {
  let knownErrors = new WeakMap<FieldNode, ErrorState>()
  let knownFieldsNames: string[] = []

  return function memoized(
    field: FieldNode,
    fieldNames: string[],
    fields: FieldNode[],
  ) {
    // memoize by fieldNames
    const fieldsNamesHasChanged = !equals(knownFieldsNames, fieldNames)

    if (fieldsNamesHasChanged) {
      knownErrors = new WeakMap()
      knownFieldsNames = fieldNames
    }

    // memoize by field
    if (knownErrors.has(field)) {
      return knownErrors.get(field)!
    }

    const fieldResult = validator(field, fieldNames, fields)
    knownErrors.set(field, fieldResult)

    return fieldResult
  }
}

function validateField(
  field: FieldNode,
  fieldNames: string[],
  fields: FieldNode[],
): ErrorState {
  const fieldName = field.data.name
  const isDuplicated =
    fieldNames.filter((name) => name === fieldName).length > 1
  const fieldNamesSet = new Set(fieldNames)

  if (isDuplicated) {
    return ErrorCodeEnum.DuplicatedName
  }

  if (definitions[field.type].props.name) {
    if (!fieldName.match(/^[a-z]/i)) {
      return ErrorCodeEnum.InvalidName
    }
  }

  if (isExpr(field.data.hidden)) {
    if (ruleHasError(field.data.hidden, fieldNamesSet, fields)) {
      return ErrorCodeEnum.HiddenRule
    }
  }

  if (isExpr(field.data.required)) {
    if (ruleHasError(field.data.required, fieldNamesSet, fields)) {
      return ErrorCodeEnum.RequiredRule
    }
  }

  return false
}
