import { useState } from 'react'
import type { Identifier } from 'react-admin'
import type { SfmShowProps } from 'src/AppScopes'

import { Show as EntitlementShow } from '../Show'
import { UserList } from '../UserList'

export function Show(props: SfmShowProps) {
  const [deletedUsers, setDeletedUsers] = useState<Set<Identifier>>(new Set())
  return (
    <EntitlementShow {...props}>
      <UserList
        label="resource.entitlements.operators"
        resource="autorisations"
        deletedUsers={deletedUsers}
        setDeletedUsers={setDeletedUsers}
      />
    </EntitlementShow>
  )
}
