import {
  Checkbox,
  Radio,
  CircularProgress,
  IconButton,
  InputAdornment,
  InputBase,
  makeStyles,
  Popover,
  Typography,
} from '@material-ui/core'
import classNames from 'classnames'
import { debounce } from 'lodash'
import type { ReactElement } from 'react'
import React, { useMemo, useState } from 'react'
import type { Record } from 'react-admin'
import { useTranslate, Button } from 'react-admin'
import { CancelIcon } from 'src/UI/theme/icons'
import { border, shadow } from 'src/UI/theme/mixins'

import { Separator } from '../Separator'

import { OptionsList, SearchOptionList } from './OptionsList'

const useStyles = makeStyles(function (theme) {
  return {
    popover: {
      boxShadow: `${shadow(5)}`,
      width: theme.spacing(40),
      padding: theme.spacing(1),
      display: 'flex',
      flexDirection: 'column',
    },
    title: {
      width: '100%',
      padding: `0 ${theme.spacing(2)}px`,
      color: theme.palette.text.primary,
    },
    button: {
      maxWidth: '48ch',
    },
    searchRoot: {
      border: border(theme),
      margin: `0 ${theme.spacing(1)}px`,
      backgroundColor: theme.palette.background.default,
      '&:hover': {
        borderColor: theme.palette.grey[300],
      },
    },
    searchInput: {
      padding: theme.spacing(1),
    },
    optionsBody: {
      maxHeight: theme.spacing(40),
      overflowY: 'scroll',
    },
    loadingIcon: {
      margin: theme.spacing(1),
      display: 'flex',
      justifyContent: 'center',
    },
  }
})

export interface BasePickerProps<T> {
  loading?: boolean
  saving?: boolean
  optionsLoading?: boolean
  options: T[]
  onSearchChange: (query: string) => void
  onOpen?: () => void
  onClose: () => void
  label: string
  title: string
  searchPlaceholder?: string
  getOptionTitle: (record: T) => string
  getOptionSubtitle?: (record: T) => string
  Icon?: ReactElement
  single?: boolean
  className?: string
}

type PickerProps<T> = BasePickerProps<T> & {
  values: T[]
  handleSelect: (record: T) => void
  handleUnSelect: (record: T) => void
}

export type SinglePickerProps<T> = BasePickerProps<T> & {
  value?: T
  onChange: (value?: T) => void
}

export type MultiplePickerProps<T> = BasePickerProps<T> & {
  values: T[]
  onChange: (newValue: T[]) => void
}

export function Picker<T extends Record = Record>(props: PickerProps<T>) {
  const {
    loading = false,
    saving = false,
    optionsLoading = false,
    label,
    title,
    options,
    onSearchChange,
    handleSelect,
    handleUnSelect,
    onClose,
    onOpen,
    values,
    searchPlaceholder,
    Icon,
    single,
    className,
    ...optionListProps
  } = props
  const classes = useStyles()
  const __ = useTranslate()
  const SelectComponent = useMemo(() => {
    if (single) return Radio
    return Checkbox
  }, [single])

  const valuesIds = useMemo(
    () => new Set(values.map((record) => record.id)),
    [values],
  )

  const nonSelectedOptions = useMemo(() => {
    if (valuesIds.size === 0) return options

    return options.filter((record) => !valuesIds.has(record.id))
  }, [options, valuesIds])

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    setAnchorEl(event.currentTarget)
    onOpen?.()
  }
  const handleClose = () => {
    setAnchorEl(null)
    onClose()
  }

  const [search, setSearch] = useState('')

  const debouncedSearchChange = useMemo(
    () => debounce((query: string) => onSearchChange(query), 500),
    [onSearchChange],
  )

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const query = event.target.value
    setSearch(query)
    debouncedSearchChange(query)
  }

  const resetSearch = () => {
    setSearch('')
    onSearchChange('')
  }

  function body() {
    if (loading) {
      return (
        <div className={classes.loadingIcon}>
          <CircularProgress />
        </div>
      )
    }

    if (search) {
      return (
        <>
          <Typography variant="caption">{__('inputs.picker.list')}</Typography>
          {optionsLoading ? (
            <div className={classes.loadingIcon}>
              <CircularProgress />
            </div>
          ) : (
            <SearchOptionList
              {...optionListProps}
              valuesIds={valuesIds}
              options={options}
              handleSelect={handleSelect}
              handleUnSelect={handleUnSelect}
              SelectComponent={SelectComponent}
            />
          )}
        </>
      )
    }

    return (
      <>
        {values.length > 0 && (
          <>
            <Typography variant="caption">
              {__('inputs.picker.selected')}
            </Typography>
            <OptionsList
              {...optionListProps}
              options={values}
              selected
              handleChange={handleUnSelect}
              SelectComponent={SelectComponent}
            />
            <Separator />
          </>
        )}

        <Typography variant="caption">{__('inputs.picker.list')}</Typography>
        {optionsLoading ? (
          <div className={classes.loadingIcon}>
            <CircularProgress />
          </div>
        ) : (
          <OptionsList
            {...optionListProps}
            options={nonSelectedOptions}
            handleChange={handleSelect}
            SelectComponent={SelectComponent}
          />
        )}
      </>
    )
  }

  return (
    <>
      <Button
        className={classNames(classes.button, className)}
        label={label}
        disabled={saving}
        onClick={handleClick}
      >
        {saving ? <CircularProgress size={16} /> : Icon}
      </Button>
      <Popover
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        classes={{ paper: classes.popover }}
      >
        <Typography className={classes.title} align="center" variant="h3">
          {__(title)}
        </Typography>
        <Separator />
        <InputBase
          classes={{ root: classes.searchRoot, input: classes.searchInput }}
          value={search}
          onChange={handleSearchChange}
          placeholder={__(searchPlaceholder ?? 'actions.search')}
          endAdornment={
            <InputAdornment position="end">
              {search && (
                <IconButton size="small" onClick={resetSearch}>
                  <CancelIcon />
                </IconButton>
              )}
            </InputAdornment>
          }
        />
        <Separator />
        <div className={classes.optionsBody}>{body()}</div>
      </Popover>
    </>
  )
}

export function SinglePicker<T extends Record = Record>(
  props: SinglePickerProps<T>,
) {
  const { value, onChange, ...pickerProps } = props
  return (
    <Picker<T>
      values={value ? [value] : []}
      handleSelect={(record) => onChange(record)}
      handleUnSelect={() => onChange(undefined)}
      single
      {...pickerProps}
    />
  )
}

export function MultiplePicker<T extends Record = Record>(
  props: MultiplePickerProps<T>,
) {
  const { values, onChange, ...pickerProps } = props
  return (
    <Picker<T>
      handleSelect={(record: T) => {
        onChange([...values, record])
      }}
      handleUnSelect={(record: T) => {
        onChange(values.filter((value) => value.id !== record.id))
      }}
      values={values}
      {...pickerProps}
    />
  )
}
