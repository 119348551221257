import { createContext, useContext, useMemo, useRef } from 'react'
import type { PropsWithChildren } from 'react'
import { useGetIdentity as useRaGetIdentity } from 'react-admin'
import { useGetList } from 'src/libs/useGetList'
import { useMemoByValues } from 'src/libs/useMemoByValues'
import type { User, Client } from 'src/types/api'
import { useDeepCompareMemo } from 'use-deep-compare'

import type { RoleEnum } from './Persona'
import { PersonaRootProvider } from './Persona'
import type { UserProfile } from './types'

type ReturnState =
  | {
      identity?: Partial<UserProfile>
      error: undefined
      loading: true
      loaded: false
    }
  | { identity: UserProfile; error: undefined; loading: false; loaded: true }
  | {
      identity?: Partial<UserProfile>
      error: any
      loading: false
      loaded: true
    }

const IdentityContext = createContext<ReturnState>({
  loaded: false,
  loading: true,
  identity: undefined,
  error: undefined,
})

export function IdentityProvider(props: PropsWithChildren<{}>) {
  const { children } = props

  const { identity: oidcProfile, ...auth } = useRaGetIdentity()
  const authenticationId = oidcProfile?.sub
  const { data: user, ...userResult } = useQueryUser(authenticationId)
  const { data: clients, ...clientsResult } = useQueryClients(authenticationId)

  const loading = auth.loading || userResult.loading || clientsResult.loading
  const loaded = auth.loaded && userResult.loaded && clientsResult.loaded
  const error = auth.error || userResult.error || clientsResult.error

  const identity = useMemo<Partial<UserProfile> | undefined>(() => {
    if (!user) return undefined
    return {
      ...oidcProfile,
      ...user,
      roles: user.roles as RoleEnum[] | undefined,
      clients,
    }
  }, [clients, user, oidcProfile])

  const value = useMemoByValues({
    loading,
    loaded,
    error,
    identity,
  }) as ReturnState

  return (
    <IdentityContext.Provider value={value}>
      <PersonaRootProvider identity={identity}>{children}</PersonaRootProvider>
    </IdentityContext.Provider>
  )
}

export function useGetIdentity() {
  return useContext(IdentityContext)
}

// 🙄 react-admin useQuery is broken
function useResultCache<T>(value: T | undefined): T | undefined {
  const resultCache = useRef<T | undefined>()
  resultCache.current = value ?? resultCache.current
  return useDeepCompareMemo(() => resultCache.current, [resultCache.current])
}

function useQueryUser(authenticationId?: string) {
  const enabled = Boolean(authenticationId)

  const usersResult = useGetList<User>(
    'users',
    { pagination: { perPage: 1 }, filter: { authenticationId } },
    { enabled },
  )
  const user = enabled ? usersResult.data?.[0] : undefined

  return { ...usersResult, data: useResultCache(user) }
}

function useQueryClients(authenticationId?: string) {
  const enabled = Boolean(authenticationId)
  const result = useGetList<Client>(
    'clients',
    { filter: { 'users.authenticationId': authenticationId } },
    { enabled },
  )

  const { data } = result
  const clients = useResultCache(enabled && data?.length ? data : undefined)
  return {
    ...result,
    data: clients,
  }
}
