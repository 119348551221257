import Box from '@material-ui/core/Box'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import footer from '@nartex/smartforest-design-tokens/graphics/svg/footerDesktop.svg'
import { createElement, memo } from 'react'
import type { ReduxState } from 'react-admin'
import { useTranslate, MenuItemLink } from 'react-admin'
import { useSelector } from 'react-redux'
import { Separator } from 'src/components/Separator'
import { useCreateResourceUrl } from 'src/Resources/useCreateResourceUrl'
import {
  useRoles,
  ClientPicker,
  RoleEnum,
  useGetIdentity,
} from 'src/UserIdentity'

import {
  InterventionIcon,
  ClientIcon,
  ProjectIcon,
  EntitlementIcon,
  UserIcon,
  MaterialIcon,
  WorkOrderIcon,
  FormIcon,
  DocumentIcon,
  AdminIcon,
  ReportIcon,
  GroupIcon,
} from './theme/icons'
import { overflowEllipsis } from './theme/mixins'

const useStyles = makeStyles(function (theme) {
  return {
    menuHeader: {
      ...overflowEllipsis(),
      padding: `${theme.spacing(1.5)}px ${theme.spacing(2)}px`,
      background: theme.palette.info.main,
      color: theme.palette.info.contrastText,
      display: 'flex',
      alignItems: 'center',
      gap: theme.spacing(1),
    },
    menuHeaderIcon: {
      flex: 0,
      minWidth: theme.spacing(3),
    },
    menuHeaderText: {
      flex: 1,
    },
    root: {
      minHeight: '100%',
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
    },
    footerPlaceholder: {
      height: theme.spacing(9),
      position: 'sticky',
      width: 'calc(100% - 1px)',
      bottom: 0,
      background: theme.palette.background.default,
      marginTop: 'auto',
    },
    footer: {
      marginLeft: theme.spacing(2),
      minWidth: theme.spacing(22),
      maxWidth: theme.spacing(22),
    },
  }
})

export function Menu() {
  const isOpen = useSelector((state: ReduxState) => {
    return state.admin.ui.sidebarOpen
  })

  const styles = useStyles()

  return (
    <Box className={styles.root}>
      <AdminMenu isOpen={isOpen} />
      <ClientMenu isOpen={isOpen} />

      {isOpen && (
        <div className={styles.footerPlaceholder}>
          <img className={styles.footer} src={footer} alt="Eiffage (logo)" />
        </div>
      )}
    </Box>
  )
}

interface AdminMenuProps {
  isOpen?: boolean
}

const AdminMenu = memo(function AdminMenu(props: AdminMenuProps) {
  const { isOpen } = props

  const __ = useTranslate()
  const styles = useStyles()
  const roles = useRoles()

  if (!roles.ROLE_ADMIN) return null
  return (
    <Box paddingBottom={2}>
      <Typography className={styles.menuHeader} variant="button">
        <AdminIcon className={styles.menuHeaderIcon} />
        {isOpen && (
          <span className={styles.menuHeaderText}>
            {__('menu.administration.title')}
          </span>
        )}
      </Typography>

      <MenuItem
        resource="clients"
        role={RoleEnum.ROLE_ADMIN}
        label="menu.administration.clients"
        icon={ClientIcon}
      />

      <MenuItem
        resource="users"
        role={RoleEnum.ROLE_ADMIN}
        label="menu.administration.users"
        icon={UserIcon}
      />

      <MenuItem
        resource="habilitations"
        role={RoleEnum.ROLE_ADMIN}
        label="menu.administration.habilitations"
        icon={EntitlementIcon}
      />
    </Box>
  )
})

interface ClientMenuProps {
  isOpen?: boolean
}

const ClientMenu = memo(function ClientMenu(props: ClientMenuProps) {
  const { isOpen } = props

  const roles = useRoles()
  const { identity, loading } = useGetIdentity()
  const __ = useTranslate()

  if (loading && !identity?.clients) {
    return (
      <Box paddingX={2} paddingY={2}>
        <Typography variant="body2">{__('info.loading')}</Typography>
      </Box>
    )
  }

  if (!roles.ROLE_CONFIGURATOR && !roles.ROLE_MANAGER) {
    return null
  }
  if (!identity?.clients?.length) {
    return (
      <Box paddingX={2} paddingY={2}>
        <Typography variant="body2">
          {__('menu.configuration.no_client')}
        </Typography>
      </Box>
    )
  }

  return (
    <>
      <ClientPicker compact={!isOpen} />
      <MenuItem
        resource="clientInformation"
        label="menu.configuration.client"
        icon={ClientIcon}
      />
      <MenuItem
        resource="autorisations"
        label="menu.configuration.autorisations"
        icon={EntitlementIcon}
      />
      <MenuItem
        resource="groups"
        label="menu.configuration.groups"
        icon={GroupIcon}
      />
      <Separator />
      <MenuItem
        resource="projects"
        label="menu.configuration.projects"
        icon={ProjectIcon}
      />

      <MenuItem
        resource="work_orders"
        label="menu.configuration.work_orders"
        icon={WorkOrderIcon}
      />

      <MenuItem
        resource="interventions"
        label="menu.configuration.interventions"
        icon={InterventionIcon}
      />
      <MenuItem
        resource="round"
        label="menu.configuration.round"
        icon={InterventionIcon}
      />

      <Separator />
      <MenuItem
        resource="documents"
        role={RoleEnum.ROLE_CONFIGURATOR}
        label="menu.configuration.documents"
        icon={DocumentIcon}
      />
      <MenuItem
        resource="items"
        label="menu.configuration.items"
        icon={MaterialIcon}
      />

      <MenuItem
        resource="equipment"
        label="menu.configuration.equipment"
        icon={MaterialIcon}
      />

      <Separator />

      <MenuItem
        resource="forms"
        label="menu.configuration.forms"
        icon={FormIcon}
      />

      <MenuItem
        resource="reports"
        label="menu.configuration.reports"
        icon={ReportIcon}
      />
    </>
  )
})

interface MenuItemProps {
  resource: string
  role?: RoleEnum
  label: string
  icon: any
}

const MenuItem = memo(function MenuItem(itemProps: MenuItemProps) {
  const { resource, role, label, icon } = itemProps

  const __ = useTranslate()
  const makeResourceUrl = useCreateResourceUrl()

  return (
    <MenuItemLink
      to={makeResourceUrl(resource, { role })}
      primaryText={__(label)}
      leftIcon={createElement(icon)}
    />
  )
})
