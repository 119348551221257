import { GetApp } from '@material-ui/icons'
import { format } from 'date-fns'
import { saveAs } from 'file-saver'
import JSZip from 'jszip'
import { last } from 'lodash'
import { useCallback } from 'react'
import type { BulkActionProps } from 'react-admin'
import { useDataProvider, BooleanInput, Button } from 'react-admin'
import { useMutation } from 'react-query'
import { SfmList } from 'src/AppScopes/resource'
import type { SfmListProps } from 'src/AppScopes/resource'
import { CustomDatagrid } from 'src/components/CustomDatagrid'
import { SfmDateField, SfmTextField } from 'src/components/SfmFields'
import { getFormExportFilename } from 'src/libs/getFormExportFilename'
import type { Form, FormVersion } from 'src/types/api'
import { ResourceEnum } from 'src/types/api/resources'
import { RoleEnum } from 'src/UserIdentity'

export function List(props: SfmListProps) {
  return (
    <SfmList
      {...props}
      acceptedEditRoles={[RoleEnum.ROLE_CONFIGURATOR]}
      filters={[
        <BooleanInput
          key="archives"
          label="filters.archived.forms"
          source="showArchived"
        />,
      ]}
      hasExport={false}
      bulkActionButtons={<BulkActionButtons />}
    >
      <CustomDatagrid
        isRowSelectable={(record) => {
          const form = record as Form
          return Boolean(form.lastVersion || form.formVersions.length)
        }}
      >
        <SfmTextField source="name" label="resource.forms.name" />
        <SfmDateField source="updatedAt" label="resource.forms.updatedAt" />
      </CustomDatagrid>
    </SfmList>
  )
}

function BulkActionButtons(props: BulkActionProps) {
  const { selectedIds } = props

  const dataProvider = useDataProvider()

  const exportForms = useCallback(async () => {
    if (!selectedIds) return
    const forms = (
      await dataProvider.getMany<Form>(ResourceEnum.forms, {
        ids: selectedIds,
      })
    ).data.filter(
      (form) => form.lastVersion || Boolean(form.formVersions.length),
    )

    const zip = new JSZip()
    const formsPromises = forms.map((form) => {
      const formversionId = form.lastVersion ?? last(form.formVersions)!

      async function writeFile() {
        const formVersion = await dataProvider.getOne<FormVersion>(
          ResourceEnum.form_versions,
          {
            id: formversionId,
          },
        )
        const blob = new Blob([JSON.stringify(formVersion.data.payload)], {
          type: 'application/json',
        })
        zip.file(getFormExportFilename(form, formVersion.data), blob)
      }

      return writeFile()
    })

    await Promise.all(formsPromises)

    const zipBlob = await zip.generateAsync({ type: 'blob' })
    saveAs(zipBlob, `forms-export-${format(new Date(), 'yyyy-MM-dd')}.zip`)
  }, [selectedIds, dataProvider])

  const { mutate, isLoading } = useMutation({
    mutationFn: exportForms,
  })

  return (
    <Button
      label="actions.export"
      onClick={() => mutate()}
      disabled={isLoading}
    >
      <GetApp />
    </Button>
  )
}
