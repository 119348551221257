// THIS FILE IS AUTO-GENERATED BY FIGMAGIC. DO NOT MAKE EDITS IN THIS FILE! CHANGES WILL GET OVER-WRITTEN BY ANY FURTHER PROCESSING.

const letterSpacings = {
 "code": "0em",
 "label": "0em",
 "button": "0em",
 "microbutton": "0.05em",
 "bodyXsBold": "0.03em",
 "bodyXs": "0em",
 "bodySBold": "0em",
 "bodyS": "0em",
 "bodyMBold": "0em",
 "bodyM": "0em",
 "bodyL": "0em",
 "headingXxs": "0.07em",
 "headingXs": "0em",
 "headingS": "0em",
 "headingM": "0em",
 "headingL": "0em"
} as const;

export default letterSpacings;