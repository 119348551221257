import type { CreateProps } from 'react-admin'
import { SfmCreate } from 'src/AppScopes'
import { ProtectedPage } from 'src/components/ProtectedPage'

import { ContactForm } from './Form'

export function Create(props: CreateProps) {
  return (
    <ProtectedPage>
      <SfmCreate {...props}>
        <ContactForm />
      </SfmCreate>
    </ProtectedPage>
  )
}
