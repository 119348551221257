import ContentAdd from '@material-ui/icons/Add'

import { useState } from 'react'
import type { Identifier, Record } from 'react-admin'
import {
  required,
  ReferenceInput,
  NumberInput,
  ArrayInput,
  AutocompleteInput,
  SimpleFormIterator,
  Button,
  useNotify,
  useRefresh,
  useTranslate,
} from 'react-admin'

import { useHttpClient } from 'src/adapters/HTTPClient'
import { CustomFormDialog } from 'src/components/CustomFormDialog'
import { formatApiPlatformId } from 'src/libs/apiPlatformId'

interface Props {
  intervention: Record
}

interface FormValue {
  items: { id: Identifier; quantity: number }[]
}

export function AssignItems(props: Props) {
  const { intervention } = props
  const __ = useTranslate()
  const notify = useNotify()
  const refresh = useRefresh()
  const httpClient = useHttpClient()
  const [loadingItems, setLoadingItems] = useState(false)

  const [showDialogItems, setShowDialogItems] = useState(false)
  const closeDialog = () => setShowDialogItems(false)

  const submitItems = (params: FormValue) => {
    const { items } = params

    if (!items) {
      notify('error.no_item', 'error')
      return
    }
    setLoadingItems(true)
    httpClient
      .post(
        '/intervention_items/batch/' + formatApiPlatformId(intervention.id),
        {
          items: items.map((item) => {
            const { id, quantity } = item
            return {
              id: formatApiPlatformId(id),
              quantity,
            }
          }),
        },
      )
      .then(() => {
        notify('notifications.assign.items', 'success')
        closeDialog()
        refresh()
      })
      .catch((error) => notify(error.message, 'error'))
      .finally(() => setLoadingItems(false))
  }

  return (
    <>
      <Button onClick={() => setShowDialogItems(true)} label="actions.add">
        <ContentAdd />
      </Button>
      <CustomFormDialog
        isOpen={showDialogItems}
        onClose={closeDialog}
        submit={submitItems}
        isLoading={loadingItems}
        resource="contact"
        title={__('actions.assign_items')}
      >
        <ArrayInput source="items">
          <SimpleFormIterator>
            <ReferenceInput
              validate={required()}
              source="id"
              reference="items"
              label="resource.intervention_items.item"
            >
              <AutocompleteInput optionText="name" />
            </ReferenceInput>
            <NumberInput
              validate={required()}
              source="quantity"
              label="resource.intervention_items.quantity"
            />
          </SimpleFormIterator>
        </ArrayInput>
      </CustomFormDialog>
    </>
  )
}
