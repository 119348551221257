import { FormControlLabel, makeStyles, Switch } from '@material-ui/core'
import { useMemo, useState } from 'react'
import { FieldTitle, TextField } from 'react-admin'
import { SfmList } from 'src/AppScopes/resource'
import type { SfmListProps } from 'src/AppScopes/resource'
import { CustomDatagrid } from 'src/components/CustomDatagrid'
import { FullNameField } from 'src/components/FullNameField'
import { SegmentedControl } from 'src/components/SegmentedControl'
import {
  SfmTextField,
  SfmDateField,
  SfmFunctionField,
  EmptyText,
  SfmReferenceField,
} from 'src/components/SfmFields'
import StatusField from 'src/components/StatusField'
import type { Intervention, IriReference } from 'src/types/api'
import { InterventionStatusEnum } from 'src/types/api'
import { ResourceEnum } from 'src/types/api/resources'
import { RoleEnum, useGetIdentity, usePersona } from 'src/UserIdentity'

type ManagerFilterType = 'active' | 'not_ready' | 'needing_validation' | 'ended'

const useStyles = makeStyles(function (theme) {
  return {
    filters: {
      display: 'flex',
      gap: theme.spacing(2),
    },
  }
})

export function List(props: SfmListProps & { isRound: boolean }) {
  const { isRound, ...listProps } = props
  const { identity } = useGetIdentity()
  const { role } = usePersona()
  const userId = identity?.['@id']
  const isManager = role === RoleEnum.ROLE_MANAGER

  const [filterType, setFilterType] = useState<ManagerFilterType>('active')
  const [showOnlyAssignedToMe, setShowOnlyAssignedToMe] =
    useState<boolean>(true)
  const filters = useMemo(
    () =>
      getFilterParams(filterType, showOnlyAssignedToMe ? userId : undefined),
    [userId, filterType, showOnlyAssignedToMe],
  )

  const styles = useStyles()

  const managerFilters = [
    {
      value: 'not_ready',
      label: 'resource.interventions.filters.not_ready',
    },
    !isRound && {
      value: 'needing_validation',
      label: 'resource.interventions.filters.needing_validation',
    },
  ].filter(Boolean) as { value: ManagerFilterType; label: string }[]

  return (
    <SfmList
      {...listProps}
      acceptedEditRoles={[RoleEnum.ROLE_CONFIGURATOR]}
      sort={{ field: isRound ? 'startRound' : 'start', order: 'DESC' }}
      filter={filters}
      filters={
        <div className={styles.filters}>
          <SegmentedControl<ManagerFilterType>
            key="managerFilter"
            value={filterType}
            onChange={setFilterType}
            options={[
              {
                value: 'active',
                label: 'resource.interventions.filters.active',
              },
              ...(isManager ? managerFilters : []),
              {
                value: 'ended',
                label: 'resource.interventions.filters.ended',
              },
            ]}
          />
          {filterType === 'needing_validation' && (
            <FormControlLabel
              control={
                <Switch
                  color="primary"
                  checked={showOnlyAssignedToMe}
                  onChange={(e, checked) => setShowOnlyAssignedToMe(checked)}
                />
              }
              label={
                <FieldTitle label="filters.show_only_interventions_assigned_to_me" />
              }
            />
          )}
        </div>
      }
    >
      <CustomDatagrid>
        {isRound ? (
          <SfmDateField
            source="startRound"
            label="resource.interventions.start"
          />
        ) : (
          <SfmDateField source="start" label="resource.interventions.start" />
        )}
        {role === RoleEnum.ROLE_MANAGER && (
          <StatusField source="status" label="resource.interventions.status" />
        )}
        <SfmTextField source="name" label="resource.interventions.name" />
        {role === RoleEnum.ROLE_MANAGER && (
          <UserOrGroupField
            label="resource.interventions.operator"
            userSource="operator"
            groupSource="operatorsGroup"
          />
        )}
        {role === RoleEnum.ROLE_MANAGER && !isRound && (
          <UserOrGroupField
            label="resource.interventions.validator"
            userSource="validator"
            groupSource="validatorsGroup"
          />
        )}

        {role === RoleEnum.ROLE_CONFIGURATOR && (
          <SfmReferenceField
            source="form"
            reference="projects"
            label="resource.interventions.form"
          >
            <SfmTextField source="name" />
          </SfmReferenceField>
        )}
      </CustomDatagrid>
    </SfmList>
  )
}

interface UserOrGroupFieldProps {
  userSource: keyof Intervention
  groupSource: keyof Intervention
  label: string
}
function UserOrGroupField(props: UserOrGroupFieldProps) {
  const { userSource, groupSource, label } = props
  return (
    <SfmFunctionField
      label={label}
      render={(record) => {
        if (!record) return <EmptyText />
        const intervention = record as Intervention
        if (intervention[userSource]) {
          return (
            <SfmReferenceField
              source={userSource}
              reference="users"
              record={intervention}
            >
              <FullNameField />
            </SfmReferenceField>
          )
        }
        if (intervention[groupSource]) {
          return (
            <SfmReferenceField
              source={groupSource}
              reference={ResourceEnum.groups}
              record={intervention}
            >
              <TextField source="name" />
            </SfmReferenceField>
          )
        }
        return <EmptyText />
      }}
    />
  )
}

function getFilterParams(
  filterType: ManagerFilterType,
  userId?: IriReference,
): Record<string, any> {
  if (filterType === 'active') {
    return {
      status: [
        InterventionStatusEnum.created,
        InterventionStatusEnum.ready,
        InterventionStatusEnum.started,
      ],
    }
  }
  if (filterType === 'ended') {
    return {
      showValidated: true,
      status: [InterventionStatusEnum.ended, InterventionStatusEnum.validated],
    }
  }
  if (filterType === 'not_ready') {
    return {
      status: InterventionStatusEnum.created,
    }
  }
  if (filterType === 'needing_validation') {
    return {
      needValidation: true,
      'validator.id': userId,
    }
  }
  return {} as never
}
