import { BooleanInput } from 'react-admin'

import { SfmList } from 'src/AppScopes/resource'
import type { SfmListProps } from 'src/AppScopes/resource'
import { CustomDatagrid } from 'src/components/CustomDatagrid'
import { SfmTextField } from 'src/components/SfmFields'
import type { Client } from 'src/types/api'

export function ClientList(props: SfmListProps) {
  return (
    <SfmList
      {...props}
      filters={[
        <BooleanInput
          key="disabled"
          label="filters.disabled_clients"
          source="showDisable"
        />,
      ]}
    >
      <CustomDatagrid isRowDisabled={(record: Client) => !record.valid}>
        <SfmTextField source="name" label="resource.clients.name" />
        <SfmTextField source="reference" label="resource.clients.reference" />
      </CustomDatagrid>
    </SfmList>
  )
}
