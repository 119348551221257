import { makeStyles } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import type { PropsWithChildren } from 'react'
import type { PaginationProps } from 'react-admin'
import {
  useTranslate,
  List as RaList,
  SearchInput,
  Pagination,
} from 'react-admin'
import { ListActions } from 'src/components/ProtectedActions'
import { arrayify } from 'src/libs/arrayify'
import { RoleEnum } from 'src/UserIdentity'

import type { SfmListProps } from './types'

const useStyle = makeStyles(function (theme) {
  return {
    container: {
      display: 'flex',
      gap: theme.spacing(0),
      margin: `${theme.spacing(2)}px 0`,
      flexDirection: 'column',
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
      alignItems: 'flex-start',
    },
    search: {
      margin: 0,
    },
  }
})

export function List(props: PropsWithChildren<SfmListProps>) {
  const {
    title,
    caption,
    acceptedEditRoles = [RoleEnum.ROLE_ADMIN, RoleEnum.ROLE_CONFIGURATOR],
    filters,
    hasExport,
    extraFilters: otherFilters,
    ...raListProps
  } = props

  const __ = useTranslate()

  return (
    <>
      {caption && (
        <Typography
          variant="caption"
          display="block"
          color="secondary"
          gutterBottom
        >
          {caption}
        </Typography>
      )}
      <Typography variant="h1">{__(title)}</Typography>
      <RaList
        actions={
          <ListActions
            acceptedRoles={acceptedEditRoles}
            hasExport={hasExport}
          />
        }
        bulkActionButtons={false}
        perPage={50}
        pagination={<SfmPagination />}
        sort={{ field: 'updatedAt', order: 'DESC' }}
        empty={false}
        {...raListProps}
        syncWithLocation={false}
        filters={[
          <FiltersContainer key="filters" alwaysOn>
            {filters}
          </FiltersContainer>,
          ...arrayify(otherFilters ?? []),
        ]}
      />
    </>
  )
}

function SfmPagination(props: PaginationProps) {
  return (
    <Pagination
      rowsPerPageOptions={[10, 25, 50, 100]}
      limit={<span />}
      {...props}
    />
  )
}

interface FiltersContainerProps {
  alwaysOn?: boolean // required by react-admin
}

function FiltersContainer(props: PropsWithChildren<FiltersContainerProps>) {
  const { children } = props
  const style = useStyle()

  return (
    <div className={style.container}>
      <SearchInput key="q" source="q" className={style.search} />
      {children}
    </div>
  )
}
