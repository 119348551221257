import { IonItem, useIonToast } from '@ionic/react'
import BarCodeIcon from '@nartex/smartforest-design-tokens/graphics/react/BxBxBarcode'
import ScanIcon from '@nartex/smartforest-design-tokens/graphics/react/BxBxBarcodeReader'
import { useFormContext } from 'react-hook-form'
import { usePlugins } from 'src/plugins'

import type { InputProps } from '../types'

export function BarCodeInput(props: InputProps) {
  const { label } = props
  const { setValue } = useFormContext()
  const { BarCodePlugin } = usePlugins()

  const [present] = useIonToast()
  const scan = () => {
    BarCodePlugin.scan()
      .then((data) => {
        if (!('value' in data)) return

        const qrValue = JSON.parse(data.value)
        if (qrValue) {
          Object.entries(qrValue).forEach(([key, value]) => {
            setValue(key, value)
          })
          present({
            message: 'Formulaire complété avec les informations du QR Code.',
            duration: 1500,
            color: 'primary',
          })
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  return (
    <IonItem
      button
      detail={false}
      onClick={scan}
      lines="none"
      className="border-input"
    >
      <BarCodeIcon slot="start" className="input-icon -isPrefix" />

      {/* TODO Translate */}
      <label className="input-label">{label || 'Scan QrCode'}</label>

      <ScanIcon slot="end" className="input-icon -isAction" />
    </IonItem>
  )
}
