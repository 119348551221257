import { makeStyles } from '@material-ui/core'

import { rulerStroke } from '../theme/mixins'

export const useStyles = makeStyles(function (theme) {
  return {
    mainContainer: {
      position: 'relative',
      height: '100%',
      margin: 0,
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(1),
      padding: theme.spacing(1),
      '&:before': {
        ...rulerStroke(theme, 'vertical'),
      },
    },
    listContainer: {
      justifyContent: 'space-between',
      overflowY: 'hidden',
    },
    list: {
      listStyle: 'none',
      padding: 0,
      overflowY: 'auto',
      margin: 0,
    },
    listItem: {
      display: 'flex',
      alignItems: 'center',
      gap: theme.spacing(1),

      '& > svg': {
        minWidth: theme.spacing(3),
      },
    },

    listItemContent: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      overflowWrap: 'break-word',
    },
    fieldLabel: {
      color: theme.palette.text.hint,
    },
    varName: {
      textTransform: 'initial',
    },
  }
})
