import type { SfmCreateProps } from 'src/AppScopes'
import { SfmCreate } from 'src/AppScopes'
import { RoleEnum } from 'src/UserIdentity'

import { Form } from './Form'

export function Create(props: SfmCreateProps) {
  return (
    <SfmCreate {...props} acceptedRoles={[RoleEnum.ROLE_MANAGER]}>
      <Form />
    </SfmCreate>
  )
}
