import { List, ListItem, makeStyles } from '@material-ui/core'
import type { ReactElement } from 'react'
import type { Record } from 'react-admin'
import { Link, useListContext, LinearProgress } from 'react-admin'
import { useCreateResourceUrl } from 'src/Resources/useCreateResourceUrl'
import { usePersona } from 'src/UserIdentity'

import { EmptyText } from './SfmFields'

const useStyles = makeStyles(function () {
  return {
    listItem: {
      paddingLeft: 0,
      paddingRight: 0,
    },
    list: {
      paddingTop: 0,
      paddingBottom: 0,
    },
  }
})

interface Props<T> {
  render?: (record: T, basePath: string) => ReactElement
  getItemLabel?: (record: T) => string
  to?: (record: T) => string
  readonly?: boolean
}
export function DataList<T extends Record = Record>(props: Props<T>) {
  const { render, getItemLabel, to, readonly } = props
  const { data, loading, basePath } = useListContext<T>()
  const persona = usePersona()
  const resourceUrl = useCreateResourceUrl()
  const styles = useStyles()

  if (loading) return <LinearProgress />

  if (Object.keys(data).length < 1) return <EmptyText />

  const list = Object.values(data).map((record) => {
    if (render) return render(record, basePath)

    if (readonly) {
      return (
        <ListItem key={record.id} className={styles.listItem}>
          {getItemLabel ? getItemLabel(record) : record.name}
        </ListItem>
      )
    }

    return (
      <ListItem key={record.id} className={styles.listItem}>
        <Link
          to={
            to
              ? to(record)
              : resourceUrl(basePath.replace('/', ''), record.id, 'show', {
                  clientId:
                    typeof record.client === 'string'
                      ? record.client
                      : persona.clientId,
                })
          }
        >
          {getItemLabel ? getItemLabel(record) : record.name}
        </Link>
      </ListItem>
    )
  })

  return (
    <List dense className={styles.list}>
      {list}
    </List>
  )
}
