import { BooleanInput } from 'react-admin'
import { SfmList } from 'src/AppScopes/resource'
import type { SfmListProps } from 'src/AppScopes/resource'
import { CustomDatagrid } from 'src/components/CustomDatagrid'
import { SfmTextField } from 'src/components/SfmFields'
import { RoleEnum } from 'src/UserIdentity'

export function List(props: SfmListProps) {
  return (
    <SfmList
      {...props}
      acceptedEditRoles={[RoleEnum.ROLE_CONFIGURATOR]}
      filters={[
        <BooleanInput
          key="archives"
          label="filters.archived.equipment"
          source="showArchived"
        />,
      ]}
    >
      <CustomDatagrid>
        <SfmTextField source="name" label="resource.equipment.name" />
        <SfmTextField source="reference" label="resource.equipment.reference" />
        <SfmTextField
          source="designation"
          label="resource.equipment.designation"
        />
        <SfmTextField source="location" label="resource.equipment.location" />
      </CustomDatagrid>
    </SfmList>
  )
}
