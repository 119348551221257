import { required } from 'react-admin'
import { SfmTextInput, SfmTextArea } from 'src/components/SfmInputs'
import { useValidateUnicity } from 'src/components/UniqueInput'
import type { Item } from 'src/types/api'
import { ResourceEnum } from 'src/types/api/resources'

export function ItemForm() {
  const unique = useValidateUnicity<Item>(ResourceEnum.items)
  return (
    <>
      <SfmTextInput
        source="name"
        label="resource.items.name"
        validate={[required('validation.required')]}
      />
      <SfmTextInput
        source="reference"
        label="resource.items.reference"
        validate={[required('validation.required'), unique('reference')]}
      />
      <SfmTextArea source="description" label="resource.items.description" />
    </>
  )
}
