import { makeStyles } from '@material-ui/core'
import Paper from '@material-ui/core/Paper'

import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import type { InputName } from '@nartex/sfm-wysiwyg'
import { useState } from 'react'
import {
  ReferenceField,
  useRecordContext,
  useGetOne,
  useTranslate,
  Button,
  useNotify,
  useRefresh,
} from 'react-admin'
import type { ShowProps, ButtonProps } from 'react-admin'

import { useHttpClient } from 'src/adapters/HTTPClient'
import { SfmShow } from 'src/AppScopes/resource'
import { FullNameField } from 'src/components/FullNameField'
import { MinioFileField } from 'src/components/MinioFileField'
import {
  EmptyText,
  SfmDateField,
  SfmReferenceField,
  SfmTextField,
} from 'src/components/SfmFields'
import { formatApiPlatformId } from 'src/libs/apiPlatformId'
import { InterventionDataStatusEnum } from 'src/types/api'
import type { Document, Intervention } from 'src/types/api'

import type {
  FullFormVersion,
  FullInterventionData,
} from 'src/types/api/extendedTypes'

import { ResourceEnum } from 'src/types/api/resources'
import { CancelIcon, CheckIcon, RefreshIcon } from 'src/UI/theme/icons'

import { LabeledField } from '../../components/LabeledField'
import { ShowLayout } from '../../components/ShowLayout'

import { ReportSection } from './ReportSection'

const useStyles = makeStyles(function (theme) {
  return {
    buttons: {
      display: 'flex',
      gap: theme.spacing(2),
      marginTop: theme.spacing(2),
    },
  }
})

export function Show(props: ShowProps) {
  return (
    <SfmShow {...props} acceptedEditRoles={[]}>
      <ShowWrapper />
    </SfmShow>
  )
}

function ShowWrapper(props: any) {
  // any because it doesn't work with SimpleShowLayoutProps
  const interventionData: FullInterventionData =
    useRecordContext<FullInterventionData>(props)
  const { payload } = interventionData
  const values = payload?.values
  return (
    <ShowLayout
      title="resource.intervention_datas.intervention_datas"
      subtitle={`status.${interventionData.status}`}
    >
      <SfmDateField
        source="start"
        showTime
        label="resource.intervention_datas.start"
      />
      <SfmDateField
        source="endTime"
        showTime
        label="resource.intervention_datas.endTime"
      />
      <SfmReferenceField
        label="resource.intervention_datas.operator"
        source="operator"
        reference="users"
      >
        <FullNameField />
      </SfmReferenceField>

      {interventionData.idItemChecker && (
        <SfmTextField
          source="idItemChecker"
          label="resource.intervention_datas.idItemChecker"
        />
      )}
      <LabeledField label={'resource.intervention_datas.datas'} fullBleed>
        {values ? (
          <ReferenceField
            source="formVersion"
            reference="form_versions"
            addLabel={false}
            link={false}
          >
            <FieldTable values={values} interventionData={interventionData} />
          </ReferenceField>
        ) : (
          <EmptyText />
        )}
      </LabeledField>
      <ReportSection interventionData={interventionData} />
    </ShowLayout>
  )
}

interface FieldTableProps {
  values: { [key: string]: string }
  interventionData: FullInterventionData
}

function FieldTable(props: FieldTableProps) {
  const { values, interventionData } = props
  const formVersion = useRecordContext<FullFormVersion>(props)
  const { payload } = formVersion
  const { fieldNames } = payload
  const translate = useTranslate()

  return (
    <>
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>
                {translate('resource.intervention_datas.variables')}
              </TableCell>
              <TableCell align="left">
                {translate('resource.intervention_datas.label')}
              </TableCell>
              <TableCell align="left">
                {translate('resource.intervention_datas.data')}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {fieldNames?.map((field, index) => {
              const { type, label, name } = field

              return (
                <TableRow key={index}>
                  <TableCell component="th" scope="row">
                    {'${' + name + '}'}
                  </TableCell>
                  <TableCell align="left">{label}</TableCell>
                  <TableCell align="left">
                    <ValueCell type={type} value={values?.[name]} />
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
      {interventionData.status ===
        InterventionDataStatusEnum.need_validation && (
        <ValidationButtons interventionData={interventionData} />
      )}
    </>
  )
}

interface ValueCellProps {
  type: InputName
  value: string | string[]
}

function ValueCell(props: ValueCellProps) {
  const translate = useTranslate()
  const { type, value } = props

  if (type === 'Checkbox') {
    if (value) return <CheckIcon />
    return <CancelIcon />
  }
  if (!value) {
    return <EmptyText />
  }
  if (value === '...') {
    return <>{translate('resource.intervention_datas.noValues')}</>
  }
  switch (type) {
    case 'Photo':
      return <DocumentDownload id={value as string} />
    case 'Video':
      return <DocumentDownload id={value as string} />
    case 'Audio':
      return <DocumentDownload id={value as string} />
    case 'Signature':
      return <DocumentDownload id={value as string} />
    case 'SelectMany':
      return <>{(value as string[]).join(', ')}</>

    default:
      return <>{value}</>
  }
}

interface DocumentDownloadProps {
  id: string
}

function DocumentDownload(props: DocumentDownloadProps) {
  const translate = useTranslate()
  const { id } = props

  const { data, loading } = useGetOne<Document>(
    'documents',
    '/api/documents/' + id,
  )

  if (loading) {
    return <>{translate('resource.intervention_datas.loading')}</>
  }
  if (!data) {
    return <EmptyText />
  }
  return (
    <MinioFileField
      fileName={data?.fileName || ''}
      bucketId={data?.client || ''}
      title={data?.name}
    />
  )
}

interface ValidationButtonsProps {
  interventionData: FullInterventionData
}

const translationKeys = {
  validate: 'validated',
  reject: 'rejected',
  repipe: 'to_complete',
}

function ValidationButtons(props: ValidationButtonsProps) {
  const { interventionData } = props
  const httpClient = useHttpClient()

  const { data } = useGetOne<Intervention>(
    ResourceEnum.interventions,
    interventionData.intervention,
  )

  const [loading, setLoading] = useState<boolean>(false)
  const notify = useNotify()
  const refresh = useRefresh()

  function request(action: 'validate' | 'reject' | 'repipe') {
    setLoading(true)
    const translationKey = translationKeys[action]
    httpClient
      .get(
        `/intervention_data/${action}/${formatApiPlatformId(
          interventionData.id,
        )}`,
      )
      .then(() => {
        notify(`notifications.validation.intervention_datas.${translationKey}`)
        refresh()
      })
      .catch((error) => {
        notify(error.message, 'error')
      })
      .finally(() => setLoading(false))
  }

  const validate = () => {
    request('validate')
  }

  const reject = () => {
    request('reject')
  }

  const repipe = () => {
    request('repipe')
  }

  const styles = useStyles()

  const defaultButtonProps: Partial<ButtonProps> = {
    variant: 'contained',
    fullWidth: true,
    alignIcon: 'right',
    disabled: loading,
  }

  return (
    <div className={styles.buttons}>
      <Button
        label="actions.validate_datas"
        onClick={validate}
        {...defaultButtonProps}
        color="primary"
      >
        <CheckIcon />
      </Button>
      <Button
        label="actions.reject_datas"
        onClick={reject}
        {...defaultButtonProps}
        color="secondary"
      >
        <CancelIcon />
      </Button>
      {data && !data.isRecurrent && (
        <Button
          label="actions.repipe_datas"
          onClick={repipe}
          {...defaultButtonProps}
          color="default"
        >
          <RefreshIcon />
        </Button>
      )}
    </div>
  )
}
