import { useState } from 'react'
import type { Identifier } from 'react-admin'
import {
  useNotify,
  useRecordContext,
  useTranslate,
  useUpdate,
} from 'react-admin'
import type { SfmShowProps } from 'src/AppScopes'
import { SfmShow } from 'src/AppScopes'
import { MultipleResourcePicker } from 'src/components/Picker'
import { RichDataList } from 'src/components/RichDataList'
import { SfmReferenceArrayField } from 'src/components/SfmFields'
import { ShowLayout } from 'src/components/ShowLayout'
import type { Group, User } from 'src/types/api'
import { ResourceEnum } from 'src/types/api/resources'
import { AddIcon } from 'src/UI/theme/icons'
import { RoleEnum, usePersona } from 'src/UserIdentity'

export function Show(props: SfmShowProps) {
  return (
    <SfmShow {...props} acceptedEditRoles={[RoleEnum.ROLE_MANAGER]}>
      <ShowLayout<Group> title={(group) => group.name}>
        <UserList />
      </ShowLayout>
    </SfmShow>
  )
}

function UserList() {
  const group = useRecordContext<Group>()
  const __ = useTranslate()
  const notify = useNotify()

  const [ids, setIds] = useState<Identifier[]>(group.users)

  const [update, { loading }] = useUpdate()
  const save = (values: User[]) => {
    const selectedIds = values.map((user) => user.id)
    if (ids.join() !== selectedIds.join()) {
      update(
        ResourceEnum.groups,
        group.id,
        {
          users: selectedIds,
        },
        {},
        {
          onSuccess: function (res) {
            setIds(res.data.users)
            notify('notifications.update.group', 'success')
          },
          onFailure: function (error) {
            notify(error, 'error')
          },
        },
      )
    }
  }

  const { role } = usePersona()

  return (
    <SfmReferenceArrayField
      actions={
        role === RoleEnum.ROLE_MANAGER && (
          <MultipleResourcePicker<User>
            resource={ResourceEnum.users}
            saving={loading}
            selectedIds={ids}
            label="actions.add"
            title="actions.add_users"
            getOptionTitle={(user) => `${user.firstName} ${user.lastName}`}
            getOptionSubtitle={(user) => user.emailAddress ?? ''}
            onSave={save}
            Icon={<AddIcon />}
          />
        )
      }
      label="resource.groups.users"
      record={{
        ...group,
        users: ids,
      }}
      source="users"
      reference="users"
    >
      <RichDataList<User>
        hasShowLink={false}
        title={(user) => `${user.firstName} ${user.lastName}`}
        subtitle={(user) => user.emailAddress}
      />
    </SfmReferenceArrayField>
  )
}
