import { SfmCreate } from 'src/AppScopes/resource'
import type { SfmCreateProps } from 'src/AppScopes/resource'

import { RoleEnum } from 'src/UserIdentity'

import { WorkOrderForm } from './Form'

export function Create(props: SfmCreateProps) {
  return (
    <SfmCreate {...props} acceptedRoles={[RoleEnum.ROLE_CONFIGURATOR]}>
      <WorkOrderForm />
    </SfmCreate>
  )
}
