import { stringifyUrl } from 'query-string'
import { formatApiPlatformId } from 'src/libs/apiPlatformId'
import { useParsedLocation } from 'src/libs/useParsedLocation'

import type { RoleEnum } from './types'

interface Params {
  role?: RoleEnum
  clientId?: string
}

export function useCreateRoleUrl() {
  const { query, pathname, hash } = useParsedLocation()

  const secondPath = pathname.split('/')[2]
  const isRecordUrl = secondPath && secondPath !== 'create'
  const listUrl = '/' + pathname.split('/')[1]

  return function createRoleUrl(params: Params) {
    const clientId = formatApiPlatformId(params.clientId)
    let url = pathname
    const isSettingDifferentClient =
      query.clientId && clientId && query.clientId !== clientId

    // redirects to the list if switching to a different client
    if (isRecordUrl && isSettingDifferentClient) {
      url = listUrl
    }

    return stringifyUrl({
      url,
      fragmentIdentifier: hash,
      query: {
        ...query,
        role: params.role || query.role,
        clientId: clientId || query.clientId,
      },
    })
  }
}
