import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles(function () {
  return {
    root: {
      margin: 'auto',
      maxWidth: 1200,
    },
  }
})
