import { IonItem, IonLabel, IonNote } from '@ionic/react'
import classNames from 'classnames'

import type { SvgComponent } from '../../types'

import type { WrapperProps } from '../Wrapper'
import { Wrapper } from '../Wrapper'

interface BaseInputProps extends WrapperProps {
  showLabel?: boolean
  className?: string
  Icon?: SvgComponent
}

export function BaseInputWrapper(props: BaseInputProps) {
  const {
    showLabel = true,
    className,
    Icon: Icon_,
    render,
    ...wrapperProps
  } = props
  const Icon = Icon_ as SvgComponent

  return (
    <Wrapper
      {...wrapperProps}
      render={(field, renderProps) => {
        const { label, error } = renderProps
        return (
          <IonItem
            lines="none"
            className={classNames(className, error && '-hasError')}
          >
            {Icon && <Icon slot="start" className="input-icon -isPrefix" />}
            {showLabel && (
              <IonLabel className="input-label" position="stacked">
                {label ?? field.name}
              </IonLabel>
            )}
            {render(field, { label, error })}
            {error && (
              <IonNote slot="end" className="input-error">
                {error.message || error.type}
              </IonNote>
            )}
          </IonItem>
        )
      }}
    />
  )
}
