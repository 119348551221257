import { makeStyles } from '@material-ui/core'

import { shadow, smallCode } from '../theme/mixins'

export const useSelectStyle = makeStyles(function (theme) {
  return {
    root: {
      color: theme.palette.background.paper,
      backgroundColor: theme.palette.primary.main,
      border: 'none',
      minHeight: '38px',
      verticalAlign: 'baseline',
      borderRadius: '1px',
      boxShadow: shadow(),
    },
    select: {
      // paddingLeft: theme.spacing(0.5),
      paddingBottom: '4px',
      paddingTop: '4px',
    },
    option: {
      gap: theme.spacing(0.5),
      display: 'flex',
      alignItems: 'center',
      ...smallCode(),
      '& > svg': {
        opacity: 0.5,
      },
    },
  }
})
