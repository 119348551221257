import { useState } from 'react'
import type { Identifier } from 'react-admin'
import { useNotify, useRecordContext, useUpdate } from 'react-admin'
import { DataList } from 'src/components/DataList'
import { MultipleResourcePicker } from 'src/components/Picker'
import { SfmReferenceArrayField } from 'src/components/SfmFields'
import type { Form, Report } from 'src/types/api'
import { ResourceEnum } from 'src/types/api/resources'
import { AddIcon } from 'src/UI/theme/icons'

export function ReportsField() {
  const form = useRecordContext<Form>()
  const [reportsIds, setReportsIds] = useState<Identifier[]>(form.reports)

  const notify = useNotify()
  const [update, { loading }] = useUpdate()

  const save = (values: Report[]) => {
    const selectedIds = values.map((report) => report.id)
    if (reportsIds.join() !== selectedIds.join()) {
      update(
        'forms',
        form.id,
        {
          reports: selectedIds,
        },
        {},
        {
          onSuccess(res) {
            setReportsIds(res.data.reports)
            notify('notifications.assign.reports', 'success')
          },
          onFailure(error) {
            notify(error, 'error')
          },
        },
      )
    }
  }

  return (
    <SfmReferenceArrayField
      actions={
        <MultipleResourcePicker<Report>
          resource={ResourceEnum.reports}
          label="actions.assign"
          title="actions.assign_reports"
          getOptionTitle={(report) => report.name}
          selectedIds={reportsIds}
          saving={loading}
          onSave={save}
          Icon={<AddIcon />}
        />
      }
      record={{
        ...form,
        reports: reportsIds,
      }}
      label="resource.forms.reports"
      source="reports"
      reference="reports"
    >
      <DataList<Report> />
    </SfmReferenceArrayField>
  )
}
