import { extension } from 'mime-types'
import { useState } from 'react'
import type { CreateProps } from 'react-admin'
import {
  useTranslate,
  useNotify,
  useRedirect,
  useCreate,
  useRefresh,
  FileField,
  FileInput,
  required,
} from 'react-admin'

import { Redirect } from 'react-router'
import { SfmCreate } from 'src/AppScopes'
import { LabeledField } from 'src/components/LabeledField'
import { SfmSimpleForm } from 'src/components/SfmSimpleForm'
import { useMinioFile } from 'src/libs/useMinioFile'
import { useParsedLocation } from 'src/libs/useParsedLocation'
import type { IriReference, ReportVersion } from 'src/types/api'
import { RoleEnum } from 'src/UserIdentity'
import { v4 as uuidv4 } from 'uuid'

interface FormValues {
  file: { rawFile: File }
}

export function Create(props: CreateProps) {
  return (
    <SfmCreate {...props} acceptedRoles={[RoleEnum.ROLE_CONFIGURATOR]} noForm>
      <CreateInContext />
    </SfmCreate>
  )
}

function CreateInContext() {
  const notify = useNotify()
  const redirect = useRedirect()
  const refresh = useRefresh()
  const translate = useTranslate()
  const { reportId } = useParsedLocation<{ reportId?: IriReference }>().query

  const [create] = useCreate()
  const { upload } = useMinioFile()
  const [loading, setLoading] = useState(false)

  if (!reportId) {
    return <Redirect to="/reports" />
  }

  const onSubmit = (value: FormValues) => {
    setLoading(true)
    const {
      file: { rawFile: file },
    } = value

    const fileExt = extension(file.type)
    if (!fileExt) {
      notify('notifications.error.unrecognized_file', 'error')
      return
    }
    const uuid = uuidv4()
    const fileName = `${uuid}.${fileExt}`
    const reportVersion: Partial<ReportVersion> = {
      id: uuid as unknown as IriReference,
      report: reportId,
      fileName,
      mimeType: file.type,
    }
    upload(fileName, file, reportId)
      .then(() => {
        create('report_versions', reportVersion, {
          onSuccess() {
            notify('notifications.creation.report_version', 'success')
            redirect('show', '/reports', reportId)
            refresh()
          },
        })
      })
      .catch((error) => {
        notify(error.message, 'error')
      })
      .finally(() => setLoading(false))
  }

  const acceptedExtensions = '.docx,.xlsx'
  return (
    <SfmSimpleForm save={onSubmit} saving={loading}>
      <LabeledField label="resource.reports.newReportFile" fullBleed>
        <FileInput
          source="file"
          multiple={false}
          validate={required('validation.required')}
          label=""
          accept={acceptedExtensions}
          helperText={translate('resource.reports.newReportFile.helperText', {
            extensions: acceptedExtensions,
          })}
        >
          <FileField source="src" title="rawFile.name" />
        </FileInput>
      </LabeledField>
    </SfmSimpleForm>
  )
}
