import { Chip, makeStyles, Typography } from '@material-ui/core'
import React, { useState } from 'react'
import type { Identifier } from 'react-admin'
import {
  useTranslate,
  useNotify,
  useRecordContext,
  useUpdate,
} from 'react-admin'
import { MultipleResourcePicker } from 'src/components/Picker'
import { RichDataList } from 'src/components/RichDataList'
import { SfmReferenceArrayField } from 'src/components/SfmFields'

import type { User, Client } from 'src/types/api'
import { ResourceEnum } from 'src/types/api/resources'
import { AddIcon } from 'src/UI/theme/icons'

const useStyles = makeStyles(function (theme) {
  return {
    container: {
      display: 'flex',
      gap: theme.spacing(0.5),
    },
    chip: {
      overflow: 'visible',
    },
  }
})

interface Props {
  readonly?: boolean
}

export function UserList(props: Props) {
  const { readonly } = props
  const client = useRecordContext<Client>()
  const __ = useTranslate()
  const notify = useNotify()

  const [usersIds, setUserIds] = useState<Identifier[]>(client.users)

  const [update, { loading }] = useUpdate()
  const save = (values: User[]) => {
    const selectedIds = values.map((user) => user.id)
    if (usersIds.join() !== selectedIds.join()) {
      update(
        ResourceEnum.clients,
        client.id,
        {
          users: selectedIds,
        },
        {},
        {
          onSuccess: function (res) {
            setUserIds(res.data.users)
            notify('notifications.update.client', 'success')
          },
          onFailure: function (error) {
            notify(error, 'error')
          },
        },
      )
    }
  }

  const styles = useStyles()

  return (
    <SfmReferenceArrayField
      actions={
        !readonly && (
          <MultipleResourcePicker<User>
            saving={loading}
            resource={ResourceEnum.users}
            selectedIds={usersIds}
            label="actions.add"
            title="actions.add_users"
            getOptionTitle={(user) => `${user.firstName} ${user.lastName}`}
            getOptionSubtitle={(user) => user.emailAddress ?? ''}
            onSave={save}
            Icon={<AddIcon />}
          />
        )
      }
      label="resource.clients.users"
      record={{
        ...client,
        users: usersIds,
      }}
      source="users"
      reference="users"
    >
      <RichDataList<User>
        title={(user) => `${user.firstName} ${user.lastName}`}
        subtitle={(user) => {
          return (
            <div className={styles.container}>
              {user.roles.map((role: string) => (
                <Chip
                  key={role}
                  label={
                    <Typography variant="body2">
                      {__(`apiRoles.${role}`)}
                    </Typography>
                  }
                  size="small"
                  classes={{ label: styles.chip }}
                />
              ))}
            </div>
          )
        }}
      />
    </SfmReferenceArrayField>
  )
}
