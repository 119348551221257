import { SfmEdit } from 'src/AppScopes/resource'
import type { SfmEditProps } from 'src/AppScopes/resource'
import { joinStrings } from 'src/libs/joinStrings'

import type { User } from 'src/types/api'

import { Form } from './Form'

export function Edit(props: SfmEditProps) {
  return (
    <SfmEdit<User>
      {...props}
      getName={(user) => joinStrings([user.firstName, user.lastName], ' ')}
    >
      <Form />
    </SfmEdit>
  )
}
