import { makeStyles } from '@material-ui/core'
import { RulesTitle } from 'src/theme/mixins'

export const useStyles = makeStyles(function (theme) {
  const padding = theme.spacing(2)

  return {
    mainContainer: {
      padding,

      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(1),
    },
    dynamicRules: {
      color: theme.palette.grey['800'],
      ...RulesTitle(),
    },
    header: {
      display: 'flex',
      flexDirection: 'row',
      gap: theme.spacing(1),
      alignItems: 'flex-start',
      wordWrap: 'break-word',
    },

    headerContent: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(1),
      flex: 1,
    },

    deleteButton: {
      flex: 0,
      padding: theme.spacing(1),
      minHeight: theme.spacing(1 + 3 + 1),
      minWidth: theme.spacing(1 + 3 + 1),
      marginTop: -1 * theme.spacing(1),
      marginRight: -1 * theme.spacing(1),
    },

    input: {
      minWidth: theme.spacing(8),
    },
  }
})
