import { makeStyles } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import type { AxiosResponse } from 'axios'
import { useState } from 'react'
import { ReferenceField, useRecordContext, useTranslate } from 'react-admin'
import { useHttpClient } from 'src/adapters/HTTPClient'
import { CustomDatagrid } from 'src/components/CustomDatagrid'
import { FullNameField } from 'src/components/FullNameField'
import { SingleResourcePicker } from 'src/components/Picker'
import { Section } from 'src/components/Section'
import {
  SfmBooleanField,
  SfmDateField,
  SfmTextField,
} from 'src/components/SfmFields'
import StatusField from 'src/components/StatusField'
import { formatApiPlatformId } from 'src/libs/apiPlatformId'
import { useGetList } from 'src/libs/useGetList'
import type { Intervention, User, WorkOrder } from 'src/types/api'
import { ResourceEnum } from 'src/types/api/resources'
import { AddIcon } from 'src/UI/theme/icons'

interface Props {
  isManager?: boolean
}

export function InterventionList(props: Props) {
  const { isManager } = props
  const workOrder = useRecordContext<WorkOrder>()
  const { recordMap, ids, loaded, refetch } = useGetList<Intervention>(
    ResourceEnum.interventions,
    {
      filter: {
        'workOrder.id': workOrder.id,
        showValidated: true,
      },
    },
  )

  return (
    <Section label="resource.work_orders.interventions">
      {isManager && <AssignOperatorCascad refetch={refetch} />}
      <CustomDatagrid
        data={recordMap}
        ids={ids}
        loaded={loaded}
        currentSort={{ field: 'id', order: 'ASC' }}
        rowClick="show"
        basePath="/interventions"
      >
        <SfmTextField source="name" label="resource.interventions.name" />
        <SfmTextField
          source="location"
          label="resource.interventions.location"
        />
        <SfmDateField source="start" label="resource.interventions.start" />
        <ReferenceField
          source="operator"
          reference="users"
          label="resource.interventions.operator"
        >
          <FullNameField />
        </ReferenceField>

        <ReferenceField
          source="project"
          reference="projects"
          label="resource.interventions.project"
        >
          <SfmTextField source="name" />
        </ReferenceField>

        <StatusField source="status" label="resource.interventions.status" />
        <SfmBooleanField
          source="isRecurrent"
          label="resource.interventions.recurring"
        />
      </CustomDatagrid>
    </Section>
  )
}

interface Response {
  nb_interventions_already_assigned_to_this_user: number
  nb_interventions_newly_assigned_to_this_user: number
  nb_interventions_where_entitlements_doesnt_match: number
}

interface Result {
  alreadyAssigned: number
  newlyAssigned: number
  notAssigned: number
}

const useStyles = makeStyles(function () {
  return {
    button: {
      maxWidth: '60ch',
    },
  }
})

interface AssignOperatorProps {
  refetch: () => void
}

function AssignOperatorCascad(props: AssignOperatorProps) {
  const { refetch } = props
  const workOrder = useRecordContext<WorkOrder>()
  const __ = useTranslate()

  const [result, setResult] = useState<Result>()

  const httpClient = useHttpClient()
  const handleSubmit = (operator?: User) => {
    setResult(undefined)
    if (!operator) {
      return
    }

    httpClient
      .get(
        `/work_orders/${formatApiPlatformId(
          workOrder.id,
        )}/assign_operator/${formatApiPlatformId(operator.id)}`,
      )
      .then((res: AxiosResponse<Response>) => {
        refetch()
        const {
          nb_interventions_already_assigned_to_this_user: alreadyAssigned,
          nb_interventions_newly_assigned_to_this_user: newlyAssigned,
          nb_interventions_where_entitlements_doesnt_match: notAssigned,
        } = res.data
        setResult({
          alreadyAssigned,
          newlyAssigned,
          notAssigned,
        })
      })
  }

  const styles = useStyles()

  if (workOrder.interventions.length === 0) return null
  return (
    <>
      <SingleResourcePicker<User>
        Icon={<AddIcon />}
        label="resource.work_orders.assign_operator"
        resource={ResourceEnum.operators}
        title="actions.assign_operator"
        getOptionTitle={(operator) =>
          `${operator.firstName} ${operator.lastName}`
        }
        onSave={handleSubmit}
        className={styles.button}
      />
      {result && (
        <>
          {result.alreadyAssigned > 0 && (
            <Alert severity="info">
              {__('resource.work_orders.already_assigned', {
                smart_count: result.alreadyAssigned,
              })}
            </Alert>
          )}
          {result.newlyAssigned > 0 && (
            <Alert severity="success">
              {__('resource.work_orders.newly_assigned', {
                smart_count: result.newlyAssigned,
              })}
            </Alert>
          )}

          {result.notAssigned > 0 && (
            <Alert severity="warning">
              {__('resource.work_orders.not_assigned', {
                smart_count: result.notAssigned,
              })}
            </Alert>
          )}
        </>
      )}
    </>
  )
}
