import ellipsize from 'ellipsize'
import { SfmList } from 'src/AppScopes/resource'
import type { SfmListProps } from 'src/AppScopes/resource'
import { CustomDatagrid } from 'src/components/CustomDatagrid'
import { SfmFunctionField, SfmTextField } from 'src/components/SfmFields'
import type { Project } from 'src/types/api'
import { RoleEnum } from 'src/UserIdentity'

export function List(props: SfmListProps) {
  return (
    <SfmList {...props} acceptedEditRoles={[RoleEnum.ROLE_CONFIGURATOR]}>
      <CustomDatagrid>
        <SfmTextField source="name" label="resource.projects.name" />
        <SfmFunctionField
          label="resource.projects.description"
          render={(project) => {
            return ellipsize((project as Project).description, 120)
          }}
        />
      </CustomDatagrid>
    </SfmList>
  )
}
