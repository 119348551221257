import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import type { PropsWithChildren } from 'react'
import { useRecordContext, useResourceContext, useTranslate } from 'react-admin'

type RecordGetter<T extends Record<string, any>> = (record: T) => any
interface ShowLayoutProps<T extends Record<string, any>> {
  caption?: string | RecordGetter<T>
  title?: string | RecordGetter<T>
  subtitle?: string | RecordGetter<T>
  meta?: string | RecordGetter<T>
}
export function ShowLayout<T extends Record<string, any>>(
  props: PropsWithChildren<ShowLayoutProps<T>>,
) {
  const resourceName = useResourceContext()
  const record = useRecordContext<T>()
  const { caption, title, subtitle, meta, children } = props
  const translate = useTranslate()
  const getText = (text: string | RecordGetter<T> | undefined) => {
    if (!text) {
      return
    }
    if (typeof text === 'string') {
      return translate(text)
    }
    return text?.(record)
  }
  return (
    <Box pb={4}>
      <Typography
        variant="caption"
        display="block"
        color="secondary"
        gutterBottom
      >
        {caption
          ? getText(caption)
          : resourceName && translate(`resourceNames.${resourceName}`)}
      </Typography>
      <Typography variant="h1" gutterBottom>
        {getText(title)}
      </Typography>
      <Typography variant="h2" gutterBottom>
        {getText(subtitle)}
      </Typography>
      <Typography variant="h3" gutterBottom>
        {getText(meta)}
      </Typography>
      <Box display="flex" flexDirection="column" gridGap={3 * 8} pt={3}>
        {children}
      </Box>
    </Box>
  )
}
