import { IconButton } from '@material-ui/core'
import type { Case, Eval, Rule } from '@nartex/sfm-form-engine'
import { isExpr, func } from '@nartex/sfm-form-engine'
import XIcon from '@nartex/smartforest-design-tokens/graphics/react/BxBxX'
import type { ProfunctorState } from '@staltz/use-profunctor-state'
import classNames from 'classnames'
import type { FunctionComponent } from 'react'
import type { DropResult } from 'react-beautiful-dnd'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import type { MessageDescriptor } from 'react-intl'
import { defineMessage } from 'react-intl'
import { useFields } from 'src/FieldsContext'
import { caseHasError } from 'src/Validation/ruleValidation'

import { useTranslate } from '../adapters/I18nProvider'

import { encodeArguments } from './argumentsValuesUtils'
import { OperatorComponent, OperatorSelect } from './OperatorComponent'
import type { OperatorNames } from './operators'
import { OperatorMap } from './operators'
import type { PropertyValueToggleProps } from './PropertyValueToggle'

import { useEditorStyle } from './useEditorStyle'

interface ConditionsListEditorProps {
  propertyStore: ProfunctorState<boolean | Rule | Eval | undefined>
  PropertyToggle: FunctionComponent<PropertyValueToggleProps>
}
export function ConditionsListEditor(props: ConditionsListEditorProps) {
  const { propertyStore, PropertyToggle } = props
  const conditionsStore = propertyStore.promap<Case[] | undefined>({
    get(property) {
      if (isExpr(property) && property.type === 'rule') {
        return property.cases
      }
      return undefined
    },
    set(newValue, property) {
      if (newValue && isExpr(property) && property.type === 'rule') {
        if (newValue.length === 0) {
          return property.default
        }
        return { ...property, cases: newValue }
      }
      return property
    },
  })

  const { state: conditions } = conditionsStore

  if (!conditions) return null

  function dragEnd(result: DropResult) {
    if (!result.destination) return
    if (result.destination.index === result.source.index) return
    if (!conditions) return
    const updatedRulesList = reorder(
      conditions,
      result.source.index,
      result.destination.index,
    )
    conditionsStore.setState(() => updatedRulesList)
  }

  function reorder(list: Case[], startIndex: number, endIndex: number): Case[] {
    const sortedRules = Array.from(list)
    const [removed] = sortedRules.splice(startIndex, 1)
    sortedRules.splice(endIndex, 0, removed)
    return sortedRules
  }
  return (
    <DragDropContext onDragEnd={dragEnd}>
      <Droppable droppableId="list">
        {(provided) => (
          <div ref={provided.innerRef} {...provided.droppableProps}>
            {conditions.map((item, index) => (
              <Draggable
                draggableId={index.toString()}
                key={index}
                index={index}
              >
                {(provid) => (
                  <div
                    ref={provid.innerRef}
                    {...provid.draggableProps}
                    {...provid.dragHandleProps}
                  >
                    <ConditionEditor
                      key={index}
                      conditionsStore={conditionsStore}
                      PropertyToggle={PropertyToggle}
                      index={index}
                    />
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  )
}
interface ConditionEditorProps {
  conditionsStore: ProfunctorState<Case[] | undefined>
  PropertyToggle: FunctionComponent<PropertyValueToggleProps>
  index: number
}

function ConditionEditor(props: ConditionEditorProps) {
  const { conditionsStore, PropertyToggle, index } = props
  const ruleStyles = useEditorStyle()
  const __ = useTranslate()

  const conditionStore = conditionsStore.promap<Case | undefined>({
    get(conditionlist) {
      return conditionlist?.[index]
    },
    set(newCondition, prevConditions) {
      if (!prevConditions) return
      if (!newCondition) {
        return prevConditions.filter(
          (_, conditionIndex) => conditionIndex !== index,
        )
      }
      const result = [...prevConditions]
      result[index] = newCondition
      return result
    },
  })

  const { state: condition, setState } = conditionStore

  const fields = useFields()

  if (!condition) return null

  const fieldNames = new Set(fields.map((fieldNode) => fieldNode.data.name))
  const hasError = caseHasError(condition, fieldNames, fields)

  const toggleChange = (value: boolean) => {
    setState((prevCase) => {
      if (!prevCase) return
      return {
        ...prevCase,
        then: value,
      }
    })
  }

  const whenMessage: MessageDescriptor =
    index === 0
      ? defineMessage({
          id: 'RuleEditor.firstCondition.if',
          defaultMessage: 'quand',
          description: 'Text describing the first condition',
        })
      : defineMessage({
          id: 'RuleEditor.condition.if',
          defaultMessage: 'sinon quand',
          description: 'Text describing a condition',
        })

  function setOperator(operatorName: OperatorNames) {
    setState((prevCase) => {
      if (!prevCase) return
      const operator = OperatorMap[operatorName]
      return {
        ...prevCase,
        condition: {
          ...func(operatorName),
          args: encodeArguments({}, operator),
        },
      }
    })
  }
  return (
    <div
      className={classNames(ruleStyles.condition, hasError && ruleStyles.error)}
    >
      <span className={ruleStyles.case}>
        {__(whenMessage)}
        {condition.condition ? (
          <OperatorComponent
            condition={condition.condition}
            caseStore={conditionStore}
          />
        ) : (
          <OperatorSelect onSelect={setOperator} />
        )}
      </span>
      <span className={ruleStyles.then}>
        {__({
          id: 'RuleEditor.condition.then',
          defaultMessage: 'alors',
          description: 'Text displaying the result of a condition',
        })}
        <PropertyToggle
          value={condition.then ?? false}
          onChange={toggleChange}
        />
      </span>
      <IconButton
        className={ruleStyles.deleteButtonIcon}
        onClick={() => setState((_) => undefined)}
      >
        <XIcon />
      </IconButton>
    </div>
  )
}
