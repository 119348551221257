import {
  EditButton,
  Loading,
  RecordContextProvider,
  TopToolbar,
} from 'react-admin'
import { useDefineAppLocation } from 'src/libs/useDefineAppLocation'

import { PageLayout } from 'src/UI/PageLayout'
import { RoleEnum, usePersona } from 'src/UserIdentity'

import { ShowFields } from './showFields'

export function ClientInformation() {
  const { client, role } = usePersona()

  useDefineAppLocation('client.information')

  if (client) {
    return (
      <PageLayout>
        <RecordContextProvider value={client}>
          <TopToolbar>
            {role === RoleEnum.ROLE_ADMIN && client.id && (
              <EditButton
                basePath="/clients"
                record={{ ...client, id: client.id }}
              />
            )}
          </TopToolbar>
          <ShowFields readonly />
        </RecordContextProvider>
      </PageLayout>
    )
  }

  return <Loading />
}
