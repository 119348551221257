import { makeStyles, useTheme } from '@material-ui/core'
import type { DatagridProps } from 'react-admin'
import { Datagrid } from 'react-admin'

import { FullBleed } from './FullBleed'

import { EmptyText } from './SfmFields'

const useStyles = makeStyles(function () {
  return {
    headerCell: {
      fontWeight: 'bold',
      lineHeight: 1.1,
    },
  }
})

interface Props<T> extends DatagridProps {
  isRowDisabled?: (record: T) => any
}

export function CustomDatagrid<
  T extends Record<string, any> = Record<string, any>,
>(props: Props<T>) {
  const {
    children,
    isRowDisabled = (record: any) => record.isArchived,
    ...datagridProps
  } = props
  const classes = useStyles()

  const theme = useTheme()

  return (
    <FullBleed>
      <Datagrid
        rowClick="show"
        classes={{ headerCell: classes.headerCell }}
        rowStyle={(record) => {
          if (isRowDisabled(record as unknown as T)) {
            return {
              color: theme.palette.grey[400],
            }
          }
        }}
        empty={<EmptyText />}
        {...datagridProps}
      >
        {children}
      </Datagrid>
    </FullBleed>
  )
}
