import { stringifyUrl } from 'query-string'
import { linkToRecord } from 'react-admin'
import { formatApiPlatformId } from 'src/libs/apiPlatformId'
import { usePersona } from 'src/UserIdentity'
import type { Persona } from 'src/UserIdentity'

interface CreateResourceUrlFunc {
  (
    resourceName: string,
    id: string | number,
    type: 'show' | 'edit',
    params?: Record<string, any>,
  ): string
  (resourceName: string, type: 'create', params?: Record<string, any>): string
  (resourceName: string, params?: Record<string, any>): string
}

export function createResourceUrlImpl(
  persona: Persona,
  resourceName: string,
  id: string | number,
  type: 'show' | 'edit',
  query?: Record<string, any>,
): string
export function createResourceUrlImpl(
  persona: Persona,
  resourceName: string,
  type: 'create',
  query?: Record<string, any>,
): string
export function createResourceUrlImpl(
  persona: Persona,
  resourceName: string,
  query?: Record<string, any>,
): string
export function createResourceUrlImpl(
  persona: Persona,
  resourceName: string,
  id?: any,
  type?: 'show' | 'edit' | Record<string, any>,
  query?: Record<string, any>,
): string {
  if (id === 'create') {
    const queryParams = {
      ...persona,
      ...(type as Record<string, any>),
    }
    return stringifyUrl({
      url: `/${resourceName}/create/`,
      query: {
        ...queryParams,
        clientId: formatApiPlatformId(queryParams.clientId),
      },
    })
  }

  if (id && typeof id === 'string') {
    const queryParams = {
      ...persona,
      ...query,
    }
    return stringifyUrl({
      url: `/${linkToRecord(resourceName, id, type as string)}/`,
      query: {
        ...queryParams,
        clientId: formatApiPlatformId(queryParams.clientId),
      },
    })
  }

  const queryParams = {
    ...persona,
    ...id,
  }
  return stringifyUrl({
    url: `/${resourceName}/`,
    query: {
      ...queryParams,
      clientId: formatApiPlatformId(queryParams.clientId),
    },
  })
}

export function useCreateResourceUrl(): CreateResourceUrlFunc {
  const { role, clientId } = usePersona()

  return function createResourceUrl(
    resourceName: string,
    id?: any,
    type?: 'show' | 'edit' | Record<string, any>,
    params?: Record<string, any>,
  ): string {
    return createResourceUrlImpl(
      { role, clientId },
      resourceName,
      id,
      type as any,
      params,
    )
  }
}
