import type { Spec } from '@nartex/sfm-form-engine'
import { DummyFormProvider } from '@nartex/sfm-form-engine'

import classNames from 'classnames'
import { useCallback } from 'react'
import type { DroppableProvided } from 'react-beautiful-dnd'
import { Droppable } from 'react-beautiful-dnd'

import { AreaNameEnum } from '../DragDropChoregrapher'

import { DraggableField } from './DraggableField'
import { useStyles } from './styles'

interface Props {
  className?: string
  fields: Spec['fields']
  setSelectedFieldId: (id: string | null) => void
  selectedFieldId: string | null
}

export function Canvas(props: Props) {
  const { className, fields, setSelectedFieldId, selectedFieldId } = props

  const styles = useStyles()

  const droppableRenderer = useCallback(
    (provided: DroppableProvided) => {
      const { innerRef, droppableProps, placeholder } = provided

      return (
        <ul
          className={classNames(styles.elements)}
          ref={innerRef}
          {...droppableProps}
        >
          {/* TODO render an initial state with a few components, explaining what to do */}
          {fields.map((field, index) => {
            return (
              <DraggableField
                index={index}
                field={field as any}
                key={field.data['@id']}
                onSelect={() => setSelectedFieldId(field.data['@id'])}
                selected={selectedFieldId === field.data['@id']}
              />
            )
          })}

          {placeholder}
        </ul>
      )
    },
    [styles.elements, fields, setSelectedFieldId, selectedFieldId],
  )

  return (
    <DummyFormProvider>
      <section
        className={classNames(styles.mainContainer, className)}
        onClick={(event) => {
          if (event.defaultPrevented) {
            return
          }

          // it's a bit dangerous to rely on some implementation details like that
          // but I did not take the time to search for a better solution yet
          const isDraggableElement = (event.target as HTMLElement).hasAttribute(
            'draggable',
          )
          if (!isDraggableElement) {
            setSelectedFieldId(null)
          }
        }}
      >
        <Droppable droppableId={AreaNameEnum.CANVAS}>
          {droppableRenderer}
        </Droppable>
      </section>
    </DummyFormProvider>
  )
}
