import type { AxiosInstance, AxiosRequestConfig } from 'axios'
import type {
  CreateParams,
  DataProvider as RaDataProvider,
  DeleteManyParams,
  DeleteParams,
  DeleteResult,
  GetListParams,
  GetListResult,
  GetManyParams,
  GetManyReferenceParams,
  GetOneParams,
  Record as RaRecord,
  UpdateManyParams,
  UpdateParams,
} from 'react-admin'
import { formatApiPlatformId } from 'src/libs/apiPlatformId'
import spec from 'src/types/api/spec.json'

import { requestBuilder } from './requestBuilder'
import type { HydraCollection, HydraItem, WithHeaders } from './types'

interface DataProviderConfig {
  httpClient: AxiosInstance
}

export function DataProvider(config: DataProviderConfig): RaDataProvider {
  const { httpClient } = config

  function request(requestConfig: AxiosRequestConfig) {
    return httpClient.request({
      ...requestConfig,
      headers: {
        ...requestConfig.headers,
        'X-Sfm-ApiVersion': spec.info.version,
        'X-Sfm-NoDeletedAt': 'true',
      },
    })
  }

  return {
    async getList<RecordType extends RaRecord = RaRecord>(
      resource: string,
      options: GetListParams & WithHeaders,
    ) {
      const { data } = await request(
        requestBuilder.getList(resource, processListParams(options)),
      )
      return processListResponse<RecordType>(data)
    },

    async getOne(resource, options: GetOneParams & WithHeaders) {
      const { data } = await request(requestBuilder.getOne(resource, options))
      return { data: processHydraItem(data) }
    },

    async getMany<RecordType extends RaRecord = RaRecord>(
      resource: string,
      options: GetManyParams & WithHeaders,
    ) {
      const { ids } = options

      const responses = await Promise.all(
        ids.map((id) => this.getOne<RecordType>(resource, { ...options, id })),
      )
      const data = responses.map((x) => x.data).filter(Boolean)
      return { data, total: data.length }
    },

    async getManyReference<RecordType extends RaRecord = RaRecord>(
      resource: string,
      options: GetManyReferenceParams & WithHeaders,
    ) {
      const { data } = await request(
        requestBuilder.getManyReference(resource, processListParams(options)),
      )
      return processListResponse<RecordType>(data)
    },

    async update(resource, options: UpdateParams & WithHeaders) {
      const { data } = options
      const response = await request(
        requestBuilder.update(resource, {
          ...options,
          data: { ...data, q: undefined },
        }),
      )
      return { data: response.data }
    },

    async updateMany(resource, options: UpdateManyParams & WithHeaders) {
      const { ids, data } = options

      await Promise.all(
        ids.map((id) =>
          this.update(resource, { ...options, id, data, previousData: { id } }),
        ),
      )
      return { data: ids }
    },

    async create(resource, options: CreateParams & WithHeaders) {
      const { data } = options

      const createOptions = data.id
        ? {
            ...options,
            data: { ...data, id: undefined },
            headers: {
              ...options.headers,
              'X-CREATED-ID': formatApiPlatformId(data.id),
            },
          }
        : options

      const response = await request(
        requestBuilder.create(resource, createOptions),
      )
      return { data: { ...response.data, id: response.data['@id'] } }
    },

    async delete<RecordType extends RaRecord = RaRecord>(
      resource: string,
      options: DeleteParams & WithHeaders,
    ): Promise<DeleteResult<RecordType>> {
      await request(requestBuilder.delete(resource, options))
      return {
        data: {
          ...options.previousData,
          id: options.id,
        } as RecordType,
      }
    },

    async deleteMany(resource, options: DeleteManyParams & WithHeaders) {
      const { ids } = options

      const results = await Promise.all(
        ids.map((id) =>
          this.delete(resource, { ...options, id, previousData: { id } }),
        ),
      )

      function isDefined<T>(item: T | undefined): item is T {
        return Boolean(item)
      }

      return {
        data: results
          .map((deleteResult) => deleteResult?.data?.id)
          .filter(isDefined),
      }
    },
  }
}

function processListParams<T extends GetListParams>(options: T): T {
  const hasDefaultSort = options.sort.field === 'id'

  return {
    ...options,
    sort: hasDefaultSort ? { field: 'updatedAt', order: 'DESC' } : options.sort,
    filter: {
      'exists[deletedAt]': false,
      ...options.filter,
    },
  }
}

export function processListResponse<RecordType extends RaRecord = RaRecord>(
  response: HydraCollection,
): GetListResult<RecordType> {
  return {
    data: response['hydra:member'].map(
      processHydraItem,
    ) as unknown as RecordType[],
    total: response['hydra:totalItems'],
  }
}

function processHydraItem<RecordType extends RaRecord = RaRecord>(
  item: HydraItem,
): RecordType {
  return { ...item, id: item['@id'] ?? item.id } as unknown as RecordType
}
