import { Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import type { PropsWithChildren } from 'react'
import { useAppScope } from 'src/AppScopes'
import { ProtectedPage } from 'src/components/ProtectedPage'
import type { RoleEnum } from 'src/UserIdentity'
import { getAcceptedRoles, usePersona, RoleSwitcher } from 'src/UserIdentity'

import { BreadCrumbs } from './BreadCrumbs'
import { border } from './theme'
import { rulerStroke } from './theme/mixins'

const useStyles = makeStyles(function (theme) {
  return {
    innerHeader: {
      display: 'flex',
      flexDirection: 'row',
      borderBottom: border,
      paddingLeft: `${theme.spacing(4)}px`,
      paddingRight: `${theme.spacing(2)}px`,
      minHeight: theme.spacing(6),
      background: theme.palette.background.default,
      zIndex: 20,
    },

    roleSwitcher: {
      marginLeft: 'auto',
    },

    content: {
      overflowX: 'hidden',

      '& > :last-child::before': {
        ...rulerStroke(theme, 'vertical'),
        zIndex: 10,
      },
    },
  }
})

type Props = PropsWithChildren<{
  acceptedRoles?: RoleEnum[]
}>

export function PageLayout(props: Props) {
  const { children, acceptedRoles } = props

  const styles = useStyles()
  const persona = usePersona()
  const defaultAcceptedRoles = getAcceptedRoles(useAppScope())

  return (
    <ProtectedPage acceptedRoles={acceptedRoles ?? defaultAcceptedRoles}>
      <Box className={styles.innerHeader}>
        {/* TODO : implement breadcrumbs that do not depend on AppLocationContext, but simply using props */}
        <BreadCrumbs />
        <RoleSwitcher
          role={persona.role!}
          acceptedRoles={acceptedRoles ?? defaultAcceptedRoles}
          className={styles.roleSwitcher}
        />
      </Box>
      <Box padding={4} className={styles.content}>
        {children}
      </Box>
    </ProtectedPage>
  )
}
