import { makeStyles } from '@material-ui/core'
import type { SimpleFormProps, ToolbarProps } from 'react-admin'
import { SaveButton, Button, SimpleForm, Toolbar } from 'react-admin'
import { useHistory } from 'react-router'
import { BackArrow } from 'src/UI/theme/icons'

const useStyle = makeStyles(function (theme) {
  return {
    root: {
      justifyContent: 'end',
      gap: theme.spacing(1),
    },
  }
})

function FormToolbar(props: ToolbarProps) {
  const style = useStyle()
  const history = useHistory()
  return (
    <Toolbar {...props} className={style.root}>
      <Button
        label="actions.back"
        variant="contained"
        color="inherit"
        onClick={history.goBack}
      >
        <BackArrow />
      </Button>
      <SaveButton disabled={props.pristine} />
    </Toolbar>
  )
}

export function SfmSimpleForm(props: SimpleFormProps) {
  return <SimpleForm toolbar={<FormToolbar />} redirect="show" {...props} />
}
