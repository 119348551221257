import type { SfmEditProps } from 'src/AppScopes/resource'
import { SfmEdit } from 'src/AppScopes/resource'
import type { WorkOrder } from 'src/types/api'
import { RoleEnum } from 'src/UserIdentity'

import { WorkOrderForm } from './Form'

export function Edit(props: SfmEditProps) {
  return (
    <SfmEdit<WorkOrder>
      {...props}
      getName={(workOrder) => workOrder.reference || ''}
      acceptedRoles={[RoleEnum.ROLE_CONFIGURATOR]}
    >
      <WorkOrderForm />
    </SfmEdit>
  )
}
