import { Typography, Checkbox } from '@material-ui/core'
import classNames from 'classnames'
import { useMemo, useState } from 'react'

import { useTranslate } from '../adapters/I18nProvider'
import { definitions } from '../ComponentsLibrary/definitions'
import { useFields } from '../FieldsContext'
import type { Variable } from '../libs/fieldNamesUtils'
import {
  displayFieldName,
  fieldsToVariablesList,
} from '../libs/fieldNamesUtils'
import { QrCodeGenerator } from '../QrCodeGenerator'

import { EmptyMessage } from './EmptyMessage'
import { useStyles } from './styles'

export function VariablesList() {
  const style = useStyles()
  const __ = useTranslate()

  const fields = useFields()

  const variablesList = fieldsToVariablesList(fields)
  const [selectedVariables, setSelectedVariables] = useState<Set<string>>(
    new Set([]),
  )

  const selectedFields = useMemo(() => {
    return fields.filter((field) => selectedVariables.has(field.data['@id']))
  }, [selectedVariables, fields])

  const onClick = (
    event: React.ChangeEvent<HTMLInputElement>,
    variable: Variable,
  ) => {
    const newCheckedState = event.target.checked
    if (newCheckedState) {
      return setSelectedVariables(
        (previousState) => new Set(previousState.add(variable.id)),
      )
    }
    setSelectedVariables((previousState) => {
      previousState.delete(variable.id)
      return new Set(previousState)
    })
  }

  if (fields.length === 0) {
    return (
      <section className={classNames(style.mainContainer)}>
        <EmptyMessage />
      </section>
    )
  }

  return (
    <section className={classNames(style.mainContainer, style.listContainer)}>
      <ul className={style.list}>
        {variablesList.map((variable, index) => {
          const definition = definitions[variable.type]
          return (
            <li key={index} className={classNames(style.listItem)}>
              <Checkbox
                checked={selectedVariables.has(variable.id)}
                onChange={(e) => onClick(e, variable)}
                disabled={!variable.selectable}
              />

              {definition.icon}
              <div className={style.listItemContent}>
                <Typography
                  variant="caption"
                  title={variable.name}
                  className={style.varName}
                >
                  {displayFieldName(variable.name)}
                </Typography>

                <Typography className={style.fieldLabel} variant="body2">
                  {variable.label} ({__(definition.displayName)})
                </Typography>
              </div>
            </li>
          )
        })}
      </ul>
      <QrCodeGenerator fields={selectedFields} />
    </section>
  )
}
