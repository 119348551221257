import type { Spec, InputName, FieldNode } from '@nartex/sfm-form-engine'
import type { SetState } from '@staltz/use-profunctor-state'
import { insert } from 'ramda'
import { useCallback } from 'react'
import type { PropsWithChildren } from 'react'
import type {
  DropResult,
  DraggableLocation,
  DragStart,
} from 'react-beautiful-dnd'
import { DragDropContext } from 'react-beautiful-dnd'
import { getDefaultFieldData } from 'src/ComponentsLibrary/defaults'
import { v4 as uuidV4 } from 'uuid'

import { useTranslate } from '../adapters/I18nProvider'
import { definitions } from '../ComponentsLibrary/definitions'
import type { Variable } from '../libs/fieldNamesUtils'
import { getNewFieldName } from '../libs/fieldNamesUtils'

export enum AreaNameEnum {
  'LIBRARY' = 'LIBRARY',
  'CANVAS' = 'CANVAS',
}

interface Props {
  onChange: SetState<Spec['fields']>
  setSelectedFieldId: (id: string | null) => void
  fieldNames: Variable[]
}

export function DragDropChoregrapher(props: PropsWithChildren<Props>) {
  const { children, onChange, setSelectedFieldId, fieldNames } = props
  const translate = useTranslate()

  const onDragEnd = useCallback(
    (result: DropResult) => {
      const { source, destination, draggableId } = result

      if (!destination) return

      const sourceArea = getAreaName(source)
      const destinationArea = getAreaName(destination)

      const isInsertion =
        sourceArea === AreaNameEnum.LIBRARY &&
        destinationArea === AreaNameEnum.CANVAS

      const isMove =
        sourceArea === AreaNameEnum.CANVAS &&
        destinationArea === AreaNameEnum.CANVAS

      if (isInsertion) {
        const definition = definitions[draggableId as InputName]
        const inserted: FieldNode = {
          type: draggableId as InputName,
          data: {
            ...getDefaultFieldData(draggableId as InputName),
            '@id': uuidV4(),
            ...getNewFieldName(translate(definition.displayName), fieldNames),
          },
        }

        setSelectedFieldId(inserted.data['@id'])
        return onChange((fields) => {
          return insert(destination.index, inserted, fields)
        })
      }

      if (isMove) {
        setSelectedFieldId(draggableId)
        return onChange((fields) => {
          return move(fields, source.index, destination.index)
        })
      }
    },
    [translate, onChange, setSelectedFieldId, fieldNames],
  )

  const onDragStart = useCallback(
    (dragStart: DragStart) => {
      const { source, draggableId } = dragStart
      const sourceArea = getAreaName(source)

      if (sourceArea === AreaNameEnum.CANVAS) {
        setSelectedFieldId(draggableId)
      }
    },
    [setSelectedFieldId],
  )

  return (
    <DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart}>
      {children}
    </DragDropContext>
  )
}

function move<T>(target: T[], fromIndex: number, toIndex: number): T[] {
  const result = Array.from(target)
  const [moved] = result.splice(fromIndex, 1)
  result.splice(toIndex, 0, moved)

  return result
}

function getAreaName(
  location: DraggableLocation | undefined,
): AreaNameEnum | undefined {
  if (!location) return undefined
  if (location.droppableId.startsWith(AreaNameEnum.LIBRARY)) {
    return AreaNameEnum.LIBRARY
  }
  if (location.droppableId === AreaNameEnum.CANVAS) {
    return AreaNameEnum.CANVAS
  }
}
