import type { Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/core'
import type { CreateCSSProperties } from '@material-ui/core/styles/withStyles'

import { border, shadow } from '../theme/mixins'

function listStyle(theme: Theme): CreateCSSProperties<{}> {
  return {
    margin: 0,
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
    listStyle: 'none',
    padding: 0,
  }
}

export const useStyles = makeStyles(function (theme) {
  const canvasGap = theme.spacing(3)
  const listGap = theme.spacing(1)

  const draggingStyle: CreateCSSProperties<{}> = {
    border: `1px solid ${theme.palette.text.primary}`,
    boxShadow: shadow(),

    // artificially increase element margin for better transitions on drop on canvas
    marginTop: canvasGap / 2,
    marginBottom: canvasGap / 2,
  }

  return {
    mainContainer: {
      ...listStyle(theme),
      padding: theme.spacing(2),
      paddingBottom: theme.spacing(8),
    },
    section: {
      ...listStyle(theme),
      gap: 0,
    },
    // cancel extra margin
    sectionLastElementHovered: {
      marginBottom: -canvasGap / 2 - listGap / 2,
    },
    draggableElement: {
      border: border(theme),
      background: theme.palette.background.default,
      padding: theme.spacing(2),
      display: 'flex',
      alignItems: 'center',
      gap: theme.spacing(1),
      marginTop: listGap / 2,
      marginBottom: listGap / 2,

      '&:hover': {
        ...draggingStyle,
        // cancel extra margin here too
        position: 'relative',
        top: listGap / 2 - canvasGap / 2,

        '& + *': {
          marginTop: -canvasGap / 2,
        },
      },
    },
    draggableElementDragging: {
      ...draggingStyle,
      border: `1px solid ${theme.palette.primary.main}`,
    },
    draggableElementClone: {
      ...draggingStyle,
      marginTop: listGap / 2,
      marginBottom: listGap / 2,
    },
  }
})
