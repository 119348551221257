import { Box, Typography } from '@material-ui/core'
import { Loading, useTranslate } from 'react-admin'
import { useGetIdentity, useRoles } from 'src/UserIdentity'

import { PageLayout } from './PageLayout'

export function Dashboard() {
  const __ = useTranslate()

  const { identity, loading } = useGetIdentity()
  const roles = useRoles()

  if (loading && !identity) return <Loading />

  const displayRoles = identity?.roles
    ?.map((role) => __(`apiRoles.${role}`))
    .join(', ')

  return (
    <PageLayout>
      <Typography
        variant="caption"
        display="block"
        color="secondary"
        gutterBottom
      >
        {displayRoles}
      </Typography>
      <Typography variant="h1" gutterBottom>
        {__('dashboard.title', { name: identity?.fullName })}
      </Typography>

      <Box display="flex" flexDirection="column" gridGap={8}>
        {roles.ROLE_ADMIN && (
          <Typography variant="body1" gutterBottom>
            {__('dashboard.administration')}
          </Typography>
        )}
        {(roles.ROLE_CONFIGURATOR || roles.ROLE_MANAGER) && (
          <Typography variant="body1" gutterBottom>
            {__('dashboard.management')}
          </Typography>
        )}
        {!roles.ROLE_ADMIN && !roles.ROLE_CONFIGURATOR && !roles.ROLE_MANAGER && (
          <Typography variant="body1" color="secondary" gutterBottom>
            {__('dashboard.unauthorized')}
          </Typography>
        )}
      </Box>
    </PageLayout>
  )
}
