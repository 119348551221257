import AddVideoIcon from '@nartex/smartforest-design-tokens/graphics/react/BxBxsCameraMovie'
import VideoIcon from '@nartex/smartforest-design-tokens/graphics/react/BxBxsVideos'

import type { BaseMediaInputProps } from '../types'

import { MediaInput } from './MediaInput'

export function VideoInput(props: BaseMediaInputProps) {
  return (
    <MediaInput
      {...props}
      mediaType="Video"
      Icon={VideoIcon}
      AddIcon={AddVideoIcon}
      renderMedia={(src) => (
        <video controls src={src} controlsList="nodownload" />
      )}
    />
  )
}
