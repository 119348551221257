import { BooleanInput } from 'react-admin'
import { SfmList } from 'src/AppScopes/resource'
import type { SfmListProps } from 'src/AppScopes/resource'
import { CustomDatagrid } from 'src/components/CustomDatagrid'
import { FullNameField } from 'src/components/FullNameField'
import { RolesField } from 'src/components/RolesField'
import { SfmDateField, SfmTextField } from 'src/components/SfmFields'
import type { User } from 'src/types/api'

export function List(props: SfmListProps) {
  return (
    <SfmList
      {...props}
      filters={[
        <BooleanInput
          key="isEnabled"
          label="filters.disabled_users"
          source="showDisabled"
        />,
      ]}
    >
      <CustomDatagrid<User> isRowDisabled={(user: User) => !user.isEnabled}>
        <FullNameField label="resource.users.fullname" />
        <SfmTextField
          source="personalNumber"
          label="resource.users.personalNumber"
        />
        <SfmTextField source="companyName" label="resource.users.companyName" />
        <RolesField source="roles" label="resource.users.roles" />
        <SfmDateField source="lastLogin" label="resource.users.lastLogin" />
      </CustomDatagrid>
    </SfmList>
  )
}
