import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core'

import { ReactComponent as CancelIcon } from '@nartex/smartforest-design-tokens/graphics/svg/bxBxX.svg'
import type { ReactNode } from 'react'
import React from 'react'
import type { Record } from 'react-admin'
import { Button, SaveButton } from 'react-admin'

import type { CustomFormProps } from './CustomForm'
import { CustomForm } from './CustomForm'

interface Props<T extends Record> extends Omit<CustomFormProps<T>, 'render'> {
  isLoading: boolean
  isOpen: boolean
  onClose: () => void
  title?: ReactNode
  style?: string
}
export function CustomFormDialog<T extends Record = Record>(
  props: React.PropsWithChildren<Props<T>>,
) {
  const { isOpen, onClose, children, title, isLoading, style, ...formProps } =
    props
  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      fullWidth
      classes={{ paper: style }}
    >
      {title && <DialogTitle>{title}</DialogTitle>}
      <CustomForm
        {...formProps}
        render={(formSubmit) => (
          <>
            <DialogContent>{children}</DialogContent>
            <DialogActions>
              <Button
                label="actions.cancel"
                onClick={onClose}
                disabled={isLoading}
              >
                <CancelIcon />
              </Button>
              <SaveButton
                handleSubmitWithRedirect={formSubmit}
                saving={isLoading}
                disabled={isLoading}
              />
            </DialogActions>
          </>
        )}
      />
    </Dialog>
  )
}
