import type { FieldNode, InputName } from '@nartex/sfm-form-engine'
import { PhotoSizeEnum, VideoQualityEnum } from '@nartex/sfm-form-engine'

export function getDefaultFieldData(
  type: InputName,
): Omit<FieldNode['data'], '@id' | 'name'> {
  if (type === 'Photo') {
    return {
      quality: PhotoSizeEnum.MEDIUM,
    }
  }
  if (type === 'Video') {
    return {
      quality: VideoQualityEnum.HIGH,
    }
  }

  return {}
}
