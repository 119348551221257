import type { SfmResourceProps } from 'src/AppScopes'
import { SfmList, SfmEdit, SfmCreate, SfmResource } from 'src/AppScopes'
import { CustomDatagrid } from 'src/components/CustomDatagrid'
import { SfmTextField } from 'src/components/SfmFields'
import { RoleEnum } from 'src/UserIdentity'

import { EntitlementForm } from '../Form'

import { Show } from './Show'

export function AutorisationResource(props: SfmResourceProps) {
  return (
    <SfmResource
      {...props}
      show={Show}
      list={(listProps) => (
        <SfmList
          {...listProps}
          acceptedEditRoles={[RoleEnum.ROLE_CONFIGURATOR]}
        >
          <CustomDatagrid>
            <SfmTextField source="name" label="resource.entitlements.name" />
          </CustomDatagrid>
        </SfmList>
      )}
      create={(createProps) => (
        <SfmCreate
          {...createProps}
          acceptedRoles={[RoleEnum.ROLE_CONFIGURATOR]}
        >
          <EntitlementForm />
        </SfmCreate>
      )}
      edit={(editProps) => (
        <SfmEdit {...editProps} acceptedRoles={[RoleEnum.ROLE_CONFIGURATOR]}>
          <EntitlementForm />
        </SfmEdit>
      )}
    />
  )
}
