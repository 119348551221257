import CreateIcon from '@nartex/smartforest-design-tokens/graphics/react/BxBxPlus'
import type { ShowActionsProps } from 'react-admin'
import { useRecordContext, Button, FunctionField } from 'react-admin'

import { Link } from 'react-router-dom'
import type { SfmShowProps } from 'src/AppScopes'
import { SfmShow } from 'src/AppScopes'
import { ArchiveButton } from 'src/components/ArchiveButton'
import { CustomDatagrid } from 'src/components/CustomDatagrid'
import { MinioFileField } from 'src/components/MinioFileField'
import { ShowActions } from 'src/components/ProtectedActions'
import {
  SfmDateField,
  SfmReferenceArrayField,
  SfmTextField,
} from 'src/components/SfmFields'
import { ShowLayout } from 'src/components/ShowLayout'
import type { IriReference, Report, ReportVersion } from 'src/types/api'
import { RoleEnum, usePersona } from 'src/UserIdentity'

import { useCreateResourceUrl } from '../useCreateResourceUrl'

export function Show(props: SfmShowProps) {
  const acceptedRoles = [
    RoleEnum.ROLE_MANAGER,
    RoleEnum.ROLE_CONFIGURATOR,
    RoleEnum.ROLE_ADMIN,
  ]
  return (
    <SfmShow
      {...props}
      acceptedEditRoles={acceptedRoles}
      actions={<ReportActions />}
    >
      <ShowLayout<Report> title={(report) => report.name}>
        <VersionList />
      </ShowLayout>
    </SfmShow>
  )
}

function VersionList() {
  const report = useRecordContext<Report>()

  return (
    <SfmReferenceArrayField
      label="resource.reports.reportVersions"
      actions={<CreateVersionLink reportId={report.id} />}
      source="reportVersions"
      reference="report_versions"
      sort={{
        field: 'version',
        order: 'DESC',
      }}
    >
      <CustomDatagrid rowClick="">
        <SfmTextField source="version" />
        <SfmDateField source="createdAt" label="resource.reports.updatedAt" />
        <FunctionField<ReportVersion>
          label="resource.reports.file"
          render={(reportVersion) => {
            if (!reportVersion) return null

            return (
              <MinioFileField
                fileName={reportVersion.fileName || ''}
                bucketId={reportVersion.report}
                title={`${report.name} v${reportVersion.version}`}
              />
            )
          }}
        />
      </CustomDatagrid>
    </SfmReferenceArrayField>
  )
}

interface Props {
  reportId: IriReference
}
function CreateVersionLink(props: Props) {
  const { reportId } = props
  const createUrl = useCreateResourceUrl()

  return (
    <Button
      component={Link}
      to={createUrl('report_versions', 'create', { reportId })}
      label="resource.reports.createReportVersion"
    >
      <CreateIcon />
    </Button>
  )
}

function ReportActions(props: ShowActionsProps) {
  const { data: report } = props
  const { role } = usePersona()

  const canArchive =
    role && [RoleEnum.ROLE_ADMIN, RoleEnum.ROLE_CONFIGURATOR].includes(role)

  const canBeDeleted = report && !reportHasRelation(report as Report)

  return (
    <ShowActions {...props} hasDelete={canBeDeleted}>
      {canArchive && report && (
        <ArchiveButton resource="reports" record={report} />
      )}
    </ShowActions>
  )
}

function reportHasRelation(report: Report): boolean {
  if (report.interventions.length > 0) return true
  if (report.forms.length > 0) return true
  return false
}
