import { SfmEdit } from 'src/AppScopes/resource'
import type { SfmEditProps } from 'src/AppScopes/resource'

import { RoleEnum } from 'src/UserIdentity'

import { Form } from './Form'

export function Edit(props: SfmEditProps & { isRound?: boolean }) {
  const { isRound = false } = props
  return (
    <SfmEdit {...props} acceptedRoles={[RoleEnum.ROLE_CONFIGURATOR]}>
      <Form isRound={isRound} />
    </SfmEdit>
  )
}
