/** https://marmelab.com/react-admin/Caching.html */
import type { DataProvider } from 'react-admin'
import { ResourceEnum } from 'src/types/api/resources'

const cacheEscapedResources: ResourceEnum[] = [
  ResourceEnum.projects,
  ResourceEnum.operators,
]

const cacheEscapedFilterQuery: Record<string, string[]> = {
  [ResourceEnum.interventions]: ['workOrder.id'],
}

export function cacheDataProviderProxy(
  dataProvider: DataProvider,
  duration = 1 * 60 * 1000,
) {
  return new Proxy(dataProvider, {
    get(_, name: any) {
      if (typeof name !== 'string') return dataProvider[name]
      return function wrappedMethod(resource: string, params: any) {
        if (
          ['getOne', 'getMany', 'getList'].includes(name) &&
          !cacheEscapedResources.includes(resource as ResourceEnum) &&
          !hasEscapedFilter(resource as ResourceEnum, params)
        ) {
          return dataProvider[name](resource, params).then(
            (response: Record<string, any>) => {
              const validUntil = new Date()
              validUntil.setTime(validUntil.getTime() + duration)

              response.validUntil = validUntil
              return response
            },
          )
        }

        return dataProvider[name](resource, params)
      }
    },
  })
}

function hasEscapedFilter(resource: ResourceEnum, params: any) {
  const filter = params?.filter
  if (!filter) return false

  const filterNameArray = Object.keys(filter)

  return cacheEscapedFilterQuery[resource]?.every((value) =>
    filterNameArray.includes(value),
  )
}
