import { IonButton } from '@ionic/react'
import ButtonArrowIcon from '@nartex/smartforest-design-tokens/graphics/react/ButtonArrow'

export function SubmitButton() {
  return (
    <IonButton expand="block" type="submit" color="primary">
      Envoyer <ButtonArrowIcon style={{ marginLeft: '1rem' }} />
    </IonButton>
  )
}
