import type { PropsWithChildren } from 'react'
import { useState } from 'react'
import type { Record as RaRecord } from 'react-admin'
import { useUpdate, useNotify, useRecordContext } from 'react-admin'

import type { LabeledFieldProps } from 'src/components/LabeledField'
import { SingleResourcePicker } from 'src/components/Picker'
import { SfmReferenceField } from 'src/components/SfmFields'
import type { Group, IriReference, User } from 'src/types/api'
import { ResourceEnum } from 'src/types/api/resources'
import { AddIcon, CreateIcon } from 'src/UI/theme/icons'

interface Props<T> {
  Icon?: LabeledFieldProps['Icon']
  label: string
  userSource: keyof T & string
  groupSource: keyof T & string
  resource: ResourceEnum
  userReference?: ResourceEnum
  record?: T

  editSuccessMessage: string
  userSearchFilter?: Partial<Record<keyof User, any>> & Record<string, any>

  readonly?: boolean

  refetchOnOpen?: boolean
}

export function EditableUserOrGroupField<T extends RaRecord>(
  props: PropsWithChildren<Props<T>>,
) {
  const {
    Icon,
    label,
    userSource,
    groupSource,
    userReference = ResourceEnum.users,
    children,
    readonly,
    resource,
    refetchOnOpen,
    editSuccessMessage,
    userSearchFilter,
  } = props

  const notify = useNotify()
  const contextRecord = useRecordContext<T>()

  const record = props.record ?? contextRecord

  const [update, { loading }] = useUpdate()
  const [selectedId, setSelectedId] = useState<IriReference | null>()
  const [selectedGroupId, setSelectedGroupId] = useState<IriReference | null>()

  const userValue = selectedId === undefined ? record[userSource] : selectedId
  const groupValue =
    selectedGroupId === undefined ? record[groupSource] : selectedGroupId

  const hasValue = Boolean(userValue || groupValue)

  const onSave = (type: 'user' | 'group') => {
    return (newValue?: RaRecord) => {
      const prevValue = type === 'user' ? userValue : groupValue
      const isDissociated = !newValue && prevValue
      const isNewValue = newValue && newValue.id !== (userValue || groupValue)
      if (isDissociated || isNewValue) {
        update(
          resource,
          record.id,
          {
            [groupSource]: null,
            [userSource]: null,
            [type === 'user' ? userSource : groupSource]: newValue?.id ?? null,
          },
          {},
          {
            onSuccess(res) {
              notify(editSuccessMessage, { type: 'success' })
              setSelectedId(res.data[userSource])
              setSelectedGroupId(res.data[groupSource])
            },
            onFailure({ error }) {
              notify(error.message, { type: 'error' })
            },
          },
        )
      }
    }
  }

  const referenceFieldProps = groupValue
    ? {
        source: groupSource,
        reference: ResourceEnum.groups,
      }
    : {
        source: userSource,
        reference: userReference,
      }

  return (
    <SfmReferenceField
      Icon={Icon}
      label={label}
      record={{
        ...record,
        [userSource]: userValue,
        [groupSource]: groupValue,
      }}
      actions={
        !readonly && (
          <>
            <SingleResourcePicker<User>
              Icon={hasValue ? <CreateIcon /> : <AddIcon />}
              saving={loading}
              resource={userReference}
              label={
                hasValue ? 'actions.reassociate.user' : 'actions.associate.user'
              }
              getOptionTitle={(user) => `${user.firstName} ${user.lastName}`}
              getOptionSubtitle={(user) => user.emailAddress ?? ''}
              title="actions.assign"
              selectedId={userValue || undefined}
              onSave={onSave('user')}
              filters={userSearchFilter}
              refetchOnOpen={refetchOnOpen}
            />
            <SingleResourcePicker<Group>
              Icon={hasValue ? <CreateIcon /> : <AddIcon />}
              saving={loading}
              resource={ResourceEnum.groups}
              label={
                hasValue
                  ? 'actions.reassociate.group'
                  : 'actions.associate.group'
              }
              getOptionTitle={(group) => group.name}
              title="actions.assign"
              selectedId={groupValue || undefined}
              onSave={onSave('group')}
              refetchOnOpen={refetchOnOpen}
            />
          </>
        )
      }
      {...referenceFieldProps}
    >
      {children as any}
    </SfmReferenceField>
  )
}
