import { SfmTextInput, SfmTextArea } from 'src/components/SfmInputs'

export function ProjectForm() {
  return (
    <>
      <SfmTextInput source="name" label="resource.projects.name" />
      <SfmTextArea source="description" label="resource.projects.description" />
    </>
  )
}
