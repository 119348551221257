import { Chip } from '@material-ui/core'
import Box from '@material-ui/core/Box'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Typography from '@material-ui/core/Typography'
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt'
import CancelIcon from '@material-ui/icons/Cancel'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import { saveAs } from 'file-saver'
import { extension } from 'mime-types'
import type { Identifier } from 'react-admin'
import {
  LinearProgress,
  FunctionField,
  useTranslate,
  ReferenceField,
  useRecordContext,
  Button,
  useNotify,
} from 'react-admin'
import { useQuery } from 'react-query'
import { Link } from 'react-router-dom'
import { useHttpClient } from 'src/adapters/HTTPClient'
import { CustomDatagrid } from 'src/components/CustomDatagrid'
import { FullNameField } from 'src/components/FullNameField'
import {
  SfmDateField,
  SfmFunctionField,
  SfmReferenceArrayField,
} from 'src/components/SfmFields'
import { formatApiPlatformId } from 'src/libs/apiPlatformId'
import { useCreateResourceUrl } from 'src/Resources/useCreateResourceUrl'
import type { InterventionData } from 'src/types/api'
import { InterventionDataStatusEnum } from 'src/types/api'
import type { FullIntervention } from 'src/types/api/extendedTypes'
import { typeScale } from 'src/UI/theme/tokens'

const useStyles = makeStyles(function (theme) {
  return {
    showLink: {
      textDecoration: 'none',
      color: theme.palette.primary.main,
      display: 'block',
      ...typeScale(0),
    },
  }
})

export function InterventionDataSection() {
  const intervention = useRecordContext<FullIntervention>()
  const __ = useTranslate()

  return (
    <SfmReferenceArrayField
      source="interventionData"
      reference="intervention_datas"
      label="resource.interventions.interventionData"
      sort={{
        field: 'endTime',
        order: 'desc',
      }}
    >
      <CustomDatagrid rowClick="">
        <ReferenceField
          source="operator"
          reference="users"
          label="resource.interventions.operator"
        >
          <FullNameField />
        </ReferenceField>
        <SfmDateField
          source="start"
          label="resource.intervention_datas.start"
          showTime
        />
        <SfmDateField
          source="endTime"
          label="resource.intervention_datas.endTime"
          showTime
        />

        <SfmFunctionField
          label="resource.intervention_datas.integrity"
          render={() => <InterventionDataIntegrity />}
        />

        <GenerateDataReportButton intervention={intervention} />
        <FunctionField<InterventionData>
          render={(intervention_data) => {
            if (
              intervention_data?.status ===
              InterventionDataStatusEnum.need_validation
            ) {
              return (
                <Chip
                  color="secondary"
                  label={__('resource.intervention_datas.needing_validation')}
                />
              )
            }
          }}
        />

        <InterventionDataShowButton />
      </CustomDatagrid>
    </SfmReferenceArrayField>
  )
}

export function InterventionDataShowButton() {
  const interventionData = useRecordContext<InterventionData>()
  const resourceUrl = useCreateResourceUrl()
  const translate = useTranslate()
  const styles = useStyles()
  return (
    <Typography
      className={styles.showLink}
      variant="button"
      component={(muiProps: any) => (
        <Link
          to={resourceUrl('intervention_datas', interventionData.id, 'show')}
          {...muiProps}
        >
          <Box display="flex" alignItems="center">
            <span>{translate('resource.intervention_datas.show')}</span>
            <ArrowRightAltIcon fontSize="small" />
          </Box>
        </Link>
      )}
    />
  )
}

interface DataReportVersion {
  name: string
  reportId: Identifier
  reportVersionId: Identifier
  version: string
}

export function GenerateDataReportButton(props: {
  intervention: FullIntervention
}) {
  const { intervention } = props
  const interventionData = useRecordContext<InterventionData>()
  const notify = useNotify()
  const __ = useTranslate()

  const httpClient = useHttpClient()
  const reportVersion = interventionData?.reportVersions?.[0] as unknown as
    | DataReportVersion
    | undefined

  const interventionDefaultReportVersion =
    intervention.defaultReportObject?.lastVersion

  if (interventionData.status !== InterventionDataStatusEnum.started) {
    if (
      (reportVersion || interventionDefaultReportVersion) &&
      interventionData.payload
    ) {
      const onGenerate = () => {
        httpClient
          .get(
            `report/generate-report/${formatApiPlatformId(
              interventionData.id,
            )}/${formatApiPlatformId(
              reportVersion?.reportVersionId ??
                interventionDefaultReportVersion,
            )}`,
            {
              responseType: 'blob',
            },
          )
          .then((res: { data: Blob }) => {
            const ext = extension(res.data.type)
            saveAs(
              res.data,
              `${__('resource.reports.resource')}-${intervention.name}-${
                interventionData.endTime
              }.${ext}`,
            )
          })
          .catch((error) => {
            notify(error.message, 'error')
          })
      }

      return <Button onClick={onGenerate} label="actions.generate_report" />
    }
    return <Button disabled label="actions.generate_report" />
  }
  return null
}

export function InterventionDataIntegrity() {
  const interventionData = useRecordContext<InterventionData>()

  const httpClient = useHttpClient()
  const { data, isLoading } = useQuery(['integrity', interventionData.id], () =>
    httpClient.get<boolean>(
      `documents/check_minio/${formatApiPlatformId(interventionData.id)}`,
    ),
  )

  if (isLoading) {
    return <LinearProgress />
  }

  if (data?.data === true) {
    return <CheckCircleIcon htmlColor="green" />
  }
  if (data?.data === false) {
    return <CancelIcon htmlColor="red" />
  }

  return null
}
