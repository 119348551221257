import { ListItem, makeStyles, Typography } from '@material-ui/core'
import ButtonArrow from '@nartex/smartforest-design-tokens/graphics/react/ButtonArrow'
import type { ReactElement } from 'react'
import { ShowButton } from 'react-admin'
import type { Record as RaRecord } from 'react-admin'

import { border } from 'src/UI/theme'

import { DataList } from './DataList'

const useStyles = makeStyles(function (theme) {
  return {
    container: {
      border: border,
    },
    li: {
      justifyContent: 'space-between',
      padding: theme.spacing(1),

      '&:not(:last-child)': {
        borderBottom: border,
      },
      backgroundColor: theme.palette.background.default,
    },
    content: {
      display: 'flex',
      flexDirection: 'column',
    },
    title: {
      display: 'flex',
      alignItems: 'baseline',
      gap: theme.spacing(1),
    },
    actions: {
      display: 'flex',
      gap: theme.spacing(1),
      alignSelf: 'flex-end',
    },
  }
})

type RecordGetter<T extends Record<string, any>> = (record: T) => any
interface Props<T extends Record<string, any>> {
  title: RecordGetter<T>
  caption?: RecordGetter<T>
  subtitle?: RecordGetter<T>
  content?: RecordGetter<T>
  dataAction?: (record: T) => ReactElement | undefined
  basePath?: string | ((record: T) => string | undefined)
  getShowRecord?: (record: T) => RaRecord
  hasShowLink?: boolean
}

export function RichDataList<T extends RaRecord = RaRecord>(props: Props<T>) {
  const {
    title,
    caption,
    subtitle,
    content,
    dataAction,
    basePath,
    getShowRecord,
    hasShowLink = true,
  } = props

  const styles = useStyles()

  function getBasePath(record: T, dataBasePath: string) {
    if (!basePath) return dataBasePath
    if (typeof basePath === 'string') {
      return basePath
    }
    return basePath?.(record) ?? dataBasePath
  }

  function render(record: T, dataBasePath: string) {
    return (
      <ListItem key={record.id} classes={{ root: styles.li }}>
        <div className={styles.content}>
          {caption && (
            <Typography variant="caption" color="secondary">
              {caption(record)}
            </Typography>
          )}
          <div className={styles.title}>
            <Typography variant="h2">{title(record)}</Typography>
            {subtitle && (
              <Typography variant="h3" component="span">
                {subtitle(record)}
              </Typography>
            )}
          </div>
          {content && (
            <Typography variant="body2">{content(record)}</Typography>
          )}
        </div>
        <div className={styles.actions}>
          {dataAction && dataAction(record)}
          {hasShowLink && (
            <ShowButton
              basePath={getBasePath(record, dataBasePath)}
              // needed to use the correct id in a ManyToManyField
              record={getShowRecord ? getShowRecord(record) : record}
              alignIcon="right"
              icon={<ButtonArrow />}
            />
          )}
        </div>
      </ListItem>
    )
  }

  return <DataList<T> render={render} />
}
