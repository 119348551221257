import { useTranslate, useRecordContext } from 'react-admin'
import type { SfmShowProps } from 'src/AppScopes/resource'
import { SfmShow } from 'src/AppScopes/resource'
import { EditableReferenceField } from 'src/components/EditableReferenceField'
import { EntitlementsFields } from 'src/components/EntitlementsFields'
import { Section } from 'src/components/Section'
import { SfmDateField, SfmTextField } from 'src/components/SfmFields'

import { ShowLayout } from 'src/components/ShowLayout'
import type { Project, WorkOrder } from 'src/types/api'
import { ResourceEnum } from 'src/types/api/resources'
import { RoleEnum, usePersona } from 'src/UserIdentity'

import { InterventionList } from './InterventionList'

export function Show(props: SfmShowProps) {
  const { role } = usePersona()
  const readOnly = role && ![RoleEnum.ROLE_CONFIGURATOR].includes(role)
  const __ = useTranslate()

  const isManager = role && [RoleEnum.ROLE_MANAGER].includes(role)

  return (
    <SfmShow {...props}>
      <ShowLayout<WorkOrder>
        title={(workOrder) => workOrder.reference}
        caption={(workOrder) => {
          if (workOrder.status)
            return __(`resource.work_orders.fullStatus.${workOrder.status}`)
          return __('resourceNames.work_orders')
        }}
      >
        <SfmDateField source="start" label="resource.work_orders.start" />
        <EditableReferenceField<WorkOrder, Project>
          label="resource.work_orders.project"
          source="project"
          reference={ResourceEnum.projects}
          resource={ResourceEnum.work_orders}
          editOptionText="name"
          editSuccessMessage="notifications.update.work_orders"
          readonly={readOnly}
        >
          <SfmTextField source="name" />
        </EditableReferenceField>
        <SfmTextField source="notes" label="resource.work_orders.notes" />

        <WorkOrderEntitlements />

        <InterventionList isManager={isManager} />
      </ShowLayout>
    </SfmShow>
  )
}

function WorkOrderEntitlements() {
  const workOrder = useRecordContext<WorkOrder>()

  return (
    <Section label="resourceNames.entitlements">
      <EntitlementsFields
        filter={{ 'workOrders.id': workOrder.id }}
        resource={ResourceEnum.work_orders}
        notifResource="work_orders"
      />
    </Section>
  )
}
