import { useContext } from 'react'
import type { ListActionsProps } from 'react-admin'
import {
  ExportButton,
  FilterButton,
  FilterContext,
  CreateButton,
  TopToolbar,
  sanitizeListRestProps,
} from 'react-admin'
import { RoleEnum, usePersona } from 'src/UserIdentity'

import type { ActionsProps } from './types'

export function ListActions(
  props: ListActionsProps & ActionsProps & { hasExport?: boolean },
) {
  const {
    acceptedRoles = [RoleEnum.ROLE_ADMIN, RoleEnum.ROLE_CONFIGURATOR],
    children,
    hasExport = true,
    ...rest
  } = props
  const { role } = usePersona()

  const canEdit = role && acceptedRoles.includes(role)
  const filters = useContext(FilterContext)

  return (
    <TopToolbar {...sanitizeListRestProps(rest)}>
      {filters && <FilterButton />}

      {children}
      {hasExport && <ExportButton />}
      {canEdit && <CreateButton />}
    </TopToolbar>
  )
}
