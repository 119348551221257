import ArchiveIcon from '@nartex/smartforest-design-tokens/graphics/react/BxBxsArchiveIn'
import UnarchiveIcon from '@nartex/smartforest-design-tokens/graphics/react/BxBxsArchiveOut'
import type { Record } from 'react-admin'
import { useRefresh, Button, useUpdate, useNotify } from 'react-admin'

interface Props {
  resource: string
  record: Record
}
export function ArchiveButton(props: Props) {
  const { resource, record } = props

  const [update, { loading }] = useUpdate(resource)
  const notify = useNotify()
  const refresh = useRefresh()

  const onClick = () => {
    const newValue = !record.isArchived
    update(resource, record.id, { isArchived: newValue }, record, {
      onSuccess: function () {
        notify(
          `notifications.${newValue ? 'archive' : 'unarchive'}.${resource}`,
        )
        refresh()
      },
      onFailure: function (error) {
        notify(error, 'error')
      },
    })
  }

  return (
    <Button
      label={record.isArchived ? 'actions.unarchive' : 'actions.archive'}
      onClick={onClick}
      disabled={loading}
      saving={loading}
    >
      {record.isArchived ? <UnarchiveIcon /> : <ArchiveIcon />}
    </Button>
  )
}
