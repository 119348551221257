import { Typography } from '@material-ui/core'

import { useTranslate } from '../adapters/I18nProvider'

export function EmptyMessage() {
  const __ = useTranslate()

  return (
    <>
      <Typography variant="caption">
        {__({
          id: 'variablesList.emptyList.title',
          defaultMessage: 'Aucune variable dans le formulaire',
          description:
            "Title of the message printed in the component variables's list when there is no variable in the forms",
        })}
      </Typography>
      <Typography variant="subtitle2">
        {__({
          id: 'variablesList.emptyList.guide',
          defaultMessage:
            'Créez un champ de formulaire pour le voir apparaître ici',
          description:
            "Content of the message printed in the component variables's list when there is no variable in the forms, Its purpose it to guide the user",
        })}
      </Typography>
    </>
  )
}
