export enum RoleEnum {
  ROLE_ADMIN = 'ROLE_ADMIN',
  ROLE_OPERATOR = 'ROLE_OPERATOR',
  ROLE_MANAGER = 'ROLE_MANAGER',
  ROLE_CONFIGURATOR = 'ROLE_CONFIGURATOR',
  ROLE_USER = 'ROLE_USER',
}

export interface RolesMap {
  ROLE_ADMIN?: boolean
  ROLE_OPERATOR?: boolean
  ROLE_MANAGER?: boolean
  ROLE_CONFIGURATOR?: boolean
  ROLE_USER?: boolean
}
