import { format } from 'date-fns'
import type { Form, FormVersion } from 'src/types/api'

export function getFormExportFilename(form: Form, formVersion: FormVersion) {
  const version = formVersion.version ? `-v${formVersion.version}` : ''
  return `${form.name}${version}-${format(
    formVersion.updatedAt ? new Date(formVersion.updatedAt) : new Date(),
    'yyyy-MM-dd',
  )}.sfm.json`
}
