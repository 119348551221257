import { makeStyles } from '@material-ui/core/index'
import classNames from 'classnames'
import { useTranslate } from 'react-admin'

import { SwitchLink } from './SwitchLink'
import { RoleEnum } from './types'
import { useCreateRoleUrl } from './useCreateRoleUrl'
import { useRoles } from './useRoles'

const useStyles = makeStyles(function () {
  return {
    row: {
      display: 'flex',
      flexDirection: 'row',
      position: 'sticky',
      right: 0,
    },
  }
})

interface Props {
  className?: string
  acceptedRoles?: RoleEnum[]
  role: RoleEnum
}

export function RoleSwitcher(props: Props) {
  const { className, acceptedRoles, role } = props
  const styles = useStyles()
  const __ = useTranslate()
  const roles = useRoles()
  const createRoleUrl = useCreateRoleUrl()

  return (
    <div className={classNames(styles.row, className)}>
      {roles.ROLE_MANAGER && (
        <SwitchLink
          to={createRoleUrl({ role: RoleEnum.ROLE_MANAGER })}
          label={__(`roles.manager`)}
          active={role === RoleEnum.ROLE_MANAGER}
          enabled={
            !acceptedRoles || acceptedRoles.includes(RoleEnum.ROLE_MANAGER)
          }
        />
      )}
      {roles.ROLE_CONFIGURATOR && (
        <SwitchLink
          to={createRoleUrl({ role: RoleEnum.ROLE_CONFIGURATOR })}
          label={__(`roles.configurator`)}
          active={role === RoleEnum.ROLE_CONFIGURATOR}
          enabled={
            !acceptedRoles || acceptedRoles.includes(RoleEnum.ROLE_CONFIGURATOR)
          }
        />
      )}
      {roles.ROLE_ADMIN && (
        <SwitchLink
          to={createRoleUrl({ role: RoleEnum.ROLE_ADMIN })}
          label={__(`roles.administrator`)}
          active={role === RoleEnum.ROLE_ADMIN}
          enabled={
            !acceptedRoles || acceptedRoles.includes(RoleEnum.ROLE_ADMIN)
          }
        />
      )}
    </div>
  )
}
