import { SfmCreate } from 'src/AppScopes/resource'
import type { SfmCreateProps } from 'src/AppScopes/resource'

import { RoleEnum } from 'src/UserIdentity'

import { Form } from './Form'

export function Create(props: SfmCreateProps & { isRound: boolean }) {
  const { isRound } = props
  return (
    <SfmCreate {...props} acceptedRoles={[RoleEnum.ROLE_CONFIGURATOR]}>
      <Form isRound={isRound} />
    </SfmCreate>
  )
}
