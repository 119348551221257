import type { ShowActionsProps } from 'react-admin'
import type { SfmShowProps } from 'src/AppScopes/resource'
import { SfmShow } from 'src/AppScopes/resource'
import { ArchiveButton } from 'src/components/ArchiveButton'
import { DataList } from 'src/components/DataList'
import { ShowActions } from 'src/components/ProtectedActions'
import {
  SfmReferenceArrayField,
  SfmReferenceManyField,
} from 'src/components/SfmFields'
import { ShowLayout } from 'src/components/ShowLayout'
import type { Intervention, Project, WorkOrder } from 'src/types/api'
import { ResourceEnum } from 'src/types/api/resources'
import { RoleEnum, usePersona } from 'src/UserIdentity'

export function Show(props: SfmShowProps) {
  return (
    <SfmShow {...props} actions={<ProjectActions />}>
      <ShowLayout<Project>
        title={(project) => project.name}
        meta={(project) => project.description}
      >
        <SfmReferenceManyField
          label="resourceNames.work_orders"
          reference={ResourceEnum.work_orders}
          target="project.id"
        >
          <DataList<WorkOrder>
            getItemLabel={(workOrder) => workOrder.reference || workOrder.id}
          />
        </SfmReferenceManyField>
        <SfmReferenceArrayField
          label="resource.projects.interventions"
          reference={ResourceEnum.interventions}
          source="interventions"
        >
          <DataList<Intervention> />
        </SfmReferenceArrayField>
      </ShowLayout>
    </SfmShow>
  )
}

function ProjectActions(props: ShowActionsProps) {
  const { data: project } = props
  const { role } = usePersona()

  const canArchive =
    role && [RoleEnum.ROLE_ADMIN, RoleEnum.ROLE_CONFIGURATOR].includes(role)

  const canBeDeleted = project && !projectHasRelation(project as Project)

  return (
    <ShowActions {...props} hasDelete={canBeDeleted}>
      {canArchive && project && (
        <ArchiveButton resource="projects" record={project} />
      )}
    </ShowActions>
  )
}

function projectHasRelation(project: Project): boolean {
  if (project.interventions.length > 0) return true
  if (project.workOrders.length > 0) return true
  return false
}
