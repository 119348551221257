import type { EditProps } from 'react-admin'
import { SfmEdit } from 'src/AppScopes/resource'
import { SfmTextInput } from 'src/components/SfmInputs'
import { RoleEnum } from 'src/UserIdentity'

export function Edit(props: EditProps) {
  return (
    <SfmEdit {...props} acceptedRoles={[RoleEnum.ROLE_CONFIGURATOR]}>
      <SfmTextInput source="name" label="resource.forms.name" />
    </SfmEdit>
  )
}
