import { createContext, useContext } from 'react'

export enum PhotoSizeEnum {
  SMALL = 'small',
  MEDIUM = 'medium',
  LARGE = 'large',
}

export enum VideoQualityEnum {
  LOW = 'low',
  HIGH = 'high',
}

export interface SpecConfig {
  photoSize: {
    [k in PhotoSizeEnum]: number
  }
  videoQuality: {
    [k in VideoQualityEnum]: number
  }
}

const defaultSpecConfig: SpecConfig = {
  photoSize: {
    [PhotoSizeEnum.SMALL]: 800,
    [PhotoSizeEnum.MEDIUM]: 1600,
    [PhotoSizeEnum.LARGE]: 3200,
  },
  videoQuality: {
    [VideoQualityEnum.LOW]: 0,
    [VideoQualityEnum.HIGH]: 1,
  },
}

export const SpecConfigContext = createContext<SpecConfig | undefined>(
  undefined,
)

export function useSpecConfig() {
  const specConfig = useContext(SpecConfigContext)

  if (!specConfig) return defaultSpecConfig

  return specConfig
}
