import type { History } from 'history'
import type OIDC from 'oidc-client'
import { useEffect, useState } from 'react'
import { Admin, RouteWithoutLayout } from 'react-admin'
import type {
  DataProvider as IDataProvider,
  I18nProvider as II18nProvider,
  AuthProvider as IAuthProvider,
} from 'react-admin'
import { Route } from 'react-router-dom'
import type { IEnv } from 'src/adapters/Env'
import { EnvContext } from 'src/adapters/Env'
import { resources } from 'src/Resources'

import { ClientInformation } from './Resources/Client/Information'
import { Wysiwyg } from './Resources/Form/Wysiwyg'
import { Layout, theme } from './UI'
import { Dashboard } from './UI/Dashboard'
import { KitchenSink } from './UI/KitchenSink'
import { LoadingPage } from './UI/LoadingPage'

const customRoutes = [
  <Route key="styles" exact path="/styles" component={KitchenSink} />,
  <Route
    key="information"
    exact
    path="/clientInformation"
    component={ClientInformation}
  />,
  <RouteWithoutLayout
    noLayout
    key="wysiwyg"
    path="/wysiwyg/:id"
    component={Wysiwyg}
  />,
]
interface Props {
  env: IEnv
  storage: Storage
  dataProvider: IDataProvider
  i18nProvider: II18nProvider
  authProvider: IAuthProvider & { init: () => Promise<OIDC.User | null> }
  history: History
}

export function App(props: Props) {
  const { env, dataProvider, authProvider, i18nProvider, history } = props
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    let canceled = false
    run()
    return () => {
      canceled = true
    }

    async function run() {
      setIsLoading(true)
      await authProvider.init()
      if (canceled) return
      setIsLoading(false)
    }
  }, [authProvider, setIsLoading])

  return (
    <EnvContext.Provider value={env}>
      {isLoading ? (
        <LoadingPage />
      ) : (
        <Admin
          layout={Layout}
          theme={theme}
          dataProvider={dataProvider}
          authProvider={authProvider}
          i18nProvider={i18nProvider}
          history={history}
          loginPage={LoadingPage}
          customRoutes={customRoutes}
          dashboard={Dashboard}
        >
          {resources}
        </Admin>
      )}
    </EnvContext.Provider>
  )
}
