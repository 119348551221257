import { useTheme } from '@material-ui/core'

import { border } from '../theme/mixins'

export function Separator() {
  const theme = useTheme()

  return (
    <hr style={{ border: 0, borderBottom: border(theme), width: '100%' }} />
  )
}
