/* inspired from @api-platform/admin/src/hydra/dataProvider.js */
import removeDiacritics from 'remove-diacritics'

export function buildFilters(filters: Record<string, any>) {
  const result: Record<string, string> = {}

  const buildFilterParams = (
    key: string,
    nestedFilter: Record<string, any>,
    rootKey: string,
  ) => {
    const filterValue = nestedFilter[key]

    if (Array.isArray(filterValue)) {
      filterValue.forEach((arrayFilterValue, index) => {
        result[`${rootKey}[${index}]`] = arrayFilterValue
      })
      return result
    }

    if (!filterValue || typeof filterValue !== 'object') {
      result[rootKey] =
        filterValue && rootKey === 'q'
          ? removeDiacritics(filterValue)
          : filterValue
      return
    }

    Object.keys(filterValue).forEach((subKey) => {
      if (rootKey === 'exists') {
        buildFilterParams(subKey, filterValue, `exists[${subKey}]`)
        return
      }

      if (
        [
          'after',
          'before',
          'strictly_after',
          'strictly_before',
          'lt',
          'gt',
          'lte',
          'gte',
          'between',
        ].includes(subKey)
      ) {
        buildFilterParams(subKey, filterValue, `${rootKey}[${subKey}]`)
        return
      }

      buildFilterParams(subKey, filterValue, `${rootKey}.${subKey}`)
    })
  }

  Object.keys(filters).forEach((key) => {
    return buildFilterParams(key, filters, key)
  })

  return result
}
