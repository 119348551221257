import type { ReactElement } from 'react'
import type { Record } from 'react-admin'
import {
  CreateContextProvider,
  useCreateController,
  FormWithRedirect,
} from 'react-admin'

export interface CustomFormProps<T extends Record> {
  resource: string
  submit: (value: any | T) => void
  render: (formSubmit: () => void) => ReactElement
  record?: T
}

/**
 * Custom form to be used outside of create & edit context
 *
 * @prop {string} resource The name of the resource modified. For the underlying Create component used to correctlly instantiate the FormContext.
 * @prop {function} submit The submit function that will be wrapped & returned in the render function
 * @prop {function} render The render function for the form, it receives as argument the wrapped submit function
 * @prop {Record} record The default record for the form
 *
 * @example
 *
 * <CustomForm resource="example" submit={submit}
 *  render={(handleSubmit) => (
 *      <>
 *        {FORM_FIELDS}
 *        <SaveButton submit={handleSubmit} />
 *      </>
 *    )}
 * />
 */
export function CustomForm<T extends Record>(props: CustomFormProps<T>) {
  const { resource, submit, render, record } = props
  const createController = useCreateController({
    resource,
    basePath: '',
  })

  return (
    <CreateContextProvider value={createController}>
      <FormWithRedirect
        record={record}
        save={submit}
        render={(params) => render(params.handleSubmit)}
      />
    </CreateContextProvider>
  )
}
