import type { AxiosInstance } from 'axios'
import axios from 'axios'
import { createContext, useContext } from 'react'

import type { IEnv } from './Env'

interface Params {
  getAccessToken: () => string | undefined
  env: IEnv
}

export function HTTPClient(params: Params) {
  const { getAccessToken, env } = params
  const httpClient = axios.create({ baseURL: env.API_URL })

  httpClient.interceptors.request.use((config) => {
    const token = getAccessToken()
    if (!token) {
      return config
    }

    return {
      ...config,
      headers: {
        ...config.headers,
        Authorization: `Bearer ${token}`,
      },
    }
  })

  return httpClient
}

export const HttpClientContext = createContext<AxiosInstance | undefined>(
  undefined,
)

export function useHttpClient(): AxiosInstance {
  const httpClient = useContext(HttpClientContext)

  if (!httpClient) throw new Error('Please provide an HttpClient')

  return httpClient
}
