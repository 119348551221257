import { isExpr } from '../Interpreter'
import type { Literal, Rule } from '../Interpreter/types'

export function getWatchedProperties(value: any | Rule): string[] | undefined {
  if (!isExpr(value)) return []

  if (value.type === 'eval') return undefined

  if (value.type === 'rule') {
    const watchedProps = value.cases
      .map((ruleCase) => {
        if (!ruleCase.condition) throw new Error('undefined condition')
        return getWatchedProperties(ruleCase.condition)
      })
      .flat()

    if (watchedProps.some((val) => val === undefined)) {
      return undefined
    }

    return watchedProps as string[]
  }

  if (value.type === 'function') {
    if (value.funcName === 'getValues') {
      return undefined
    }

    if (value.funcName === 'getValue') {
      return [(value.args[0] as Literal<string>).value]
    }

    const watchedProps = value.args.map(getWatchedProperties).flat()

    if (watchedProps.some((val) => val === undefined)) {
      return undefined
    }

    return watchedProps as string[]
  }

  return []
}
