import { makeStyles } from '@material-ui/core'
import Chip from '@material-ui/core/Chip'
import { useTranslate } from 'react-admin'

import type { SfmFunctionFieldProps } from './SfmFields'
import { EmptyText, SfmFunctionField } from './SfmFields'

const useStyles = makeStyles(function (theme) {
  return {
    container: {
      display: 'flex',
      gap: theme.spacing(0.5),
    },
    chip: {
      overflow: 'visible',
    },
  }
})

export function RolesField(props: Omit<SfmFunctionFieldProps, 'render'>) {
  const __ = useTranslate()
  const styles = useStyles()
  return (
    <SfmFunctionField
      {...props}
      render={(record: any) => {
        if (!record?.roles || record.roles.length < 1) {
          return <EmptyText />
        }
        return (
          <div className={styles.container}>
            {record.roles.map((role: string) => (
              <Chip
                key={role}
                label={__(`apiRoles.${role}`)}
                size="small"
                classes={{ label: styles.chip }}
              />
            ))}
          </div>
        )
      }}
    />
  )
}
