import type { CreateProps } from 'react-admin'
import { SfmCreate } from 'src/AppScopes/resource'
import { SfmTextInput } from 'src/components/SfmInputs'
import { RoleEnum } from 'src/UserIdentity'

export function Create(props: CreateProps) {
  return (
    <SfmCreate {...props} acceptedRoles={[RoleEnum.ROLE_CONFIGURATOR]}>
      <SfmTextInput source="name" label="resource.forms.name" />
    </SfmCreate>
  )
}
