import type { PropsWithChildren } from 'react'
import { createContext, useContext } from 'react'
import { useLocation } from 'react-router'
import type { ResourceDefinitions } from 'src/Resources'
import type { ResourceEnum } from 'src/types/api/resources'

export enum AppScopeEnum {
  CLIENT = 'client',
  ADMIN = 'admin',
  DASHBOARD = 'dashboard',
}

const AppScopeContext = createContext<AppScopeEnum | null>(null)

export function useAppScope(): AppScopeEnum {
  return useContext(AppScopeContext) ?? AppScopeEnum.DASHBOARD
}

type AppScopeProviderProps = PropsWithChildren<{
  resourceDefinitions: ResourceDefinitions
}>

export function AppScopeProvider(props: AppScopeProviderProps) {
  const { children, resourceDefinitions } = props
  const { pathname } = useLocation()

  const resource = pathname.split('/')[1] as ResourceEnum
  const scope = resourceDefinitions[resource]?.scope || null

  return (
    <AppScopeContext.Provider value={scope}>
      {children}
    </AppScopeContext.Provider>
  )
}
