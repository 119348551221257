import ActionDelete from '@material-ui/icons/Delete'
import React from 'react'
import type { Identifier } from 'react-admin'
import {
  useNotify,
  useRecordContext,
  useRefresh,
  DeleteWithConfirmButton,
} from 'react-admin'
import { RichDataList } from 'src/components/RichDataList'
import { SfmReferenceArrayField } from 'src/components/SfmFields'
import type { Contact } from 'src/types/api'

import { AddContactButton } from '../Contact/AddContactButton'

interface Props {
  deletedContacts: Set<Identifier>
  setDeletedContacts: React.Dispatch<React.SetStateAction<Set<Identifier>>>
  readonly?: boolean
}

/**
 * Contact List to be used in Client show view
 */
export function ContactList(props: Props) {
  const { deletedContacts, setDeletedContacts, readonly } = props
  const client = useRecordContext()
  const notify = useNotify()
  const refresh = useRefresh()

  const contactList =
    client?.contacts?.filter(
      (contactId: string) => !deletedContacts.has(contactId),
    ) || []

  return (
    <>
      <SfmReferenceArrayField
        actions={
          readonly ? undefined : (
            <AddContactButton clientId={client.id} onChange={refresh} />
          )
        }
        label="resource.clients.contacts"
        record={{
          ...client,
          contacts: contactList,
        }}
        source="contacts"
        reference="contacts"
        filter={{
          deletedAt: null,
        }}
      >
        <RichDataList<Contact>
          title={(contact) => contact.name}
          caption={(contact) => contact.serviceName}
          content={(contact) => contact.notes}
          dataAction={(contact) => {
            if (readonly) {
              return
            }
            return (
              <DeleteWithConfirmButton
                disabled={contactList.length <= 1}
                undoable={false}
                record={contact}
                onSuccess={() => {
                  setDeletedContacts(
                    new Set([...Array.from(deletedContacts), contact.id]),
                  )
                  notify('notifications.delete.contact', 'success')
                }}
                onFailure={(error) => notify(error.message, 'error')}
              >
                <ActionDelete />
              </DeleteWithConfirmButton>
            )
          }}
        />
      </SfmReferenceArrayField>
    </>
  )
}
