export enum ResourceEnum {
  'clients' = 'clients',
  'users' = 'users',
  'entitlements' = 'entitlements',
  'habilitations' = 'habilitations',
  'autorisations' = 'autorisations',
  'user_entitlements' = 'user_entitlements',
  'contacts' = 'contacts',
  'interventions' = 'interventions',
  'reports' = 'reports',
  'projects' = 'projects',
  'work_orders' = 'work_orders',
  'intervention_datas' = 'intervention_datas',
  'intervention_documents' = 'intervention_documents',
  'documents_of_intervention' = 'documents_of_intervention', // Resource to get all documents of intervention with source = 'intervention' without the associated table
  'intervention_items' = 'intervention_items',
  'intervention_equipments' = 'intervention_equipments',
  'equipment_of_intervention' = 'equipment_of_intervention', // Resource to get all equipments of intervention without the associated table'
  'operators' = 'operators', // TODO : remove this virtual resource, merge it back with users
  'documents' = 'documents',
  'items' = 'items',
  'equipment' = 'equipment',
  'forms' = 'forms',
  'form_documents' = 'form_documents',
  'documents_of_form' = 'documents_of_form', // Resource to get all documents of a form without the associated table
  'report_versions' = 'report_versions',
  'form_versions' = 'form_versions',
  'round' = 'round',
  'groups' = 'groups',
}
