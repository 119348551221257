import filesize from 'filesize'
import type { ShowActionsProps } from 'react-admin'
import { useRecordContext, useLocale, useTranslate } from 'react-admin'
import type { SfmShowProps } from 'src/AppScopes'
import { SfmShow } from 'src/AppScopes'
import { ArchiveButton } from 'src/components/ArchiveButton'
import { LabeledField } from 'src/components/LabeledField'
import { ShowActions } from 'src/components/ProtectedActions'
import { SfmFunctionField, SfmTextField } from 'src/components/SfmFields'
import { ShowLayout } from 'src/components/ShowLayout'
import { getFileExtension } from 'src/libs/fileMimeType'
import type { Document } from 'src/types/api/index'
import { ResourceEnum } from 'src/types/api/resources'
import { RoleEnum, usePersona } from 'src/UserIdentity'

import { DocumentFileField } from './DocumentFileField'

export function DocumentShow(props: SfmShowProps) {
  const __ = useTranslate()
  return (
    <SfmShow {...props} actions={<DocumentActions />}>
      <ShowLayout<Document>
        title={(document) => document.name}
        caption={(document) =>
          `${__('resourceNames.documents')} ${__(
            `resource.documents.type.${document.type}`,
          )}`
        }
      >
        <SfmFunctionField
          label="resource.documents.mimeType"
          render={(document) => {
            if (document?.isUrl) return __('resource.documents.url')
            return getFileExtension(document?.mimeType)
          }}
        />

        <SizeField />
        <SfmTextField source="comment" label="resource.documents.comment" />
        <LabeledField label="resource.documents.file">
          <DocumentFileField />
        </LabeledField>
      </ShowLayout>
    </SfmShow>
  )
}

function SizeField() {
  const document = useRecordContext<Document>()
  const locale = useLocale()

  if (document.isUrl) return null

  return (
    <LabeledField label="resource.documents.size">
      {document && filesize(document.size, { locale: locale })}
    </LabeledField>
  )
}

function DocumentActions(props: ShowActionsProps) {
  const { data: document } = props
  const { role } = usePersona()

  const canArchive =
    role && [RoleEnum.ROLE_ADMIN, RoleEnum.ROLE_CONFIGURATOR].includes(role)

  const canBeDeleted = document
    ? !documentHasRelation(document as Document)
    : false

  return (
    <ShowActions {...props} hasDelete={canBeDeleted}>
      {canArchive && document && (
        <ArchiveButton resource={ResourceEnum.documents} record={document} />
      )}
    </ShowActions>
  )
}

function documentHasRelation(document: Document): boolean {
  if (document.formDocuments.length > 0) return true
  if (document.interventionDocuments.length > 0) return true
  if (document.userEntitlement) return true
  return false
}
