import { Typography } from '@material-ui/core'

import { useTranslate } from '../adapters/I18nProvider'

export function EmptyMessage() {
  const __ = useTranslate()

  return (
    <>
      <Typography variant="caption">
        {__({
          id: 'componentEditor.emptySelection.title',
          defaultMessage: 'Aucun élément sélectionné',
          description:
            'Title of the message printed in the component props editor when there is no selected element',
        })}
      </Typography>
      <Typography variant="subtitle2">
        {__({
          id: 'componentEditor.emptySelection.guide',
          defaultMessage:
            'Cliquez sur un élément dans le canvas ci-contre pour le sélectionner et modifier ses propriétés',
          description:
            'Content of the message printed in the component props editor when there is no selected element, Its purpose it to guide the user',
        })}
      </Typography>
    </>
  )
}
