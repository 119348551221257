import IconButton from '@material-ui/core/IconButton'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import { GetApp } from '@material-ui/icons'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import PublishIcon from '@nartex/smartforest-design-tokens/graphics/react/BxBxCloudUpload'
import EditIcon from '@nartex/smartforest-design-tokens/graphics/react/BxBxsPencil'
import type { AxiosError } from 'axios'
import { saveAs } from 'file-saver'
import { isEmpty } from 'lodash'
import { useState } from 'react'
import type { ShowActionsProps } from 'react-admin'
import {
  Link,
  useUpdate,
  useNotify,
  useTranslate,
  FunctionField,
  Button,
  useRefresh,
  useRecordContext,
  useCreate,
  useDataProvider,
} from 'react-admin'
import { useMutation } from 'react-query'
import type { SfmShowProps } from 'src/AppScopes/resource'
import { SfmShow } from 'src/AppScopes/resource'
import { ArchiveButton } from 'src/components/ArchiveButton'
import { CustomDatagrid } from 'src/components/CustomDatagrid'
import { ShowActions } from 'src/components/ProtectedActions'
import {
  SfmDateField,
  SfmReferenceArrayField,
  SfmTextField,
} from 'src/components/SfmFields'
import { getFormExportFilename } from 'src/libs/getFormExportFilename'
import type { FormVersion, Form } from 'src/types/api'
import type { FullFormVersion } from 'src/types/api/extendedTypes'
import { ResourceEnum } from 'src/types/api/resources'
import { RoleEnum, usePersona } from 'src/UserIdentity'

import { ShowLayout } from '../../components/ShowLayout'
import { useCreateResourceUrl } from '../useCreateResourceUrl'

import { DocumentsField } from './DocumentsField'
import { InterventionsField } from './InterventionsField'

import { ReportsField } from './ReportsField'

export function Show(props: SfmShowProps) {
  return (
    <SfmShow {...props} actions={<FormActions />}>
      <ShowInContext />
    </SfmShow>
  )
}

function FormActions(props: ShowActionsProps) {
  const { data: form } = props
  const { role } = usePersona()

  const isConfigurator =
    role && [RoleEnum.ROLE_ADMIN, RoleEnum.ROLE_CONFIGURATOR].includes(role)

  const canBeDeleted = form ? !formHasRelation(form as Form) : false

  return (
    <ShowActions {...props} hasDelete={canBeDeleted}>
      {isConfigurator && form && (
        <>
          {!!(form as Form).formVersions.length && (
            <ExportButton form={form as Form} />
          )}
          <ArchiveButton resource={ResourceEnum.forms} record={form} />
        </>
      )}
    </ShowActions>
  )
}

function formHasRelation(form: Form): boolean {
  if (form.interventions.length > 0) return true
  if (form.formDocuments.length > 0) return true
  if (form.reports.length > 0) return true
  if (form.formVersions.length > 0) return true
  return false
}

function ShowInContext() {
  const { role } = usePersona()
  const form = useRecordContext<Form>()

  const isConfigurator = role === RoleEnum.ROLE_CONFIGURATOR
  const isManager = role === RoleEnum.ROLE_MANAGER

  return (
    <ShowLayout<Form> title={(record) => record.name}>
      {isManager && <ReportsField />}

      <SfmReferenceArrayField
        label="resource.forms.formVersions"
        actions={isConfigurator ? <OpenWysiwygButton form={form} /> : undefined}
        source="formVersions"
        reference="form_versions"
        filter={isManager ? { isDraft: false } : {}}
        sort={{ field: 'version', order: 'desc' }}
      >
        <CustomDatagrid rowClick="">
          <SfmTextField
            source="version"
            label="resource.form_versions.version"
          />
          <SfmDateField
            source="updatedAt"
            label="resource.form_versions.updatedAt"
          />
          {
            isConfigurator && [
              <PopupButton key="variables" />,
              <PublishButton key="publish" />,
              <FormVersionMenu key="republish" />,
            ] // JSX Fragment broke Datagrid Layout
          }
        </CustomDatagrid>
      </SfmReferenceArrayField>

      {isConfigurator && <DocumentsField />}
      <InterventionsField />
    </ShowLayout>
  )
}

interface OpenWysiwygButtonProps {
  form: Form
}

function OpenWysiwygButton(props: OpenWysiwygButtonProps) {
  const { form } = props
  const createUrl = useCreateResourceUrl()

  return (
    <Link to={{ pathname: createUrl('wysiwyg', form.id, 'edit') }}>
      <Button label="actions.open_form_editor" size="small">
        <EditIcon />
      </Button>
    </Link>
  )
}

function PublishButton() {
  const notify = useNotify()
  const refresh = useRefresh()
  const [update, { loading: updateLoading }] = useUpdate()
  const record = useRecordContext<FormVersion>()

  const publishVersion = (formVersion: FormVersion) => {
    update('form_versions', formVersion.id, { isDraft: false }, formVersion, {
      onSuccess: function () {
        refresh()
        notify('notifications.publish.form_version')
      },
      onFailure: function ({ error }) {
        notify(error.message, 'error')
      },
    })
  }

  if (record?.isDraft) {
    return (
      <Button
        label="actions.publish"
        size="small"
        onClick={(event) => {
          event.stopPropagation()
          publishVersion(record)
        }}
        saving={updateLoading}
      >
        <PublishIcon />
      </Button>
    )
  }

  return null
}

function PopupButton() {
  const translate = useTranslate()
  function openPopup(
    width: number,
    height: number,
    html: string,
    windowName: string,
  ) {
    const newWindow = window.open(
      '',
      windowName,
      `height=${height}, width=${width}`,
    )
    const fullHtml = `
    <html>
      <head>
      <meta charset="utf-8">
      </head>
    ${html}
    </html>
    `

    if (newWindow) {
      newWindow.document.body.innerHTML = fullHtml
    }
  }

  function generateAndOpenPopup(variables: string[], id: string) {
    const { width, height } = window.screen
    const html = variables
      ?.map((variable: string) => {
        return `<p>${variable}</p>`
      })
      .join(' ')

    const realWidth = width / 3
    openPopup(realWidth, height, html, id)
  }

  return (
    <FunctionField<FullFormVersion>
      addLabel={false}
      render={(record) => {
        const variables = record?.payload?.fieldNames?.map(
          (field: any) => field.label + ': ${' + field.name + '}',
        )
        if (!variables || isEmpty(variables)) {
          return <p>Aucune variable</p>
        }
        if (record) {
          return (
            <Button
              size="small"
              color="primary"
              onClick={() => generateAndOpenPopup(variables, record.id)}
            >
              <>{translate('resource.forms.variables')}</>
            </Button>
          )
        }
      }}
    />
  )
}

function FormVersionMenu() {
  const translate = useTranslate()
  const notify = useNotify()
  const refresh = useRefresh()
  const [create] = useCreate<FormVersion>()

  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)

  const record = useRecordContext<FormVersion>()
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const repostVersion = () => {
    create(
      'form_versions',
      {
        payload: record.payload,
        form: record.form,
        isDraft: true,
      },
      {
        onSuccess: async function () {
          setAnchorEl(null)
          refresh()
          notify('notifications.republish.form_version')
        },
        onFailure: function (error) {
          if ((error as AxiosError)?.response?.status === 409) {
            notify('notifications.error.draft_version_exists', 'error')
            return
          }
          notify(error.message, 'error')
        },
      },
    )
  }

  if (record?.isDraft) return null

  return (
    <div>
      <IconButton
        aria-label="repost"
        aria-controls="repost"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="repost"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
      >
        <MenuItem
          onClick={(event) => {
            event.stopPropagation()
            repostVersion()
          }}
        >
          {translate('actions.republish')}
        </MenuItem>
      </Menu>
    </div>
  )
}
interface ExportButtonProps {
  form: Form
}
function ExportButton(props: ExportButtonProps) {
  const { form } = props

  const dataprovider = useDataProvider()

  const { mutate, isLoading } = useMutation({
    mutationFn: exportForm,
  })

  async function exportForm() {
    let formversionId

    if (form.lastVersion) {
      formversionId = form.lastVersion
    } else {
      formversionId = form.formVersions[form.formVersions.length - 1]
    }

    const result = await dataprovider.getOne<FormVersion>(
      ResourceEnum.form_versions,
      {
        id: formversionId,
      },
    )
    const formVersion = result.data
    const blob = new Blob([JSON.stringify(formVersion.payload)], {
      type: 'application/json',
    })

    saveAs(blob, getFormExportFilename(form, formVersion))
  }

  return (
    <Button
      label="actions.export"
      onClick={() => mutate()}
      disabled={isLoading}
    >
      <GetApp />
    </Button>
  )
}
