import { useInput } from 'react-admin'
import { MultipleResourcePicker } from 'src/components/Picker'
import { RichDataList } from 'src/components/RichDataList'
import { SfmReferenceArrayField } from 'src/components/SfmFields'
import { SfmTextInput } from 'src/components/SfmInputs'
import type { User } from 'src/types/api'
import { ResourceEnum } from 'src/types/api/resources'
import { AddIcon } from 'src/UI/theme/icons'

export function Form() {
  return (
    <>
      <SfmTextInput source="name" label="resource.groups.name" />
      <UsersInput />
    </>
  )
}

function UsersInput() {
  const {
    input: { onChange, value },
  } = useInput({ source: 'users' })
  const ids = Array.isArray(value) ? value : []

  return (
    <SfmReferenceArrayField
      actions={
        <MultipleResourcePicker<User>
          resource={ResourceEnum.users}
          selectedIds={ids}
          label="actions.add"
          title="actions.add_users"
          getOptionTitle={(user) => `${user.firstName} ${user.lastName}`}
          getOptionSubtitle={(user) => user.emailAddress ?? ''}
          onSave={(users) => onChange(users.map((user) => user.id))}
          Icon={<AddIcon />}
        />
      }
      label="resource.groups.users"
      record={{
        id: '',
        users: ids,
      }}
      source="users"
      reference="users"
    >
      <RichDataList<User>
        hasShowLink={false}
        title={(user) => `${user.firstName} ${user.lastName}`}
        subtitle={(user) => user.emailAddress}
      />
    </SfmReferenceArrayField>
  )
}
