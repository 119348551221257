import type { TabsActions } from '@material-ui/core'
import { Tabs, Tab, makeStyles, Typography } from '@material-ui/core'
import classNames from 'classnames'
import { useEffect, useRef, useState } from 'react'

import { useTranslate } from '../adapters/I18nProvider'
import { ComponentsLibrary } from '../ComponentsLibrary'
import { VariablesList } from '../VariablesList'

const useStyle = makeStyles(function (theme) {
  return {
    root: {
      display: 'flex',
      flexDirection: 'column',
    },
    tabs: {
      background: theme.palette.background.default,
    },
    tabPanel: {
      maxHeight: '100%',
      overflowY: 'auto',
    },
  }
})

interface Props {
  className?: string
}
export function LeftPane(props: Props) {
  const { className } = props
  const [selectedTab, changeTab] = useState('lib')
  const __ = useTranslate()
  const style = useStyle()
  const ref = useRef<TabsActions>(null)

  // https://github.com/mui-org/material-ui/issues/20527
  useEffect(() => {
    setTimeout(() => ref.current?.updateIndicator(), 0)
  }, [])

  return (
    <div className={classNames(className, style.root)}>
      <Tabs
        className={style.tabs}
        value={selectedTab}
        onChange={(_event, newTab) => changeTab(newTab)}
        action={ref}
      >
        <Tab
          label={
            <Typography variant="caption">
              {__({
                id: 'tabs.componentsLibrary.label',
                defaultMessage: "Bibliothèque d'éléments",
                description: 'Component library tab label',
              })}
            </Typography>
          }
          value="lib"
        />
        <Tab
          label={
            <Typography variant="caption">
              {__({
                id: 'tabs.variableList.label',
                defaultMessage: 'Liste des variables',
                description: 'Variable list tab label',
              })}
            </Typography>
          }
          value="variables"
        />
      </Tabs>
      {selectedTab === 'lib' && (
        <ComponentsLibrary className={style.tabPanel} />
      )}
      {selectedTab === 'variables' && <VariablesList />}
    </div>
  )
}
