import { required } from 'react-admin'
import { SfmTextInput, SfmTextArea } from 'src/components/SfmInputs'
import { useValidateUnicity } from 'src/components/UniqueInput'
import type { Equipment } from 'src/types/api'
import { ResourceEnum } from 'src/types/api/resources'

export function EquipementForm() {
  const unique = useValidateUnicity<Equipment>(ResourceEnum.equipment)
  return (
    <>
      <SfmTextInput source="name" label="resource.equipment.name" />
      <SfmTextInput
        source="reference"
        label="resource.equipment.reference"
        validate={[required('validation.required'), unique('reference')]}
      />
      <SfmTextArea
        source="description"
        lable="resource.equipment.description"
      />
      <SfmTextInput source="location" label="resource.equipment.location" />
    </>
  )
}
