import type { SfmListProps } from 'src/AppScopes'
import { SfmList } from 'src/AppScopes'
import { CustomDatagrid } from 'src/components/CustomDatagrid'
import { SfmDateField, SfmTextField } from 'src/components/SfmFields'
import { RoleEnum } from 'src/UserIdentity'

export function List(props: SfmListProps) {
  return (
    <SfmList {...props} acceptedEditRoles={[RoleEnum.ROLE_CONFIGURATOR]}>
      <CustomDatagrid>
        <SfmTextField source="name" label="resource.reports.name" />
        <SfmDateField source="updatedAt" label="resource.reports.updatedAt" />
      </CustomDatagrid>
    </SfmList>
  )
}
