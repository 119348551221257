/*
 * Beware, this code is AUTO-GENERATED
 * Any manual changes on this file would be eaten by the VØ|D
 */

type Integer = number

type Year = Integer
type Month = Integer
type Day = Integer
type Hour = Integer
type Minute = Integer
type Second = Integer
type HoursOffset = Integer
type SecondsOffset = Integer
export type DateTimeString =
  `${Year}-${Month}-${Day}T${Hour}:${Minute}:${Second}+${HoursOffset}:${SecondsOffset}`

type Base = string
type Id = string
type Resource = string
export type IriReference = `/${Base}/${Resource}/${Id}`

export interface Client {
  readonly '@id': IriReference
  readonly '@type': 'Client'
  readonly id: IriReference
  name: string
  reference?: string
  isValidAt?: DateTimeString // date-time
  disabledAt?: DateTimeString // date-time
  documents: IriReference[]
  entitlements: IriReference[]
  users: IriReference[]
  items: IriReference[]
  equipments: IriReference[]
  workOrders: IriReference[]
  contacts: IriReference[]
  forms: IriReference[]
  interventions: IriReference[]
  reports: IriReference[]
  projects: IriReference[]
  groups: IriReference[]
  isArchived: boolean
  deletedAt?: DateTimeString // date-time
  readonly updatedAt?: DateTimeString // date-time
  readonly createdAt?: DateTimeString // date-time
  q?: string
  emailAddress?: string // email
  phoneNumbers?: string[]
  street?: string
  zipCode?: string
  city?: string
  country?: string
  readonly valid: boolean
}

export interface Contact {
  readonly '@id': IriReference
  readonly '@type': 'Contact'
  readonly id: IriReference
  name: string
  serviceName?: string
  notes?: string
  client: IriReference // iri-reference
  isMain?: boolean
  isArchived: boolean
  deletedAt?: DateTimeString // date-time
  readonly updatedAt?: DateTimeString // date-time
  readonly createdAt?: DateTimeString // date-time
  emailAddress?: string // email
  phoneNumbers?: string[]
  street?: string
  zipCode?: string
  city?: string
  country?: string
  q?: string
}

export interface DataCollector {
  readonly '@id': IriReference
  readonly '@type': 'DataCollector'
  readonly id: IriReference
  resource: string
  payload?: string
  operator?: string
  recordedAt: DateTimeString // date-time
  statusCode?: Integer
  apiResponse?: string
  url: string
  deletedAt?: DateTimeString // date-time
  readonly updatedAt?: DateTimeString // date-time
  readonly createdAt?: DateTimeString // date-time
}

export interface Document {
  readonly '@id': IriReference
  readonly '@type': 'Document'
  readonly id: IriReference
  name?: string
  size: Integer
  mimeType?: string
  fileName?: string
  client?: IriReference // iri-reference
  interventionDocuments: IriReference[]
  comment?: string
  type: DocumentTypeEnum
  userEntitlement?: IriReference // iri-reference
  formDocuments: IriReference[]
  isUrl: boolean
  deletedAt?: DateTimeString // date-time
  readonly updatedAt?: DateTimeString // date-time
  readonly createdAt?: DateTimeString // date-time
  isArchived: boolean
  q?: string
}

export enum DocumentTypeEnum {
  'document' = 'document',
  'generique' = 'generique',
  'other' = 'other',
}

export interface Entitlement {
  readonly '@id': IriReference
  readonly '@type': 'Entitlement'
  readonly id: IriReference
  name: string
  userEntitlements: IriReference[]
  type?: EntitlementTypeEnum
  client?: IriReference // iri-reference
  interventions: IriReference[]
  description?: string
  workOrders: IriReference[]
  deletedAt?: DateTimeString // date-time
  readonly updatedAt?: DateTimeString // date-time
  readonly createdAt?: DateTimeString // date-time
  q?: string
  isArchived: boolean
}

export enum EntitlementTypeEnum {
  'autorisation' = 'autorisation',
  'habilitation' = 'habilitation',
}

export interface Equipment {
  readonly '@id': IriReference
  readonly '@type': 'Equipment'
  readonly id: IriReference
  client: IriReference // iri-reference
  location?: string
  interventionEquipments: IriReference[]
  deletedAt?: DateTimeString // date-time
  readonly updatedAt?: DateTimeString // date-time
  readonly createdAt?: DateTimeString // date-time
  name?: string
  reference?: string
  designation?: string
  comment?: string
  description?: string
  isArchived: boolean
  q?: string
}

export interface Form {
  readonly '@id': IriReference
  readonly '@type': 'Form'
  readonly id: IriReference
  client: IriReference // iri-reference
  interventions: IriReference[]
  formVersions: IriReference[]
  name: string
  reports: IriReference[]
  lastVersion?: IriReference // iri-reference
  formDocuments: IriReference[]
  deletedAt?: DateTimeString // date-time
  readonly updatedAt?: DateTimeString // date-time
  readonly createdAt?: DateTimeString // date-time
  isArchived: boolean
  q?: string
}

export interface FormDocument {
  readonly '@id': IriReference
  readonly '@type': 'FormDocument'
  readonly id: IriReference
  form: IriReference // iri-reference
  document: IriReference // iri-reference
  isArchived: boolean
  deletedAt?: DateTimeString // date-time
  readonly updatedAt?: DateTimeString // date-time
  readonly createdAt?: DateTimeString // date-time
  q?: string
}

export interface FormVersion {
  readonly '@id': IriReference
  readonly '@type': 'FormVersion'
  readonly id: IriReference
  version?: number
  payload?: string
  form: IriReference // iri-reference
  interventionData: IriReference[]
  isDraft: boolean
  isArchived: boolean
  deletedAt?: DateTimeString // date-time
  readonly updatedAt?: DateTimeString // date-time
  readonly createdAt?: DateTimeString // date-time
  q?: string
}

export interface Group {
  readonly '@id': IriReference
  readonly '@type': 'Group'
  readonly id: IriReference
  name: string
  users: IriReference[]
  interventionsAssignedAsOperator: IriReference[]
  interventionsAssignedAsValidator: IriReference[]
  interventionsAssignedAsRecipient: IriReference[]
  client: IriReference // iri-reference
  isArchived: boolean
  deletedAt?: DateTimeString // date-time
  readonly updatedAt?: DateTimeString // date-time
  readonly createdAt?: DateTimeString // date-time
  q?: string
}

export interface Intervention {
  readonly '@id': IriReference
  readonly '@type': 'Intervention'
  readonly id: IriReference
  name?: string
  location?: string
  start?: DateTimeString // date-time
  form: IriReference // iri-reference
  entitlements: IriReference[]
  operator?: IriReference // iri-reference
  client: IriReference // iri-reference
  workOrder?: IriReference // iri-reference
  project?: IriReference // iri-reference
  interventionDocuments: IriReference[]
  interventionItems: IriReference[]
  interventionEquipments: IriReference[]
  interventionData: IriReference[]
  status?: InterventionStatusEnum
  isRecurrent: boolean
  validator?: IriReference // iri-reference
  defaultReport?: IriReference // iri-reference
  recipientUsers: IriReference[]
  recipientContacts: IriReference[]
  validatedBy?: IriReference // iri-reference
  needValidation: boolean
  currentInterventionData?: string[]
  fullListChecker?: string
  statusListChecker?: string
  isRound?: boolean
  listCheckerField?: string
  endTime?: DateTimeString // date-time
  operatorsGroup?: IriReference // iri-reference
  validatorsGroup?: IriReference // iri-reference
  recipientsGroup?: IriReference // iri-reference
  deletedAt?: DateTimeString // date-time
  readonly updatedAt?: DateTimeString // date-time
  readonly createdAt?: DateTimeString // date-time
  sendToOperator?: boolean
  startBy?: IriReference // iri-reference
  startRound?: DateTimeString // date-time
  roundOperator?: string
  isArchived: boolean
  q?: string
  readonly projectName?: string
  readonly assignOperatorAuthenticationId?: string
  readonly defaultReportObject?: string[]
}

export enum InterventionStatusEnum {
  'created' = 'created',
  'ready' = 'ready',
  'started' = 'started',
  'ended' = 'ended',
  'validated' = 'validated',
}

export interface InterventionData {
  readonly '@id': IriReference
  readonly '@type': 'InterventionData'
  readonly id: IriReference
  start?: DateTimeString // date-time
  endTime?: DateTimeString // date-time
  payload?: string
  operator?: IriReference // iri-reference
  intervention: IriReference // iri-reference
  formVersion: IriReference // iri-reference
  medias?: string[]
  reportVersions?: string[]
  status: InterventionDataStatusEnum
  validatedBy?: IriReference // iri-reference
  interventionDocuments: IriReference[]
  idItemChecker?: string
  isArchived: boolean
  deletedAt?: DateTimeString // date-time
  readonly updatedAt?: DateTimeString // date-time
  readonly createdAt?: DateTimeString // date-time
  q?: string
  readonly authenticationId?: string
}

export enum InterventionDataStatusEnum {
  'started' = 'started',
  'need_validation' = 'need_validation',
  'refused' = 'refused',
  'validated' = 'validated',
}

export interface InterventionDocument {
  readonly '@id': IriReference
  readonly '@type': 'InterventionDocument'
  readonly id: IriReference
  source?: InterventionDocumentSourceEnum
  document: IriReference // iri-reference
  intervention: IriReference // iri-reference
  interventionData?: IriReference // iri-reference
  isArchived: boolean
  deletedAt?: DateTimeString // date-time
  readonly updatedAt?: DateTimeString // date-time
  readonly createdAt?: DateTimeString // date-time
  q?: string
}

export enum InterventionDocumentSourceEnum {
  'formVersion' = 'formVersion',
  'interventionData' = 'interventionData',
  'intervention' = 'intervention',
}

export interface InterventionEquipment {
  readonly '@id': IriReference
  readonly '@type': 'InterventionEquipment'
  readonly id: IriReference
  quantity?: Integer
  equipment: IriReference // iri-reference
  intervention: IriReference // iri-reference
  isArchived: boolean
  deletedAt?: DateTimeString // date-time
  readonly updatedAt?: DateTimeString // date-time
  readonly createdAt?: DateTimeString // date-time
  q?: string
}

export interface InterventionItem {
  readonly '@id': IriReference
  readonly '@type': 'InterventionItem'
  readonly id: IriReference
  quantity?: Integer
  item?: IriReference // iri-reference
  intervention: IriReference // iri-reference
  isArchived: boolean
  deletedAt?: DateTimeString // date-time
  readonly updatedAt?: DateTimeString // date-time
  readonly createdAt?: DateTimeString // date-time
  q?: string
}

export interface Item {
  readonly '@id': IriReference
  readonly '@type': 'Item'
  readonly id: IriReference
  client: IriReference // iri-reference
  interventionItems: IriReference[]
  type?: ItemTypeEnum
  deletedAt?: DateTimeString // date-time
  readonly updatedAt?: DateTimeString // date-time
  readonly createdAt?: DateTimeString // date-time
  name?: string
  reference?: string
  designation?: string
  comment?: string
  description?: string
  isArchived: boolean
  q?: string
}

export enum ItemTypeEnum {
  'consommable' = 'consommable',
  'non consommable' = 'non consommable',
}

export interface Project {
  readonly '@id': IriReference
  readonly '@type': 'Project'
  readonly id: IriReference
  name?: string
  description?: string
  workOrders: IriReference[]
  interventions: IriReference[]
  client: IriReference // iri-reference
  isArchived: boolean
  deletedAt?: DateTimeString // date-time
  readonly updatedAt?: DateTimeString // date-time
  readonly createdAt?: DateTimeString // date-time
  q?: string
}

export interface Report {
  readonly '@id': IriReference
  readonly '@type': 'Report'
  readonly id: IriReference
  name: string
  client: IriReference // iri-reference
  interventions: IriReference[]
  reportVersions: IriReference[]
  forms: IriReference[]
  lastVersion?: IriReference // iri-reference
  isArchived: boolean
  deletedAt?: DateTimeString // date-time
  readonly updatedAt?: DateTimeString // date-time
  readonly createdAt?: DateTimeString // date-time
  q?: string
  readonly version?: number
}

export interface ReportVersion {
  readonly '@id': IriReference
  readonly '@type': 'ReportVersion'
  readonly id: IriReference
  version: number
  report: IriReference // iri-reference
  mimeType?: string
  fileName?: string
  isArchived: boolean
  deletedAt?: DateTimeString // date-time
  readonly updatedAt?: DateTimeString // date-time
  readonly createdAt?: DateTimeString // date-time
  q?: string
}

export interface User {
  readonly '@id': IriReference
  readonly '@type': 'User'
  readonly id: IriReference
  lastName: string
  firstName: string
  personalNumber?: string
  username: string
  companyName?: string
  serviceName?: string
  workplace?: string
  lastActiveAt: DateTimeString // date-time
  authenticationId: string
  roles: string[]
  userEntitlements: IriReference[]
  clients: IriReference[]
  interventions: IriReference[]
  interventionData: IriReference[]
  /** CodegenError: Cannot generate property type : {} */
  password: any
  isEnabled?: boolean
  readonly isOperator: boolean
  readonly isConfigurator?: boolean
  readonly isManager?: boolean
  readonly isAdmin?: boolean
  approvedInterventions: IriReference[]
  validatedInterventions: IriReference[]
  lastLogin?: DateTimeString // date-time
  groups: IriReference[]
  startedInterventions: IriReference[]
  isArchived: boolean
  deletedAt?: DateTimeString // date-time
  readonly updatedAt?: DateTimeString // date-time
  readonly createdAt?: DateTimeString // date-time
  emailAddress?: string // email
  phoneNumbers?: string[]
  street?: string
  zipCode?: string
  city?: string
  country?: string
  q?: string
  readonly userIdentifier: string
  /** CodegenError: Cannot generate property type : {"readOnly":true} */
  readonly salt: any
}

export interface UserEntitlement {
  readonly '@id': IriReference
  readonly '@type': 'UserEntitlement'
  readonly id: IriReference
  start?: DateTimeString // date-time
  endTime?: DateTimeString // date-time
  userId: User
  entitlementId: IriReference // iri-reference
  deletedAt?: DateTimeString // date-time
  readonly updatedAt?: DateTimeString // date-time
  readonly createdAt?: DateTimeString // date-time
  document?: IriReference // iri-reference
  isArchived: boolean
  q?: string
}

export interface WorkOrder {
  readonly '@id': IriReference
  readonly '@type': 'WorkOrder'
  readonly id: IriReference
  reference?: string
  notes?: string
  start?: DateTimeString // date-time
  client: IriReference // iri-reference
  project?: IriReference // iri-reference
  interventions: IriReference[]
  status?: WorkOrderStatusEnum
  entitlements: IriReference[]
  isArchived: boolean
  deletedAt?: DateTimeString // date-time
  readonly updatedAt?: DateTimeString // date-time
  readonly createdAt?: DateTimeString // date-time
  q?: string
}

export enum WorkOrderStatusEnum {
  'created' = 'created',
  'started' = 'started',
  'validating' = 'validating',
  'ended' = 'ended',
}
