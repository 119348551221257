import {
  ArrayInput,
  required,
  SimpleFormIterator,
  DateInput,
} from 'react-admin'
import { Section } from 'src/components/Section'
import { SfmTextInput } from 'src/components/SfmInputs'
import { useValidateUnicity } from 'src/components/UniqueInput'
import type { Client } from 'src/types/api'
import { ResourceEnum } from 'src/types/api/resources'

export function Form() {
  const unique = useValidateUnicity<Client>(ResourceEnum.clients)
  return (
    <>
      <SfmTextInput
        source="name"
        label="resource.clients.name"
        validate={required('validation.required')}
      />
      <SfmTextInput
        reference={ResourceEnum.clients}
        source="reference"
        label="resource.clients.reference"
        validate={[required('validation.required'), unique('reference')]}
      />
      <Section label="resource.clients.sections.validity">
        <DateInput
          source="isValidAt"
          label="resource.clients.isValidAt"
          validate={required('validation.required')}
        />
        <DateInput source="disabledAt" label="resource.clients.disabledAt" />
      </Section>

      <Section label="resource.clients.sections.contact_detail">
        <SfmTextInput
          source="emailAddress"
          label="resource.clients.emailAddress"
          type="email"
        />
        <ArrayInput source="phoneNumbers" label="resource.clients.phoneNumbers">
          <SimpleFormIterator>
            <SfmTextInput
              source="number"
              label="resource.clients.phoneNumber"
            />
          </SimpleFormIterator>
        </ArrayInput>
        <SfmTextInput source="street" label="resource.clients.street" />
        <SfmTextInput source="zipCode" label="resource.clients.zipCode" />
        <SfmTextInput source="city" label="resource.clients.city" />
        <SfmTextInput source="country" label="resource.clients.country" />
      </Section>
    </>
  )
}
