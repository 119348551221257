import { useTheme, makeStyles, useMediaQuery } from '@material-ui/core'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import classNames from 'classnames'
import { Link } from 'react-router-dom'

const useStyles = makeStyles(function (theme) {
  return {
    switchLink: {
      ...theme.typography.button,
      textDecoration: 'none',
      display: 'block',
      padding: `calc(${theme.spacing(1.5)}px - 2px) ${theme.spacing(1.5)}px`,
      color: theme.palette.text.secondary,
    },
    activeLink: {
      color: theme.palette.text.primary,
    },
    disabledLink: {
      color: theme.palette.text.disabled,
    },
  }
})

interface Props {
  to: string
  label: string
  active: boolean
  enabled: boolean
}

export function SwitchLink(props: Props) {
  const { active, enabled, to, label } = props
  const styles = useStyles()
  const theme = useTheme()

  const isSmall = useMediaQuery(theme.breakpoints.down('sm'))

  const activeBorder = active
    ? `4px solid ${theme.palette.secondary.main}`
    : '4px solid transparent'

  if (isSmall && !enabled) return null
  return (
    <Box borderBottom={activeBorder} clone>
      <Typography
        className={classNames(
          styles.switchLink,
          !enabled && styles.disabledLink,
          active && styles.activeLink,
        )}
        variant="button"
        component={(muiProps) => {
          if (enabled && !active) {
            return (
              <Link to={to} {...muiProps}>
                {label}
              </Link>
            )
          }
          return <span {...muiProps}>{label}</span>
        }}
      />
    </Box>
  )
}
