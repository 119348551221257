import type { PropsWithChildren } from 'react'
import { useState } from 'react'
import type { Record as RaRecord } from 'react-admin'
import { useUpdate, useNotify, useRecordContext } from 'react-admin'

import type { LabeledFieldProps } from 'src/components/LabeledField'
import { SfmReferenceField } from 'src/components/SfmFields'
import type { IriReference } from 'src/types/api'
import type { ResourceEnum } from 'src/types/api/resources'
import { AddIcon, CreateIcon } from 'src/UI/theme/icons'

import { SingleResourcePicker } from './Picker'

interface Props<T, U> {
  Icon?: LabeledFieldProps['Icon']
  label: string
  source: keyof T & string
  resource: ResourceEnum
  reference: ResourceEnum
  record?: T

  editLabel?: (value: boolean) => string
  editSuccessMessage: string
  editOptionText: (keyof U & string) | ((record?: U) => string)
  editOptionLabel?: (keyof U & string) | ((record?: U) => string)
  editSearchFilter?: Partial<Record<keyof U, any>> & Record<string, any>

  readonly?: boolean

  refetchOnOpen?: boolean
}

export function EditableReferenceField<T extends RaRecord, U extends RaRecord>(
  props: PropsWithChildren<Props<T, U>>,
) {
  const {
    Icon,
    label,
    source,
    reference,
    children,
    readonly,
    resource,
    refetchOnOpen,
  } = props
  const {
    editLabel,
    editSuccessMessage,
    editOptionText,
    editOptionLabel,
    editSearchFilter,
  } = props

  const notify = useNotify()
  const contextRecord = useRecordContext<T>()

  const record = props.record ?? contextRecord

  const [update, { loading }] = useUpdate()
  const [selectedId, setSelectedId] = useState<IriReference | null>()

  const value = selectedId === undefined ? record[source] : selectedId
  const defaultActionLabel = value ? 'actions.reassociate' : 'actions.associate'

  const onSave = (newValue?: U) => {
    if (newValue?.id !== (value ?? undefined)) {
      update(
        resource,
        record.id,
        { [source]: newValue?.id ?? null },
        {},
        {
          onSuccess(res) {
            notify(editSuccessMessage, 'success')
            setSelectedId(res.data[source])
          },
          onFailure({ error }) {
            notify(error.message, 'error')
          },
        },
      )
    }
  }

  return (
    <SfmReferenceField
      Icon={Icon}
      label={label}
      record={{
        ...record,
        [source]: value,
      }}
      actions={
        !readonly && (
          <SingleResourcePicker<U>
            Icon={value ? <CreateIcon /> : <AddIcon />}
            saving={loading}
            resource={reference}
            label={editLabel?.(Boolean(value)) ?? defaultActionLabel}
            getOptionTitle={(option) => {
              if (typeof editOptionText === 'function') {
                return editOptionText(option)
              }
              return option?.[editOptionText] ?? ''
            }}
            getOptionSubtitle={(option) => {
              if (!editOptionLabel) return ''
              if (typeof editOptionLabel === 'function') {
                return editOptionLabel(option)
              }
              return option?.[editOptionLabel] ?? ''
            }}
            title="actions.assign"
            selectedId={value ?? undefined}
            onSave={onSave}
            filters={editSearchFilter}
            refetchOnOpen={refetchOnOpen}
          />
        )
      }
      source={source}
      reference={reference}
    >
      {children as any}
    </SfmReferenceField>
  )
}
