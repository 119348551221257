import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  makeStyles,
} from '@material-ui/core'
import type { FieldNode } from '@nartex/sfm-form-engine'
import QrCodeIcon from '@nartex/smartforest-design-tokens/graphics/react/BxBxBarcode'
import { useState } from 'react'

import { useTranslate } from '../adapters/I18nProvider'

import { QrCodeForm } from './QrCodeForm'

interface Props {
  fields: FieldNode[]
}

const useStyles = makeStyles(function (theme) {
  return {
    modal: {
      backgroundColor: theme.palette.background.default,
      maxWidth: 'none',
      minWidth: 800,
    },
    '@keyframes slidein': {
      from: {
        transform: 'translateY(100%)',
      },

      to: {
        transform: 'translateY(0)',
      },
    },
    button: {
      animation: '$slidein 0.25s',
    },
  }
})

export function QrCodeGenerator(props: Props) {
  const { fields } = props
  const __ = useTranslate()

  const [dialogIsOpen, setDialogIsOpen] = useState(false)
  const onClose = () => {
    setDialogIsOpen(false)
  }

  const styles = useStyles()

  return (
    <>
      {fields.length > 0 && (
        <Button
          startIcon={<QrCodeIcon />}
          variant="contained"
          color="primary"
          className={styles.button}
          onClick={() => setDialogIsOpen(true)}
        >
          {__({
            id: 'QrCodeGenerator.QrCodeButton.label',
            defaultMessage: 'Générer un QrCode',
            description: 'Label of the QrCode generator button',
          })}
        </Button>
      )}
      <Dialog
        open={dialogIsOpen}
        onClose={onClose}
        classes={{ paper: styles.modal }}
      >
        <DialogTitle>
          {__({
            id: 'QrCodeGenerator.QrCodeModal.title',
            defaultMessage: 'Génération de QrCode',
            description: 'Title of the modal to generate a QrCode',
          })}
        </DialogTitle>
        <DialogContent>
          <QrCodeForm fields={fields} />
        </DialogContent>
      </Dialog>
    </>
  )
}
