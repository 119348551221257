import { InputBase, MenuItem, Select } from '@material-ui/core'
import type { Case, Eval, Func } from '@nartex/sfm-form-engine'
import type { ProfunctorState } from '@staltz/use-profunctor-state'
import { useCallback, useMemo } from 'react'

import { useTranslate } from '../adapters/I18nProvider'
import { useFields } from '../FieldsContext'

import { ArgumentSelect } from './ArgumentSelect'
import { getArgumentsValues, encodeArguments } from './argumentsValuesUtils'
import { useCurrentField } from './CurrentFieldContext'
import type { ArgumentValue, OperatorNames } from './operators'
import { OperatorMap } from './operators'
import { useEditorStyle } from './useEditorStyle'
import { useSelectStyle } from './useSelectStyle'

interface OperatorProps {
  condition: Eval | Func
  caseStore: ProfunctorState<Case | undefined>
}
export function OperatorComponent(props: OperatorProps) {
  const { condition, caseStore } = props
  const ruleStyle = useEditorStyle()
  if (condition.type === 'eval')
    throw new Error('Condition type "eval" not supported yet')

  if (condition.funcName === 'getValue' || condition.funcName === 'getValues')
    throw new Error('GetValue does not correspond to an operator')

  const operator = useMemo(
    () => OperatorMap[condition.funcName as OperatorNames],
    [condition.funcName],
  )
  const __ = useTranslate()

  const fields = useFields()

  const argumentsValue: Record<string, ArgumentValue> = useMemo(
    () => getArgumentsValues(fields, operator, condition.args),
    [fields, operator, condition.args],
  )

  const setArgument = useCallback(
    (arg: ArgumentValue | undefined, argName: string) => {
      caseStore.setState((prevCase) => {
        if (!prevCase) return
        if (!prevCase.condition) return prevCase

        const newArgs = Object.assign({}, argumentsValue, { [argName]: arg })
        return {
          ...prevCase,
          condition: {
            ...prevCase.condition,
            args: encodeArguments(newArgs, operator),
          },
        }
      })
    },
    [caseStore, argumentsValue, operator],
  )

  const currentField = useCurrentField()

  const variables = useMemo(() => {
    const result: Record<string, JSX.Element> = {}
    Object.entries(operator.arguments).forEach(([argName, value], index) => {
      const argument = condition.args[index]

      const options = value.options(fields, argumentsValue, currentField)

      return (result[argName] = (
        <ArgumentSelect
          arg={argument}
          argumentList={options}
          setArgument={(arg) => setArgument(arg, argName)}
        />
      ))
    })
    return result
  }, [condition, operator, fields, argumentsValue, setArgument, currentField])

  return (
    <div className={ruleStyle.operatorComponent}>
      {__(operator.displayText, variables)}
    </div>
  )
}

interface OperatorSelectProps {
  onSelect: (value: OperatorNames) => void
}

export function OperatorSelect(props: OperatorSelectProps) {
  const { onSelect } = props
  const __ = useTranslate()

  const styles = useSelectStyle()
  const ruleStyle = useEditorStyle()
  return (
    <Select
      value=""
      displayEmpty
      onChange={(event) => onSelect(event.target.value as OperatorNames)}
      input={
        <InputBase
          classes={{
            root: styles.root,
            input: ruleStyle.select,
          }}
        />
      }
      classes={{
        root: styles.root,
      }}
    >
      <MenuItem value="" disabled>
        {__({
          id: 'OperatorSelect.placeholder',
          description: 'Operator selection component placeholder',
          defaultMessage: 'Opérateur',
        })}
      </MenuItem>
      {Object.entries(OperatorMap).map(([key, operator]) => (
        <MenuItem value={key} key={key}>
          {__(operator.selectText)}
        </MenuItem>
      ))}
    </Select>
  )
}
