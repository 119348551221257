import { memoize } from 'lodash'
import { useMemo } from 'react'
import type { Validator, Record as RaRecord } from 'react-admin'
import { useDataProvider } from 'react-admin'
import { arrayify } from 'src/libs/arrayify'
import type { ResourceEnum } from 'src/types/api/resources'

import { SfmTextInput } from './SfmInputs'

interface Props<T> {
  reference: ResourceEnum
  source: keyof T & string
  label: string
  validate?: Validator | Validator[]
  maxLength?: number
}

export function UniqueInput<T extends RaRecord>(props: Props<T>) {
  const { reference, source, label, validate, maxLength } = props
  const unique = useValidateUnicity(reference)

  return (
    <SfmTextInput
      source={source}
      label={label}
      validate={[...arrayify(validate ?? []), unique(source)]}
      maxLength={maxLength}
    />
  )
}

const defaultSort = { field: 'id', order: '' }

export function useValidateUnicity<T extends RaRecord>(
  reference: ResourceEnum,
) {
  const dataProvider = useDataProvider()
  const findMatchFor = useMemoizedCallback(
    async (source: keyof T, value: string) => {
      const { data } = await dataProvider.getList(reference, {
        filter: { [source]: value },
        pagination: { perPage: 1, page: 1 },
        sort: defaultSort,
      })

      return data[0] as T | undefined
    },
    [dataProvider, reference],
  )

  return function unique(source: keyof T): Validator {
    return async function validateUnicity(value: string, record: Partial<T>) {
      const match = await findMatchFor(source, value)

      if (match && match.id !== record.id) {
        return 'error.value_not_available'
      }

      return null
    }
  }
}

type AnyFunction = (...args: any[]) => any

function useMemoizedCallback<T extends AnyFunction>(
  callbackFunc: T,
  deps: any[],
): T {
  return useMemo(() => {
    return memoize(callbackFunc, (...args) => JSON.stringify(args))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps)
}
