import type { SfmResourceProps } from 'src/AppScopes'
import { SfmResource } from 'src/AppScopes'

import { Create } from './Create'
import { Edit } from './Edit'
import { List } from './List'
import { Show } from './Show'

export function UserGroupResource(props: SfmResourceProps) {
  return (
    <SfmResource
      {...props}
      list={List}
      create={Create}
      show={Show}
      edit={Edit}
    />
  )
}
