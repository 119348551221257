import { IonItem, IonLabel, IonButton, IonNote } from '@ionic/react'
import RemoveMediaIcon from '@nartex/smartforest-design-tokens/graphics/react/BxBxTrash'
import classNames from 'classnames'
import type { ReactElement } from 'react'
import { useMemo } from 'react'
import type { FieldError } from 'react-hook-form'
import type { PhotoSizeEnum, VideoQualityEnum } from 'src/Form'
import { useSpecConfig } from 'src/Form/SpecConfigContext'
import { usePlugins } from 'src/plugins'

import type { SvgComponent } from '../../types'
import type { BaseMediaInputProps } from '../types'
import { Wrapper } from '../Wrapper'

import { useMediaSrc } from './useMediaSrc'

interface MediaInputProps extends BaseMediaInputProps {
  mediaType: MediaType
  Icon: SvgComponent
  AddIcon: SvgComponent
  renderMedia: (src: string) => ReactElement
}
export function MediaInput(props: MediaInputProps) {
  return (
    <Wrapper
      {...props}
      render={(controllerProps, itemProps) => {
        const { value, onChange } = controllerProps
        const { label, error } = itemProps

        return (
          <MediaInputContent
            {...props}
            value={value}
            onChange={onChange}
            label={label}
            error={error}
          />
        )
      }}
    />
  )
}

interface MediaInputContentProps extends MediaInputProps {
  value?: string
  label?: string
  onChange: (...event: any[]) => void
  error?: FieldError
}

type MediaType = 'Audio' | 'Video' | 'Image' | 'Signature'

function useMediaMethods(
  mediaType: MediaType,
  quality?: PhotoSizeEnum | VideoQualityEnum,
) {
  const { MediaPlugin, SignaturePlugin } = usePlugins()
  const specConfig = useSpecConfig()

  return useMemo(() => {
    if (mediaType === 'Audio') {
      return {
        take() {
          return MediaPlugin.takeAudio()
        },
        remove(value: string) {
          return MediaPlugin.deleteMedia({ mediaType: 'Audio', mediaId: value })
        },
      }
    }

    if (mediaType === 'Video') {
      return {
        take() {
          return MediaPlugin.takeVideo({
            quality: specConfig.videoQuality[quality as VideoQualityEnum],
          })
        },
        remove(value: string) {
          return MediaPlugin.deleteMedia({ mediaType: 'Video', mediaId: value })
        },
      }
    }

    if (mediaType === 'Image') {
      return {
        take() {
          return MediaPlugin.takeImage({
            resolution: specConfig.photoSize[quality as PhotoSizeEnum],
          })
        },
        remove(value: string) {
          return MediaPlugin.deleteMedia({ mediaType: 'Image', mediaId: value })
        },
      }
    }

    if (mediaType === 'Signature') {
      return {
        take() {
          return SignaturePlugin.sign()
        },
        remove(value: string) {
          return SignaturePlugin.deleteSignature({ signatureId: value })
        },
      }
    }

    throw Error(`Unsupported media type : ${JSON.stringify(mediaType)}`)
  }, [
    MediaPlugin,
    SignaturePlugin,
    mediaType,
    quality,
    specConfig.photoSize,
    specConfig.videoQuality,
  ])
}

function MediaInputContent(props: MediaInputContentProps) {
  const { value, onChange, mediaType, renderMedia, label, quality, error } =
    props
  const { Icon, AddIcon } = props

  const { take, remove } = useMediaMethods(mediaType, quality)
  const mediaSrc = useMediaSrc(mediaType, value)
  const isEmpty = !value

  const onTakeMedia = async () => {
    const result = await take()
    if ('code' in result) {
      throw Error(`Error code ${result.code} : ${result.message}`)
    }
    onChange(result.value)
  }

  const onDeleteMedia = async () => {
    if (!value) return

    onChange(undefined)
    const { success } = await remove(value)
    if (!success) {
      onChange(value)
      throw Error(`An error occured while deleting media`)
    }
  }

  return (
    <div
      className={classNames(
        'media-input border-input item',
        error && '-hasError',
      )}
    >
      <IonItem
        button={isEmpty}
        lines="none"
        onClick={isEmpty ? onTakeMedia : undefined}
      >
        <Icon slot="start" className="input-icon -isPrefix" />
        {label && <IonLabel className="input-label">{label}</IonLabel>}
        {error && (
          <IonNote
            slot="end"
            className="input-error"
            style={{ marginRight: '0.5rem' }}
          >
            {error.message || error.type}
          </IonNote>
        )}
        {isEmpty ? (
          <AddIcon className="input-icon -isAction" slot="end" />
        ) : (
          <IonButton
            fill="clear"
            onClick={onDeleteMedia}
            style={{ marginRight: '-0.25rem' }}
          >
            <RemoveMediaIcon className="input-icon -isDanger" slot="end" />
          </IonButton>
        )}
      </IonItem>

      {!isEmpty && mediaSrc && renderMedia(mediaSrc)}
    </div>
  )
}
