import type { SfmEditProps } from 'src/AppScopes'
import { SfmEdit } from 'src/AppScopes'
import { RoleEnum } from 'src/UserIdentity'

import { DocumentForm } from './Form'

export function DocumentEdit(props: SfmEditProps) {
  return (
    <SfmEdit {...props} acceptedRoles={[RoleEnum.ROLE_CONFIGURATOR]}>
      <DocumentForm edit />
    </SfmEdit>
  )
}
