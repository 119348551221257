import type { Spec, FieldNode } from '@nartex/sfm-form-engine'

function isContainer(node?: FieldNode): node is FieldNode {
  return Boolean(node && node.type === 'Accordion')
}

function flatten(fields: Spec['fields']): Spec['fields'] {
  const result = fields.flatMap((element) => {
    if (isContainer(element)) {
      return [{ ...element, children: [] }, ...(element.children ?? [])]
    }
    return element
  })

  return result
}

function expand(fields: Spec['fields']): Spec['fields'] {
  const result: Spec['fields'] = []
  fields.forEach((element) => {
    if (isContainer(element)) {
      result.push({ ...element, children: [] })
      return
    }
    const lastInsert = last(result)
    if (isContainer(lastInsert)) {
      lastInsert.children = lastInsert.children ?? []
      lastInsert.children.push(element)
      return
    }

    result.push(element)
  })

  return result
}

function last<T>(arr: T[]): T | undefined {
  return arr[arr.length - 1]
}

export const flatFieldsLens = {
  get(spec: Spec) {
    return flatten(spec.fields)
  },
  set(fields: Spec['fields'], spec: Spec) {
    return {
      ...spec,
      fields: expand(fields),
    }
  },
}
