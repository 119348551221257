import PlusIcon from '@nartex/smartforest-design-tokens/graphics/react/BxBxPlus'
import type { PropsWithChildren } from 'react'
import React, { useState } from 'react'

import type { Identifier } from 'react-admin'
import {
  AutocompleteInput,
  Button,
  DateInput,
  FileField,
  FileInput,
  ReferenceInput,
  required,
  useNotify,
  useTranslate,
  useDataProvider,
} from 'react-admin'
import type { UserEntitlementFormData } from 'src/adapters/antiCorruptionLayerProxy'

import { CustomFormDialog } from 'src/components/CustomFormDialog'

import type { Entitlement, User } from 'src/types/api'
import { ResourceEnum } from 'src/types/api/resources'

import type { EntitlementsTypes } from '.'

interface Props {
  type: EntitlementsTypes
  resource: 'user' | 'entitlement'
  resourceId: Identifier
  onChange: () => void
}

interface FileType {
  rawFile: File
  src: string
}
interface FormValues {
  userId: string
  entitlementId: string
  start?: string
  endTime?: string
  document?: FileType
}

export function AddUserEntitlementButton(props: PropsWithChildren<Props>) {
  const { type, resource, resourceId, onChange } = props
  const [showDialog, setShowDialog] = useState(false)
  const [loading, setLoading] = useState(false)
  const __ = useTranslate()
  const notify = useNotify()
  const dataProvider = useDataProvider()

  const closeDialog = () => setShowDialog(false)

  const handleSubmit = async (values: FormValues) => {
    const { start, endTime, document, userId, entitlementId } = values

    setLoading(true)

    const [entitlement, user] = await Promise.all([
      dataProvider.getOne<Entitlement>(ResourceEnum.entitlements, {
        id: entitlementId,
      }),
      dataProvider.getOne<User>(ResourceEnum.users, {
        id: userId,
      }),
    ])

    dataProvider
      .create<UserEntitlementFormData>('user_entitlements', {
        data: {
          userId,
          entitlementId,
          start,
          endTime,
          document: {
            file: document,
            bucket: userId,
            name: `${entitlement.data.name}-${user.data.username}`,
          },
        },
      })
      .then(() => {
        closeDialog()
        onChange()
        notify(`notifications.assign.${type}`, 'success')
      })
      .catch((error) => {
        notify(error.message, 'error')
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const options =
    resource === 'user'
      ? {
          record: {
            id: '',
            userId: resourceId,
          },
          label: `resource.users.user_${type}`,
          source: 'entitlementId',
          reference: type,
          optionText: 'name',
        }
      : {
          record: {
            id: '',
            entitlementId: resourceId,
          },
          label: 'resource.entitlements.operators',
          source: 'userId',
          reference: 'operators',
          optionText: function (user?: User) {
            if (user) {
              return `${user.lastName} ${user.firstName}`
            }
            return ''
          },
        }

  return (
    <>
      <Button onClick={() => setShowDialog(true)} label="actions.add">
        <PlusIcon />
      </Button>
      <CustomFormDialog
        isOpen={showDialog}
        onClose={closeDialog}
        submit={handleSubmit}
        isLoading={loading}
        resource="user_entitlements"
        title={__('actions.add')}
        record={options.record}
      >
        <ReferenceInput
          perPage={25}
          label={options.label}
          source={options.source}
          reference={options.reference}
          validate={required()}
        >
          <AutocompleteInput optionText={options.optionText} />
        </ReferenceInput>

        <DateInput label={'resource.user_entitlements.start'} source="start" />
        <DateInput
          label={'resource.user_entitlements.endDate'}
          source="endTime"
        />
        <FileInput
          source="document"
          label="resource.documents.file"
          multiple={false}
        >
          <FileField source="src" title="rawFile.name" />
        </FileInput>
      </CustomFormDialog>
    </>
  )
}
