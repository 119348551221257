import {
  // https://stackoverflow.com/a/64135466
  unstable_createMuiStrictModeTheme as createMuiTheme,
} from '@material-ui/core'
import type { Theme } from '@material-ui/core'
import {
  colors,
  fontFamilies,
  fontWeights,
  letterSpacings,
} from '@nartex/smartforest-design-tokens'
import { mergeDeepRight } from 'rambda'
import { defaultTheme } from 'react-admin'

import type { RaThemeOptions } from 'react-admin'

const fontFamilyMap = {
  'Frank Ruhl Libre': '"Frank Ruhl Libre", serif',
  'Source Sans Pro': '"Source Sans Pro", sans-serif',
}

const baseFS = 14
const multiplier = 1.3
const spacing = 8
const rem = spacing * 2
const maxWidth = '60ch'

export function typeScale(increment: number, narrow = false) {
  const fontSize = Math.ceil(baseFS * multiplier ** increment)
  const extraSpace = increment < 0 ? spacing / 2 : spacing
  let lineHeight = fontSize - (fontSize % spacing)
  if (!narrow) {
    lineHeight += extraSpace
  }

  return {
    maxWidth,
    fontSize,
    lineHeight: `${lineHeight / rem}rem`,
  }
}

const options: RaThemeOptions = {
  // breakpoints: {},
  // direction: {},
  shape: {
    borderRadius: 1,
  },
  palette: {
    primary: {
      main: colors.info,
      contrastText: colors.onInfo,
    },
    secondary: {
      main: colors.primary,
      contrastText: colors.onPrimary,
    },
    info: {
      main: colors.shadeMain,
      contrastText: colors.onShade,
    },
    warning: {
      main: colors.error,
      contrastText: colors.onError,
    },
    error: {
      main: colors.primary,
      contrastText: colors.onPrimary,
    },
    success: {
      main: colors.success,
      contrastText: colors.onSuccess,
    },
    background: {
      default: colors.background,
      paper: colors.surface,
    },
    grey: {
      '100': colors.shade10,
      '200': colors.shade20,
      '400': colors.shade40,
      '600': colors.shadeMain,
      '800': colors.shade80,
      '900': colors.shade100,
    },
    text: {
      disabled: colors.shade20,
      hint: colors.shadeMain,
      primary: colors.onBackground,
      secondary: colors.shade80,
    },
  },
  props: {},
  spacing,
  shadows: ['none', ...Array(25 - 1).fill('none')] as any as Theme['shadows'],
  transitions: {},
  typography: {
    htmlFontSize: rem,
    fontFamily: fontFamilies.bodyM,
    fontSize: baseFS,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 700,
    h1: {
      fontFamily: fontFamilyMap[fontFamilies.headingL],
      fontWeight: fontWeights.headingL,
      letterSpacing: letterSpacings.headingL,
      color: colors.shadeMain,
      ...typeScale(4, true),
    },
    h2: {
      fontFamily: fontFamilyMap[fontFamilies.headingM],
      fontWeight: fontWeights.headingM,
      letterSpacing: letterSpacings.headingM,
      color: colors.shade80,
      ...typeScale(2),
    },
    h3: {
      fontFamily: fontFamilyMap[fontFamilies.headingS],
      fontWeight: fontWeights.headingS,
      letterSpacing: letterSpacings.headingS,
      color: colors.shade40,

      ...typeScale(1),
    },
    h4: {
      fontFamily: fontFamilyMap[fontFamilies.headingXs],
      fontWeight: fontWeights.headingXs,
      letterSpacing: letterSpacings.headingXs,

      ...typeScale(0),
    },
    h5: {
      fontFamily: fontFamilyMap[fontFamilies.headingXxs],
      fontWeight: fontWeights.headingXxs,
      letterSpacing: letterSpacings.headingXxs,
      textTransform: 'uppercase',

      ...typeScale(1),
    },
    h6: {
      fontFamily: fontFamilyMap[fontFamilies.headingXxs],
      fontWeight: fontWeights.headingXxs,
      letterSpacing: letterSpacings.headingXxs,
      textTransform: 'uppercase',

      ...typeScale(0),
    },

    subtitle1: {
      fontFamily: fontFamilyMap[fontFamilies.bodyL],
      fontWeight: fontWeights.bodyL,
      ...typeScale(1.5),
    },
    subtitle2: {
      fontFamily: fontFamilyMap[fontFamilies.bodyM],
      fontWeight: fontWeights.bodyM,

      ...typeScale(0.5),
    },
    body1: {
      fontFamily: fontFamilyMap['Source Sans Pro'],
      fontWeight: fontWeights.bodyS,
      letterSpacing: letterSpacings.bodyS,

      ...typeScale(0),
    },
    body2: {
      fontFamily: fontFamilyMap['Source Sans Pro'],
      fontWeight: fontWeights.bodyXs,

      ...typeScale(-0.5),
    },
    button: {
      fontFamily: fontFamilyMap[fontFamilies.button],
      fontWeight: 700,
      textTransform: 'uppercase',
      letterSpacing: '0.01em',

      ...typeScale(0.5),
    },
    caption: {
      fontFamily: fontFamilyMap[fontFamilies.headingXxs],
      fontWeight: fontWeights.headingXxs,
      textTransform: 'uppercase',
      letterSpacing: '0.1ch',
      ...typeScale(-0.75),
    },
    overline: {
      textTransform: 'none',
      ...typeScale(-0.5),
    },
  },
  zIndex: {},
}

export const tokens = createMuiTheme(mergeDeepRight(defaultTheme, options))
