import type { CreateProps } from 'react-admin'
import { SfmCreate } from 'src/AppScopes/resource'
import { RoleEnum } from 'src/UserIdentity'

import { ItemForm } from './Form'

export function Create(props: CreateProps) {
  return (
    <SfmCreate {...props} acceptedRoles={[RoleEnum.ROLE_CONFIGURATOR]}>
      <ItemForm />
    </SfmCreate>
  )
}
