import { ReactComponent as PlusIcon } from '@nartex/smartforest-design-tokens/graphics/svg/bxBxPlus.svg'
import type { PropsWithChildren } from 'react'
import React, { useState } from 'react'

import { Button, useCreate, useNotify, useTranslate } from 'react-admin'

import type { Identifier } from 'react-admin'

import { CustomFormDialog } from 'src/components/CustomFormDialog'

import { ContactForm } from './Form'

interface Props {
  clientId: string | Identifier
  onChange: () => void
}

export function AddContactButton(props: PropsWithChildren<Props>) {
  const { clientId, onChange } = props
  const __ = useTranslate()
  const notify = useNotify()
  const [showDialog, setShowDialog] = useState(false)
  const [create, { loading }] = useCreate('contacts')

  const closeDialog = () => setShowDialog(false)

  const handleSubmit = (values: any) => {
    create(
      {
        payload: {
          data: {
            ...values,
            client: clientId,
          },
        },
      },
      {
        onSuccess() {
          notify('notifications.creation.contact', 'success')
          closeDialog()
          onChange()
        },
        onFailure(params: { error: any }) {
          const { error } = params
          notify(error.message, 'error')
        },
      },
    )
  }

  return (
    <>
      <Button onClick={() => setShowDialog(true)} label="actions.create">
        <PlusIcon />
      </Button>
      <CustomFormDialog
        isOpen={showDialog}
        onClose={closeDialog}
        submit={handleSubmit}
        isLoading={loading}
        resource="contact"
        title={__('actions.add_contact')}
      >
        <ContactForm />
      </CustomFormDialog>
    </>
  )
}
