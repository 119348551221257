import polyglotI18nProvider from 'ra-i18n-polyglot'
import { useEffect } from 'react'
import { useSetLocale } from 'react-admin'

import { translations as defaultTranslations } from './en'

const SUPPORTED_LANGUAGES = ['fr', 'en']
const DEFAULT_LOCALE = 'en'

export function I18nProvider() {
  return polyglotI18nProvider((language) => {
    if (language === 'fr') {
      return import('./fr').then((module) => module.translations)
    }

    return defaultTranslations
  }, DEFAULT_LOCALE)
}

export function useSupportedBrowserLocale() {
  const setLocale = useSetLocale()
  useEffect(() => {
    const preferredLanguage = navigator.languages.find((lang) =>
      SUPPORTED_LANGUAGES.includes(lang),
    )

    if (preferredLanguage && preferredLanguage !== DEFAULT_LOCALE) {
      setLocale(preferredLanguage)
    }
  }, [setLocale])
}
