import { IonBadge, IonItem, IonLabel } from '@ionic/react'
import ChevronDownIcon from '@nartex/smartforest-design-tokens/graphics/react/BxBxChevronDown'
import ChevronUpIcon from '@nartex/smartforest-design-tokens/graphics/react/BxBxChevronUp'
import classNames from 'classnames'
import type { MouseEvent, PropsWithChildren } from 'react'
import { useEffect, useRef } from 'react'
import { useFormContext, useFormState } from 'react-hook-form'
import type { Rule } from 'src/Interpreter/types'

import type { FieldNode } from '../types'
import { useInterpretProperty } from '../useInterpretProperty'

import { useAccordionContext } from './AccordionContext'
import { AccordionHiddenContext } from './AccordionHiddenContext'

export * from './AccordionContext'

interface Props {
  id: string
  name: string
  hidden?: boolean | Rule
  label?: string
  fields?: FieldNode[]
}

export function Accordion(props: PropsWithChildren<Props>) {
  const { id, label, hidden, fields, children } = props
  const { openedAccordionId, setOpenedAccordionId } = useAccordionContext()
  const isOpen = openedAccordionId === id

  const rootRef = useRef<HTMLDivElement>(null)
  useEffect(() => {
    // scroll into view on open
    if (isOpen) {
      rootRef.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'start',
      })
    }
  }, [isOpen])

  const onClick = (event: MouseEvent) => {
    event.stopPropagation()
    setOpenedAccordionId(isOpen ? null : id)
  }

  // Get error number
  const formState = useFormState()
  const errorsCount =
    fields?.filter(
      (field) =>
        'name' in field.data &&
        Boolean(formState.errors[field.data.name as string]),
    ).length || 0

  const isHidden = useInterpretProperty(hidden, useFormContext())

  return (
    <div
      ref={rootRef}
      className={classNames('accordion-container', isOpen && '-isOpen')}
      hidden={isHidden}
    >
      <IonItem
        button
        onClick={onClick}
        className={classNames('header', isOpen && '-sticky')}
        detail={false}
        lines="none"
      >
        <IonLabel className="title">{label}</IonLabel>
        {errorsCount > 0 && (
          <IonBadge slot="end" color="error" className="error-badge">
            {errorsCount}
          </IonBadge>
        )}
        {isOpen ? (
          <ChevronUpIcon slot="end" className="input-icon -isAction" />
        ) : (
          <ChevronDownIcon slot="end" className="input-icon -isAction" />
        )}
      </IonItem>
      <AccordionHiddenContext.Provider value={isHidden}>
        <div className="content" hidden={!isOpen}>
          {children}
        </div>
      </AccordionHiddenContext.Provider>
    </div>
  )
}
