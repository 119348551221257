import { SfmCreate } from 'src/AppScopes/resource'
import type { SfmCreateProps } from 'src/AppScopes/resource'

import { Form } from './Form'

export function Create(props: SfmCreateProps) {
  return (
    <SfmCreate {...props}>
      <Form />
    </SfmCreate>
  )
}
