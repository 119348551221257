import { SfmTextInput, SfmTextArea } from 'src/components/SfmInputs'

export function EntitlementForm() {
  return (
    <>
      <SfmTextInput source="name" label="resource.entitlements.name" />
      <SfmTextArea
        source="description"
        label="resource.entitlements.description"
      />
    </>
  )
}
