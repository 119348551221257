import type { FieldNode, FuncArgs, Get } from '@nartex/sfm-form-engine'
import { FuncNameEnum } from '@nartex/sfm-form-engine'

import type { ArgumentLiteral, ArgumentValue, Operator } from './operators'
import { isArgumentVariableValue, ArgumentTypeEnum } from './operators'

export function getArgumentsValues(
  fields: FieldNode[],
  operator: Operator,
  args: FuncArgs,
): Record<string, ArgumentValue> {
  const argumentsValue: Record<string, ArgumentValue> = {}
  const operatorArguments = Object.keys(operator.arguments)
  operatorArguments.forEach((argName, index) => {
    const value = args[index]
    if (!value) return
    if (value.type === 'literal') {
      if (('meta' in value) as any) {
        argumentsValue[argName] = {
          type: (value as ArgumentLiteral).meta.argumentType,
          value: value.value,
        }
        return
      }
      argumentsValue[argName] = {
        type: ArgumentTypeEnum.openTextValue,
        value: value.value,
      }
      return
    }
    if (value.type === 'function' && value.funcName === FuncNameEnum.getValue) {
      argumentsValue[argName] = {
        type: ArgumentTypeEnum.variable,
        value: fields.find((field) => field.data.name === value.args[0].value),
      }
      return
    }
    throw new Error(`Unknown value type ${JSON.stringify(value)}`)
  })

  return argumentsValue
}

export function encodeArguments(
  argumentsValue: Record<string, ArgumentValue>,
  operator: Operator,
): FuncArgs {
  const operatorArguments = Object.keys(operator.arguments)
  return operatorArguments.map((argName) => {
    const argValue = argumentsValue[argName]

    if (!argValue) return undefined

    if (isArgumentVariableValue(argValue)) {
      if (!argValue.value) return undefined
      return {
        $$isExpr: true,
        type: 'function',
        funcName: FuncNameEnum.getValue,
        args: [
          {
            $$isExpr: true,
            type: 'literal',
            meta: {
              argumentType: argValue.type,
            },
            value: argValue.value.data.name,
          } as ArgumentLiteral,
        ],
      } as Get
    }

    return {
      $$isExpr: true,
      type: 'literal',
      meta: {
        argumentType: argValue.type,
      },
      value: argValue.value,
    } as ArgumentLiteral
  })
}
