import { Typography } from '@material-ui/core'
import classNames from 'classnames'
import { useCallback, useState } from 'react'
import type { DroppableProvided } from 'react-beautiful-dnd'
import { Droppable } from 'react-beautiful-dnd'

import { useTranslate } from '../adapters/I18nProvider'
import { Separator } from '../components/Separator'
import { AreaNameEnum } from '../DragDropChoregrapher'

import { DraggableElement } from './DraggableElement'
import { useStyles } from './styles'
import type { BlueprintsGroup } from './types'

interface SectionProps extends BlueprintsGroup {
  isFirst: boolean
}

export function Section(props: SectionProps) {
  const { displayName, bluePrints, isFirst } = props

  const __ = useTranslate()
  const styles = useStyles()

  const [isHoveringLastElement, setIsHovering] = useState(false)

  const droppableRenderer = useCallback(
    (provided: DroppableProvided) => {
      const { innerRef, droppableProps } = provided

      return (
        <ul
          className={classNames(
            styles.section,
            isHoveringLastElement && styles.sectionLastElementHovered,
          )}
          ref={innerRef}
          {...droppableProps}
        >
          {bluePrints.map((bluePrint, index) => {
            const isLast = index === bluePrints.length - 1
            return (
              <DraggableElement
                key={bluePrint.inputName}
                bluePrint={bluePrint}
                index={index}
                onHover={isLast ? setIsHovering : undefined}
              />
            )
          })}
        </ul>
      )
    },
    [
      bluePrints,
      styles.section,
      styles.sectionLastElementHovered,
      setIsHovering,
      isHoveringLastElement,
    ],
  )

  return (
    <>
      {!isFirst && <Separator />}
      <Typography variant="caption">{__(displayName)}</Typography>
      <Droppable
        droppableId={`${AreaNameEnum.LIBRARY}/${displayName.id}`}
        isDropDisabled={true}
      >
        {droppableRenderer}
      </Droppable>
    </>
  )
}
