import type { Identifier } from 'react-admin'
import { FileField, useRecordContext } from 'react-admin'
import { useMinioFile } from 'src/libs/useMinioFile'

interface Props {
  fileName: string
  bucketId: Identifier
  title?: string
  label?: string
  className?: string
}

export function MinioFileField(props: Props) {
  const { fileName, bucketId, title, label, className } = props
  const record = useRecordContext()
  const { getFileUrl } = useMinioFile()

  return (
    <FileField
      record={{ ...record, file: getFileUrl(fileName, bucketId) }}
      source="file"
      title={title || fileName}
      label={label}
      target="_blank"
      className={className}
    />
  )
}
