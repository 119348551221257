import { makeStyles } from '@material-ui/core'
import type { CreateCSSProperties } from '@material-ui/core/styles/withStyles'
import type { Spec, FieldNode } from '@nartex/sfm-form-engine'
import useProfunctorState from '@staltz/use-profunctor-state'
import { useState, useEffect } from 'react'
import { useDeepCompareMemo } from 'use-deep-compare'

import { useTranslate } from './adapters/I18nProvider'
import { Canvas } from './Canvas'
import { ComponentEditor } from './ComponentEditor'
import { DragDropChoregrapher } from './DragDropChoregrapher'
import { FieldsContext } from './FieldsContext'
import { LeftPane } from './LeftPane'
import { fieldsToVariablesList } from './libs/fieldNamesUtils'
import { flatFieldsLens } from './libs/flatFieldsLens'
import { border } from './theme/mixins'
import { TopBar } from './TopBar'
import { ErrorsContextProvider } from './Validation/Errors'

const useStyle = makeStyles(function (theme) {
  const scrollable: CreateCSSProperties<{}> = {
    maxHeight: '100%',
    overflowY: 'auto',
  }

  return {
    appLayout: {
      width: '100vw',
      height: '100vh',

      display: 'grid',
      gridTemplateAreas: `
        "nav       nav    nav"
        "left-pane canvas props"
      `,
      gridTemplateColumns: `${theme.spacing(40)}px 1fr ${theme.spacing(60)}px`,
      gridTemplateRows: `${theme.spacing(7)}px 1fr`,
    },
    topBar: {
      gridArea: 'nav',

      background: theme.palette.secondary.main,
      color: theme.palette.secondary.contrastText,
    },
    leftPane: {
      gridArea: 'left-pane',

      background: theme.palette.background.paper,
      color: theme.palette.text.secondary,

      borderRight: border(theme),
      maxHeight: '100%',
      overflow: 'hidden',
    },
    canvas: {
      gridArea: 'canvas',

      background: theme.palette.background.default,
      color: theme.palette.text.secondary,

      ...scrollable,
    },
    componentEditor: {
      gridArea: 'props',

      background: theme.palette.background.paper,
      color: theme.palette.text.secondary,

      borderLeft: border(theme),
      ...scrollable,
    },
  }
})

export interface AppProps {
  initialValues: Spec
  onSave: (values: Spec) => Promise<void>
  title?: string
  backLinkTo: string
}

export function App(props: AppProps) {
  const { initialValues, onSave, title, backLinkTo } = props

  const style = useStyle()
  const __ = useTranslate()

  const [selectedFieldId, setSelectedFieldId] = useState<string | null>(null)

  const stateStore = useProfunctorState(initialValues)
  const [formIsDirty, setFormIsDirty] = useState(false)
  const dirtynessStore = stateStore.promap({
    get(spec) {
      return spec
    },
    set(spec: Spec) {
      // maybe find a better way to track dirtyness ?
      setFormIsDirty(true)
      return spec
    },
  })
  const fieldsStore = dirtynessStore.promap(flatFieldsLens)

  const selectedFieldStore = fieldsStore.promap<FieldNode | undefined>(
    (fields) => {
      if (!selectedFieldId) return undefined
      return fields.find((field) => field.data['@id'] === selectedFieldId)
    },
    (updatedField, fields) => {
      if (!updatedField) {
        return fields.filter((field) => field.data['@id'] !== selectedFieldId)
      }

      const fieldIndex = fields.findIndex(
        (field) => field.data['@id'] === selectedFieldId,
      )
      const currentField = fields[fieldIndex]
      if (updatedField === currentField) return fields

      const result = Array.from(fields)
      result[fieldIndex] = updatedField
      return result
    },
    [fieldsStore.state, selectedFieldId],
  )

  useEffect(() => {
    setFormIsDirty(false)
    stateStore.setState(() => initialValues)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateStore.setState, initialValues])

  const fieldNames = useDeepCompareMemo(() => {
    return fieldsToVariablesList(fieldsStore.state)
  }, [fieldsStore.state])

  return (
    <FieldsContext.Provider value={fieldsStore.state}>
      <ErrorsContextProvider fieldNames={fieldNames.map((x) => x.name)}>
        <DragDropChoregrapher
          fieldNames={fieldNames}
          onChange={fieldsStore.setState}
          setSelectedFieldId={setSelectedFieldId}
        >
          <div className={style.appLayout}>
            <TopBar
              className={style.topBar}
              backLinkTo={backLinkTo}
              title={title}
              onSave={async () => {
                await onSave({ ...stateStore.state, fieldNames: fieldNames })
                setFormIsDirty(false)
              }}
              isDirty={formIsDirty}
              store={dirtynessStore}
            />

            <LeftPane className={style.leftPane} />
            <Canvas
              className={style.canvas}
              fields={fieldsStore.state}
              setSelectedFieldId={setSelectedFieldId}
              selectedFieldId={selectedFieldId}
            />
            <ComponentEditor
              className={style.componentEditor}
              store={selectedFieldStore}
            />
          </div>
        </DragDropChoregrapher>
      </ErrorsContextProvider>
    </FieldsContext.Provider>
  )
}
