import { Typography } from '@material-ui/core'
import { useEffect } from 'react'
import {
  ReferenceInput,
  AutocompleteInput,
  BooleanInput,
  required,
  FormDataConsumer,
  useTranslate,
} from 'react-admin'
import { useForm } from 'react-final-form'
import { SfmTextInput } from 'src/components/SfmInputs'
import { usePersona } from 'src/UserIdentity'

import { UploadEquipmentsSheetInput } from './UploadEquipmentsSheet'

export function Form(props: { isRound: boolean }) {
  const { isRound } = props

  const persona = usePersona()
  const translate = useTranslate()

  const form = useForm()
  useEffect(() => {
    form.setConfig('validate', (values) => {
      const errors: Record<string, string> = {}
      if (isRound && !values.fullListChecker) {
        errors.fullListChecker = 'required'
      }

      return errors
    })
  }, [form, isRound])

  return (
    <>
      <SfmTextInput
        source="name"
        label="resource.interventions.name"
        validate={required('validation.required')}
      />
      <SfmTextInput source="location" label="resource.interventions.location" />
      <FormDataConsumer>
        {(formProps) => {
          const { formData } = formProps

          if (isRound) return null

          if (formData?.interventionData?.length > 0) {
            return (
              <>
                <Typography color="primary">
                  {translate('error.recurring_value_blocked')}
                </Typography>
                <BooleanInput
                  source="isRecurrent"
                  label="resource.interventions.recurring"
                  defaultValue={false}
                  disabled
                />
              </>
            )
          }
          return (
            <BooleanInput
              source="isRecurrent"
              label="resource.interventions.recurring"
              defaultValue={false}
            />
          )
        }}
      </FormDataConsumer>
      <ReferenceInput
        source="form"
        reference="forms"
        label="resource.interventions.form"
        filter={{
          'exists[lastVersion]': true,
          isArchived: false,
        }}
        filterToQuery={(searchText) => {
          return { q: searchText }
        }}
        validate={required('validation.required')}
      >
        <AutocompleteInput />
      </ReferenceInput>
      <ReferenceInput
        source="project"
        reference="projects"
        label="resource.interventions.project"
        filter={{ 'workOrders.client': persona.clientId }}
        filterToQuery={(searchText) => {
          return { q: searchText }
        }}
      >
        <AutocompleteInput resettable />
      </ReferenceInput>
      {!isRound && (
        <FormDataConsumer key="workOrder">
          {(params) => {
            const { formData, ...rest } = params
            return (
              <ReferenceInput
                source="workOrder"
                reference="work_orders"
                label="resource.interventions.workOrder"
                filter={{ client: persona.clientId, project: formData.project }}
                filterToQuery={(searchText) => {
                  return { reference: searchText }
                }}
                {...rest}
              >
                <AutocompleteInput optionText="reference" resettable />
              </ReferenceInput>
            )
          }}
        </FormDataConsumer>
      )}

      {isRound && <UploadEquipmentsSheetInput />}
    </>
  )
}
