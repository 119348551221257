import AddAudioIcon from '@nartex/smartforest-design-tokens/graphics/react/BxBxsMicrophone'
import AudioIcon from '@nartex/smartforest-design-tokens/graphics/react/BxBxsVolumeFull'

import type { InputProps } from '../types'

import { MediaInput } from './MediaInput'

export function AudioInput(props: InputProps) {
  return (
    <MediaInput
      {...props}
      mediaType="Audio"
      Icon={AudioIcon}
      AddIcon={AddAudioIcon}
      renderMedia={(src) => (
        <audio controls src={src} controlsList="nodownload" />
      )}
    />
  )
}
