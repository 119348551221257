import type { SfmCreateProps } from 'src/AppScopes'
import { SfmCreate } from 'src/AppScopes'
import { SfmTextInput } from 'src/components/SfmInputs'
import { RoleEnum } from 'src/UserIdentity'

export function Create(props: SfmCreateProps) {
  return (
    <SfmCreate {...props} acceptedRoles={[RoleEnum.ROLE_CONFIGURATOR]}>
      <SfmTextInput source="name" label="resource.reports.name" />
    </SfmCreate>
  )
}
