import { validate as validateEmail } from 'email-validator'
import { SelectArrayInput, useTranslate, required } from 'react-admin'
import { ClientInput } from 'src/components/ClientInput'
import { Section } from 'src/components/Section'
import { SfmTextInput } from 'src/components/SfmInputs'
import { useValidateUnicity } from 'src/components/UniqueInput'
import type { User } from 'src/types/api'
import { ResourceEnum } from 'src/types/api/resources'

export function Form() {
  const __ = useTranslate()
  const roles_choices = [
    { id: 'ROLE_ADMIN', name: __('apiRoles.ROLE_ADMIN') },
    { id: 'ROLE_MANAGER', name: __('apiRoles.ROLE_MANAGER') },
    { id: 'ROLE_CONFIGURATOR', name: __('apiRoles.ROLE_CONFIGURATOR') },
    { id: 'ROLE_OPERATOR', name: __('apiRoles.ROLE_OPERATOR') },
  ]
  const unique = useValidateUnicity<User>(ResourceEnum.users)

  const emailValidation = (value: string) => {
    if (!validateEmail(value)) {
      return 'error.invalid_email'
    }
    return undefined
  }

  return (
    <>
      <Section label="resource.users.sections.login">
        <SfmTextInput
          source="username"
          label="resource.users.username"
          validate={[required('validation.required'), unique('username')]}
        />
      </Section>
      <Section label="resource.users.sections.identity">
        <SfmTextInput
          source="lastName"
          label="resource.users.lastname"
          validate={required('validation.required')}
        />
        <SfmTextInput
          source="firstName"
          label="resource.users.firstname"
          validate={required('validation.required')}
        />
        <SfmTextInput
          source="emailAddress"
          label="resource.users.emailAddress"
          type="email"
          validate={[
            required('validation.required'),
            unique('emailAddress'),
            emailValidation,
          ]}
        />
      </Section>

      <Section label="resource.users.sections.position">
        <SfmTextInput
          source="personalNumber"
          label="resource.users.personalNumber"
        />
        <SfmTextInput source="companyName" label="resource.users.companyName" />
        <SfmTextInput source="serviceName" label="resource.users.serviceName" />
        <SfmTextInput source="workplace" label="resource.users.workplace" />
      </Section>
      <Section label="resource.users.sections.access">
        <SelectArrayInput
          source="roles"
          label="resource.users.roles"
          choices={roles_choices}
          validate={required('validation.required')}
        />

        <ClientInput source="clients" label="resource.users.clients" many />
      </Section>
    </>
  )
}
