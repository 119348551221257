import { useTranslate } from 'react-admin'
import type { ShowProps } from 'react-admin'

import { SfmShow } from 'src/AppScopes'
import { AddressField } from 'src/components/AddressField'
import { SfmTextField, SfmEmailField } from 'src/components/SfmFields'
import { ShowLayout } from 'src/components/ShowLayout'

import type { Contact } from 'src/types/api'

import { PhoneNumbersField } from '../../components/PhoneNumbersField'

export function Show(props: ShowProps) {
  const __ = useTranslate()

  return (
    <SfmShow {...props}>
      <ShowLayout<Contact> title={(contact) => contact.name}>
        <SfmTextField
          source="serviceName"
          label="resource.contacts.serviceName"
        />

        <SfmEmailField source="emailAddress" label="resource.contacts.email" />

        <PhoneNumbersField label="resource.contacts.phoneNumbers" />
        <AddressField label="resource.contacts.address" multiline />
        <SfmTextField source="notes" label="resource.contacts.notes" />
      </ShowLayout>
    </SfmShow>
  )
}
