import type { Theme } from '@material-ui/core'
import type { CreateCSSProperties } from '@material-ui/core/styles/withStyles'

export function border(theme: Theme) {
  return `1px solid ${theme.palette.grey[100]}`
}
export function shadow(mul = 1) {
  return `${1 * mul}px ${2 * mul}px 0 0px rgba(0, 0, 0, 0.1)`
}

export function ruleShadow() {
  return { boxShadow: `0px 2px 4px rgba(0, 0, 0, 0.1)` }
}

export function rulerStroke(
  theme: Theme,
  orientation: 'horizontal' | 'vertical',
): CreateCSSProperties<{}> {
  const base: CreateCSSProperties<{}> = {
    content: '""',
    position: 'absolute',
    display: 'block',
    zIndex: 1,
  }

  if (orientation === 'vertical') {
    return {
      ...base,
      borderLeft: border(theme),
      top: 0,
      bottom: 0,
    }
  }

  if (orientation === 'horizontal') {
    return {
      ...base,
      borderTop: border(theme),
      left: 0,
      right: 0,
    }
  }

  return null as never
}

export function code(): CreateCSSProperties<{}> {
  return {
    fontFamily: 'Roboto Mono, monospace',
    fontSize: '13px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '16px',
    letterSpacing: '0em',
    textAlign: 'left',
  }
}
export function smallCode(): CreateCSSProperties<{}> {
  return {
    fontFamily: 'Roboto Mono, monospace',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '16px',
    letterSpacing: '0em',
    textAlign: 'left',
  }
}

export function bodyS(): CreateCSSProperties<{}> {
  return {
    fontFamily: 'Frank Ruhl Libre',
    fontSize: '14px',
    fontStyle: 'normal',
    lineHeight: '16px',
    letterSpacing: '0em',
    textAlign: 'left',
  }
}
export function bodySBold(): CreateCSSProperties<{}> {
  return {
    ...bodyS(),
    fontWeight: 900,
    fontSize: '14px',
  }
}
export function microButton(): CreateCSSProperties<{}> {
  return {
    fontFamily: 'Source Sans Pro',
    fontSize: '12px',
    fontStyle: 'normal',
    fontHeight: 700,
    lineHeight: '16px',
    letterSpacing: '0.05em',
    textAlign: 'left',
  }
}
export function RulesTitle(): CreateCSSProperties<{}> {
  return {
    fontFamily: 'Source Sans Pro',
    fontSize: '16px',
    fontStyle: 'normal',
    fontHeight: 700,
    lineHeight: '16px',
    letterSpacing: '0.07em',
    textAlign: 'left',
  }
}
