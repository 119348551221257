import { IonDatetime } from '@ionic/react'
import IconCalendar from '@nartex/smartforest-design-tokens/graphics/react/BxBxCalendar'
import IconTime from '@nartex/smartforest-design-tokens/graphics/react/BxBxTimeFive'
import { format, parseISO } from 'date-fns'

import type { SvgComponent } from '../../types'

import type { DateTimeInputProps } from '../types'

import { BaseInputWrapper as InputWrapper } from './BaseInputWrapper'
import { PICKER_OPTION } from './SelectInput'

// TODO translate
const MONTH_NAMES = [
  'Janvier',
  'Février',
  'Mars',
  'Avril',
  'Mai',
  'Juin',
  'Juillet',
  'Août',
  'Septembre',
  'Octobre',
  'Novembre',
  'Décembre',
]

const DAY_NAMES = [
  'Dimanche',
  'Lundi',
  'Mardi',
  'Mercredi',
  'Jeudi',
  'Vendredi',
  'Samedi',
]

interface BaseProps extends DateTimeInputProps {
  displayFormat?: string
  Icon?: SvgComponent
  formatValue?: (value: string | null | undefined) => any
}

function BasicDateTimeInput(props: BaseProps) {
  const { min, max, displayFormat, formatValue } = props
  return (
    <InputWrapper
      {...props}
      className="border-input"
      render={(renderProps) => {
        const { value, onChange } = renderProps
        return (
          <IonDatetime
            displayFormat={displayFormat}
            value={value}
            min={min}
            max={max}
            onIonChange={(e) =>
              onChange(
                formatValue ? formatValue(e.detail.value) : e.detail.value,
              )
            }
            monthNames={MONTH_NAMES}
            dayNames={DAY_NAMES}
            style={{ '--padding-top': 0 }}
            {...PICKER_OPTION}
          />
        )
      }}
    />
  )
}

export function TimeInput(props: DateTimeInputProps) {
  return (
    <BasicDateTimeInput
      {...props}
      displayFormat="HH:mm:ss"
      Icon={IconTime}
      formatValue={(value) => {
        if (!value) return value
        try {
          const date = parseISO(value)
          return format(date, 'HH:mm:ss')
        } catch (error) {
          if (error instanceof RangeError) {
            return value
          }
          throw error
        }
      }}
    />
  )
}

const DATETIME_FORMAT = 'DD MMMM YYYY HH:mm'

export function DateTimeInput(props: DateTimeInputProps) {
  return (
    <BasicDateTimeInput
      {...props}
      Icon={IconCalendar}
      displayFormat={DATETIME_FORMAT}
    />
  )
}

export function CalendarInput(props: DateTimeInputProps) {
  return (
    <BasicDateTimeInput
      {...props}
      Icon={IconCalendar}
      displayFormat="DDDD DD MMMM YYYY"
    />
  )
}
