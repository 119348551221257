import { makeStyles, Typography, Box } from '@material-ui/core'
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt'
import { useTranslate, Link } from 'react-admin'

import { typeScale } from 'src/UI/theme'

const useStyles = makeStyles(function (theme) {
  return {
    showLink: {
      textDecoration: 'none',
      color: theme.palette.primary.main,
      display: 'block',
      ...typeScale(0),
    },
  }
})

interface ShowLinkProps {
  to: string
  label?: string
  arrow?: boolean
}

export function ShowLink(props: ShowLinkProps) {
  const { to, label, arrow = false } = props
  const styles = useStyles()
  const translate = useTranslate()
  return (
    <Typography
      className={styles.showLink}
      variant="button"
      component={(muiProps) => (
        <Link to={to} {...muiProps}>
          <Box display="flex" alignItems="center">
            <span>{label || translate('actions.show')}</span>
            {arrow && <ArrowRightAltIcon fontSize="small" />}
          </Box>
        </Link>
      )}
    />
  )
}
