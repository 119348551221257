import { format, formatISO } from 'date-fns'
import type { PropsWithChildren, ReactElement } from 'react'
import { useEffect, useMemo, createElement } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { QueryClient, QueryClientProvider } from 'react-query'

import { SubmitButton } from '../components/SubmitButton'
import type { InputName, FieldNode } from '../fields'
import { fieldRenderersMap } from '../fields'
import { AccordionContextProvider } from '../fields/Accordion'
import { PluginsContext } from '../plugins'
import type { FormValues, Plugins } from '../plugins'

import type { SpecConfig } from './SpecConfigContext'
import { SpecConfigContext } from './SpecConfigContext'

export type { SpecConfig } from './SpecConfigContext'
export { PhotoSizeEnum, VideoQualityEnum } from './SpecConfigContext'

const queryClient = new QueryClient()

const dummyPlugins: Plugins = {
  BarCodePlugin: {} as any,
  MediaPlugin: {} as any,
  NfcPlugin: {} as any,
  SignaturePlugin: {} as any,
}

interface DummyFormProviderProps {}

export function DummyFormProvider(
  props: PropsWithChildren<DummyFormProviderProps>,
) {
  const { children } = props

  const methods = useForm()
  return (
    <PluginsContext.Provider value={dummyPlugins}>
      <FormProvider {...methods}>
        <QueryClientProvider client={queryClient}>
          {children}
        </QueryClientProvider>
      </FormProvider>
    </PluginsContext.Provider>
  )
}

export interface FieldName {
  type: InputName
  name: string
  label: string
}

export interface Spec {
  fields: FieldNode[]
  fieldNames: FieldName[]
  config?: SpecConfig
}

interface Props<T extends FormValues['values']> {
  spec: Spec
  onSubmit: (values: T) => void
  onChange: (values: T) => void
  initialValues?: T
}

export function Form<T extends FormValues['values'] = FormValues['values']>(
  props: Props<T>,
) {
  const { spec, onSubmit, onChange, initialValues } = props

  const [renderedFields, defaultValues] = useMemo(() => {
    return [renderFields(spec.fields), getDefaultValues(spec.fields)]
  }, [spec.fields])

  const methods = useForm({
    defaultValues,
  })
  const { handleSubmit, watch, reset } = methods

  useEffect(() => {
    if (initialValues) reset({ ...defaultValues, ...initialValues })
  }, [reset, initialValues, defaultValues])

  useEffect(() => {
    const sub = watch(onChange as any)
    return () => sub.unsubscribe()
  }, [watch, onChange])

  return (
    <SpecConfigContext.Provider value={spec.config}>
      <FormProvider {...methods}>
        <AccordionContextProvider>
          <QueryClientProvider client={queryClient}>
            <form
              onSubmit={handleSubmit(onSubmit as any)}
              className="form"
              onFocusCapture={(event) => {
                if (['INPUT', 'TEXTAREA'].includes(event.target.tagName)) {
                  const { target } = event
                  setTimeout(() => {
                    target?.scrollIntoView({
                      behavior: 'smooth',
                      block: 'center',
                      inline: 'center',
                    })
                  }, 100)
                }
              }}
            >
              {renderedFields}
              <SubmitButton />
            </form>
          </QueryClientProvider>
        </AccordionContextProvider>
      </FormProvider>
    </SpecConfigContext.Provider>
  )
}

export function renderFields(fields: FieldNode[]): ReactElement[] {
  return fields.map((field, idx) => {
    const props =
      field.type === 'Accordion'
        ? {
            fields: field.children,
            children: renderFields(field.children || []),
          }
        : {}
    return createElement(fieldRenderersMap[field.type] as any, {
      id: field.data['@id'],
      key: idx,
      ...field.data,
      ...props,
    })
  })
}

function getDefaultValues(fields: FieldNode[]) {
  const defaultValues: Record<string, any> = {}
  const flattenedFields = fields
    .map((field) => {
      if (field.type === 'Accordion') {
        return field.children
      }
      return field
    })
    .flat()

  flattenedFields.forEach((field) => {
    if (!field) return

    const {
      type,
      data: { name, defaultValue, hasDefaultValue },
    } = field

    if (defaultValue !== undefined) {
      return (defaultValues[name] = defaultValue)
    }
    if (hasDefaultValue) {
      if (type === 'DateTime')
        return (defaultValues[name] = formatISO(new Date()))
      if (type === 'Date')
        return (defaultValues[name] = format(new Date(), 'yyyy-MM-dd'))
      if (type === 'Time')
        return (defaultValues[name] = format(new Date(), 'HH:mm:ss'))
    }
    if (type === 'Checkbox') return (defaultValues[name] = false)
  })

  return defaultValues
}
