import type { TextInputProps } from 'react-admin'
import {
  TextInput,
  composeValidators,
  maxLength as maxLengthValidator,
} from 'react-admin'

export type SfmTextInputProps = TextInputProps & {
  maxLength?: number
}

export function SfmTextInput(props: SfmTextInputProps) {
  const { maxLength = 255, validate, ...textFieldProps } = props

  return (
    <TextInput
      {...textFieldProps}
      validate={composeValidators(validate, maxLengthValidator(maxLength))}
    />
  )
}

type SfmTextAreaProps = Omit<TextInputProps, 'multiline'> & { source: string }

export function SfmTextArea(props: SfmTextAreaProps) {
  return <SfmTextInput multiline minRows={4} {...props} />
}
