import { Typography } from '@material-ui/core'
import { useState } from 'react'

import type { Identifier } from 'react-admin'
import { useTranslate } from 'react-admin'

import { AddressField } from 'src/components/AddressField'

import { PhoneNumbersField } from 'src/components/PhoneNumbersField'
import { SfmDateField, SfmEmailField } from 'src/components/SfmFields'
import { ShowLayout } from 'src/components/ShowLayout'

import type { Client } from 'src/types/api'
import { RoleEnum, usePersona } from 'src/UserIdentity'

import { ContactList } from './ContactList'

import { UserList } from './UserList'

interface ShowFieldsProps {
  readonly?: boolean
}

export function ShowFields(props: ShowFieldsProps) {
  const { readonly = false } = props
  // Manage deleted contact still in cache
  const [deletedContacts, setDeletedContacts] = useState<Set<Identifier>>(
    new Set(),
  )

  const { role } = usePersona()

  return (
    <ShowLayout<Client>
      title={(record) => record.name}
      subtitle={(record) => record.reference}
      meta={IsValidField}
    >
      <SfmEmailField
        source="emailAddress"
        label="resource.clients.emailAddress"
      />
      <PhoneNumbersField label="resource.clients.phoneNumbers" />
      <AddressField multiline label="resource.clients.address" />
      <UserList readonly={readonly} />

      {role !== RoleEnum.ROLE_ADMIN && (
        <ContactList
          deletedContacts={deletedContacts}
          setDeletedContacts={setDeletedContacts}
          readonly={role !== RoleEnum.ROLE_CONFIGURATOR}
        />
      )}
    </ShowLayout>
  )
}

function IsValidField(record: Client) {
  const __ = useTranslate()

  if (record.valid) {
    if (record.disabledAt) {
      return (
        <SfmDateField
          source="disabledAt"
          label="resource.clients.valid_until"
          color="primary"
        />
      )
    }
    if (record.isValidAt) {
      return (
        <SfmDateField
          source="isValidAt"
          label="resource.clients.valid_since"
          color="primary"
        />
      )
    }
    return (
      <Typography color="primary">{__('resource.clients.valid')}</Typography>
    )
  }

  if (record.isValidAt && new Date(record.isValidAt) > new Date()) {
    return (
      <SfmDateField
        source="isValidAt"
        label="resource.clients.valid_at"
        color="secondary"
      />
    )
  }

  if (record.disabledAt) {
    return (
      <SfmDateField
        source="disabledAt"
        label="resource.clients.disabled_since"
        color="secondary"
      />
    )
  }

  return (
    <Typography color="secondary">{__('resource.clients.disabled')}</Typography>
  )
}
