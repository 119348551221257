import { useFormContext } from 'react-hook-form'

import type { Expr } from '../../Interpreter/types'
import { useInterpretProperty } from '../useInterpretProperty'
import './index.css'

export interface TextDisplayProps {
  label: string
  hidden?: boolean | Expr
}

export function HeadingM(props: TextDisplayProps) {
  const { label, hidden } = props

  const isHidden = useInterpretProperty(hidden, useFormContext())
  if (isHidden) return null

  return <h2 className="BaseTextDisplay -HeadingM">{label}</h2>
}

export function HeadingS(props: TextDisplayProps) {
  const { label, hidden } = props

  const isHidden = useInterpretProperty(hidden, useFormContext())
  if (isHidden) return null

  return <h3 className="BaseTextDisplay -HeadingS">{label}</h3>
}

export function BodyM(props: TextDisplayProps) {
  const { label, hidden } = props

  const isHidden = useInterpretProperty(hidden, useFormContext())
  if (isHidden) return null

  // create a new paragraph if there is a double line break, to increase spacing
  return label.split('\n\n').map((text) => (
    <p key={text} className="BaseTextDisplay -BodyM">
      {text.trim()}
    </p>
  ))
}

export function BodyS(props: TextDisplayProps) {
  const { label, hidden } = props

  const isHidden = useInterpretProperty(hidden, useFormContext())
  if (isHidden) return null

  // create a new paragraph if there is a double line break, to increase spacing
  return label.split('\n\n').map((text) => (
    <p key={text} className="BaseTextDisplay -BodyS">
      {text.trim()}
    </p>
  ))
}
