import { useTranslate } from 'react-admin'
import { joinStrings } from 'src/libs/joinStrings'

import type { SfmFunctionFieldProps } from './SfmFields'
import { SfmFunctionField, EmptyText } from './SfmFields'

export function FullNameField(props: Omit<SfmFunctionFieldProps, 'render'>) {
  const __ = useTranslate()

  function getFullName(record?: any) {
    if (!record) return null

    const fullName = joinStrings(
      [record.lastName, record.firstName],
      ' ',
    ).trim()

    return fullName || <EmptyText />
  }
  return <SfmFunctionField {...props} render={getFullName} />
}
