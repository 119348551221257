export type Expr = Literal<any> | Func | Eval | Rule

export interface Literal<T> {
  $$isExpr: true
  type: 'literal'
  value: T
}

export enum FuncNameEnum {
  getValues = 'getValues',
  getValue = 'getValue',
  isEqualTo = 'isEqualTo',
  isFilled = 'isFilled',
  isBetween = 'isBetween',
  isGreaterThan = 'isGreaterThan',
  isGreaterThanOrEqualTo = 'isGreaterThanOrEqualTo',
  isLessThan = 'isLessThan',
  isLessThanOrEqualTo = 'isLessThanOrEqualTo',
}

export type FuncArgs = (Literal<string | number | boolean> | Get | undefined)[]

export interface Func {
  $$isExpr: true
  type: 'function'
  funcName: FuncNameEnum
  args: FuncArgs
}

export interface Get {
  $$isExpr: true
  type: 'function'
  funcName: FuncNameEnum.getValue
  args: [Literal<string>]
}

export interface Eval {
  $$isExpr: true
  type: 'eval'
  code: string
}

export interface Case {
  condition?: Func | Eval
  then: boolean
}

export interface Rule {
  $$isExpr: true
  type: 'rule'
  cases: Case[]
  default: boolean
}

export type AnyFunction = (...args: any[]) => any
