import { defineMessage } from 'react-intl'

import { definitions } from './definitions'
import type { BlueprintsGroup } from './types'

export const groups: BlueprintsGroup[] = [
  {
    displayName: defineMessage({
      id: 'bluePrintGroups.Layout.sections',
      defaultMessage: 'Mise en page',
      description: 'Titre du groupe de composants "Mise en page"',
    }),
    bluePrints: [
      definitions.Accordion,
      definitions.HeadingM,
      definitions.HeadingS,
      definitions.BodyM,
      definitions.BodyS,
      definitions.PictureDisplay,
    ],
  },
  {
    displayName: defineMessage({
      id: 'bluePrintGroups.Basics.displayName',
      defaultMessage: 'Basiques',
      description: 'Titre du groupe de composants "Basiques"',
    }),
    bluePrints: [definitions.Text, definitions.Number],
  },
  {
    displayName: defineMessage({
      id: 'bluePrintGroups.Choices.displayName',
      defaultMessage: 'Choix',
      description: 'Titre du groupe de composants "Choix"',
    }),
    bluePrints: [
      definitions.Checkbox,
      definitions.SelectOne,
      definitions.SelectMany,
    ],
  },

  {
    displayName: defineMessage({
      id: 'bluePrintGroups.Temporals.displayName',
      defaultMessage: 'Temporalité',
      description: 'Titre du groupe de composants "Temporalité"',
    }),
    bluePrints: [definitions.Time, definitions.Date, definitions.DateTime],
  },

  {
    displayName: defineMessage({
      id: 'bluePrintGroups.Media.displayName',
      defaultMessage: 'Médias',
      description: 'Titre du groupe de composants "Médias"',
    }),
    bluePrints: [
      definitions.Photo,
      definitions.Video,
      definitions.Audio,
      definitions.BarCodeReader,
      definitions.Signature,
    ],
  },
]
