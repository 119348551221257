import { Capacitor } from '@capacitor/core'
import { Filesystem, Directory } from '@capacitor/filesystem'
import PictureIcon from '@nartex/smartforest-design-tokens/graphics/react/IconPicture'
import { useFormContext } from 'react-hook-form'
import { useQuery } from 'react-query'

import type { Expr } from '../../Interpreter/types'
import type { ImageData } from '../types'
import { useInterpretProperty } from '../useInterpretProperty'

import './styles.css'

export interface PictureDisplayProps {
  media?: ImageData
  hidden?: boolean | Expr
}

export function PictureDisplay(props: PictureDisplayProps) {
  const { media, hidden = false } = props
  const isHidden = useInterpretProperty(hidden, useFormContext())
  const src = usePictureSrc(media?.fileName) ?? media?.dataURI

  if (isHidden) return null

  return (
    <div className="PictureDisplay">
      {src ? (
        <img className="image" src={src} alt={media?.name} />
      ) : (
        <div className="placeholder">
          <PictureIcon />
        </div>
      )}
    </div>
  )
}

export function usePictureSrc(pictureName?: string) {
  const { data: mediaSrc } = useQuery(['pictureSrc', pictureName], async () => {
    if (!pictureName) return undefined

    const { uri } = await Filesystem.getUri({
      path: `form/${pictureName}`,
      directory: Directory.Data,
    })

    return Capacitor.convertFileSrc(uri)
  })

  return mediaSrc
}
