import {
  Dialog as MuiDialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from '@material-ui/core'

import { useTranslate } from '../adapters/I18nProvider'

interface DialogProps {
  isOpen: boolean
  onClose: () => void
  title: string
  content: string
  confirmButtonProps: {
    onConfirm: () => void
    label: string
  }
}
export function Dialog(props: DialogProps) {
  const { isOpen, onClose, title, content, confirmButtonProps } = props

  const __ = useTranslate()

  return (
    <MuiDialog open={isOpen} onClose={onClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{content}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          {__({
            id: 'ExitDialog.Action.Cancel',
            defaultMessage: 'Annuler',
            description: 'Text of the button to close the dialog.',
          })}
        </Button>
        <Button onClick={confirmButtonProps.onConfirm} color="secondary">
          {confirmButtonProps.label}
        </Button>
      </DialogActions>
    </MuiDialog>
  )
}
