import { IonSelect, IonSelectOption, IonChip } from '@ionic/react'
import MultiChoiceIcon from '@nartex/smartforest-design-tokens/graphics/react/IconMultiChoice'
import SingleChoiceIcon from '@nartex/smartforest-design-tokens/graphics/react/IconSingleChoice'
import classNames from 'classnames'

import type { SelectInputProps } from '../types'

import { BaseInputWrapper as InputWrapper } from './BaseInputWrapper'

// TODO translate
export const PICKER_OPTION = {
  cancelText: 'annuler',
  okText: 'valider',
  doneText: 'valider',
}

export function SelectOne(props: SelectInputProps) {
  return <SelectInput {...props} multiple={false} />
}

export function SelectMany(props: SelectInputProps) {
  return <SelectInput {...props} multiple={true} />
}

type BaseProps = SelectInputProps & { multiple: boolean }

function SelectInput(props: BaseProps) {
  const { options, multiple } = props

  if (options && options?.length < 5) {
    return <LabelSelectInput options={options} {...props} />
  }

  return (
    <InputWrapper
      {...props}
      Icon={multiple ? MultiChoiceIcon : SingleChoiceIcon}
      className="border-input"
      render={(renderProps) => {
        const { value, onChange } = renderProps
        return (
          <IonSelect
            value={value}
            onIonChange={(e) => onChange(e.detail.value)}
            interface="alert"
            multiple={multiple}
            {...PICKER_OPTION}
          >
            {options?.map((option, index) => {
              if (typeof option === 'string') {
                return <IonSelectOption key={option}>{option}</IonSelectOption>
              }
              return (
                <IonSelectOption
                  key={`${props.name}.${option.value}.${index}`}
                  value={option.value}
                >
                  {option.label}
                </IonSelectOption>
              )
            })}
          </IonSelect>
        )
      }}
    />
  )
}

function LabelSelectInput(props: BaseProps) {
  const { options, multiple } = props
  return (
    <InputWrapper
      {...props}
      className="label-select"
      render={(renderProps) => {
        const { value, onChange } = renderProps

        if (!multiple) {
          return (
            <LabelSelectContent
              name={props.name}
              options={options}
              isSelected={(option) => value === option}
              onClick={onChange}
            />
          )
        }

        const valueSet = new Set(value as string[])
        const isSelected = (option: string) => valueSet.has(option)
        const onClick = (option: string) => {
          if (isSelected(option)) {
            valueSet.delete(option)
            return onChange([...Array.from(valueSet)])
          } else return onChange([...Array.from(valueSet), option])
        }
        return (
          <LabelSelectContent
            name={props.name}
            options={options}
            isSelected={isSelected}
            onClick={onClick}
          />
        )
      }}
    />
  )
}

type LabelSelectProps = {
  options?: (string | { label: string; value: string })[]
  isSelected: (option: string) => boolean
  onClick: (option: string) => void
} & Pick<BaseProps, 'name'>

function LabelSelectContent(props: LabelSelectProps) {
  const { name, options, isSelected, onClick } = props
  return (
    <div className="label-container">
      {options?.map((option, index) => {
        const label = typeof option === 'string' ? option : option.label
        const value = typeof option === 'string' ? option : option.value
        const selected = isSelected(value)

        return (
          <IonChip
            color="primary"
            outline
            className={classNames('choice-chip', selected && '-selected')}
            key={`${name}.${value}.${index}`}
            onClick={() => onClick(value)}
            tabIndex={0}
            aria-role="button"
          >
            {label}
          </IonChip>
        )
      })}
    </div>
  )
}
