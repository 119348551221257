import { makeStyles, useTheme } from '@material-ui/core'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import type { PropsWithChildren, ReactNode } from 'react'
import { useTranslate } from 'react-admin'
import { typeScale } from 'src/UI/theme'
import type { IconComponent } from 'src/UI/theme/icons'

import { FullBleed } from './FullBleed'

export interface LabeledFieldProps {
  label?: string
  children: ReactNode
  actions?: ReactNode
  fullBleed?: boolean
  Icon?: IconComponent
}
export function LabeledField(props: LabeledFieldProps) {
  const { label, actions, children, fullBleed, Icon } = props

  const translate = useTranslate()
  const theme = useTheme()

  const hasIcon = Icon && !fullBleed
  const body = (
    <Box display={'flex'} flexDirection={'column'} gridGap={4} flex={1}>
      {(label || actions) && (
        <CustomLabel actions={actions} fullWidth={fullBleed}>
          {label ? translate(label) : ''}
        </CustomLabel>
      )}
      {fullBleed ? <FullBleed>{children}</FullBleed> : children}
    </Box>
  )

  if (hasIcon) {
    return (
      <Box
        marginLeft={-3.5}
        display={'flex'}
        flexDirection={'row'}
        gridGap={4}
        alignItems={'center'}
      >
        <Icon style={{ color: theme.palette.grey[400] }} />
        {body}
      </Box>
    )
  }

  return body
}

const useStyles = makeStyles(function (theme) {
  return {
    root: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'flex-end',
      maxWidth: '56ch',
      gap: theme.spacing(2),
      maxHeight: theme.spacing(3),
    },

    label: {
      ...typeScale(-0.75),
      color: theme.palette.grey[800],
    },

    actions: {
      marginLeft: 'auto',
      maxHeight: theme.spacing(3),
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      position: 'relative',
      top: theme.spacing(0.5),
    },
  }
})

type CustomLabelProps = PropsWithChildren<{
  actions?: ReactNode
  fullWidth?: boolean
}>

export function CustomLabel(props: CustomLabelProps) {
  const { actions, children, fullWidth } = props
  const styles = useStyles()

  return (
    <div className={styles.root} style={fullWidth ? { maxWidth: '100%' } : {}}>
      <Typography variant="body1" className={styles.label}>
        {children}
      </Typography>
      {actions && <div className={styles.actions}>{actions}</div>}
    </div>
  )
}
