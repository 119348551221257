import type { Theme } from '@material-ui/core'
import { Link, Box } from '@material-ui/core'
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt'
import { makeStyles } from '@material-ui/styles'
import { useRecordContext, useTranslate } from 'react-admin'
import { MinioFileField } from 'src/components/MinioFileField'
import type { Document } from 'src/types/api'

const useStyles = makeStyles(function (theme: Theme) {
  const color = theme.palette.primary.main
  return {
    icon: { color },
    fileField: {
      '& a': { textDecoration: 'none', color },
    },
  }
})

interface ShowDocumentButtonProps {
  title?: string
}

export function ShowDocumentButton(props: ShowDocumentButtonProps) {
  const { title } = props
  const document = useRecordContext<Document>()
  const translate = useTranslate()
  const { fileField, icon } = useStyles()
  return (
    <Box
      display="flex"
      alignItems="center"
      onClick={(e: any) => {
        e.stopPropagation()
      }}
    >
      {document.isUrl ? (
        <Link
          className={fileField}
          href={document.fileName}
          target="_blank"
          rel="noreferrer"
        >
          {translate(title || 'actions.show')}
        </Link>
      ) : (
        <MinioFileField
          className={fileField}
          title={translate(title || 'actions.show')}
          fileName={document?.fileName || ''}
          bucketId={document?.client || ''}
        />
      )}
      <ArrowRightAltIcon className={icon} fontSize="small" />
    </Box>
  )
}
