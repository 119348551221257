import { createContext, useContext } from 'react'

import type {
  BarCodePlugin,
  MediaPlugin,
  NfcPlugin,
  SignaturePlugin,
  MyKeyboardPlugin
} from './types'

export * from './types'

export interface Plugins {
  BarCodePlugin: BarCodePlugin
  MediaPlugin: MediaPlugin
  NfcPlugin: NfcPlugin
  SignaturePlugin: SignaturePlugin
  MyKeyboardPlugin: MyKeyboardPlugin
}

export const PluginsContext = createContext<null | Plugins>(null)

export function usePlugins() {
  const plugins = useContext(PluginsContext)
  if (!plugins) throw Error('Accessing plugins outside of PluginsContext')

  return plugins
}
