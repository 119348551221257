import { makeStyles } from '@material-ui/core'

import { bodyS, bodySBold, microButton, ruleShadow } from '../theme/mixins'

export const useEditorStyle = makeStyles(function (theme) {
  return {
    root: {
      display: 'flex',
      alignItems: 'center',
      backgroundColor: theme.palette.secondary.contrastText,
      overflowX: 'auto',
      color: theme.palette.grey['800'],
      marginBottom: theme.spacing(1),
      ...ruleShadow(),
      position: 'relative',
      paddingRight: theme.spacing(2),
    },
    then: {
      ...bodyS(),
      display: 'grid',
      gridTemplateColumns: '50px fit-content(200px) ',
      alignItems: 'center',
    },
    wrapper: {
      display: 'flex',
      flexDirection: 'column',
    },
    select: {
      backgroundColor: theme.palette.primary.main,
      padding: theme.spacing(0.5),
      color: theme.palette.secondary.contrastText,
    },
    condition: {
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
      gap: theme.spacing(1),
      padding: theme.spacing(1),
      position: 'relative',
      margin: theme.spacing(0.5),
      backgroundColor: theme.palette.secondary.contrastText,
    },
    error: {
      border: `1px solid ${theme.palette.error.main}`,
    },
    operatorComponent: {
      backgroundColor: theme.palette.warning.main,
      color: theme.palette.grey['900'],
      padding: theme.spacing(0.5),
      display: 'flex',
      alignItems: 'center',
      gap: theme.spacing(0.5),
      borderRadius: '1px',
    },
    dndButton: {
      position: 'absolute',
      top: `calc(50% - 12px)`,
      left: '-24px',
      color: theme.palette.grey['200'],
    },
    case: {
      display: 'grid',
      gridTemplateColumns: '50px fit-content(200px)',
      ...bodySBold(),
      alignItems: 'center',
      color: theme.palette.grey['800'],
      borderRadius: '1px',
    },
    addRule: {
      color: theme.palette.primary.main,
      width: 'fit-content',
      padding: 0,
      display: 'flex',
      gap: theme.spacing(10),
      ...microButton(),
      '&:hover': {
        backgroundColor: 'transparent',
        color: theme.palette.primary.dark,
      },
    },
    deleteButtonIcon: {
      color: theme.palette.grey[600],
      position: 'absolute',
      top: theme.spacing(1),
      padding: 0,
      right: theme.spacing(1),
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
    addButtonIcon: {
      marginRight: theme.spacing(1),
    },
  }
})
