import { useTranslate } from 'react-admin'
import type { SfmListProps } from 'src/AppScopes'
import { SfmList } from 'src/AppScopes'
import { AddressField } from 'src/components/AddressField'
import { CustomDatagrid } from 'src/components/CustomDatagrid'
import { SfmTextField, SfmEmailField } from 'src/components/SfmFields'

import { PhoneNumbersField } from '../../components/PhoneNumbersField'

export function List(props: SfmListProps) {
  const __ = useTranslate()

  return (
    <SfmList {...props}>
      <CustomDatagrid>
        <SfmTextField source="name" label="resource.contacts.name" />
        <SfmTextField
          source="serviceName"
          label="resource.contacts.serviceName"
        />
        <SfmEmailField source="emailAddress" label="resource.contacts.email" />
        <PhoneNumbersField label="resource.contacts.phoneNumbers" />
        <AddressField source="address" label="resource.contacts.address" />
      </CustomDatagrid>
    </SfmList>
  )
}
