import type { SfmResourceProps } from 'src/AppScopes'
import { SfmResource } from 'src/AppScopes'

import { DocumentCreate } from './Create'
import { DocumentEdit } from './Edit'
import { DocumentList } from './List'
import { DocumentShow } from './Show'

export function Resource(props: SfmResourceProps) {
  return (
    <SfmResource
      {...props}
      list={DocumentList}
      create={DocumentCreate}
      show={DocumentShow}
      edit={DocumentEdit}
    />
  )
}
